@charset "utf-8";
/* RESPONSIVE MEDIA QUERIES - FIDELIZARTE */


@media (min-width: 1367px) {
    .jumbotron.jumbotron-product-detail {
        min-height: 425px;
    }
}

@media (min-width: 1025px) {
    .breakpoint-off .classynav ul li .megamenu {
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 1024px) {
    .jumbotron.jumbotron-product-detail {
        background-size: cover;
    }

    .header-area .top-header-area .top-social-info a {
        margin-right: 20px;
    }
}

@media (min-width: 993px) and (max-width: 1024px) {
    #deliciousNav .num-items-in-cart .title_cart {
        display: none;
    }
}

@media (max-width: 768px) {
    .filter_c {
        height: 95px;
    }

    /* Menu */
    #deliciousNav {
        display: block !important;
    }

    #deliciousNav::after {
        content: '';
        display: table;
        clear: both;
    }

    #deliciousNav .nav-brand,
    #deliciousNav .classy-navbar-toggler-container {
        display: block;
        box-sizing: border-box;
        width: 50%;
        float: left;
    }

    #deliciousNav .nav-brand {
        width: 30%;
    }

    #deliciousNav .classy-navbar-toggler-container {
        width: 70%;
    }

    #deliciousNav .nav-brand img {
        margin-top: 10px;
        max-width: 100%;
        width: 100%;
        display: block;
    }

    #deliciousNav .classy-navbar-toggler-container {
        padding-top: 15px;
        text-align: right;
        line-height: 40px;
    }

    .header-area .delicious-main-menu {
        height: 115px;
    }
}

@media (max-width: 480px) {
    body .related-products .page-title {
        height: 90px;
    }

    .price span.previous-price {
        position: relative;
        right: initial;
        top: initial;
    }

    #deliciousNav .nav-brand,
    #deliciousNav .classy-navbar-toggler-container {
        width: 50%;
    }

    .header-area .delicious-main-menu {
        height: 80px;
    }

    .owl-dots .owl-dot .fas,
    .owl-dots .owl-dot .fa,
    .owl-dots .owl-dot .far {
        font-size: 12px;
    }

    .hero-slides .owl-dots {
        bottom: 15px;
        width: 30%;
        left: initial;
        right: 0;
    }

    .hero-slides .single-hero-slide .hero-slides-content p {
        margin-bottom: 20px;
    }

    .hero-slides.swiper-container .swiper-button-prev,
    .hero-slides.swiper-container .swiper-button-next {
        top: initial;
        bottom: -30px;
    }

    .hero-slides.swiper-container .swiper-button-prev {
        left: -5px;
    }

    .hero-slides.swiper-container .swiper-button-next {
        right: -5px;
    }

    .hero-area,
    .hero-slides {
        width: 100%;
    }
}

@media (min-width: 0px) and (max-width: 380px) {
    .hero-slides .single-hero-slide {
        height: 360px;
    }
}

@media (min-width: 381px) and (max-width: 480px) {
    .hero-slides .single-hero-slide {
        height: 400px;
    }
}
