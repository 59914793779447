@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    to {
        -webkit-transform: rotateY(365deg);
        transform: rotateY(365deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    to {
        -webkit-transform: rotateY(365deg);
        transform: rotateY(365deg);
    }
}

@-webkit-keyframes rotationxy {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(365deg);
        transform: rotate(365deg);
    }
}

@keyframes rotationxy {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(365deg);
        transform: rotate(365deg);
    }
}

@-webkit-keyframes rotation1 {
    from {
        -webkit-transform: rotate(-4deg);
        transform: rotate(-4deg);
    }

    to {
        -webkit-transform: rotate(4deg);
        transform: rotate(4deg);
    }
}

@keyframes rotation1 {
    from {
        -webkit-transform: rotate(-4deg);
        transform: rotate(-4deg);
    }

    to {
        -webkit-transform: rotate(4deg);
        transform: rotate(4deg);
    }
}

@-webkit-keyframes moveleft {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px);
    }

    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes moveleft {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px);
    }

    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@-webkit-keyframes moveboll {
    from {
        left: 0;
    }

    to {
        left: 100%;
    }
}

@keyframes moveboll {
    from {
        left: 0;
    }

    to {
        left: 100%;
    }
}

@-webkit-keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

@keyframes bounce {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

@-webkit-keyframes bounce1 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-30px) translateX(50px) rotateX(20deg);
        transform: translateY(-30px) translateX(50px) rotateX(20deg);
    }

    100% {
        -webkit-transform: translateY(-60px) translateX(90px) rotateX(30deg);
        transform: translateY(-60px) translateX(90px) rotateX(30deg);
    }
}

@keyframes bounce1 {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    50% {
        -webkit-transform: translateY(-30px) translateX(50px) rotateX(20deg);
        transform: translateY(-30px) translateX(50px) rotateX(20deg);
    }

    100% {
        -webkit-transform: translateY(-60px) translateX(90px) rotateX(30deg);
        transform: translateY(-60px) translateX(90px) rotateX(30deg);
    }
}

@-webkit-keyframes move1 {
    0% {
        -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg);
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(260deg) translateX(10px) rotate(-260deg);
        transform: rotate(260deg) translateX(10px) rotate(-260deg);
    }
}

@keyframes move1 {
    0% {
        -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg);
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(260deg) translateX(10px) rotate(-260deg);
        transform: rotate(260deg) translateX(10px) rotate(-260deg);
    }
}

@-webkit-keyframes move2 {
    0% {
        -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg);
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg) translateX(10px) rotate(-360deg);
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}

@keyframes move2 {
    0% {
        -webkit-transform: rotate(0deg) translateX(10px) rotate(0deg);
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg) translateX(10px) rotate(-360deg);
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}

@-webkit-keyframes move3 {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    100% {
        -webkit-transform: translateX(-300px) rotate(-365deg);
        transform: translateX(-300px) rotate(-365deg);
    }
}

@keyframes move3 {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    100% {
        -webkit-transform: translateX(-300px) rotate(-365deg);
        transform: translateX(-300px) rotate(-365deg);
    }
}

@-webkit-keyframes backanimat {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 3000px 0;
    }
}

@keyframes backanimat {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 3000px 0;
    }
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0);
    }

    1% {
        -webkit-transform: rotateZ(15deg);
        transform: rotateZ(15deg);
    }

    3% {
        -webkit-transform: rotateZ(-14deg);
        transform: rotateZ(-14deg);
    }

    5% {
        -webkit-transform: rotateZ(17deg);
        transform: rotateZ(17deg);
    }

    7% {
        -webkit-transform: rotateZ(-16deg);
        transform: rotateZ(-16deg);
    }

    9% {
        -webkit-transform: rotateZ(15deg);
        transform: rotateZ(15deg);
    }

    11% {
        -webkit-transform: rotateZ(-14deg);
        transform: rotateZ(-14deg);
    }

    13% {
        -webkit-transform: rotateZ(13deg);
        transform: rotateZ(13deg);
    }

    15% {
        -webkit-transform: rotateZ(-12deg);
        transform: rotateZ(-12deg);
    }

    17% {
        -webkit-transform: rotateZ(12deg);
        transform: rotateZ(12deg);
    }

    19% {
        -webkit-transform: rotateZ(-10deg);
        transform: rotateZ(-10deg);
    }

    21% {
        -webkit-transform: rotateZ(9deg);
        transform: rotateZ(9deg);
    }

    23% {
        -webkit-transform: rotateZ(-8deg);
        transform: rotateZ(-8deg);
    }

    25% {
        -webkit-transform: rotateZ(7deg);
        transform: rotateZ(7deg);
    }

    27% {
        -webkit-transform: rotateZ(-5deg);
        transform: rotateZ(-5deg);
    }

    29% {
        -webkit-transform: rotateZ(5deg);
        transform: rotateZ(5deg);
    }

    31% {
        -webkit-transform: rotateZ(-4deg);
        transform: rotateZ(-4deg);
    }

    33% {
        -webkit-transform: rotateZ(3deg);
        transform: rotateZ(3deg);
    }

    35% {
        -webkit-transform: rotateZ(-2deg);
        transform: rotateZ(-2deg);
    }

    37% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg);
    }

    39% {
        -webkit-transform: rotateZ(-1deg);
        transform: rotateZ(-1deg);
    }

    41% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg);
    }

    43% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0);
    }

    100% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0);
    }
}

@keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0);
    }

    1% {
        -webkit-transform: rotateZ(15deg);
        transform: rotateZ(15deg);
    }

    3% {
        -webkit-transform: rotateZ(-14deg);
        transform: rotateZ(-14deg);
    }

    5% {
        -webkit-transform: rotateZ(17deg);
        transform: rotateZ(17deg);
    }

    7% {
        -webkit-transform: rotateZ(-16deg);
        transform: rotateZ(-16deg);
    }

    9% {
        -webkit-transform: rotateZ(15deg);
        transform: rotateZ(15deg);
    }

    11% {
        -webkit-transform: rotateZ(-14deg);
        transform: rotateZ(-14deg);
    }

    13% {
        -webkit-transform: rotateZ(13deg);
        transform: rotateZ(13deg);
    }

    15% {
        -webkit-transform: rotateZ(-12deg);
        transform: rotateZ(-12deg);
    }

    17% {
        -webkit-transform: rotateZ(12deg);
        transform: rotateZ(12deg);
    }

    19% {
        -webkit-transform: rotateZ(-10deg);
        transform: rotateZ(-10deg);
    }

    21% {
        -webkit-transform: rotateZ(9deg);
        transform: rotateZ(9deg);
    }

    23% {
        -webkit-transform: rotateZ(-8deg);
        transform: rotateZ(-8deg);
    }

    25% {
        -webkit-transform: rotateZ(7deg);
        transform: rotateZ(7deg);
    }

    27% {
        -webkit-transform: rotateZ(-5deg);
        transform: rotateZ(-5deg);
    }

    29% {
        -webkit-transform: rotateZ(5deg);
        transform: rotateZ(5deg);
    }

    31% {
        -webkit-transform: rotateZ(-4deg);
        transform: rotateZ(-4deg);
    }

    33% {
        -webkit-transform: rotateZ(3deg);
        transform: rotateZ(3deg);
    }

    35% {
        -webkit-transform: rotateZ(-2deg);
        transform: rotateZ(-2deg);
    }

    37% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg);
    }

    39% {
        -webkit-transform: rotateZ(-1deg);
        transform: rotateZ(-1deg);
    }

    41% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg);
    }

    43% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0);
    }

    100% {
        -webkit-transform: rotateZ(0);
        transform: rotateZ(0);
    }
}

@-webkit-keyframes o-rotate-360 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes o-rotate-360 {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/*-----------------------------------------------------------------------------------

    Template Name: Bigdeal
    Template URI: themes.pixelstrap.com/bigdeal
    Description: This is E-commerce Theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap
----------------------------------------------------------------------------------- */
/*-----------------------------------------------------------------------------------
1.General CSS start
  1.1.Button CSS start
2.Services CSS start
3.Instagram CSS start
4. Brand panel CSS start
5. Testimonial CSS start
6. Offer-banner CSS start
7. Deal banner CSS start
8. Discount banner CSS start
9. Contact banner CSS start
10. Rounded category CSS start
11. Box category CSS start
12.Blog CSS start
13. Collection banner CSS start
14. Product CSS start
15. Hot Deal CSS start
16. Header CSS start
17. Media Banner start
18. Footer start
19.  Slider start
20. Title css
21. Tab-product css
22. Newsleatter css
23. breadcrumb CSS start
24. payment offer CSS start
25.Inner pages CSS start
26.add to cart CSS start
27.add to account bar css start
28. Tab to top CSS start
29. Modal CSS start
30. Loader css start
31. masonory-banner css start
31. masonory-banner css start
----------------------------------------------------------------------------------- */
/*=====================
  1.General CSS start
==========================*/
html {
    scroll-behavior: smooth;
}

body {
    font-family: "PT Sans", sans-serif;
    font-size: 14px;
    color: #777;
    background-color: rgba(199, 109, 98, 0.5);
}

h1 {
    font-weight: 700;
    margin-bottom: 0;
    font-size: calc(30px + (72 - 30) * ((100vw - 320px) / (1920 - 320)));
}

h2 {
    font-weight: 400;
    margin-bottom: 0;
    font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
}

h3 {
    font-weight: 700;
    margin-bottom: 0;
    font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
}

h4 {
    font-weight: 700;
    margin-bottom: 0;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

h5 {
    font-weight: 700;
    margin-bottom: 0;
    font-size: 16px;
}

h6 {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 400;
}

.form-group {
    margin-bottom: 15px;
}

label {
    color: #333333;
    margin-bottom: 10px;
}

textarea,
input {
    letter-spacing: 0.05em;
}

ul {
    padding-left: 0;
    margin-bottom: 0;
}

::-moz-selection {
    background-color: #042c49;
    color: #ef9f77;
}

::selection {
    background-color: #042c49;
    color: #ef9f77;
}

.small-section {
    padding-top: 35px;
    padding-bottom: 35px;
}

li {
    display: inline-block;
}

.o-hidden {
    overflow: hidden;
}

p {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 0;
    letter-spacing: 0.05em;
}

a {
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
    text-decoration: none;
    color: #042c49;
}

a:hover {
    text-decoration: none;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

a:focus {
    outline: none;
}

button:focus {
    outline: none;
}

.alert {
    border-radius: 0;
    // background-color: white;
    // border: 1px solid #5cb85c;
}

.alert span {
    color: #5cb85c;
}

.alert .progress {
    margin-top: 5px;
    Height: 10px;
}

.alert .progress-bar {
    background-color: #5cb85c;
    Height: 10px;
}

.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.media .media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.btn-close:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.section-pt-space {
    padding-top: 15px;
}

.section-pb-space {
    padding-bottom: 15px;
}

.section-py-space {
    padding: 15px 0;
}

.section-big-pt-space {
    padding-top: 50px;
}

.section-big-pb-space {
    padding-bottom: 50px;
}

.section-big-py-space {
    padding-top: 50px;
    padding-bottom: 50px;
}

.section-mb-space {
    margin-bottom: 15px;
}

.section-big-my-space {
    margin-top: 50px;
    margin-bottom: 50px;
}

.section-my-space {
    margin: 15px 0;
}

.mb--5 {
    margin-bottom: -5px;
}

.mb--10 {
    margin-bottom: -10px;
}

.mt--5 {
    margin-top: -5px;
}

.section-mt-space {
    margin-top: 15px;
}

.section-big-mt-space {
    margin-top: 50px;
}

.section-big-mb-space {
    margin-bottom: 50px;
}

.gutter-15 .col-sm-12,
.gutter-15 .col-sm-6,
.gutter-15 .col-md-12 {
    padding-right: 7.5px;
    padding-left: 7.5px;
}

.gutter-15 .masonary-banner-block2 .col-sm-6 {
    padding-left: 15px;
}

.bg-light {
    background-color: rgba(199, 109, 98, 0.05) !important;
}

.b-g-light {
    background-color: #f9f9f9;
}

.no-arrow>.slick-next,
.no-arrow>.slick-prev {
    display: none !important;
}

.arrow-1 .slick-next,
.arrow-1 .slick-prev {
    background-color: #042c49;
    z-index: 1;
    height: 35px;
    font-weight: 700;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.arrow-1 .slick-next::before,
.arrow-1 .slick-prev::before {
    content: "\f105";
    color: #ffffff;
    opacity: 1;
    font-family: FontAwesome;
}

.arrow-1 .slick-next {
    right: 0;
}

.arrow-1 .slick-prev {
    left: 0;
}

.arrow-1:hover .slick-prev,
.arrow-1:hover .slick-next {
    opacity: 1;
    visibility: visible;
}

.no-dots .slick-dots {
    display: none !important;
}

.timer span {
    display: inline-grid;
    background-color: #ffffff;
    padding: 12px 0 18px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    margin: 0 8px;
    min-width: 70px;
}

.timer span:first-child {
    margin-left: 0;
}

.timer span:last-child {
    margin-right: 0;
}

.timer span .padding-l {
    display: none;
}

.timer span .timer-cal {
    font-size: 12px;
    color: #777777;
}

.timer span>span {
    color: #e8ebf2;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-left: 0;
    /*text-transform: capitalize;*/
}

.timer p {
    margin-bottom: 0;
}

.txt-default {
    color: #042c49;
}

.bg-light0 {
    background-color: #f1e7e6;
}

.bg-light1 {
    background-color: #d0edff;
}

.bg-light2 {
    background-color: #bfbfbf;
}

.b-g-white {
    background-color: #ffffff !important;
}

.bg-silver {
    background-color: #f2f2f2 !important;
}

.fb-color {
    background-color: #39539a;
}

.twt-color {
    background-color: #2fb0f6;
}

.indid-color {
    background-color: #2164f3;
}

.print-color {
    background-color: #de0422;
}

.insta-color {
    background-color: #c42253;
}

.color-variant li {
    margin-right: 5px;
    border-radius: 50%;
}

.ratio_40 .bg-size:before {
    padding-top: 40%;
    content: "";
    display: block;
}

.ratio_45 .bg-size:before {
    padding-top: 45%;
    content: "";
    display: block;
}

.ratio2_1 .bg-size:before {
    padding-top: 50%;
    content: "";
    display: block;
}

.ratio2_3 .bg-size:before {
    padding-top: 60%;
    content: "";
    display: block;
}

.ratio3_2 .bg-size:before {
    padding-top: 66.66%;
    content: "";
    display: block;
}

.ratio_landscape .bg-size:before {
    padding-top: 75%;
    content: "";
    display: block;
}

.ratio_square .bg-size:before {
    padding-top: 100%;
    content: "";
    display: block;
}

.ratio_asos .bg-size:before {
    padding-top: 127.7777778%;
    content: "";
    display: block;
}

.ratio_portrait .bg-size:before {
    padding-top: 150%;
    content: "";
    display: block;
}

.ratio1_2 .bg-size:before {
    padding-top: 200%;
    content: "";
    display: block;
}

.b-top {
    background-position: top !important;
}

.b-bottom {
    background-position: bottom !important;
}

.b-center {
    background-position: center !important;
}

.b_size_content {
    background-size: contain !important;
    background-repeat: no-repeat;
}

.light-layout {
    background-color: #f1f1f1;
}

.b-g-white {
    background-color: #ffffff;
}

.b-g-white1 {
    background-color: #ffffff !important;
}

button {
    cursor: pointer;
}

.btn-block {
    width: 100%;
}

.btn {
    /*text-transform: capitalize;*/
}

.btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-rounded {
    font-size: 14px;
    padding: 18px 50px;
    font-weight: 700;
    color: #ffffff !important;
    background-color: #042c49;
    border-radius: 25px;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 1;
    display: inline-block;
    letter-spacing: 0.05em;
}

.btn-rounded.btn-outline {
    background-color: #ffffff;
    border: 1px solid #042c49;
    color: #444444 !important;
}

.btn-rounded.btn-outline:hover {
    background-color: #042c49;
    color: #ffffff !important;
}

.btn-rounded:hover {
    background-color: #000000;
}

.btn-rounded.btn-xs {
    padding: 10px 15px;
    /*text-transform: capitalize;*/
}

.btn-rounded.btn-sm {
    padding: 10px 30px;
    /*text-transform: capitalize;*/
}

.btn-rounded.btn-md {
    padding: 15px 40px;
}

.btn-white {
    font-size: 14px;
    padding: 16px 45px;
    font-weight: 700;
    color: #444444 !important;
    background-color: #ffffff;
    border-radius: 25px;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: inline-block;
    line-height: 1;
    letter-spacing: 0.05em;
}

.btn-white.btn-outline {
    background-color: transparent;
    border: 1px solid #042c49;
}

.btn-white.btn-outline:hover {
    background-color: #042c49;
}

.btn-white:hover {
    background-color: #000000;
    color: #ffffff !important;
}

.btn-normal {
    font-size: 14px;
    padding: 18px 45px;
    font-weight: 700;
    color: #ffffff !important;
    background-color: #042c49;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: inline-block;
    line-height: 1;
    border-radius: 0;
    letter-spacing: 0.05em;
}

.btn-normal.btn-outline {
    background-color: #ffffff;
    border: 1px solid #042c49;
    color: #444444 !important;
}

.btn-normal.btn-outline:hover {
    background-color: #042c49;
    color: #ffffff !important;
}

.btn-normal:hover {
    background-color: #000000;
}

.btn-normal.btn-md {
    padding: 15px 30px;
    /*text-transform: capitalize;*/
}

.btn-normal.btn-xs {
    padding: 10px 15px;
    /*text-transform: capitalize;*/
}

.btn-solid {
    font-size: 16px;
    padding: 18px 45px;
    font-weight: 700;
    color: #ffffff !important;
    background-color: #042c49;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: inline-block;
    line-height: 1;
    letter-spacing: 0.05em;
    border-radius: 5px;
}

.btn-solid.btn-sm {
    padding: 10px 20px;
}

.btn-solid.btn-md {
    padding: 13px 25px;
}

.btn-solid.btn-xs {
    padding: 5px 13px;
    font-size: 14px;
}

.btn-solid.btn-outline {
    background-color: #ffffff;
    border: 2px solid #042c49;
    color: #444444 !important;
}

.btn-solid.btn-outline:hover {
    background-color: #042c49;
    color: #ffffff !important;
}

/*=====================
  2.Services CSS start
==========================*/
.services {
    background-color: #ef9f77;
}

.services.services-inverse {
    background-color: #042c49;
}

.services .service-block>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 23px 0;
}

.services .service-block .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.services .service-block .media svg {
    width: 30px;
    height: 30px;
    fill: #ffffff;
    margin-right: 12px;
    -webkit-transition: 0.5s linear;
    transition: 0.5s linear;
}

.services .service-block .media h5 {
    color: #ffffff;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0.05em;
}

.services .service-block .media:hover svg {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.services.bg-light .service-block .media svg {
    fill: #042c49;
}

.services.bg-light .service-block .media h5 {
    color: #444444;
}

.services1 .services-box {
    background-color: #ffffff;
    padding-top: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.services1 .services-box:hover .icon-wrraper {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.services1 .services-box .media .icon-wrraper {
    width: calc(35px + (60 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (60 - 35) * ((100vw - 320px) / (1920 - 320)));
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 50%;
    background-color: #042c49;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.services1 .services-box .media .icon-wrraper svg {
    fill: #ffffff;
    width: 35%;
    margin-bottom: 0 !important;
}

.services1 .services-box .media svg {
    width: calc(35px + (40 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    fill: #042c49;
}

.services1 .services-box .media .media-body {
    margin-left: 15px;
}

.services1 .services-box .media .media-body h4 {
    /*text-transform: capitalize;*/
    margin-bottom: 5px;
}

.services1 .services-box .media .media-body p {
    /*text-transform: capitalize;*/
}

.services1 .services-box .media .media-body .btn {
    /*text-transform: capitalize;*/
    color: #ef9f77;
    line-height: 1;
    padding: 0;
    margin-top: 15px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.services1 .services-box .media .media-body .btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.services1.block .services-box .media {
    display: block;
    text-align: center;
}

.services1.block .services-box .media svg {
    margin-bottom: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.services1.block .services-box .media .media-body {
    padding-right: 15px;
    padding-left: 15px;
    margin-left: 0;
}

.services1.style2 .services-box {
    padding-left: 10px;
    padding-right: 10px;
}

.services1.style2 .services-box .media .icon-wrraper {
    width: calc(35px + (80 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (80 - 35) * ((100vw - 320px) / (1920 - 320)));
}

.services1.style2 .services-box .media .icon-wrraper svg {
    margin-bottom: 15px;
}

.services1.style2 .services-box .media .media-body h4 {
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 5px;
}

.services1.style2 .services-box .media .media-body p {
    font-size: 14px;
    line-height: 1.6;
}

.services1.border-services .services-box {
    border: 1px solid #dddddd;
    margin-left: 15px;
    margin-right: 15px;
}

.services1.border-services .services-box .media .icon-wrraper {
    background-color: #ffffff;
    height: auto;
}

.services1.border-services .services-box .media .icon-wrraper svg {
    fill: #042c49;
    width: calc(35px + (55 - 35) * ((100vw - 320px) / (1920 - 320)));
}

.services1 .service-block>div {
    padding-left: 0;
    padding-right: 0;
}

.services1.services-transparent .services-box {
    background-color: transparent;
}

.services2 .services-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    /*text-transform: capitalize;*/
    border-radius: 5px;
    background-color: #ffffff;
    font-size: 16px;
    margin-right: 15px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow: hidden;
}

.services2 .services-box svg {
    width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
    margin-right: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.services2 .services-box:hover {
    background-color: rgba(199, 109, 98, 0.8);
    color: #ffffff;
}

/*=====================
   3.Instagram CSS start
==========================*/
.instagram .insta-contant {
    border: 15px solid #ffffff;
    line-height: 0;
    position: relative;
}

.instagram .insta-contant .instagram-box {
    position: relative;
    overflow: hidden;
}

.instagram .insta-contant .instagram-box img {
    width: 100%;
}

.instagram .insta-contant .instagram-box .insta-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(199, 109, 98, 0.7);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
}

.instagram .insta-contant .instagram-box .insta-cover i {
    font-size: 20px;
    color: #ffffff;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: rotate(-217deg);
    transform: rotate(-217deg);
}

.instagram .insta-contant .instagram-box:hover .insta-cover {
    opacity: 1;
}

.instagram .insta-contant .instagram-box:hover .insta-cover i {
    -webkit-transform: scale(3) rotate(-360deg);
    transform: scale(3) rotate(-360deg);
}

.instagram .insta-contant .insta-sub-contant {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.instagram .insta-contant .insta-sub-contant .insta-title {
    background-color: #ffffff;
    padding: 20px 30px;
    width: auto;
}

.instagram .insta-contant .insta-sub-contant .insta-title h4 {
    color: #444444;
}

.instagram .insta-contant .insta-sub-contant .insta-title h4 span {
    margin-right: 10px;
}

.instagram .insta-contant.insta-contant-inverse {
    border-color: #f2f2f2;
}

.instagram .insta-contant1 {
    line-height: 0;
}

.instagram .insta-contant1 .instagram-box {
    position: relative;
    overflow: hidden;
}

.instagram .insta-contant1 .instagram-box .insta-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(199, 109, 98, 0.7);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
}

.instagram .insta-contant1 .instagram-box .insta-cover i {
    font-size: 20px;
    color: #ffffff;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.instagram .insta-contant1 .instagram-box:hover .insta-cover {
    opacity: 1;
}

.instagram .insta-contant1 .instagram-box:hover .insta-cover i {
    -webkit-transform: scale(3) rotate(-360deg);
    transform: scale(3) rotate(-360deg);
}

.instagram .insta-contant1 .insta-sub-contant1 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.instagram .insta-contant1 .insta-sub-contant1 .insta-title {
    background-color: #ffffff;
    padding: 18px 80px;
    text-align: center;
}

.instagram .insta-contant1 .insta-sub-contant1 .insta-title h4 {
    color: #444444;
}

.instagram .insta-contant1 .insta-sub-contant1 .insta-title h4 span {
    margin-right: 10px;
}

.instagram .insta-contant2 {
    border: 15px solid #ffffff;
    position: relative;
    line-height: 0;
}

.instagram .insta-contant2 .instagram-box {
    position: relative;
    overflow: hidden;
}

.instagram .insta-contant2 .instagram-box .insta-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(199, 109, 98, 0.7);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
}

.instagram .insta-contant2 .instagram-box .insta-cover i {
    font-size: 20px;
    color: #ffffff;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.instagram .insta-contant2 .instagram-box:hover .insta-cover {
    opacity: 1;
}

.instagram .insta-contant2 .instagram-box:hover .insta-cover i {
    -webkit-transform: scale(3) rotate(-360deg);
    transform: scale(3) rotate(-360deg);
}

.instagram .insta-contant2 .insta-sub-contant2 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.instagram .insta-contant2 .insta-sub-contant2 .insta-title {
    background-color: #ffffff;
    padding: 12px 22px;
    width: auto;
    text-align: center;
}

.instagram .insta-contant2 .insta-sub-contant2 .insta-title h4 {
    color: #444444;
}

.instagram .insta-contant2 .insta-sub-contant2 .insta-title h4 span {
    margin-right: 10px;
}

/*=====================
   4. Brand panel CSS start
==========================*/
.brand-panel .brand-panel-box {
    background-color: #ffffff;
}

.brand-panel .brand-panel-box .brand-panel-contain {
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

.brand-panel .brand-panel-box .brand-panel-contain ul li {
    padding: 0 10px;
    text-transform: uppercase;
}

.brand-panel .brand-panel-box .brand-panel-contain ul li a {
    color: #777777;
    letter-spacing: 0.05em;
}

.brand-panel .brand-panel-box .brand-panel-contain ul li:first-child a {
    color: #042c49;
    font-weight: 700;
}

.brand-panel .brand-panel-box .brand-panel-contain ul li:hover {
    color: #042c49;
    cursor: pointer;
}

.brand-block>div {
    padding-left: 8px;
    padding-right: 8px;
}

.brand-second .brand-box {
    background-color: #ffffff;
    padding: 30px;
    margin-right: 8px;
    margin-left: 8px;
}

.brand-second .brand-box img {
    margin-left: auto;
    margin-right: auto;
}

/*===========================
   5. Testimonial CSS start
=============================*/
.testimonial-block>div {
    padding-left: 0;
    padding-right: 0;
}

.testimonial {
    background-color: #ef9f77;
    background-position: center;
}

.testimonial.testimonial-inverse {
    background: #042c49;
}

.testimonial .testimonial-contain {
    padding: 50px 0;
    max-width: 840px;
    margin: 0 auto;
}

.testimonial .testimonial-contain .media .testimonial-img {
    width: 130px;
    height: 130px;
    border: 2px solid #ffffff;
    padding: 5px;
    border-radius: 50%;
}

.testimonial .testimonial-contain .media .media-body {
    color: #444444;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 20px 0 0 40px;
}

.testimonial .testimonial-contain .media .media-body h5 {
    text-transform: uppercase;
    margin-bottom: 5px;
    line-height: 1;
    letter-spacing: 0.8px;
}

.testimonial .testimonial-contain .media .media-body p {
    letter-spacing: 1px;
    line-height: 1.6;
}

.testimonial.block .testimonial-contain .media {
    display: block;
    text-align: center;
}

.testimonial.block .testimonial-contain .media .testimonial-img {
    margin-left: auto;
    margin-right: auto;
}

.testimonial.block .testimonial-contain .media-body {
    margin-left: 0;
}

.testimonial.banner-style1 .testimonial-contain .media .media-body h5 {
    color: #042c49;
}

.testimonial.banner-style1 .testimonial-contain .media .media-body p {
    color: #000000;
    margin-top: 15px;
}

.testimonial2 .testimonial-box {
    background-color: #ffffff;
    padding-top: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 15px;
    text-align: center;
}

.testimonial2 .testimonial-box .img-wrapper {
    border-radius: 50%;
    overflow: hidden;
    width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
    height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.testimonial2 .testimonial-box .img-wrapper img {
    width: 100%;
    height: auto;
}

.testimonial2 .testimonial-box .testimonial-detail h3 {
    text-transform: uppercase;
    font-weight: 600;
    color: #042c49;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 3px;
}

.testimonial2 .testimonial-box .testimonial-detail p {
    /*text-transform: capitalize;*/
    margin-bottom: 14px;
    line-height: 1.6;
    color: #444444;
}

.testimonial2 .testimonial-box .testimonial-detail h6 {
    color: #ef9f77;
    /*text-transform: capitalize;*/
    font-weight: 600;
    font-size: 16px;
    // letter-spacing: 0.03em;
}

.testimonial3 .testimonial-box {
    background-color: #ffffff;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 15px;
    text-align: center;
}

.testimonial3 .testimonial-box ul li i {
    color: #ffa800;
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.testimonial3 .testimonial-box ul li:nth-child(n+2) {
    margin-left: 5px;
}

.testimonial3 .testimonial-box .img-wrapper {
    border-radius: 50%;
    overflow: hidden;
    width: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
    height: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 18px;
}

.testimonial3 .testimonial-box .img-wrapper img {
    width: 100%;
    height: auto;
}

.testimonial3 .testimonial-box .testimonial-detail p {
    /*text-transform: capitalize;*/
    margin-bottom: 10px;
    line-height: 1.8;
    color: #444444;
}

.testimonial3 .testimonial-box .testimonial-detail h3 {
    text-transform: uppercase;
    font-weight: 600;
    color: #042c49;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 10px;
}

.testimonial3 .testimonial-box .testimonial-detail h6 {
    color: #ef9f77;
    /*text-transform: capitalize;*/
    margin-bottom: 20px;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
}

.testimonial3.testimonial-border .testimonial-box {
    border: 1px solid #dddddd;
}

.testimonial4 .testimonial-box {
    background-color: #ffffff;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 15px;
    text-align: center;
}

.testimonial4 .testimonial-box .img-wrapper {
    border-radius: 50%;
    overflow: hidden;
    width: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
    height: calc(80px + (100 - 80) * ((100vw - 320px) / (1920 - 320)));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
}

.testimonial4 .testimonial-box .img-wrapper img {
    width: 100%;
    height: auto;
}

.testimonial4 .testimonial-box .testimonial-detail p {
    /*text-transform: capitalize;*/
    line-height: 1.6;
    color: #444444;
    margin-bottom: 13px;
}

.testimonial4 .testimonial-box .testimonial-detail h3 {
    text-transform: uppercase;
    font-weight: 600;
    color: #042c49;
    margin-bottom: 3px;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
}

.testimonial4 .testimonial-box .testimonial-detail h6 {
    color: #ef9f77;
    /*text-transform: capitalize;*/
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 18px;
}

.testimonial4 .testimonial-box .testimonial-detail ul {
    line-height: 1;
}

.testimonial4 .testimonial-box .testimonial-detail ul li i {
    color: #ffa800;
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.testimonial5 {
    padding-top: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    background-blend-mode: overlay;
    background-color: #3e3e3e;
}

.testimonial5:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.testimonial5 .testimonial-detail {
    background-color: rgba(0, 0, 0, 0.3);
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    position: relative;
    margin-bottom: 30px;
    border-radius: 5px;
}

.testimonial5 .testimonial-detail::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 30px solid rgba(0, 0, 0, 0.3);
}

.testimonial5 .testimonial-detail h3 {
    color: #ffffff;
    text-transform: uppercase;
}

.testimonial5 .testimonial-detail h4 {
    color: #ffffff;
    /*text-transform: capitalize;*/
    margin-bottom: 10px;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.testimonial5 .testimonial-detail p {
    color: #ffffff;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.6;
}

.testimonial5 .testimonial-detail ul li i {
    color: #ffa800;
}

.testimonial5 .img-wrraper-mian {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.testimonial5 .img-wrraper-mian .img-wrraper {
    border-radius: 50%;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
}

.testimonial6 .testimonial-box {
    background-color: #f2f2f2;
    padding: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 15px;
    margin-left: 15px;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.testimonial6 .testimonial-box .img-wrapper {
    border-radius: 50%;
    width: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
    height: calc(60px + (80 - 60) * ((100vw - 320px) / (1920 - 320)));
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    margin-bottom: 15px;
}

.testimonial6 .testimonial-box .testimonial-detail h3 {
    text-transform: uppercase;
    font-weight: 600;
    color: #042c49;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.testimonial6 .testimonial-box .testimonial-detail h6 {
    color: #ef9f77;
    /*text-transform: capitalize;*/
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    margin-bottom: 11px;
}

.testimonial6 .testimonial-box .testimonial-detail .quate {
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0.06;
}

.testimonial6 .testimonial-box .testimonial-detail .quate i {
    color: #ffffff;
    width: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
    height: calc(50px + (70 - 50) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 50%;
    background-color: #ef9f77;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.testimonial6 .testimonial-box .testimonial-detail p {
    /*text-transform: capitalize;*/
    line-height: 1.6;
    color: #444444;
    margin-bottom: 14px;
}

.testimonial6 .testimonial-box .testimonial-detail ul {
    line-height: 1;
}

.testimonial6 .testimonial-box .testimonial-detail ul li i {
    color: #ffa800;
    font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
}

/*=====================
   6. Offer-banner CSS start
==========================*/
.offer-banner {
    position: relative;
    overflow: hidden;
}

.offer-banner .banner-contain {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.offer-banner .banner-contain h5 {
    font-size: 18px;
    color: #ffffff;
    font-family: "Raleway", sans-serif, "PT Sans", sans-serif;
    letter-spacing: 0.05em;
    font-weight: 400;
    margin-top: 25px;
}

.offer-banner .banner-contain .discount-offer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.offer-banner .banner-contain .discount-offer h1 {
    color: #ffffff;
    line-height: 1;
    font-style: italic;
}

.offer-banner .banner-contain .discount-offer sup {
    font-size: 25px;
    color: #ffffff;
    line-height: 1;
    font-style: italic;
    font-weight: 700;
}

/*=====================
   7. Deal banner CSS start
==========================*/
.deal-banner {
    background-color: #042c49;
    padding: 40px 0;
}

.deal-banner .deal-banner-containe {
    height: 100%;
}

.deal-banner .deal-banner-containe h2 {
    font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 400;
    line-height: 1;
    margin-top: -4px;
    margin-bottom: 5px;
}

.deal-banner .deal-banner-containe h1 {
    font-size: calc(16px + (40 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0.5px;
}

.deal-banner .deal-banner-containe .deal-btn {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.deal-banner.deal-banner-inverse {
    background-color: #ef9f77;
}

/*=====================
   8. Discount banner CSS start
==========================*/
.discount-banner {
    background-color: #ffffff;
    padding-top: 47px;
    padding-bottom: 47px;
}

.discount-banner .discount-banner-contain {
    text-align: center;
}

.discount-banner .discount-banner-contain h2 {
    font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
    /*text-transform: capitalize;*/
    margin-bottom: 3px;
    margin-top: -5px;
}

.discount-banner .discount-banner-contain h1 {
    font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #444444;
}

.discount-banner .discount-banner-contain h1 span {
    color: #042c49;
}

.discount-banner .discount-banner-contain h1 span+span {
    color: #c1151b;
    font-weight: 700;
}

.discount-banner .discount-banner-contain .rounded-contain {
    border: 2px dashed #c1151b;
    border-radius: 50px;
    padding: 5px;
}

.discount-banner .discount-banner-contain .rounded-contain .rounded-subcontain {
    background-color: #042c49;
    color: #ffffff;
    text-transform: uppercase;
    padding: 18px 0;
    font-weight: 600;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: .08em;
    line-height: 1;
    border-radius: 50px;
}

.discount-banner .discount-banner-contain .rounded-contain.rounded-inverse {
    border: 2px dashed #042c49;
}

.discount-banner .discount-banner-contain .rounded-contain.rounded-inverse .rounded-subcontain {
    background-color: #c1151b;
}

/*============================
   9. Contact banner CSS start
==============================*/
.contact-banner {
    background-color: #042c49;
    padding: 20px 0;
}

.contact-banner.contact-banner-inverse {
    background-color: #ef9f77;
}

.contact-banner .contact-banner-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
}

.contact-banner .contact-banner-contain .contact-banner-img img {
    margin-right: 25px;
}

.contact-banner .contact-banner-contain h3 {
    font-size: 18px;
    font-weight: bold;
    margin-right: 25px;
    line-height: 1;
}

.contact-banner .contact-banner-contain h2 {
    font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
}

.contact-banner2 h2 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
}

.contact-banner2 p {
    line-height: 1.6;
    font-size: 16px;
}

.contact-banner2 .contact-bannerbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.contact-banner2 .contact-bannerbox h2 {
    margin-bottom: 9px;
}

.contact-banner2 .contact-bannerbox p {
    margin-bottom: 15px;
}

.contact-banner2 .contact-bannerbox ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.contact-banner2 .contact-bannerbox ul li svg {
    width: 34px;
    margin-right: 10px;
    fill: #042c49;
}

.contact-banner2 .contact-bannerbox ul li h3 {
    font-size: 22px;
}

.contact-banner2 .subscribe-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    height: 100%;
}

.contact-banner2 .subscribe-box svg {
    width: 50px;
    margin-bottom: 5px;
}

.contact-banner2 .subscribe-box h2 {
    margin-bottom: 9px;
}

.contact-banner2 .subscribe-box p {
    margin-bottom: 17px;
}

.contact-banner2 .subscribe-box .form-control {
    height: calc(38px + (48 - 38) * ((100vw - 320px) / (1920 - 320)));
    border-color: #dddddd;
    padding-left: calc(12px + (17 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(12px + (17 - 12) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 22px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.contact-banner2 .subscribe-box .form-control:focus {
    border-color: #dddddd;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.contact-banner2 .subscribe-box .btn {
    background-color: #ef9f77;
    /*text-transform: capitalize;*/
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
}

.brand-third {
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ffffff;
    border: 1px solid #dddddd;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.brand-third>div {
    display: block;
    width: 100%;
}

.brand-third .brand-box {
    padding: 10px;
}

/*=====================
   10. Rounded category CSS start
==========================*/
.rounded-category {
    background-color: #ef9f77;
    padding: 50px 0 40px;
}

.rounded-category.rounded-category-inverse {
    background-color: #042c49;
}

.rounded-category.rounded-category-inverse .category-contain:hover .img-wrapper {
    border: 1px solid #ef9f77;
}

.rounded-category.rounded-category-inverse .category-contain:hover .btn-rounded {
    background-color: #ef9f77;
}

.rounded-category .category-contain {
    text-align: center;
}

.rounded-category .category-contain .img-wrapper {
    border: 1px solid #ffffff;
    padding: 5px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin: 0 auto;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

.rounded-category .category-contain .img-wrapper img {
    border-radius: 100%;
}

.rounded-category .category-contain .btn-rounded {
    padding: 10px 25px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    display: inline-block;
    margin-top: 15px;
    background-color: transparent;
    text-transform: uppercase;
}

.rounded-category .category-contain:hover .img-wrapper {
    border: 1px solid #042c49;
}

.rounded-category .category-contain:hover .btn-rounded {
    background-color: #042c49;
}

.rounded-category.vagitable-category .category-contain .img-wrapper {
    background-color: #e7e7e9;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
}

/*=====================
   11. Box category CSS start
==========================*/
.category-block>div:nth-child(n+2) {
    margin-top: 15px;
}

.box-category .box-category-contain {
    padding: 30px 0;
    background-color: #ffffff;
    text-align: center;
    margin-left: 15px;
    position: relative;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    color: #444444;
    text-transform: uppercase;
}

.box-category .box-category-contain h4 {
    line-height: 1;
    letter-spacing: 1px;
}

.box-category .box-category-contain:after {
    background: #042c49;
    content: '';
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 0;
    right: 0;
    top: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.box-category .box-category-contain:hover {
    color: #ffffff;
    background: transparent;
}

.box-category .box-category-contain:hover:after {
    height: 100%;
}

.box-category.big-category .box-category-contain {
    background-color: #042c49;
}

.box-category.big-category .box-category-contain h4 {
    color: #ffffff;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-style: italic;
}

.category1 {
    padding-top: calc(25px + (60 - 25) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(25px + (60 - 25) * ((100vw - 320px) / (1920 - 320)));
    /*background-color: #ef9f77;*/
}

.category1 .category-box {
    text-align: center;
    margin-right: 15px;
}

.category1 .category-box .img-wrapper {
    border-radius: 5px;
    overflow: hidden;
}

.category1 .category-box .img-wrapper img {
    width: 100%;
}

.category1 .category-box .category-details h4 {
    color: #042c49;
    text-transform: uppercase;
    margin-top: 15px;
}

.category1.rounded-category-inverse {
    background-color: #042c49;
}

.category2 .category-box {
    background-color: #ffffff;
    padding: 30px;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
}

.category2 .category-box .icon-wrapper svg {
    width: 25%;
    margin-bottom: 10px;
    height: auto;
    fill: #042c49;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.category2 .category-box .category-details h6 {
    display: block;
    color: #444444;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    margin-top: 20px;
    line-height: 1;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.category2 .category-box:hover .icon-wrapper svg {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.category2 .category-box:hover .category-details h6 {
    color: #042c49;
}

.category3 .category-box {
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 15px;
}

.category3 .category-box .img-wrraper a {
    display: block;
    overflow: hidden;
}

.category3 .category-box .img-wrraper img {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.category3 .category-box .category-detail {
    background-color: #ffffff;
    padding: 10px;
    /*text-transform: capitalize;*/
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.category3 .category-box:hover .img-wrraper img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.category3 .category-box:hover .category-detail {
    background-color: rgba(199, 109, 98, 0.8);
    color: #ffffff;
}

.category4 .category-box {
    background-color: #ffffff;
    margin-right: 15px;
    border-radius: 5px;
}

.category4 .category-box img {
    width: 100%;
}

.category4 .category-box .img-wrraper img {
    width: 100%;
}

.category4 .category-box .category-detail {
    padding: 20px;
}

.category4 .category-box .category-detail h2 {
    text-transform: uppercase;
    font-size: 18px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 10px;
}

.category4 .category-box .category-detail ul li {
    display: block;
    padding-left: 15px;
    position: relative;
    line-height: 1.2;
}

.category4 .category-box .category-detail ul li:nth-child(n+2) {
    margin-top: 10px;
}

.category4 .category-box .category-detail ul li:before {
    content: "";
    position: absolute;
    top: 35%;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #ef9f77;
}

.category4 .category-box .category-detail ul li a {
    font-size: 16px;
    /*text-transform: capitalize;*/
    color: #444444;
}

.category4 .category-box .category-detail .btn {
    margin-top: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    /*text-transform: capitalize;*/
    font-size: 16px;
}

.category5 .category-box {
    margin-right: 15px;
    overflow: hidden;
    background-blend-mode: overlay;
    background-color: #4b4b4b;
}

.category5 .category-box .contain-wrraper {
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    background-color: #ffffff;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.category5 .category-box .contain-wrraper svg {
    width: calc(35px + (55 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: auto;
    margin-bottom: 5px;
}

.category5 .category-box .contain-wrraper h3 {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    /*text-transform: capitalize;*/
    color: #000000;
    margin-bottom: 5px;
}

.category5 .category-box .contain-wrraper p {
    color: #444444;
    line-height: 1.6;
    letter-spacing: 1px;
}

.category5 .category-box:hover .contain-wrraper {
    -webkit-transform: translate(100%);
    transform: translate(100%);
}

/*=====================
    12.Blog CSS start
==========================*/
.blog .blog-contain {
    margin-right: 15px;
    position: relative;
}

.blog .blog-contain .blog-img {
    overflow: hidden;
    border: 1px solid #ffffff;
}

.blog .blog-contain .blog-img img {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 100%;
}

.blog .blog-contain .blog-details {
    background-color: #ffffff;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.blog .blog-contain .blog-details h4 {
    color: #444444;
    margin-bottom: 10px;
    font-size: calc(17px + (18 - 17) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px;
    font-weight: 600;
}

.blog .blog-contain .blog-details p {
    color: #939b9e;
    /*text-transform: capitalize;*/
    margin-bottom: 5px;
    letter-spacing: 0.5px;
}

.blog .blog-contain .blog-details span a {
    color: #042c49;
    /*text-transform: capitalize;*/
    font-weight: 500;
    // letter-spacing: 0.03em;
    line-height: 1;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.blog .blog-contain .blog-details-2 {
    background-color: #ffffff;
    padding: 20px;
}

.blog .blog-contain .blog-details-2 h4 {
    /*text-transform: capitalize;*/
    color: #042c49;
    line-height: 1;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.blog .blog-contain .blog-details-2 p {
    line-height: 1.6;
    margin-top: 5px;
    /*text-transform: capitalize;*/
}

.blog .blog-contain .blog-details-2 ul {
    margin-top: 15px;
    line-height: 1;
}

.blog .blog-contain .blog-details-2 ul li:first-child {
    margin-right: 30px;
}

.blog .blog-contain .blog-details-2 ul li a {
    color: #999999;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    /*text-transform: capitalize;*/
    font-weight: 500;
}

.blog .blog-contain .blog-details-2 ul li a i {
    margin-right: 10px;
}

.blog .blog-contain .blog-details-2 .btn {
    margin-top: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: block;
    padding: 10px 25px;
}

.blog .blog-contain .blog-details-2 .btn-normal {
    border-radius: 5px;
    background-color: rgba(239, 159, 119, 0.05);
    color: #ef9f77 !important;
    border-color: #ef9f77;
}

.blog .blog-contain .blog-details-2 .btn-normal:hover {
    background-color: #ef9f77;
    color: #ffffff !important;
}

.blog .blog-contain .blog-details-2.text-center .btn {
    margin-left: auto;
    margin-right: auto;
}

.blog .blog-contain .blog-label {
    position: absolute;
    top: 5%;
    left: 0;
    background-color: #042c49;
    padding: 2px 10px;
    color: #ffffff;
}

.blog .blog-contain .blog-label1 {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    background-color: #042c49;
    line-height: 1.2;
}

.blog .blog-contain .blog-label1.square {
    border-radius: 2px;
    background-color: #ef9f77;
}

.blog .blog-contain.blog-border {
    border: 1px solid #dddddd;
}

.blog .blog-contain.blog-border .blog-img {
    border: none;
}

.blog .blog-contain:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.blog.blog-inverce .blog-contain .blog-label1 {
    background-color: #ef9f77;
}

.blog.blog-inverce .blog-contain .blog-details-2 .btn {
    background-color: #ef9f77;
}

/*============================
    13. Collection banner CSS start
==============================*/
.collection-banner .padding-banner-2 {
    padding-bottom: 15px;
}

.collection-banner .collection-banner-main {
    position: relative;
    overflow: hidden;
}

.collection-banner .collection-banner-main .collection-img {
    width: 100%;
    height: 272px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.collection-banner .collection-banner-main .collection-img img {
    width: 100%;
}

.collection-banner .collection-banner-main.p-left .collection-banner-contain {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.collection-banner .collection-banner-main.p-right .collection-banner-contain {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.collection-banner .collection-banner-main.p-center .collection-banner-contain {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.collection-banner .collection-banner-main.p-top .collection-banner-contain {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.collection-banner .collection-banner-main.p-bottom .collection-banner-contain {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.collection-banner .collection-banner-main .collection-banner-contain {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 50px;
}

.collection-banner .collection-banner-main .collection-banner-contain h3 {
    color: #042c49;
    text-transform: uppercase;
    font-size: 30px;
    line-height: 1;
    margin-bottom: 8px;
}

.collection-banner .collection-banner-main .collection-banner-contain h4 {
    color: #444444;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 1;
}

.collection-banner .collection-banner-main .collection-banner-contain .shop {
    margin-top: 13px;
}

.collection-banner .collection-banner-main .collection-banner-contain .shop a {
    /*text-transform: capitalize;*/
    color: #ef9f77;
    font-weight: 700;
}

.collection-banner .collection-banner-main .collection-banner-contain .shop a i {
    margin-left: 8px;
}

.collection-banner .collection-banner-main.banner-1 .collection-banner-contain h3 {
    color: #042c49;
    /*text-transform: capitalize;*/
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 0;
}

.collection-banner .collection-banner-main.banner-1 .collection-banner-contain h4 {
    color: #ef9f77;
    font-weight: 600;
    font-size: 52px;
    /*text-transform: capitalize;*/
}

.collection-banner .collection-banner-main.banner-1 .collection-banner-contain .shop {
    margin-top: 25px;
}

.collection-banner .collection-banner-main.banner-1 .collection-banner-contain .shop a {
    color: #000000;
}

.collection-banner .collection-banner-main.banner-2 .collection-img {
    height: 225px;
}

.collection-banner .collection-banner-main.banner-2 .collection-banner-contain {
    padding: 20px;
}

.collection-banner .collection-banner-main.banner-2 .collection-banner-contain h3 {
    color: #444444;
    /*text-transform: capitalize;*/
    font-size: 24px;
    font-weight: 700;
}

.collection-banner .collection-banner-main.banner-2 .collection-banner-contain h4 {
    color: #444444;
    /*text-transform: capitalize;*/
    font-size: 24px;
    font-weight: 700;
}

.collection-banner .collection-banner-main.banner-2 .collection-banner-contain .shop {
    margin-top: 18px;
}

.collection-banner .collection-banner-main.banner-2 .collection-banner-contain .shop a {
    text-transform: uppercase;
}

.collection-banner .collection-banner-main.banner-3 .collection-img {
    height: 465px;
}

.collection-banner .collection-banner-main.banner-3 .collection-banner-contain h3 {
    color: #ef9f77;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 0;
}

.collection-banner .collection-banner-main.banner-3 .collection-banner-contain h4 {
    margin-top: 18px;
    color: #042c49;
    font-size: 18px;
    margin-bottom: 100px;
    text-transform: inherit;
}

.collection-banner .collection-banner-main.banner-4 .collection-img {
    height: 225px;
}

.collection-banner .collection-banner-main.banner-4 .collection-banner-contain h3 {
    color: #042c49;
    /*text-transform: capitalize;*/
    font-size: 24px;
    font-weight: 400;
}

.collection-banner .collection-banner-main.banner-4 .collection-banner-contain h4 {
    color: #444444;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 30px;
    /*text-transform: capitalize;*/
}

.collection-banner .collection-banner-main.banner-4 .collection-banner-contain .shop {
    margin-top: 18px;
}

.collection-banner .collection-banner-main.banner-4 .collection-banner-contain .shop a {
    text-transform: uppercase;
    color: #ef9f77;
}

.collection-banner .collection-banner-main.banner-5 .collection-img {
    height: 300px;
}

.collection-banner .collection-banner-main.banner-5 .collection-banner-contain {
    text-align: center;
    padding: 0;
}

.collection-banner .collection-banner-main.banner-5 .collection-banner-contain h3 {
    color: #777777;
    /*text-transform: capitalize;*/
    font-size: 36px;
    font-weight: 400;
    margin-bottom: 0;
}

.collection-banner .collection-banner-main.banner-5 .collection-banner-contain h4 {
    color: #042c49;
    font-weight: 700;
    font-size: 72px;
    /*text-transform: capitalize;*/
}

.collection-banner .collection-banner-main.banner-5 .collection-banner-contain h4 span {
    color: #ef9f77;
    font-weight: 500;
}

.collection-banner .collection-banner-main.banner-5 .collection-banner-contain .btn-normal {
    display: inline-block;
}

.collection-banner .collection-banner-main.banner-6 .collection-banner-contain {
    padding: 25px;
}

.collection-banner .collection-banner-main.banner-6 .collection-banner-contain h6 {
    color: #042c49;
    /*text-transform: capitalize;*/
    font-weight: 700;
}

.collection-banner .collection-banner-main.banner-6 .collection-banner-contain h4 {
    color: #ef9f77;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 24px;
    /*text-transform: capitalize;*/
    margin-top: 5px;
}

.collection-banner .collection-banner-main.banner-7 .collection-banner-contain {
    padding: 80px;
}

.collection-banner .collection-banner-main.banner-7 .collection-banner-contain h3 {
    color: #042c49;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 400;
}

.collection-banner .collection-banner-main.banner-7 .collection-banner-contain h4 {
    color: #444444;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 36px;
    /*text-transform: capitalize;*/
    margin-top: 5px;
}

.collection-banner .collection-banner-main.banner-7 .collection-banner-contain .shop {
    margin-top: 30px;
}

.collection-banner .collection-banner-main.banner-7 .collection-banner-contain .shop a {
    text-transform: uppercase;
    font-weight: 700;
    color: #ef9f77;
}

.collection-banner .collection-banner-main.banner-8 {
    background-color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.collection-banner .collection-banner-main.banner-8 .collection-banner-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    position: initial;
}

.collection-banner .collection-banner-main.banner-8 .collection-banner-contain h3 {
    font-size: 24px;
    color: #444444;
}

.collection-banner .collection-banner-main.banner-8 .collection-banner-contain h3:nth-child(2) {
    margin-bottom: 0;
}

.collection-banner .collection-banner-main.banner-8 .collection-banner-contain h1 {
    font-size: 72px;
    color: #042c49;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 5px;
}

.collection-banner .collection-banner-main.banner-8 .collection-banner-contain h6 {
    color: #777777;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 4px;
}

.collection-banner .collection-banner-main.banner-8 .collection-banner-contain .shop {
    margin-top: 5px;
}

.collection-banner .collection-banner-main.banner-9 .collection-banner-contain h3 {
    color: #ef9f77;
    font-weight: 400;
    /*text-transform: capitalize;*/
}

.collection-banner .collection-banner-main.banner-9 .collection-banner-contain h4 {
    color: #042c49;
    font-weight: 400;
    /*text-transform: capitalize;*/
}

.collection-banner .collection-banner-main.banner-9 .collection-banner-contain a {
    color: #444444;
}

.collection-banner .collection-banner-main.banner-10 .collection-img {
    height: 267px;
}

.collection-banner .collection-banner-main.banner-11 .collection-img {
    height: 550px;
}

.collection-banner .collection-banner-main.banner-12 .collection-img {
    height: 300px;
}

.collection-banner .collection-banner-main.banner-13 .collection-img {
    height: 320px;
}

.collection-banner .collection-banner-main.banner-14 .collection-img {
    height: 655px;
}

.collection-banner .collection-banner-main.banner-15 .collection-img {
    height: 512px;
}

.collection-banner .collection-banner-main.banner-16 .collection-img {
    height: 655px;
}

.collection-banner .collection-banner-main.banner-17 .collection-img {
    height: 222px;
}

.collection-banner .collection-banner-main.banner-18 .collection-img {
    height: 260px;
}

.collection-banner .collection-banner-main.collection-colortheme .collection-banner-contain h3 {
    color: #042c49;
}

.collection-banner .collection-banner-main.collection-color1 .collection-banner-contain h3 {
    color: #ae745f;
}

.collection-banner .collection-banner-main.collection-color1 .collection-banner-contain .btn {
    background-color: #ae745f;
}

.collection-banner .collection-banner-main.collection-color2 .collection-banner-contain h3 {
    color: #6f9d34;
}

.collection-banner .collection-banner-main.collection-color2 .collection-banner-contain .btn {
    background-color: #6f9d34;
}

.collection-banner .collection-banner-main.collection-color2 .collection-banner-contain h3 {
    color: #6f9d34;
}

.collection-banner .collection-banner-main.collection-color2 .collection-banner-contain .btn {
    background-color: #6f9d34;
}

.collection-banner .collection-banner-main.collection-color3 .collection-banner-contain h3 {
    color: #2da6a4;
}

.collection-banner .collection-banner-main.collection-color3 .collection-banner-contain .btn {
    background-color: #2da6a4;
}

.collection-banner .collection-banner-main.collection-color4 .collection-banner-contain h3 {
    color: #2da6a4;
}

.collection-banner .collection-banner-main.collection-color4 .collection-banner-contain .btn {
    background-color: #2da6a4;
}

.collection-banner .collection-banner-main.collection-color5 .collection-banner-contain h3 {
    color: #e9b77c;
}

.collection-banner .collection-banner-main.collection-color5 .collection-banner-contain .btn {
    background-color: #e9b77c;
}

.collection-banner .collection-banner-main.collection-color6 .collection-banner-contain h3 {
    color: #4aa4d3;
}

.collection-banner .collection-banner-main.collection-color6 .collection-banner-contain .btn {
    background-color: #4aa4d3;
}

.collection-banner .collection-banner-main.collection-color7 .collection-banner-contain h3 {
    color: #d71093;
}

.collection-banner .collection-banner-main.collection-color7 .collection-banner-contain .btn {
    background-color: #d71093;
}

.collection-banner .collection-banner-main.collection-color8 .collection-banner-contain h3 {
    color: #d49723;
}

.collection-banner .collection-banner-main.collection-color8 .collection-banner-contain .btn {
    background-color: #d49723;
}

.collection-banner .collection-banner-main.collection-color9 .collection-banner-contain h3 {
    color: #f4b139;
}

.collection-banner .collection-banner-main.collection-color9 .collection-banner-contain .btn {
    background-color: #f4b139;
}

.collection-banner .collection-banner-main.collection-color10 .collection-banner-contain h3 {
    color: #3f465c;
}

.collection-banner .collection-banner-main.collection-color10 .collection-banner-contain .btn {
    background-color: #3f465c;
}

.collection-banner .collection-banner-main.collection-color11 .collection-banner-contain h3 {
    color: #679e13;
}

.collection-banner .collection-banner-main.collection-color11 .collection-banner-contain .btn {
    background-color: #679e13;
}

.collection-banner .collection-banner-main.collection-color12 .collection-banner-contain h3 {
    color: #35a7b1;
}

.collection-banner .collection-banner-main.collection-color12 .collection-banner-contain .btn {
    background-color: #35a7b1;
}

.collection-banner .collection-banner-main.collection-color13 .collection-banner-contain h3 {
    color: #1136b2;
}

.collection-banner .collection-banner-main.collection-color13 .collection-banner-contain .btn {
    background-color: #1136b2;
}

.collection-banner .collection-banner-main.collection-color14 .collection-banner-contain h3 {
    color: #f72538;
}

.collection-banner .collection-banner-main.collection-color14 .collection-banner-contain .btn {
    background-color: #f72538;
}

.collection-banner .collection-banner-main.collection-color15 .collection-banner-contain h3 {
    color: #01917e;
}

.collection-banner .collection-banner-main.collection-color15 .collection-banner-contain .btn {
    background-color: #01917e;
}

.collection-banner .collection-banner-main.banner-style1 .collection-banner-contain {
    padding: calc(25px + (30 - 25) * ((100vw - 320px) / (1920 - 320)));
}

.collection-banner .collection-banner-main.banner-style1 .collection-banner-contain h3 {
    font-size: 16px;
    font-weight: 400;
    font-family: "Sriracha", cursive;
    margin-bottom: 16px;
}

.collection-banner .collection-banner-main.banner-style1 .collection-banner-contain h4 {
    font-size: 22px;
    color: #000000;
    font-weight: 500;
    font-family: "Rubik", sans-serif;
}

.collection-banner .collection-banner-main.banner-style1 .collection-banner-contain .shop {
    margin-top: 16px;
}

.collection-banner .collection-banner-main.banner-style2 .collection-banner-contain {
    padding: 25px;
}

.collection-banner .collection-banner-main.banner-style2 .collection-banner-contain h3 {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.collection-banner .collection-banner-main.banner-style2 .collection-banner-contain h4 {
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.collection-banner .collection-banner-main.banner-style3 .collection-banner-contain {
    padding: calc(15px + (50 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.collection-banner .collection-banner-main.banner-style3 .collection-banner-contain h3 {
    font-size: 14px;
    letter-spacing: 6px;
    color: #000000;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 15px;
}

.collection-banner .collection-banner-main.banner-style3 .collection-banner-contain h4 {
    font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: #000000;
    font-weight: 700;
    font-family: "Merriweather";
    /*text-transform: capitalize;*/
    margin-bottom: 16px;
}

.collection-banner .collection-banner-main.banner-style4 .collection-banner-contain {
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.collection-banner .collection-banner-main.banner-style4 .collection-banner-contain h3 {
    font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 400;
    font-family: "Courgette", cursive;
    margin-bottom: 9px;
    /*text-transform: capitalize;*/
    letter-spacing: 0.05em;
}

.collection-banner .collection-banner-main.banner-style4 .collection-banner-contain h4 {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #000000;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    margin-bottom: 12px;
}

.collection-banner .collection-banner-main.banner-style5 .collection-banner-contain {
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.collection-banner .collection-banner-main.banner-style5 .collection-banner-contain h3 {
    font-size: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px;
    font-weight: 600;
    font-family: "Sansita Swashed", cursive;
    /*text-transform: capitalize;*/
    margin-bottom: 19px;
}

.collection-banner .collection-banner-main.banner-style5 .collection-banner-contain h4 {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 4px;
    color: #000000;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    margin-bottom: 18px;
}

.collection-banner .collection-banner-main.banner-style6 .collection-banner-contain {
    padding: 30px;
}

.collection-banner .collection-banner-main.banner-style6 .collection-banner-contain h3 {
    font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 2px;
    font-weight: 400;
    font-family: "Chau Philomene One", sans-serif;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.collection-banner .collection-banner-main.banner-style6 .collection-banner-contain h4 {
    font-size: calc(14px + (17 - 14) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px;
    color: #000000;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    margin-bottom: 15px;
}

.collection-banner .collection-banner-main.banner-style7 .collection-banner-contain {
    padding: 25px;
}

.collection-banner .collection-banner-main.banner-style7 .collection-banner-contain h3 {
    font-size: calc(16px + (23 - 16) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px;
    font-weight: 400;
    font-family: "Days One", sans-serif;
    /*text-transform: capitalize;*/
    margin-bottom: 11px;
}

.collection-banner .collection-banner-main.banner-style7 .collection-banner-contain h4 {
    font-size: 14px;
    letter-spacing: 3px;
    color: #000000;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    margin-bottom: 14px;
}

.collection-banner .collection-banner-main.banner-style8 .collection-banner-contain {
    padding: 25px;
}

.collection-banner .collection-banner-main.banner-style8 .collection-banner-contain h3 {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Merienda", cursive;
    /*text-transform: capitalize;*/
    margin-bottom: 12px;
}

.collection-banner .collection-banner-main.banner-style8 .collection-banner-contain h4 {
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #000000;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
    margin-bottom: 12px;
}

.collection-banner .collection-banner-main.banner-style8 .collection-banner-contain .btn {
    background-color: #ffffff;
    color: #042c49 !important;
}

.collection-banner .collection-banner-main.collection-13 .collection-img {
    height: 513px;
}

.collection-banner .collection-banner-main:after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
}

.collection-banner .collection-banner-main:hover .collection-img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}

.collection-banner .collection-banner-main:hover:after {
    -webkit-animation: collection 1s;
    animation: collection 1s;
}

.collection-banner .banner-2-padding {
    margin-bottom: 15px;
}

@-webkit-keyframes collection {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}

@keyframes collection {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}

.collection-banner .collection-p1>div:first-child>div>div:nth-child(n+2),
.collection-banner .collection-p1>div:last-child>div>div:nth-child(n+2) {
    margin-top: 15px;
}

.collection-banner .collection-p1>div:nth-child(2) {
    padding-left: unset;
    padding-right: unset;
}

.collection-banner .collection-p2>div:first-child>div>div:first-child {
    padding-right: unset;
}

.collection-banner .collection-p2>div:first-child>div>div:nth-child(n+3) {
    margin-top: 15px;
}

.collection-banner .collection-p2>div:last-child {
    padding-left: unset;
}

.collection-banner .collection-p3>div:first-child {
    padding-right: unset;
}

.collection-banner .collection-p3>div:first-child>div>div:nth-child(n+2) {
    margin-top: 15px;
}

.collection-banner .collection-p3>div:last-child>div>div:first-child {
    padding-right: unset;
}

.collection-banner .collection-p3>div:last-child>div>div:nth-child(n+3) {
    margin-top: 15px;
}

.collection-banner .collection-p4>div:nth-child(2) {
    padding-left: unset;
    padding-right: unset;
}

.collection-banner .collection-p4>div:nth-child(2)>div>div:nth-child(n+2) {
    margin-top: 15px;
}

.collection-banner .collection-p5>div:nth-child(n+2) {
    margin-top: 15px;
}

.collection-banner .collection-p5>div>div>div:nth-child(n+2) {
    padding-left: unset;
}

.collection-banner .collection-p6>div:nth-child(n+2) {
    margin-top: 15px;
}

.collection2>div:nth-child(2) {
    padding-right: 0;
    padding-left: 0;
}

.collection3>div:first-child {
    padding-right: 0;
}

.collection4>div:first-child {
    padding-right: 0;
}

.collection5>div:first-child {
    margin-bottom: 15px;
}

.layout-4-collection>div:first-child {
    margin-left: 270px;
}

.layout-4-collection>div:nth-child(2) {
    padding-left: 0;
    padding-right: 0;
}

.layout-3-collection>div:first-child {
    padding-right: 0;
}

/*============================
  14. Product CSS start
==============================*/
.product-block>div:nth-child(n+2) {
    margin-top: 15px;
}

.product-block2>div {
    padding-left: 0;
    padding-right: 0;
}

.product-block2>div:nth-child(n+2) {
    margin-top: 30px;
}

.product-block3>div {
    padding-right: 0;
}

.product-block3>div:nth-child(n+2) {
    margin-top: 15px;
}

.product-block4>div .product-box {
    padding-right: unset;
}

.product-block4>div:nth-child(n+5) {
    margin-top: 30px;
}

.product .product-box {
    padding-right: 15px;
}

.product .product-box .product-imgbox {
    position: relative;
    overflow: hidden;
    background-color: #ffffff;
}

.product .product-box .product-imgbox img {
    margin: 0 auto;
    width: 100%;
}

.product .product-box .product-imgbox .product-front {
    left: 0;
    top: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-imgbox .product-back {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
}

.product .product-box .product-imgbox .on-sale {
    background-color: #ef9f77;
    color: #ffffff;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 6px;
    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-imgbox .new-label {
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    font-weight: 600;
    letter-spacing: 1px;
}

.product .product-box .product-imgbox .new-label div {
    color: #ffffff;
    text-transform: uppercase;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 3px;
}

.product .product-box .product-imgbox .new-label::before {
    width: 0;
    height: 0;
    border-top: 60px solid #042c49;
    border-right: 60px solid transparent;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
}

.product .product-box .product-imgbox .product-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-imgbox .product-icon button {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    color: #777777;
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-imgbox .product-icon button svg {
    width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-imgbox .product-icon button:hover {
    background-color: #042c49;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-imgbox .product-icon button:hover svg {
    color: #ffffff;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-imgbox .product-icon a {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    color: #777777;
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-imgbox .product-icon a svg {
    width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-imgbox .product-icon a i {
    display: block;
    padding: 15px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    color: #777777;
}

.product .product-box .product-imgbox .product-icon a:hover {
    background-color: #042c49;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-imgbox .product-icon a:hover svg {
    color: #ffffff;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-imgbox .product-icon.icon-inline {
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 100%;
}

.product .product-box .product-imgbox .product-icon.icon-inline a {
    margin-left: 7px;
}

.product .product-box .product-imgbox .product-icon.icon-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-transform: scale(0);
    transform: scale(0);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.product .product-box .product-imgbox .product-icon.icon-top {
    bottom: unset;
    top: 0;
}

.product .product-box .product-imgbox .on-sale1 {
    color: #ef9f77;
    text-transform: uppercase;
    position: absolute;
    top: 40px;
    right: -10px;
    padding: 0 6px;
    font-size: calc(11px + (15 - 11) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.product .product-box .product-imgbox .new-label1 {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #042c49;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    text-transform: uppercase;
    font-weight: 700;
    font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-imgbox .on-sale2 {
    color: #ef9f77;
    text-transform: uppercase;
    position: absolute;
    top: 40px;
    right: -10px;
    padding: 0 6px;
    font-size: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.product .product-box .product-imgbox .new-label2 {
    position: absolute;
    top: 15px;
    left: 15px;
    color: #ef9f77;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    text-transform: uppercase;
    font-weight: 700;
    font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-imgbox .on-sale3 {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #042c49;
    text-transform: uppercase;
    font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
}

.product .product-box .product-imgbox .new-label3 {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #ffffff;
    background-color: #ef9f77;
    padding: 15px 10px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-imgbox .on-sale4 {
    background-color: #ef9f77;
    color: #ffffff;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 6px;
    font-size: calc(10px + (12 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-imgbox .btn-cart {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #042c49;
    color: #ffffff;
    /*text-transform: capitalize;*/
    font-weight: 700;
    line-height: 1;
    font-size: 14px;
    padding-top: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    opacity: 0;
    visibility: hidden;
}

.product .product-box .product-imgbox .btn-cart:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    outline: none;
}

.product .product-box .product-imgbox .counter-cart {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.product .product-box:hover .product-imgbox .btn-cart {
    visibility: visible;
    overflow: hidden;
    opacity: 1;
    -webkit-animation: fadeInUp 300ms ease-in-out;
    animation: fadeInUp 300ms ease-in-out;
}

.product .product-box:hover .product-imgbox .counter-cart {
    opacity: 1;
    visibility: visible;
    -webkit-animation: fadeInUp 500ms ease-in-out;
    animation: fadeInUp 500ms ease-in-out;
}

.product .product-box:hover .product-back {
    opacity: 1;
}

.product .product-box:hover .product-icon {
    opacity: 1;
}

.product .product-box:hover .product-icon button {
    -webkit-animation: fadeInRight 300ms ease-in-out;
    animation: fadeInRight 300ms ease-in-out;
}

.product .product-box:hover .product-icon a:nth-child(2) i {
    -webkit-animation: fadeInRight 500ms ease-in-out;
    animation: fadeInRight 500ms ease-in-out;
}

.product .product-box:hover .product-icon a:nth-child(3) i {
    -webkit-animation: fadeInRight 700ms ease-in-out;
    animation: fadeInRight 700ms ease-in-out;
}

.product .product-box:hover .product-icon a:nth-child(4) i {
    -webkit-animation: fadeInRight 1000ms ease-in-out;
    animation: fadeInRight 1000ms ease-in-out;
}

.product .product-box:hover .product-icon.icon-inline {
    opacity: 1;
}

.product .product-box:hover .product-icon.icon-inline button {
    -webkit-animation: fadeInUp 300ms ease-in-out;
    animation: fadeInUp 300ms ease-in-out;
}

.product .product-box:hover .product-icon.icon-inline a:nth-child(2) i {
    -webkit-animation: fadeInUp 500ms ease-in-out;
    animation: fadeInUp 500ms ease-in-out;
}

.product .product-box:hover .product-icon.icon-inline a:nth-child(3) i {
    -webkit-animation: fadeInUp 700ms ease-in-out;
    animation: fadeInUp 700ms ease-in-out;
}

.product .product-box:hover .product-icon.icon-inline a:nth-child(4) i {
    -webkit-animation: fadeInUp 1000ms ease-in-out;
    animation: fadeInUp 1000ms ease-in-out;
}

.product .product-box:hover .product-icon.icon-center button {
    -webkit-animation: none;
    animation: none;
}

.product .product-box:hover .product-icon.icon-center a:nth-child(2) i {
    -webkit-animation: none;
    animation: none;
}

.product .product-box:hover .product-detail.detail-center .detail-title {
    opacity: 0;
}

.product .product-box:hover .product-detail.detail-center .icon-detail {
    opacity: 1;
}

.product .product-box:hover .product-detail.detail-center .icon-detail button {
    -webkit-animation: fadeInUp 300ms ease-in-out;
    animation: fadeInUp 300ms ease-in-out;
}

.product .product-box:hover .product-detail.detail-center .icon-detail a:nth-child(2) i {
    -webkit-animation: fadeInUp 500ms ease-in-out;
    animation: fadeInUp 500ms ease-in-out;
}

.product .product-box:hover .product-detail.detail-center .icon-detail a:nth-child(3) i {
    -webkit-animation: fadeInUp 700ms ease-in-out;
    animation: fadeInUp 700ms ease-in-out;
}

.product .product-box:hover .product-detail.detail-center .icon-detail a:nth-child(4) i {
    -webkit-animation: fadeInUp 1000ms ease-in-out;
    animation: fadeInUp 1000ms ease-in-out;
}

.product .product-box:hover .product-detail.product-detail2 .size-box {
    display: block;
}

.product .product-box .product-detail.detail-center1 {
    text-align: center;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ffffff;
}

.product .product-box .product-detail.detail-center1 .rating-star li i {
    color: #ffa800;
    font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-detail.detail-center1 .rating-star li:last-child i {
    color: #e8ebf2;
}

.product .product-box .product-detail.detail-center1 h6 {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    padding: 3px 0;
    /*text-transform: capitalize;*/
    color: #444444;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.product .product-box .product-detail.detail-center1 .detail-price {
    color: #ef9f77;
    font-weight: 700;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-detail.detail-center1 .detail-price span {
    margin-left: 20px;
    color: #444444;
}

.product .product-box .product-detail.product-detail2 {
    text-align: center;
    padding: 15px;
    background-color: #ffffff;
}

.product .product-box .product-detail.product-detail2 ul li {
    margin-right: 0;
}

.product .product-box .product-detail.product-detail2 ul li:nth-child(n+2) {
    margin-left: 2px;
}

.product .product-box .product-detail.product-detail2 ul li i {
    color: #ffa800;
}

.product .product-box .product-detail.product-detail2 .color-variant {
    line-height: 1;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.product .product-box .product-detail.product-detail2 .color-variant .check-color {
    width: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
    height: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 50%;
    display: inline-block;
}

.product .product-box .product-detail.product-detail2 .color-variant .check-color.pro-color1 {
    background-color: #f6d51f;
}

.product .product-box .product-detail.product-detail2 .color-variant .check-color.pro-color2 {
    background-color: #000000;
}

.product .product-box .product-detail.product-detail2 .color-variant .check-color.pro-color3 {
    background-color: #f85357;
}

.product .product-box .product-detail.product-detail2 .color-variant .check-color.pro-color4 {
    background-color: #1136b2;
}

.product .product-box .product-detail.product-detail2 .pro-bottom-slide {
    width: 85px;
    margin-left: auto;
    margin-right: auto;
}

.product .product-box .product-detail.product-detail2 button {
    border: 1px solid #042c49;
    background: #042c49;
    outline: none;
    color: #ffffff;
    /*text-transform: capitalize;*/
    padding-top: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(8px + (12 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 10px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    letter-spacing: 0.05em;
}

.product .product-box .product-detail.product-detail2 button:hover {
    background-color: transparent;
    color: #042c49;
}

.product .product-box .product-detail.product-detail2 .size-box {
    margin-top: 10px;
    display: none;
}

.product .product-box .product-detail h3 {
    font-weight: 700;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    /*text-transform: capitalize;*/
    margin-top: 5px;
    margin-bottom: 5px;
    color: #444444;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product .product-box .product-detail h5 {
    color: #042c49;
    font-weight: 600;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-detail h5 span {
    color: #333333;
    text-decoration: line-through;
}

.product .product-box .product-detail.detail-center {
    position: relative;
    overflow: hidden;
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ffffff;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-detail.detail-center .detail-title {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    display: block;
}

.product .product-box .product-detail.detail-center .detail-title .detail-left {
    width: 100%;
}

.product .product-box .product-detail.detail-center .detail-title .detail-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}

.product .product-box .product-detail.detail-center .detail-title .detail-right .price {
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
    margin-left: 10px;
    color: #042c49;
}

.product .product-box .product-detail.detail-inline {
    background-color: #ffffff;
    padding: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    overflow: auto;
}

.product .product-box .product-detail.detail-inverse .detail-title .detail-right .price {
    color: #ef9f77;
}

.product .product-box .product-detail .detail-title {
    padding-top: 10px;
}

.product .product-box .product-detail .detail-title .detail-left {
    float: left;
}

.product .product-box .product-detail .detail-title .detail-left .rating-star i {
    color: #ffa800;
    font-size: calc(11px + (14 - 11) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-detail .detail-title .detail-left .rating-star i:last-child {
    color: #e8ebf2;
}

.product .product-box .product-detail .detail-title .detail-left .price-title {
    /*text-transform: capitalize;*/
    color: #444444;
    font-weight: 700;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-detail .detail-title .detail-right {
    float: right;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-detail .detail-title .detail-right .check-price {
    text-decoration: line-through;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.product .product-box .product-detail .detail-title .detail-right .price {
    color: #ef9f77;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
}

.product .product-box .product-detail .icon-detail {
    position: absolute;
    bottom: 15px;
    width: 100%;
    left: 0;
    right: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
}

.product .product-box .product-detail .icon-detail button {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    color: #777777;
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: all 0.5s easer;
    transition: all 0.5s easer;
}

.product .product-box .product-detail .icon-detail button svg {
    width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #777777;
    -webkit-transition: all 0.5s easer;
    transition: all 0.5s easer;
}

.product .product-box .product-detail .icon-detail button:hover {
    background-color: #042c49;
}

.product .product-box .product-detail .icon-detail button:hover svg {
    color: #ffffff;
}

.product .product-box .product-detail .icon-detail a {
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-detail .icon-detail a svg {
    width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #777777;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box .product-detail .icon-detail a i {
    background-color: #ffffff;
    padding: 15px;
    border: 1px solid #dddddd;
    color: #777777;
}

.product .product-box .product-detail .icon-detail a:hover {
    background-color: #042c49;
}

.product .product-box .product-detail .icon-detail a:hover svg {
    color: #ffffff;
}

.product .product-box.product-box2 .product-icon button {
    border-radius: 50%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.product .product-box.product-box2 .product-icon button svg {
    color: #333333;
    width: 18px;
}

.product .product-box.product-box2 .product-icon button:hover {
    background-color: #042c49;
    color: #f1f3f5;
}

.product .product-box.product-box2 .product-icon a {
    width: 45px;
    height: 45px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
}

.product .product-box.product-box2 .product-icon a svg {
    width: 18px;
    color: #333333;
}

.product .product-box.product-box2 .product-icon a i {
    border-radius: 50%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product .product-box.product-box2 .product-icon a i:hover {
    background-color: #042c49;
    color: #f1f3f5;
}

.product .product-box.product-box2:hover .product-icon {
    bottom: 45%;
}

.product .product-box .tab-content {
    display: block;
}

.product .product-box .nav-tabs {
    border: unset;
}

.product .product-box .nav-tabs .nav-link {
    border: unset;
    padding: 0;
}

.product .product-box .nav-tabs .nav-link.active {
    border: unset;
    background-color: unset;
}

.product-box3 {
    background-color: #ffffff;
    padding: 15px;
    margin-right: 15px;
}

.product-box3 .product-detail {
    text-align: left;
    padding-left: 20px;
    padding-top: 0;
}

.product-box3 .product-detail .rating {
    margin-bottom: 12px;
    line-height: 1;
}

.product-box3 .product-detail .rating li a i {
    color: #ffa800;
}

.product-box3 .product-detail h3 {
    margin-bottom: 12px;
    /*text-transform: capitalize;*/
    font-weight: 600;
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: #000000;
}

.product-box3 .product-detail h4 {
    color: #acacac;
    font-weight: 600;
    margin-bottom: 12px;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.product-box3 .product-detail h4 span {
    text-decoration: line-through;
    color: #ef9f77;
    margin-left: 10px;
}

.product-box3 .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.no-slider .product-box {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: calc(25% - 30px);
    margin: 0 15px 30px;
    padding-right: 0;
}

.no-slider .product-box:nth-last-child(-n+4) {
    margin: 0 15px 0;
}

.no-slider.five-product .product-box {
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: calc(20% - 30px);
    margin: 0 15px 30px;
}

.no-slider.five-product .product-box:nth-last-child(-n+5) {
    margin: 0 15px 0;
}

.product-border {
    border: 1px solid #e8ebf2;
}

.digipro-box {
    border-radius: 10px;
    border: 1px solid #f1f4f7;
    background-color: #ffffff;
    margin-right: 15px;
    margin-left: 15px;
    overflow: hidden;
}

.digipro-box:hover .img-wrraper img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.digipro-box .img-wrraper img {
    width: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.digipro-box .product-detail {
    padding: 20px;
}

.digipro-box .product-detail h4 {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(0, 0, 0, 0.7);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 5px;
}

.digipro-box .product-detail h5 {
    color: #444444;
    font-size: 14px;
    /*text-transform: capitalize;*/
    font-weight: 400;
}

.digipro-box .product-detail .sale-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 10px;
}

.digipro-box .product-detail .sale-box ul li i {
    color: #ffa800;
}

.digipro-box .product-detail .sale-box h4.price {
    color: #444444;
}

.digipro-box .product-detail .sale-box .pro-sale h4 {
    color: #444444;
}

.digipro-box .product-detail .pro-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 8px;
}

.digipro-box .product-detail .pro-btn .btn {
    padding: 10px 15px;
    text-transform: uppercase;
    border-radius: 5px;
    font-size: 12px;
}

.digipro-box .product-detail .pro-btn .btn:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    outline: none;
}

.digipro-box .product-detail .pro-btn .btn:last-child {
    background-color: #ef9f77;
}

/*============================
   15. Hot Deal CSS start
==============================*/
.hotdeal-right-nav {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hot-deal.space-abjust .hot-deal-contain {
    padding: 25px;
}

.hot-deal .hot-deal-heading {
    margin-bottom: 25px;
}

.hot-deal .hot-deal-heading h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: #444444;
}

.hot-deal .hotdeal-block1>div:nth-child(2) {
    padding-left: 25px;
}

.hot-deal.hotdeal-first .hot-deal-contain .hotdeal-right-slick .img-wrraper>div {
    width: 100%;
    height: 455px;
}

.hot-deal.hotdeal-first .hot-deal-contain h5,
.hot-deal.hotdeal-third .hot-deal-contain h5 {
    font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 10px;
}

.hot-deal.hotdeal-first .hot-deal-contain .rating,
.hot-deal.hotdeal-third .hot-deal-contain .rating {
    margin-bottom: 12px;
}

.hot-deal.hotdeal-first .hot-deal-contain p,
.hot-deal.hotdeal-third .hot-deal-contain p {
    margin-bottom: 12px;
}

.hot-deal.hotdeal-first .hot-deal-contain .price,
.hot-deal.hotdeal-third .hot-deal-contain .price {
    margin-bottom: 18px;
}

.hot-deal.hotdeal-first .hot-deal-contain .timer,
.hot-deal.hotdeal-third .hot-deal-contain .timer {
    margin-bottom: 30px;
}

.hot-deal.hotdeal-two .hot-deal-contain {
    padding: 30px;
}

.hot-deal.hotdeal-two .hot-deal-contain.b-g-white {
    background-color: #ffffff;
}

.hot-deal.hotdeal-two .hot-deal-contain h5 {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 8px;
}

.hot-deal.hotdeal-two .hot-deal-contain .rating {
    margin-bottom: 8px;
}

.hot-deal.hotdeal-two .hot-deal-contain p {
    margin-bottom: 7px;
}

.hot-deal.hotdeal-two .hot-deal-contain h6 {
    margin-bottom: 5px;
}

.hot-deal.hotdeal-two .hot-deal-contain .timer {
    margin-bottom: 15px;
}

.hot-deal.hotdeal-two .hot-deal-contain .timer span {
    display: inline-grid;
    background-color: transparent;
    text-align: center;
    font-weight: 700;
    color: #000000;
    margin: 0 4px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    padding: 0;
    line-height: 1;
}

.hot-deal.hotdeal-two .hot-deal-contain .timer span:first-child {
    margin-left: 0;
}

.hot-deal.hotdeal-two .hot-deal-contain .timer span:last-child {
    margin-right: 0;
}

.hot-deal.hotdeal-two .hot-deal-contain .timer span .timer-cal {
    font-size: 12px;
    color: #777777;
}

.hot-deal.hotdeal-two .hot-deal-contain .timer span>span {
    font-weight: 400;
    font-size: 12px;
    padding: 0;
    margin-left: 0;
    /*text-transform: capitalize;*/
}

.hot-deal.hotdeal-two .hot-deal-contain .timer p {
    margin: 0;
}

.hot-deal.hotdeal-two .hot-deal-contain .img-wrraper {
    border: 1px solid #dddddd;
    margin-right: 2px;
}

.hot-deal.hotdeal-two .hot-deal-contain .img-wrraper>div {
    width: 100%;
    height: 260px;
}

.hot-deal.hotdeal-two .hot-deal-contain .hotdeal-right-nav-1 img {
    width: 65px;
    border: 1px solid #dddddd;
    margin: 0 0 10px auto;
}

.hot-deal.hotdeal-two .hot-deal-contain .hotdeal-right-nav-1 img:last-child {
    margin-bottom: 0;
}

.hot-deal.hotdeal-two .hot-deal-contain .btn {
    padding: 12px 25px;
}

.hot-deal.hotdeal-third .hot-deal-contain {
    padding-top: 30px;
}

.hot-deal.hotdeal-third .hot-deal-contain .timer span {
    background-color: #eeeeee;
}

.hot-deal.hotdeal-third .hot-deal-contain .hotdeal-right-slick .img-wrraper>div {
    width: 100%;
    height: 411px;
}

.hot-deal .hot-deal-contain {
    background-color: #f2f2f2;
    padding: 50px;
}

.hot-deal .hot-deal-contain h5 {
    color: #444444;
    letter-spacing: 0.05em;
    /*text-transform: capitalize;*/
}

.hot-deal .hot-deal-contain .rating {
    line-height: 1;
}

.hot-deal .hot-deal-contain .rating i {
    color: #ffa800;
}

.hot-deal .hot-deal-contain p {
    line-height: 1.6;
    letter-spacing: 0.05em;
}

.hot-deal .hot-deal-contain h6 {
    color: #042c49;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 18px;
}

.hot-deal .hot-deal-contain h6 span {
    margin-left: 10px;
    color: #acacac;
    text-decoration: line-through;
}

.hot-deal .hot-deal-contain .timer span {
    display: inline-grid;
    background-color: #ffffff;
    padding: 12px 0 18px;
    text-align: center;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    color: #000000;
    min-width: 60px;
}

.hot-deal .hot-deal-contain .timer span:nth-child(n+2) {
    margin-left: calc(2px + (8 - 2) * ((100vw - 320px) / (1920 - 320)));
}

.hot-deal .hot-deal-contain .timer span .padding-l {
    display: none;
}

.hot-deal .hot-deal-contain .timer span .timer-cal {
    font-size: 12px;
    color: #777777;
}

.hot-deal .hot-deal-contain .timer span>span {
    color: #acacac;
    font-weight: 400;
    font-size: 14px;
    padding: 0;
    margin-left: 0;
    /*text-transform: capitalize;*/
}

.hot-deal .hot-deal-contain .timer p {
    margin-bottom: 0;
}

.hot-deal .hot-deal-contain .btn {
    border-radius: 5px;
    /*text-transform: capitalize;*/
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.hot-deal .hot-deal-contain .hot-deal-subcontain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hot-deal .hot-deal-contain.hot-deal-contain2 {
    padding-top: 30px;
}

.hot-deal .hot-deal-contain.hot-deal-contain2 .hot-deal-center .timer span {
    background-color: #eeeeee;
}

.hot-deal .hot-deal-contain .hotdeal-right-slick.border {
    border: 1px solid #dddddd;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    line-height: 0.8;
}

.hot-deal .hot-deal-contain .hotdeal-right-slick.border:hover {
    border: 1px solid #042c49;
}

.hot-deal .hot-deal-contain .hotdeal-right-nav img {
    height: 130px;
    width: auto;
    margin-left: auto;
    border: 1px solid #dddddd;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.hot-deal .hot-deal-contain .hotdeal-right-nav img:hover {
    border: 1px solid #042c49;
}

.hot-deal .hot-deal-contain .slick-slider .slick-arrow {
    display: none !important;
}

.hot-deal .slick-prev {
    top: 35px;
    right: 50px;
    left: unset;
    z-index: 9;
}

.hot-deal .slick-prev:before {
    color: #999999;
    opacity: 1;
    font: normal normal normal 30px/1 FontAwesome;
    content: '\f104';
}

.hot-deal .slick-next {
    top: 35px;
    right: 25px;
    z-index: 9;
}

.hot-deal .slick-next:before {
    color: #999999;
    opacity: 1;
    content: '\f105';
    font: normal normal normal 30px/1 FontAwesome;
}

.hot-deal-padding {
    padding-right: 0;
    padding-left: 0;
}

.hot-1>div:first-child {
    padding-right: 0;
}

.hot-1>div:nth-child(2) {
    padding-left: 0;
    padding-right: 0;
}

.hot-1>div:last-child {
    padding-left: 0;
}

.hotdeal-block2>div {
    padding-right: 0;
}

.hotdeal-second .hotdeal-box {
    background-color: #ffffff;
    margin-right: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hotdeal-second .hotdeal-box .img-wrapper {
    width: 50%;
}

.hotdeal-second .hotdeal-box .img-wrapper a {
    width: 100%;
    height: 330px;
}

.hotdeal-second .hotdeal-box .hotdeal-contain {
    padding: 20px;
    width: 50%;
}

.hotdeal-second .hotdeal-box .hotdeal-contain h3 {
    /*text-transform: capitalize;*/
    color: #000000;
    font-weight: 600;
    margin-bottom: 8px;
    line-height: 1;
    font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.hotdeal-second .hotdeal-box .hotdeal-contain h5 {
    margin-bottom: 5px;
    color: #042c49;
}

.hotdeal-second .hotdeal-box .hotdeal-contain h5 span {
    text-decoration: line-through;
    color: #acacac;
}

.hotdeal-second .hotdeal-box .hotdeal-contain p {
    margin-bottom: 5px;
}

.hotdeal-second .hotdeal-box .hotdeal-contain ul {
    margin-bottom: 15px;
}

.hotdeal-second .hotdeal-box .hotdeal-contain ul li i {
    color: #ffa800;
}

.hotdeal-second .hotdeal-box .hotdeal-contain .timer2 {
    margin-bottom: 20px;
}

.hotdeal-second .hotdeal-box .hotdeal-contain .timer2 p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.hotdeal-second .hotdeal-box .hotdeal-contain .timer2 span {
    display: inline-grid;
    background-color: rgba(199, 109, 98, 0.08);
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #042c49;
    min-width: 55px;
    border-radius: 3px;
}

.hotdeal-second .hotdeal-box .hotdeal-contain .timer2 span:nth-child(n+2) {
    margin-left: 5px;
}

.hotdeal-second .hotdeal-box .hotdeal-contain .timer2 span>span {
    background-color: transparent;
    border: none;
    padding: 0;
    /*text-transform: capitalize;*/
    font-size: 14px;
    color: #ef9f77;
}

.hotdeal-second .hotdeal-box .hotdeal-contain .btn {
    /*text-transform: capitalize;*/
}

.layout-3-hotdeal>div:first-child {
    padding-right: 0;
}

.layout-3-hotdeal>div:nth-child(2) {
    padding-right: 0;
}

.layout-3-hotdeal>div:nth-child(4) {
    padding-left: 0;
}

/*============================
   16. Header CSS start
==============================*/
.top-header {
    background-color: #042c49;
    padding: 10px 0;
    z-index: 99;
}

.top-header .top-header-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.top-header .top-header-left .shpping-order {
    margin-right: 35px;
}

.top-header .top-header-left .shpping-order h6 {
    font-family: "Raleway", sans-serif, "PT Sans", sans-serif;
    color: #ffffff;
    /*text-transform: capitalize;*/
    font-size: 13px;
}

.top-header .top-header-left .app-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.top-header .top-header-left .app-link h6 {
    font-size: 13px;
    color: #ffffff;
    /*text-transform: capitalize;*/
    font-family: "Raleway", sans-serif, "PT Sans", sans-serif;
}

.top-header .top-header-left .app-link ul li {
    margin-left: 10px;
}

.top-header .top-header-left .app-link ul li a i {
    color: #ffffff;
}

.top-header .top-header-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    height: 100%;
}

.top-header .top-header-right .top-menu-block {
    text-align: right;
}

.top-header .top-header-right .top-menu-block ul {
    padding: 0;
}

.top-header .top-header-right .top-menu-block ul li {
    margin-right: 20px;
}

.top-header .top-header-right .top-menu-block ul li a {
    color: #ffffff;
    /*text-transform: capitalize;*/
    font-family: "Raleway", sans-serif, "PT Sans", sans-serif;
    font-size: 13px;
    font-weight: 800;
    letter-spacing: 1px;
    height: 100%;
}

.top-header .top-header-right .top-menu-block ul li:last-child {
    margin-right: 0;
}

.top-header .top-header-right .language-block {
    margin-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.top-header .top-header-right .language-block .language-dropdown {
    position: relative;
}

.top-header .top-header-right .language-block .language-dropdown .language-dropdown-click {
    color: #ffffff;
    font-family: "Raleway", sans-serif, "PT Sans", sans-serif;
    font-size: 13px;
    font-weight: 800;
    /*text-transform: capitalize;*/
}

.top-header .top-header-right .language-block .language-dropdown .language-dropdown-click i {
    margin-left: 10px;
}

.top-header .top-header-right .language-block .language-dropdown .language-dropdown-open {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 1px 0 #dddddd;
    box-shadow: 0 0 1px 0 #dddddd;
    padding: 10px 20px;
    margin-top: 10px;
    position: absolute;
    top: 100%;
    right: 0;
    left: unset;
    z-index: 11;
}

.top-header .top-header-right .language-block .language-dropdown .language-dropdown-open li {
    display: block;
    margin-bottom: 10px;
}

.top-header .top-header-right .language-block .language-dropdown .language-dropdown-open li:last-child {
    margin-bottom: 0;
}

.top-header .top-header-right .language-block .language-dropdown .language-dropdown-open li a {
    color: #444444;
    font-size: 12px;
    font-family: "Raleway", sans-serif, "PT Sans", sans-serif;
    /*text-transform: capitalize;*/
}

.top-header .top-header-right .language-block .curroncy-dropdown {
    position: relative;
    margin-left: 20px;
}

.top-header .top-header-right .language-block .curroncy-dropdown .curroncy-dropdown-click {
    color: #ffffff;
    font-family: "Raleway", sans-serif, "PT Sans", sans-serif;
    font-size: 13px;
    font-weight: 800;
    /*text-transform: capitalize;*/
}

.top-header .top-header-right .language-block .curroncy-dropdown .curroncy-dropdown-click i {
    margin-left: 10px;
}

.top-header .top-header-right .language-block .curroncy-dropdown .curroncy-dropdown-open {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 1px 0 #dddddd;
    box-shadow: 0 0 1px 0 #dddddd;
    margin-top: 10px;
    padding: 10px 20px;
    position: absolute;
    top: 100%;
    right: 0;
    left: unset;
    z-index: 11;
}

.top-header .top-header-right .language-block .curroncy-dropdown .curroncy-dropdown-open li {
    display: block;
    margin-bottom: 8px;
}

.top-header .top-header-right .language-block .curroncy-dropdown .curroncy-dropdown-open li:last-child {
    margin-bottom: 0;
}

.top-header .top-header-right .language-block .curroncy-dropdown .curroncy-dropdown-open li a {
    color: #444444;
    font-size: 12px;
    font-family: "Raleway", sans-serif, "PT Sans", sans-serif;
    /*text-transform: capitalize;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.top-header .top-header-right .language-block .curroncy-dropdown .curroncy-dropdown-open li a i {
    margin-right: 10px;
}

.top-header.top-header-inverse {
    background-color: #ef9f77;
}

.input-block .input-box .big-deal-form {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #dddddd;
}

.input-block .input-box .big-deal-form .input-group {
    height: 58px;
}

.input-block .input-box .big-deal-form .input-group .form-control {
    border-radius: 0;
    border: none;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    color: #939b9e;
}

.input-block .input-box .big-deal-form .input-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.input-block .input-box .big-deal-form .input-group span {
    padding: 10px 20px;
    background-color: #ffffff;
    -ms-flex-item-align: center;
    align-self: center;
}

.input-block .input-box .big-deal-form .input-group span i {
    font-size: 20px;
    color: #939b9e;
}

.input-block .input-box .big-deal-form .input-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    font-weight: 700;
    color: #939b9e;
    background: url("/../assets/images/layout-1/drop-1.png") no-repeat scroll 20px 23px;
    text-align: center;
    -moz-text-align-last: center;
    text-align-last: center;
    width: 175px;
    height: 100%;
    text-transform: uppercase;
}

.input-block .input-box .big-deal-form .input-group .input-group-text {
    background-color: transparent;
    border: none;
    padding: 0;
}

.layout-header1 {
    background-color: #ffffff;
    padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
}

.layout-header1 .main-menu-block {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 10;
}

.layout-header1 .main-menu-block .menu-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block {
    margin-right: 15px;
    cursor: pointer;
    display: none;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block span i {
    font-size: 24px;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    height: 100vh;
    z-index: 99;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow: scroll;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li {
    display: block;
    border-bottom: 1px solid #dddddd;
    padding: 10px 15px;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li.mor-slide-open ul li {
    padding-left: 0;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li a {
    color: #333333;
    /*text-transform: capitalize;*/
    font-size: 14px;
    font-weight: 600;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li:first-child {
    padding: 0;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li:last-child {
    border-bottom: none;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li:last-child a {
    color: #042c49;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li:last-child a i {
    font-size: 20px;
    margin-left: 10px;
    float: right;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li .nav-sm-back {
    font-size: 16px;
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #dddddd;
    text-align: right;
    padding: 15px;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li:hover>li a {
    color: #042c49;
}

.layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide li {
    display: block;
    border-bottom: 1px solid #dddddd;
    padding: 7px 10px;
}

.layout-header1 .main-menu-block .menu-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    float: right;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.layout-header1 .main-menu-block .menu-right .toggle-nav {
    margin-left: 15px;
}

.layout-header1 .main-menu-block .menu-right .icon-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li {
    position: relative;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li svg {
    fill: #999999;
    width: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li .cart-item {
    display: inline-block;
    color: #042c49;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "PT Sans", sans-serif;
    margin-left: 10px;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li .cart-item span {
    display: block;
    color: #000000;
    margin-top: 3px;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block svg {
    fill: #ffffff;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li .item-count-contain {
    position: absolute;
    top: -8px;
    left: 13px;
    padding-bottom: 1px;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block {
    background-color: #042c49;
    padding: 15px 20px;
    margin-left: 20px;
    position: relative;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block i {
    font-size: 29px;
    color: #ffffff;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block .cart-product {
    background-color: #ffffff;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 11px;
    position: absolute;
    top: 3px;
    right: 15px;
    line-height: 0;
    color: #042c49;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block .item-count-contain {
    position: absolute;
    top: 3px;
    left: 29px;
}

.layout-header1 .main-menu-block .menu-right .icon-nav ul li.mobile-search,
.layout-header1 .main-menu-block .menu-right .icon-nav ul li.mobile-setting {
    display: none;
}

.layout-header1 .main-menu-block .menu-right .menu-banner {
    margin-top: 20px;
}

.layout-header1.layout-header1-inverse .main-menu-block .menu-right .icon-nav .cart-block {
    background-color: #ef9f77;
}

.layout-header1 .searchbar-input {
    display: none;
}

.category-header {
    background-color: #042c49;
}

.category-header .navbar-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 9;
}

.category-header .navbar-menu .category-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.category-header .navbar-menu .category-left .nav-block {
    min-width: 270px;
    margin-right: 10px;
}

.category-header .navbar-menu .category-left .nav-block .nav-left {
    position: absolute;
    top: 0;
    width: 270px;
    z-index: 1;
    overflow: hidden;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #444444;
    padding: 28px 0;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .navbar h5 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    margin-left: 16px;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .navbar-toggler {
    width: 17px;
    height: 17px;
    padding: 0;
    background-color: #ffffff;
    color: #444444;
    border-radius: 50%;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat {
    padding: 20px;
    margin-top: 15px;
    margin-right: 10px;
    background-color: #ffffff;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li {
    display: block;
    padding-bottom: 10px;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li:last-child {
    padding-bottom: 0;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li a {
    color: #444444;
    font-size: 14px;
    /*text-transform: capitalize;*/
    // letter-spacing: 0.03em;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li img {
    margin-right: 10px;
    border: 2px solid #f0f0f0;
    border-radius: 50%;
    padding: 3px;
    height: 46.5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li:hover>a {
    color: #042c49;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li:hover>img {
    border: 2px solid #ef9f77;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat .mor-slide-click {
    color: #042c49;
    font-size: 16px;
    font-weight: 700;
    /*text-transform: capitalize;*/
    line-height: 1;
    display: block;
    width: 100%;
    margin-top: 10px;
    letter-spacing: 0.5px;
    background-color: transparent;
    border: none;
    outline: none;
    text-align: left;
}

.category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat .mor-slide-click i {
    float: right;
    font-size: 20px;
}

.category-header .navbar-menu .category-left .input-block {
    width: 745px;
}

.category-header .navbar-menu .category-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.category-header .navbar-menu .category-right .gift-block {
    background-color: #ef9f77;
    padding: 17px 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
    width: 255px;
    margin-left: 15px;
    border: 0;
}

.category-header .navbar-menu .category-right .gift-block .grif-icon i {
    font-size: 35px;
}

.category-header .navbar-menu .category-right .gift-block .gift-offer {
    margin-left: 10px;
}

.category-header .navbar-menu .category-right .gift-block .gift-offer p {
    text-transform: uppercase;
    line-height: 1;
}

.category-header .navbar-menu .category-right .gift-block .gift-offer span {
    font-size: 20px;
    /*text-transform: capitalize;*/
    font-weight: 700;
    line-height: 1.2;
}

.category-header .navbar-menu .category-right .contact-block {
    padding: 20px 0;
    -ms-flex-item-align: center;
    align-self: center;
}

.category-header .navbar-menu .category-right .contact-block i {
    font-size: 28px;
    color: #ffffff;
}

.category-header .navbar-menu .category-right .contact-block span {
    font-size: 14px;
    color: #ffffff;
    margin-left: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.category-header .navbar-menu .category-right .contact-block span span {
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
}

.category-header.category-header-inverse .navbar-menu .category-left .nav-block .nav-left .navbar {
    background-color: #ef9f77;
}

.category-header.category-header-inverse .navbar-menu .category-left .nav-block .nav-left .navbar-toggler {
    color: #ef9f77;
}

.layout-header2 {
    background-color: #ffffff;
    padding-top: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
}

.layout-header2 .main-menu-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.layout-header2 .main-menu-block .sm-nav-block {
    margin-right: 15px;
    display: none;
}

.layout-header2 .main-menu-block .sm-nav-block span i {
    font-size: 24px;
}

.layout-header2 .main-menu-block .sm-nav-block .nav-slide {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    height: 100vh;
    z-index: 99;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow: scroll;
}

.layout-header2 .main-menu-block .sm-nav-block .nav-slide li {
    display: block;
    border-bottom: 1px solid #dddddd;
    padding: 10px;
}

.layout-header2 .main-menu-block .sm-nav-block .nav-slide li.mor-slide-open ul li {
    padding-left: 0;
}

.layout-header2 .main-menu-block .sm-nav-block .nav-slide li a {
    color: #444444;
    /*text-transform: capitalize;*/
    font-size: 14px;
    font-weight: 500;
}

.layout-header2 .main-menu-block .sm-nav-block .nav-slide li:last-child {
    border-bottom: none;
}

.layout-header2 .main-menu-block .sm-nav-block .nav-slide li:last-child a i {
    font-size: 20px;
    margin-left: 10px;
    float: right;
}

.layout-header2 .main-menu-block .sm-nav-block .nav-slide li .nav-sm-back {
    font-size: 20px;
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #dddddd;
    text-align: right;
    padding: 17px;
}

.layout-header2 .main-menu-block .sm-nav-block .nav-slide li .mor-slide-click {
    color: #042c49;
}

.layout-header2 .main-menu-block .sm-nav-block .nav-slide li:hover>li a {
    color: #ef9f77;
}

/*    .layout-header2 .main-menu-block .brand-logo {
      margin-right: 45px; }*/
.layout-header2 .main-menu-block .input-block {
    width: 750px;
}

.layout-header2 .main-menu-block .icon-block {
    margin-left: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.layout-header2 .main-menu-block .icon-block ul li {
    position: relative;
    display: none;
}

.layout-header2 .main-menu-block .icon-block ul li:nth-child(n+4) {
    display: inline-block;
}

.layout-header2 .main-menu-block .icon-block ul li svg {
    width: 32px;
    fill: #444444;
}

.layout-header2 .main-menu-block .icon-block ul li .cart-block {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.layout-header2 .main-menu-block .icon-block ul li .cart-block .cart-item {
    margin-left: 10px;
}

.layout-header2 .main-menu-block .icon-block ul li .cart-block .cart-item h5 {
    color: #444444;
    text-transform: uppercase;
    // letter-spacing: 0.03em;
    font-family: "PT Sans", sans-serif;
}

.layout-header2 .main-menu-block .icon-block ul li .item-count-contain {
    position: absolute;
    top: -16px;
    left: 16px;
}

.layout-header2 .main-menu-block .icon-block ul li:nth-child(n+5) {
    display: inline-block;
}

.layout-header2 .main-menu-block .menu-nav {
    display: none;
}

.layout-header2 .searchbar-input {
    display: none;
}

.category-header-2 {
    background-color: #c1151b;
    position: relative;
}

.category-header-2 .navbar-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    z-index: 9;
}

.category-header-2 .navbar-menu .logo-block {
    display: none;
}

.category-header-2 .navbar-menu .nav-block {
    min-width: 255px;
}

.category-header-2 .navbar-menu .nav-block .nav-left {
    position: absolute;
    width: 255px;
    z-index: 1;
    overflow: hidden;
}

.category-header-2 .navbar-menu .nav-block .nav-left .navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #042c49;
    padding: 28px 0;
    cursor: pointer;
}

.category-header-2 .navbar-menu .nav-block .nav-left .navbar h5 {
    text-transform: uppercase;
    font-size: 14px;
    /* letter-spacing: 1px;*/
    margin-left: 16px;
}

.category-header-2 .navbar-menu .nav-block .nav-left .navbar-toggler {
    width: 17px;
    height: 17px;
    padding: 0;
    background-color: #ffffff;
    color: #042c49;
    border-radius: 50%;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.category-header-2 .navbar-menu .nav-block .nav-left .nav-cat {
    padding: 20px;
    margin-top: 15px;
    background-color: #ffffff;
}

.category-header-2 .navbar-menu .nav-block .nav-left .nav-cat li {
    display: block;
    padding-bottom: 10px;
}

.category-header-2 .navbar-menu .nav-block .nav-left .nav-cat li a {
    color: #444444;
    font-size: 14px;
    /*text-transform: capitalize;*/
    // letter-spacing: 0.03em;
}

.category-header-2 .navbar-menu .nav-block .nav-left .nav-cat li img {
    margin-right: 10px;
    border: 2px solid #f0f0f0;
    border-radius: 50%;
    padding: 3px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.category-header-2 .navbar-menu .nav-block .nav-left .nav-cat li:hover>a {
    color: #042c49;
}

.category-header-2 .navbar-menu .nav-block .nav-left .nav-cat li:hover>img {
    border: 2px solid #ef9f77;
}

.category-header-2 .navbar-menu .nav-block .nav-left .nav-cat li .mor-slide-click {
    color: #042c49;
    font-size: 16px;
    font-weight: 700;
    /*text-transform: capitalize;*/
    display: block;
    width: 100%;
    margin-top: 10px;
    letter-spacing: 0.5px;
}

.category-header-2 .navbar-menu .nav-block .nav-left .nav-cat li .mor-slide-click i {
    font-size: 20px;
    float: right;
}

.category-header-2 .navbar-menu .menu-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.category-header-2 .navbar-menu .menu-block .pixelstrap li .label-nav {
    background-color: #042c49;
    top: 7px;
}

.category-header-2 .navbar-menu .menu-block .pixelstrap li .label-nav:before {
    border-top: 5px solid #042c49;
}

.category-header-2 .navbar-menu .menu-block .pixelstrap .dark-menu-item {
    padding-top: 25px;
    padding-bottom: 25px;
    color: white;
}

.category-header-2 .navbar-menu .icon-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
}

.category-header-2 .navbar-menu .icon-block ul li {
    position: relative;
}

.category-header-2 .navbar-menu .icon-block ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.category-header-2 .navbar-menu .icon-block ul li:nth-child(n+2) {
    margin-left: 20px;
}

.category-header-2 .navbar-menu .icon-block ul li .item-count-contain {
    position: absolute;
    top: -9px;
    left: 14px;
}

.category-header-2 .navbar-menu .icon-block ul li .cart-item {
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "PT Sans", sans-serif;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin-left: 10px;
}

.category-header-2 .navbar-menu .icon-block ul li .cart-item span {
    display: block;
    margin-top: 3px;
}

.category-header-2 .navbar-menu .icon-block ul li svg {
    width: 30px;
    height: auto;
    fill: #ffffff;
}

.category-header-2 .navbar-menu .icon-block ul li.mobile-search,
.category-header-2 .navbar-menu .icon-block ul li.mobile-setting,
.category-header-2 .navbar-menu .icon-block ul li.mobile-cart {
    display: none;
}

.category-header-2 .navbar-menu .category-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.category-header-2 .navbar-menu .category-right .gift-block {
    background-color: #042c49;
    padding: 17px 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
    width: 255px;
    border: 0;
    cursor: pointer;
}

.category-header-2 .navbar-menu .category-right .gift-block .grif-icon i {
    font-size: 35px;
}

.category-header-2 .navbar-menu .category-right .gift-block .gift-offer {
    margin-left: 10px;
}

.category-header-2 .navbar-menu .category-right .gift-block .gift-offer p {
    text-transform: uppercase;
    line-height: 1;
}

.category-header-2 .navbar-menu .category-right .gift-block .gift-offer span {
    font-size: 20px;
    /*text-transform: capitalize;*/
    font-weight: 700;
    line-height: 1.2;
}

.category-header-2 .navbar-menu .category-right .contact-block {
    padding: 20px 28px;
    -ms-flex-item-align: center;
    align-self: center;
    background-color: #042c49;
    height: 100%;
}

.category-header-2 .navbar-menu .category-right .contact-block i {
    font-size: 28px;
    color: #ffffff;
}

.category-header-2 .navbar-menu .category-right .contact-block span {
    font-size: 14px;
    color: #ffffff;
    margin-left: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.category-header-2 .navbar-menu .category-right .contact-block span span {
    font-size: 20px;
    font-weight: 600;
}

.category-header-2 .searchbar-input {
    display: none;
}

.layout-header3 {
    background-color: #ef9f77;
    position: relative;
}

.layout-header3 .main-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}

.layout-header3 .main-menu .menu-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

.layout-header3 .main-menu .menu-left .sm-nav-block {
    margin-right: 15px;
    display: none;
}

.layout-header3 .main-menu .menu-left .sm-nav-block span i {
    font-size: 25px;
    color: #ffffff;
}

.layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    height: 100vh;
    z-index: 99;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow: scroll;
}

.layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide li {
    display: block;
    border-bottom: 1px solid #dddddd;
    padding: 7px 10px;
}

.layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide li a {
    color: #444444;
    /*text-transform: capitalize;*/
    font-size: 14px;
    font-weight: 600;
}

.layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide>li:last-child {
    border-bottom: none;
}

.layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide>li:last-child a {
    color: #042c49;
}

.layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide>li:last-child a i {
    font-size: 20px;
    margin-left: 10px;
    float: right;
}

.layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide>li .nav-sm-back {
    font-size: 18px;
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #dddddd;
    text-align: right;
    padding: 9px;
}

.layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide>li:hover>li a {
    color: #042c49;
}

.layout-header3 .main-menu .menu-left .nav-block {
    min-width: 255px;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    width: 255px;
    top: 0;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .navbar {
    padding: 28px 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .navbar h5 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    margin-left: 16px;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .navbar-toggler {
    width: 17px;
    height: 17px;
    padding: 0;
    background-color: #ffffff;
    color: #ef9f77;
    border-radius: 50%;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat {
    padding: 20px;
    margin-top: 25px;
    background-color: #ffffff;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat li {
    display: block;
    padding-bottom: 10px;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat li a {
    color: #444444;
    font-size: 14px;
    /*text-transform: capitalize;*/
    // letter-spacing: 0.03em;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat li img {
    margin-right: 10px;
    border: 2px solid #f0f0f0;
    border-radius: 50%;
    padding: 3px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat li:hover>a {
    color: #042c49;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat li:hover>img {
    border: 2px solid #ef9f77;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat .mor-slide-click a {
    color: #042c49;
    font-size: 16px;
    font-weight: 700;
    display: block;
    width: 100%;
    /*text-transform: capitalize;*/
    line-height: 1;
    letter-spacing: 0.5px;
    margin-top: 10px;
}

.layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat .mor-slide-click a i {
    font-size: 20px;
    float: right;
}

.layout-header3 .main-menu .menu-nav span i {
    color: #ffffff;
    font-size: 25px;
}

.layout-header3 .main-menu .menu-nav .desc-horizontal {
    position: fixed;
    background-color: #ffffff;
    width: 300px;
    border-radius: 0;
    border: 1px solid #dddddd;
    top: 0;
    height: 100vh;
    right: -300px;
    z-index: 99;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.layout-header3 .main-menu .menu-nav .desc-horizontal .desc-back {
    padding: 20px;
    font-size: 18px;
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #dddddd;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.layout-header3 .main-menu .menu-nav .desc-horizontal li .label-nav {
    top: -10px;
    left: 20px;
}

.layout-header3 .main-menu .input-block .input-box {
    width: 600px;
}

.layout-header3 .main-menu .input-block .input-box .big-deal-form {
    width: 100%;
    background-color: #ffffff;
    border: 1px solid #dddddd;
}

.layout-header3 .main-menu .input-block .input-box .big-deal-form .input-group {
    height: 43px;
}

.layout-header3 .main-menu .input-block .input-box .big-deal-form .input-group .form-control {
    border-radius: 0;
    border: none;
    padding: 0 13px;
}

.layout-header3 .main-menu .input-block .input-box .big-deal-form .input-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.layout-header3 .main-menu .input-block .input-box .big-deal-form .input-group span {
    padding: 10px 20px;
    background-color: #042c49;
    -ms-flex-item-align: center;
    align-self: center;
}

.layout-header3 .main-menu .input-block .input-box .big-deal-form .input-group span i {
    font-size: 20px;
    color: #ffffff;
}

.layout-header3 .main-menu .input-block .input-box .big-deal-form .input-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    outline: none;
    font-weight: 700;
    color: #444444;
    background: url("/../assets/images/layout-1/drop-1.png") no-repeat scroll 20px;
    text-align: center;
    -moz-text-align-last: center;
    text-align-last: center;
    width: 175px;
    text-transform: uppercase;
    border-left: 1px solid #dddddd;
}

.layout-header3 .main-menu .icon-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
}

.layout-header3 .main-menu .icon-block ul li {
    position: relative;
}

.layout-header3 .main-menu .icon-block ul li svg {
    fill: #ffffff;
    width: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.layout-header3 .main-menu .icon-block ul li.mobile-cart .item-count-contain {
    position: absolute;
    top: -10px;
    left: 9px;
}

.layout-header3 .main-menu .icon-block ul li.mobile-wishlist .cart-item {
    display: inline-block;
    color: #ffffff;
    font-weight: 400;
    text-transform: uppercase;
    font-family: "PT Sans", sans-serif;
    letter-spacing: 0.5px;
    margin-left: 5px;
}

.layout-header3 .main-menu .icon-block ul li.mobile-wishlist .cart-item span {
    display: block;
    margin-top: 3px;
    font-weight: 700;
}

.layout-header3 .main-menu .icon-block ul li.mobile-wishlist .item-count-contain {
    top: -5px;
    left: 14px;
}

.layout-header3 .main-menu .icon-block ul li.mobile-search,
.layout-header3 .main-menu .icon-block ul li.mobile-setting {
    display: none;
}

.layout-header3 .main-menu .icon-block .toggle-nav-desc {
    margin-left: 15px;
    display: none;
}

.layout-header3 .main-menu .icon-block .toggle-nav-desc i {
    font-size: 25px;
    color: #ffffff;
}

.layout-header3 .main-menu .gift-block {
    background-color: #c05c4f;
    padding: 17px 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
    width: auto;
    border: 0;
    cursor: pointer;
}

.layout-header3 .main-menu .gift-block .grif-icon i {
    font-size: 35px;
}

.layout-header3 .main-menu .gift-block .gift-offer {
    margin-left: 10px;
}

.layout-header3 .main-menu .gift-block .gift-offer p {
    text-transform: uppercase;
    line-height: 1;
}

.layout-header3 .main-menu .gift-block .gift-offer span {
    font-size: 20px;
    /*text-transform: capitalize;*/
    font-weight: 700;
    line-height: 1.2;
}

.layout-header4 {
    padding: 15px 0;
    background-color: #ffffff;
    position: relative;
}

.layout-header4 .contact-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.layout-header4 .contact-block .sm-nav-block {
    margin-right: 15px;
}

.layout-header4 .contact-block .sm-nav-block .sm-nav-btn {
    display: none;
}

.layout-header4 .contact-block .sm-nav-block span i {
    font-size: 24px;
}

.layout-header4 .contact-block .sm-nav-block .nav-slide {
    position: fixed;
    top: 0;
    left: -300px;
    width: 300px;
    border-radius: 0;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    height: 100vh;
    z-index: 99;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    overflow: scroll;
    cursor: pointer;
}

.layout-header4 .contact-block .sm-nav-block .nav-slide li {
    display: block;
    border-bottom: 1px solid #dddddd;
    padding: 7px 10px;
}

.layout-header4 .contact-block .sm-nav-block .nav-slide li ul li {
    padding-left: 0;
}

.layout-header4 .contact-block .sm-nav-block .nav-slide li a {
    color: #444444;
    /*text-transform: capitalize;*/
    font-size: 14px;
    font-weight: 600;
}

.layout-header4 .contact-block .sm-nav-block .nav-slide li:first-child {
    padding: 0;
}

.layout-header4 .contact-block .sm-nav-block .nav-slide li:last-child {
    border-bottom: none;
}

.layout-header4 .contact-block .sm-nav-block .nav-slide li:last-child a i {
    font-size: 20px;
    margin-left: 10px;
    float: right;
}

.layout-header4 .contact-block .sm-nav-block .nav-slide li .nav-sm-back {
    font-size: 16px;
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #dddddd;
    text-align: right;
    padding: 7px;
}

.layout-header4 .contact-block .desc-nav-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.layout-header4 .contact-block .desc-nav-block ul li:nth-child(n+2) {
    margin-left: 30px;
}

.layout-header4 .contact-block .desc-nav-block ul li svg {
    width: 30px;
    fill: #042c49;
    display: inline-block;
}

.layout-header4 .contact-block .desc-nav-block ul li .contact-item {
    color: #777777;
    text-transform: uppercase;
    margin-left: 10px;
    font-size: 12px;
    display: inline-block;
}

.layout-header4 .contact-block .desc-nav-block ul li .contact-item span {
    font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    color: #444444;
    display: block;
    line-height: 1;
    font-weight: 700;
}

.layout-header4 .contact-block .desc-nav-block ul li.mobile-user {
    color: #999999;
    font-size: calc(20px + (33 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.layout-header4 .logo-block {
    text-align: center;
}

.layout-header4 .icon-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: center;
    align-self: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.layout-header4 .icon-block ul li {
    position: relative;
}

.layout-header4 .icon-block ul li:nth-child(n+2) {
    margin-left: 25px;
}

.layout-header4 .icon-block ul li .item-count-contain {
    top: -5px;
    left: 15px;
}

.layout-header4 .icon-block ul li a svg {
    fill: #999999;
    width: 30px;
}

.layout-header4 .icon-block ul li a .cart-item {
    display: inline-block;
    color: #444444;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "PT Sans", sans-serif;
}

.layout-header4 .icon-block ul li a .cart-item span {
    display: block;
    letter-spacing: 1px;
    margin-top: 2px;
}

.layout-header4 .icon-block ul li.mobile-search,
.layout-header4 .icon-block ul li.mobile-setting,
.layout-header4 .icon-block ul li.mobile-user {
    display: none;
}

.layout-header4 .icon-block ul li.mobile-cart svg {
    fill: #042c49;
}

.layout-header4 .icon-block .toggle-nav {
    display: none;
}

.category-header-4 {
    background-color: #042c49;
    position: relative;
}

.category-header-4 .navbar-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.category-header-4 .navbar-menu .category-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.category-header-4 .navbar-menu .category-left .sm-nav-btn {
    margin-right: 15px;
    display: none;
}

.category-header-4 .navbar-menu .category-left .sm-nav-btn i {
    font-size: 24px;
}

.category-header-4 .navbar-menu .category-left .logo-block {
    display: none;
}

.category-header-4 .navbar-menu .category-left .nav-block {
    min-width: 260px;
    position: relative;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left {
    position: absolute;
    width: 260px;
    z-index: 1;
    overflow: hidden;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #ef9f77;
    padding: 28px 0;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .navbar h5 {
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    margin-left: 16px;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .navbar-toggler {
    width: 17px;
    height: 17px;
    padding: 0;
    background-color: #ffffff;
    color: #444444;
    border-radius: 50%;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat {
    padding: 30px 35px;
    margin-top: 15px;
    background-color: #ffffff;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat li {
    display: block;
    padding-bottom: 19px;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat li:last-child {
    padding-bottom: 0;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat li a {
    color: #444444;
    font-size: 14px;
    /*text-transform: capitalize;*/
    letter-spacing: 0.05em;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat li a i {
    margin-right: 15px;
    float: right;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat li:hover>a {
    color: #042c49;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat .mor-slide-click {
    padding-bottom: 0;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat .mor-slide-click a {
    color: #042c49;
    font-size: 16px;
    font-weight: 700;
    display: block;
    width: 100%;
    line-height: 1;
    /*text-transform: capitalize;*/
    letter-spacing: 0.5px;
}

.category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat .mor-slide-click a i {
    float: right;
    font-size: 20px;
}

.category-header-4 .navbar-menu .category-left .nav-block:before {
    width: 100%;
    height: 100%;
    background-color: #ef9f77;
    position: absolute;
    content: '';
    top: 0;
    left: -100%;
    z-index: 9;
}

.category-header-4 .navbar-menu .menu-block .pixelstrap .light-menu-item {
    padding-top: 25px;
    padding-bottom: 25px;
    color: #ffffff;
}

.category-header-4 .navbar-menu .menu-block .pixelstrap li .label-nav {
    background-color: #ef9f77;
    top: 7px;
}

.category-header-4 .navbar-menu .menu-block .pixelstrap li .label-nav:before {
    border-top: 5px solid #ef9f77;
}

.category-header-4 .navbar-menu .category-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.category-header-4 .navbar-menu .category-right .gift-block {
    background-color: #444444;
    padding: 12px 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
    width: 255px;
    border: 0;
    cursor: pointer;
}

.category-header-4 .navbar-menu .category-right .gift-block .grif-icon i {
    font-size: 35px;
}

.category-header-4 .navbar-menu .category-right .gift-block .gift-offer {
    margin-left: 10px;
}

.category-header-4 .navbar-menu .category-right .gift-block .gift-offer p {
    text-transform: uppercase;
    line-height: 1;
}

.category-header-4 .navbar-menu .category-right .gift-block .gift-offer span {
    font-size: 20px;
    /*text-transform: capitalize;*/
    font-weight: 700;
    line-height: 1.2;
}

.category-header-4 .navbar-menu .category-right .icon-block {
    display: none;
}

.category-header-4 .navbar-menu .category-right .icon-block ul li svg {
    width: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    fill: #ffffff;
}

.category-header-4 .navbar-menu .category-right .icon-block ul li .item-count-contain {
    top: -10px;
    right: -5px;
}

.category-header-4 .navbar-menu .category-right .icon-block ul li.mobile-search,
.category-header-4 .navbar-menu .category-right .icon-block ul li.mobile-setting {
    display: none;
}

.category-header-4 .navbar-menu .category-right .icon-block .toggle-nav {
    margin-left: 15px;
}

.category-header-4 .navbar-menu .input-block {
    width: 535px;
    margin-top: auto;
    margin-bottom: auto;
}

.category-header-4 .searchbar-input {
    display: none;
}

.category-header-6 {
    background-color: #ef9f77;
}

.category-header-6 .navbar-menu .category-left .nav-block .nav-left .navbar {
    background-color: #042c49;
}

.category-header-6 .navbar-menu .category-left .nav-block .nav-left .nav-cat li a {
    color: #333333;
}

.category-header-6 .navbar-menu .category-right .gift-block {
    background-color: #042c49;
}

.onhover-dropdown {
    position: relative;
    z-index: 9;
}

.onhover-dropdown .onhover-show-div {
    top: 50px;
    position: absolute;
    z-index: 10;
    background-color: #ffffff;
    -webkit-transition: all linear 0.5s;
    transition: all linear 0.5s;
    min-width: 160px;
    text-align: left;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    left: 0;
    opacity: 0;
    visibility: hidden;
    padding: 15px 20px;
    border: 5px solid #f2f2f2;
}

.onhover-dropdown .onhover-show-div li {
    display: block;
    line-height: 2.2;
    margin-left: 0 !important;
}

.onhover-dropdown .onhover-show-div li a {
    color: #444444;
    /*text-transform: capitalize;*/
    font-size: 14px;
    // letter-spacing: 0.03em;
    font-family: "PT Sans", sans-serif;
}

.onhover-dropdown:hover .onhover-show-div {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
}

.cart-hover-div {
    position: relative;
}

.cart-hover-div .cart-show-div {
    top: 50px;
    position: absolute;
    z-index: 10;
    background-color: #ffffff;
    -webkit-transition: all linear 0.5s;
    transition: all linear 0.5s;
    min-width: 250px;
    text-align: left;
    -webkit-box-shadow: 0 0 1px 0 #dddddd;
    box-shadow: 0 0 1px 0 #dddddd;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    right: 0;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    border: 5px solid #f2f2f2;
}

.cart-hover-div .cart-show-div.right {
    right: unset;
}

.cart-hover-div .cart-show-div li {
    margin-left: 0 !important;
    width: 100%;
}

.cart-hover-div .cart-show-div li .media {
    padding-bottom: 10px;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.cart-hover-div .cart-show-div li .media img {
    height: 70px;
    margin-right: 20px;
    border: 1px solid #dddddd;
}

.cart-hover-div .cart-show-div li .media .media-body {
    -ms-flex-item-align: center;
    align-self: center;
}

.cart-hover-div .cart-show-div li .media .media-body h6 {
    color: #444444;
    /*text-transform: capitalize;*/
    font-family: "PT Sans", sans-serif;
    padding-bottom: 5px;
}

.cart-hover-div .cart-show-div li .total {
    padding: 10px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
}

.cart-hover-div .cart-show-div li .total h6 {
    font-size: 16px;
    color: #8d8d8d;
    /*text-transform: capitalize;*/
    // letter-spacing: 0.03em;
}

.cart-hover-div .cart-show-div li .total h6 span {
    float: right;
}

.cart-hover-div .cart-show-div li .check-btn {
    /*text-transform: capitalize;*/
    font-size: 16px;
    padding-top: 10px;
}

.cart-hover-div .cart-show-div li .check-btn a {
    color: #000000;
    font-family: "PT Sans", sans-serif;
    letter-spacing: 0.05em;
}

.cart-hover-div .cart-show-div li .check-btn a:last-child {
    float: right;
}

.cart-hover-div:hover .cart-show-div {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    visibility: visible;
    opacity: 1;
}

.cart-hover-div:hover .cart-show-div.right {
    right: unset;
}

.search-overlay {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 2;
}

.search-overlay>div {
    width: 100%;
}

.search-overlay>div .close-mobile-search {
    position: absolute;
    top: 15px;
    right: 30px;
    font-size: 50px;
    color: #444444;
    cursor: pointer;
}

.search-overlay>div .overlay-content input {
    padding: 20px 0;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #dddddd;
}

.search-overlay>div .overlay-content input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.search-overlay>div .overlay-content input::-webkit-input-placeholder {
    letter-spacing: 0.05em;
    font-size: 18px;
    font-family: "PT Sans", sans-serif;
    color: #444444;
}

.search-overlay>div .overlay-content input::-moz-placeholder {
    letter-spacing: 0.05em;
    font-size: 18px;
    font-family: "PT Sans", sans-serif;
    color: #444444;
}

.search-overlay>div .overlay-content input:-ms-input-placeholder {
    letter-spacing: 0.05em;
    font-size: 18px;
    font-family: "PT Sans", sans-serif;
    color: #444444;
}

.search-overlay>div .overlay-content input::-ms-input-placeholder {
    letter-spacing: 0.05em;
    font-size: 18px;
    font-family: "PT Sans", sans-serif;
    color: #444444;
}

.search-overlay>div .overlay-content input::placeholder {
    letter-spacing: 0.05em;
    font-size: 18px;
    font-family: "PT Sans", sans-serif;
    color: #444444;
}

.search-overlay>div .overlay-content button {
    position: absolute;
    top: 10px;
    right: 20px;
    background-color: transparent;
    border: none;
    font-size: 22px;
}

.search-overlay>div .overlay-content button i {
    color: #444444 !important;
}

.mobile-setting-hover {
    position: relative;
}

.mobile-setting-hover .mobile-setting-show {
    position: absolute;
    bottom: 40px;
    right: 0;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 1px 0 #dddddd;
    box-shadow: 0 0 1px 0 #dddddd;
    min-width: 160px;
    padding: 20px;
    -webkit-transition: all linear 0.5s;
    transition: all linear 0.5s;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    visibility: hidden;
    opacity: 0;
}

.mobile-setting-hover .mobile-setting-show h6 {
    font-size: 16px;
    color: #000000;
    /*text-transform: capitalize;*/
    font-family: "PT Sans", sans-serif;
    margin-bottom: 10px;
}

.mobile-setting-hover .mobile-setting-show .curroncy {
    margin-top: 10px;
}

.mobile-setting-hover .mobile-setting-show ul li {
    display: block;
    margin-bottom: 8px;
    padding-left: 17px;
}

.mobile-setting-hover .mobile-setting-show ul li:last-child {
    margin-bottom: 0;
}

.mobile-setting-hover .mobile-setting-show ul li a {
    color: #444444;
    /*text-transform: capitalize;*/
    font-family: "PT Sans", sans-serif;
    font-size: 14px;
}

.mobile-setting-hover:hover .mobile-setting-show {
    -webkit-transform: translate(0);
    transform: translate(0);
    opacity: 1;
    visibility: visible;
}

.product-notification {
    width: 300px;
    padding: 20px;
    background-color: white;
    -webkit-box-shadow: 0 0 7px 2px rgba(158, 158, 158, 0.2);
    box-shadow: 0 0 7px 2px rgba(158, 158, 158, 0.2);
    margin: 10px;
    position: fixed;
    bottom: 0;
    -webkit-animation: movebottom 10s infinite;
    animation: movebottom 10s infinite;
    z-index: 99;
}

.product-notification img {
    height: 70px;
}

.product-notification .btn-close {
    position: absolute;
    right: 13px;
    top: 5px;
    font-size: 14px;
    z-index: 99;
    opacity: 1;
}

.item-count {
    position: relative;
}

.item-count .item-count-contain {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #042c49;
    color: #ffffff;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.item-count .item-count-contain.item-whtie {
    background-color: #ffffff;
    color: #042c49;
}

.item-count .item-count-contain.inverce {
    background-color: #ef9f77;
}

.item-count .item-count-contain.item-md {
    width: 15px;
    height: 15px;
    font-size: 10px;
}

.item-count:hover .item-count-contain {
    -webkit-animation: pulse 0.8s infinite;
    animation: pulse 0.8s infinite;
}

.mobilecat-toggle {
    display: none;
}

.icon-lable {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #042c49;
    color: #ffffff;
    font-size: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.top-header2 {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #042c49;
}

.top-header2 ul li a {
    color: #ffffff;
    font-weight: 600;
    /*text-transform: capitalize;*/
    // letter-spacing: 0.03em;
}

.top-header2 ul li a i {
    margin-right: 10px;
}

.top-header2 ul li:nth-child(n+2) {
    margin-left: 15px;
}

.top-header2 .top-header-right {
    text-align: right;
}

.top-header2.light-header {
    background-color: #f1f1f1;
}

.top-header2.light-header ul li a {
    color: #444444;
}

.header7 {
    padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ffffff;
    position: relative;
    border-bottom: 1px solid #dddddd;
}

.header7 .pixelstrap ul a,
.header7 .pixelstrap ul a:hover,
.header7 .pixelstrap ul a:focus,
.header7 .pixelstrap ul a:active,
.header7 .pixelstrap ul a.highlighted {
    font-size: 16px;
}

.header7 .header-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.header7 .header-contain .logo-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header7 .header-contain .logo-block .mobilecat-toggle {
    font-size: 24px;
    color: #042c49;
    margin-right: 15px;
}

.header7 .header-contain .menu-block .toggle-nav {
    display: none;
}

.header7 .header-contain .menu-block ul li a.dark-menu-item {
    font-size: 16px;
}

.header7 .header-contain .icon-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header7 .header-contain .icon-block.icon-lg-block {
    display: none;
}

.header7 .header-contain .icon-block ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header7 .header-contain .icon-block ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}

.header7 .header-contain .icon-block ul li:nth-child(n+2) {
    margin-left: 10px;
}

.header7 .header-contain .icon-block ul li svg {
    width: 30px;
    color: #042c49;
}

.header7 .header-contain .icon-block ul li .item-count-contain {
    top: -8px;
    right: 0;
}

.header7 .header-contain .icon-block ul li.icon-desk-none {
    display: none;
}

.header7 .header-contain .icon-block ul li.icon-lg-block {
    display: none;
}

.header7 .header-contain .icon-block ul li.icon-md-block {
    display: none;
}

.header7 .header-contain .icon-block ul.rounde-icon li {
    width: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 50%;
    border: 2px solid #dddddd;
    position: relative;
}

.header7 .header-contain .icon-block ul.rounde-icon li:nth-child(n+2) {
    margin-left: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.header7 .header-contain .icon-block ul.rounde-icon li svg {
    width: 45%;
    height: auto;
    fill: #000000;
}

.header7 .header-contain .icon-block ul.rounde-icon li .icon-lable {
    position: absolute;
    top: -5px;
    right: -4px;
}

.header7 .header-contain .icon-block ul.theme-color li {
    width: calc(30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 100%;
    background-color: #042c49;
    cursor: pointer;
}

.header7 .header-contain .icon-block ul.theme-color li svg {
    fill: #ffffff;
    width: 35%;
}

.header7 .header-contain .icon-block ul.theme-color li:nth-child(n+2) {
    margin-left: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.header7 .header-contain .icon-block ul.theme-color.color-full li:nth-child(2) {
    background-color: #ef9f77;
}

.header7 .header-contain .icon-block ul.theme-color.color-full li:nth-child(3) {
    background-color: #33b54c;
}

.header7 .header-contain .icon-block ul.theme-color.color-full li:nth-child(4) {
    background-color: #f85357;
}

.header7 .header-contain .icon-block ul.theme-color.color-full li:nth-child(5) {
    background-color: #f6d51f;
}

.header7 .header-contain .icon-block ul.icon-radius li {
    border-radius: 50%;
}

.header7 .header-contain .icon-block .toggle-nav {
    margin-left: 15px;
}

.header7 .toggle-nav {
    padding-top: 0;
    padding-bottom: 0;
}

.header-style2 .searchbar-input {
    display: none;
}

.searchbar-input {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
    z-index: 10;
}

.searchbar-input .input-group {
    height: 100%;
}

.searchbar-input .input-group .twitter-typeahead {
    width: calc(100% - 126px);
}

.searchbar-input .input-group .twitter-typeahead .form-control {
    height: 100%;
}

.searchbar-input .input-group .form-control {
    border: none;
    /*text-transform: capitalize;*/
    color: #444444;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: 0;
    border-radius: 0;
    background-color: #ffffff !important;
}

.searchbar-input .input-group .form-control::-webkit-input-placeholder {
    color: #939b9e;
    font-weight: 600;
    // letter-spacing: 0.03em;
}

.searchbar-input .input-group .form-control::-moz-placeholder {
    color: #939b9e;
    font-weight: 600;
    // letter-spacing: 0.03em;
}

.searchbar-input .input-group .form-control:-ms-input-placeholder {
    color: #939b9e;
    font-weight: 600;
    // letter-spacing: 0.03em;
}

.searchbar-input .input-group .form-control::-ms-input-placeholder {
    color: #939b9e;
    font-weight: 600;
    // letter-spacing: 0.03em;
}

.searchbar-input .input-group .form-control::placeholder {
    color: #939b9e;
    font-weight: 600;
    // letter-spacing: 0.03em;
}

.searchbar-input .input-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
}

.searchbar-input .input-group .input-group-text {
    background-color: #ffffff;
    height: 100%;
    border: none;
    cursor: pointer;
}

.searchbar-input .input-group .input-group-text:first-child {
    padding-left: 30px;
}

.searchbar-input .input-group .input-group-text:last-child {
    padding-right: 30px;
}

.searchbar-input .input-group .input-group-text svg {
    width: 22px;
    height: auto;
    fill: #939b9e;
}

.searchbar-input.open {
    width: 100%;
    opacity: 1;
    visibility: visible;
}

.category-header7 {
    background-color: #042c49;
    position: relative;
}

.category-header7 .category-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.category-header7 .category-contain .category-left .logo-block .mobilecat-toggle {
    display: none;
    margin-right: 15px;
}

.category-header7 .category-contain .category-left .logo-block .mobilecat-toggle i {
    font-size: 24px;
    color: #042c49;
}

.category-header7 .category-contain .category-left .logo-block .brand-logo {
    display: none;
}

.category-header7 .category-contain .category-right .menu-block ul li a {
    color: #ffffff;
}

.category-header7 .category-contain .icon-block {
    display: none;
}

.category-header7 .category-contain .icon-block ul li svg {
    width: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.category-header7 .category-contain .icon-block ul li .item-count-contain {
    top: -10px;
    right: -4px;
}

.category-header7 .category-contain .icon-block .toggle-nav {
    margin-left: 15px;
}

.category-header7 .category-contain .gift-block-main {
    height: 100%;
    cursor: pointer;
}

.category-header7 .category-contain .gift-block-main .gift-block {
    height: 100%;
}

.category-header7 .category-contain .gift-block-main .gift-dropdown.dropdown-menu {
    right: 0;
}

.category-header7.category-inverse {
    background-color: #ef9f77;
}

.category-header7.category-inverse .category-contain .store-locator select {
    background-color: #042c49;
}

.category-header7 .searchbar-input {
    display: none;
}

.header-category {
    z-index: 1;
    position: relative;
}

.header-category .category-toggle {
    background: #ef9f77;
    width: 100%;
    display: block;
    padding: 20px 30px;
    color: #ffffff !important;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}

.header-category .category-toggle i {
    margin-right: 15px;
}

.header-category ul.collapse-category {
    width: 100%;
    background-color: #ffffff;
    padding: 20px;
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 15px;
    border: 1px solid #dddddd;
}

.header-category ul.collapse-category li {
    display: block;
    /*text-transform: capitalize;*/
}

.header-category ul.collapse-category li.back-btn {
    padding-bottom: 15px;
    font-size: 18px;
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: none;
    border-bottom: 1px solid #dddddd;
}

.header-category ul.collapse-category li.back-btn i {
    margin-right: 10px;
}

.header-category ul.collapse-category li a {
    color: #444444;
    font-size: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    display: block;
    width: 100%;
    line-height: 1;
}

.header-category ul.collapse-category li a img {
    border: 2px solid #f0f0f0;
    padding: 3px;
    border-radius: 50%;
    margin-right: 10px;
}

.header-category ul.collapse-category li:nth-child(n+3) a {
    border-top: 1px solid #dddddd;
}

.header-category ul.collapse-category li:first-child a {
    padding-top: 0;
}

.header-category ul.collapse-category li:last-child a {
    padding-bottom: 0;
}

.header-category ul.collapse-category.hide {
    display: none;
}

.header-category ul.collapse-category.show {
    display: block;
}

.header-category2 .category-toggle {
    background: #042c49;
    width: 100%;
    display: block;
    padding: 20px 30px;
    color: #ffffff !important;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
}

.header-category2 .category-toggle i {
    margin-right: 15px;
}

.header-category2 ul.collapse-category {
    width: 100%;
    background-color: #ffffff;
    padding: 25px;
    margin-top: 15px;
    border: 1px solid #dddddd;
}

.header-category2 ul.collapse-category li {
    display: block;
    /*text-transform: capitalize;*/
}

.header-category2 ul.collapse-category li.back-btn {
    padding-bottom: 15px;
    font-size: 18px;
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: none;
    border-bottom: 1px solid #dddddd;
}

.header-category2 ul.collapse-category li.back-btn i {
    margin-right: 10px;
}

.header-category2 ul.collapse-category li a {
    color: #444444;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    line-height: 1;
}

.header-category2 ul.collapse-category li a .icon {
    margin-right: 15px;
    padding: 3px;
    border: 2px solid #f0f0f0;
    width: 41px;
    height: 41px;
    border-radius: 50%;
}

.header-category2 ul.collapse-category li a .icon>div {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: rgba(199, 109, 98, 0.07);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.header-category2 ul.collapse-category li a .icon svg {
    width: 50%;
}

.header-category2 ul.collapse-category li:nth-child(2) a {
    padding-top: 0;
}

.header-category2 ul.collapse-category li:last-child a {
    padding-bottom: 0;
}

.header-category2 ul.collapse-category.hide {
    display: none;
}

.header-category2 ul.collapse-category.show {
    display: block;
}

.header-category3 {
    z-index: 1;
    position: relative;
}

.header-category3 .category-toggle {
    background-color: #ef9f77;
    width: 100%;
    display: block;
    padding: 20px 30px;
    color: #ffffff !important;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}

.header-category3 .category-toggle i {
    margin-right: 15px;
}

.header-category3 ul li {
    display: block;
    /*text-transform: capitalize;*/
    position: relative;
    width: 240px;
    background-color: #ffffff;
}

.header-category3 ul li a {
    color: #444444;
    font-size: 16px;
    padding-bottom: 8px;
    padding-top: 8px;
    display: block;
    width: 100%;
    line-height: 1;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
}

.header-category3 ul li a span:before {
    content: "\f105";
    position: absolute;
    right: 15px;
    top: 7px;
    color: #444444;
    font-size: 20px;
    font-family: FontAwesome;
}

.header-category3 ul li a:hover {
    color: #042c49;
}

.header-category3 ul li a:hover span:before {
    color: #042c49;
}

.header-category3 ul li a i {
    float: right;
    font-weight: 600;
}

.header-category3 ul.collapse-category {
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: 15px;
    margin-top: 10px;
    border: 1px solid #dddddd;
    z-index: 1;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.header-category3 ul.collapse-category>li.back-btn {
    padding-bottom: 15px;
    font-size: 18px;
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: none;
    border-bottom: 1px solid #dddddd;
}

.header-category3 ul.collapse-category>li.back-btn i {
    margin-right: 10px;
}

.header-category3 ul.collapse-category>li:nth-child(2)>a {
    padding-top: 20px;
}

.header-category3 ul.collapse-category>li:nth-child(2)>a span:before {
    top: 17px;
}

.header-category3 ul.collapse-category>li:last-child a {
    padding-bottom: 20px;
}

.header-category3 ul.collapse-category>li ul {
    background-color: #ffffff;
    border: 1px solid #dddddd;
    -webkit-box-shadow: 0 0 0 #dddddd;
    box-shadow: 0 0 0 #dddddd;
}

.header-category3 ul.collapse-category>li ul li:first-child a {
    padding-top: 20px;
}

.header-category3 ul.collapse-category>li ul li:last-child a {
    padding-bottom: 20px;
}

.header-category3 ul.collapse-category.open {
    visibility: hidden;
    opacity: 0;
}

.header-category3 ul .categoryone .collapse-two {
    position: absolute;
    top: 0;
    left: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border-radius: 2px;
}

.header-category3 ul .categoryone .collapse-two .categorytwo .collapse-third {
    position: absolute;
    top: 0;
    left: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border-radius: 2px;
}

.header-category3 ul .categoryone .collapse-two .categorytwo:hover .collapse-third {
    opacity: 1;
    visibility: visible;
}

.header-category3 ul .categoryone:hover .collapse-two {
    opacity: 1;
    visibility: visible;
}

.header-category3 ul .categoryone:hover .collapse-mega {
    opacity: 1;
    visibility: visible;
}

.header-category3 ul .categoryone .collapse-mega {
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
    position: absolute;
    top: 0;
    left: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid #dddddd;
    border-radius: 2px;
    display: flex;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.header-category3 ul .categoryone .collapse-mega .mega-box h5 {
    color: #444444;
    /*text-transform: capitalize;*/
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: 600;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1;
}

.header-category3 ul .categoryone .collapse-mega .mega-box ul {
    border: none;
}

.header-category3 ul .categoryone .collapse-mega .mega-box ul li:first-child {
    border-top: none;
}

.header-category3 ul .categoryone .collapse-mega .mega-box ul li:first-child a {
    padding-top: 0;
}

.header-category3 ul .categoryone .collapse-mega .mega-box ul li:last-child a {
    padding-bottom: 0;
}

.category-heandle {
    position: relative;
    z-index: 3;
}

.category-heandle .heandle-left,
.category-heandle .heandle-right {
    width: 4px;
    height: 40px;
    border-radius: 3px;
    background-color: #042c49;
    position: absolute;
    top: -13px;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.category-heandle .heandle-left .point,
.category-heandle .heandle-right .point {
    position: relative;
}

.category-heandle .heandle-left .point:after,
.category-heandle .heandle-left .point:before,
.category-heandle .heandle-right .point:after,
.category-heandle .heandle-right .point:before {
    position: absolute;
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 2px solid #042c49;
    z-index: -1;
    left: -4px;
}

.category-heandle .heandle-left .point:after,
.category-heandle .heandle-right .point:after {
    bottom: -9px;
}

.category-heandle .heandle-left .point:before,
.category-heandle .heandle-right .point:before {
    top: 31px;
}

.category-heandle .heandle-left {
    left: 20px;
}

.category-heandle .heandle-right {
    right: 20px;
}

.category-heandle.open {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.header-search {
    width: 850px;
}

.header-search .input-group {
    height: 60px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.header-search .input-group .twitter-typeahead {
    width: calc(100% - 270px) !important;
}

.header-search .input-group .form-control,
.header-search .input-group .typeahead {
    border-left: none;
    border-right: none;
    /*text-transform: capitalize;*/
    color: #444444;
    padding-left: 25px;
    padding-right: 25px;
    height: 60px;
    width: 100%;
}

.header-search .input-group .form-control:focus,
.header-search .input-group .typeahead:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.header-search .input-group .input-group-text {
    padding-left: 25px;
    padding-right: 25px;
    background-color: #042c49;
    color: #ffffff;
    border: none;
}

.header-search .input-group .input-group-text:first-child {
    padding: 0;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #042c49;
}

.header-search .input-group .input-group-text:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.header-search .input-group .input-group-text select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    height: 100%;
    /*text-transform: capitalize;*/
    border: none;
    border-left: 1px solid #dddddd;
    background: url("/../assets/images/dropdown1.png") no-repeat 85%;
    background-size: 18px;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    padding-right: 64px;
    border: none;
    outline: none;
    color: #ffffff;
    font-weight: 700;
}

.header-search .input-group .input-group-text select option {
    background-color: #042c49;
}

.header-search.search-style2 .input-group {
    padding: 3px;
    border: 1px solid #dddddd;
}

.header-search.search-style2 .input-group .form-control {
    border: none;
    height: 100%;
}

.header-search.search-style2 .input-group .input-group-text {
    background-color: rgba(199, 109, 98, 0.7);
    border-left: unset;
    height: 100%;
}

.header-search.search-style2 .input-group .input-group-text select {
    background: url("/../assets/images/dropdown2.png") no-repeat 85%;
    background-size: 18px;
}

.header-search2 {
    width: 850px;
}

.header-search2 .input-group {
    height: 60px;
}

.header-search2 .input-group .form-control {
    border-left: none;
    border-right: none;
    /*text-transform: capitalize;*/
    color: #444444;
}

.header-search2 .input-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.header-search2 .input-group .input-group-text {
    padding-left: 20px;
    padding-right: 20px;
    background-color: #042c49;
    color: #ffffff;
}

.header-search2 .input-group .input-group-text select {
    background-color: transparent;
    height: 100%;
    text-transform: uppercase;
    border: none;
    border-left: 1px solid #dddddd;
    background: url("/../assets/images/pin.png") no-repeat 88%;
    background-size: 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    position: relative;
    padding-left: 20px;
    padding-right: 45px;
    border: none;
    outline: none;
    color: #ffffff;
    font-weight: 700;
}

.header-search2 .input-group .input-group-text select option {
    background-color: #042c49;
    border: none;
    outline: none;
}

.header-search2 .input-group .input-group-text:first-child {
    padding: 0;
    border-right: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.header-search3 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header-search3 .input-group {
    height: 68px;
}

.header-search3 .input-group .form-control {
    border-right: none;
    /*text-transform: capitalize;*/
    color: #444444;
    padding: 15px 25px;
    font-weight: 700;
    font-size: 16px;
}

.header-search3 .input-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.header-search3 .input-group .input-group-text {
    padding-left: 25px;
    padding-right: 25px;
    background-color: #042c49;
    color: #ffffff;
}

.header-search3 .input-group .input-group-text i {
    font-size: 20px;
}

.header-search3 .icon-block ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.header-search3 .icon-block ul li {
    width: 65px;
    height: 65px;
    border-radius: 5px;
    background-color: #042c49;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: 15px;
    position: relative;
}

.header-search3 .icon-block ul li .item-count-contain {
    position: absolute;
    top: -5px;
    right: 0;
}

.header-search3 .icon-block ul li svg {
    fill: #ffffff;
    width: 43%;
}

.store-locator {
    background-color: #ef9f77;
    height: 100%;
}

.store-locator select {
    background-color: transparent;
    height: 100%;
    border: none;
    background: url("/../assets/images/pin.png") no-repeat 88%;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-size: 14px;
    cursor: pointer;
    position: relative;
    padding-left: 25px;
    padding-right: 45px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}

.store-locator select option {
    background-color: #042c49;
}

.gift-block-main {
    position: relative;
    cursor: pointer;
}

.gift-block-main .gift-toggle {
    background-color: #042c49;
    color: #ffffff;
    font-weight: 700;
    min-width: 230px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*text-transform: capitalize;*/
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.gift-block-main .gift-toggle .grif-icon {
    margin-right: 20px;
}

.gift-block-main .gift-toggle .grif-icon i {
    font-size: 30px;
}

.gift-block-main .gift-toggle .gift-offer p {
    text-transform: uppercase;
    line-height: 1;
}

.gift-block-main .gift-toggle .gift-offer span {
    font-size: 20px;
    /*text-transform: capitalize;*/
    font-weight: 700;
    line-height: 1.2;
}

.gift-block-main .gift-block {
    background-color: #ef9f77;
    padding: 12px 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #ffffff;
    width: 255px;
    border: 0;
}

.gift-block-main .gift-block .grif-icon i {
    font-size: 35px;
}

.gift-block-main .gift-block .gift-offer {
    margin-left: 10px;
}

.gift-block-main .gift-block .gift-offer p {
    text-transform: uppercase;
    line-height: 1;
}

.gift-block-main .gift-block .gift-offer span {
    font-size: 20px;
    /*text-transform: capitalize;*/
    font-weight: 700;
    line-height: 1.2;
}

.gift-block-main .gift-dropdown.dropdown-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

@-webkit-keyframes movebottom {
    0% {
        display: block;
        bottom: -200px;
    }

    25% {
        bottom: 0px;
    }

    75% {
        bottom: 0px;
    }

    100% {
        display: none;
        bottom: -200px;
    }
}

@keyframes movebottom {
    0% {
        display: block;
        bottom: -200px;
    }

    25% {
        bottom: 0px;
    }

    75% {
        bottom: 0px;
    }

    100% {
        display: none;
        bottom: -200px;
    }
}

.gift-dropdown.dropdown-menu {
    border: 2px solid #f1f1f1;
    width: 100%;
    border-radius: 0;
    padding: 20px 10px 20px 20px;
}

.gift-dropdown.dropdown-menu .media {
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.gift-dropdown.dropdown-menu .media:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.gift-dropdown.dropdown-menu .cash {
    height: 10px;
    margin-top: -5px;
}

.gift-dropdown.dropdown-menu .fire {
    height: 13px;
    margin-top: -5px;
}

.gift-dropdown.dropdown-menu .offer-banner {
    width: 60px;
    border: 1px dashed #afafaf;
    border-radius: 5px;
    text-align: center;
    margin-right: 13px;
    font-size: 13px;
    padding: 5px 1px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
}

.gift-dropdown.dropdown-menu .media+.media {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #f1f5f8;
}

.gift-dropdown.dropdown-menu .media+.media h5 {
    padding-bottom: 2px;
}

.gift-dropdown.dropdown-menu .media .me-3 {
    height: 45px;
    width: 45px;
    background-color: white;
    padding: 7px;
    border-radius: 100%;
    margin-right: 12px !important;
}

.gift-dropdown.dropdown-menu .media .me-3 img {
    height: 30px;
}

header {
    -webkit-animation: slideInUp 300ms ease-in-out;
    animation: slideInUp 300ms ease-in-out;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 2;
    -webkit-box-shadow: 1px 1px 15px #939b9e;
    box-shadow: 1px 1px 15px #939b9e;
    -webkit-animation: slideInDown 300ms ease-in-out;
    animation: slideInDown 300ms ease-in-out;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.sticky .header7 {
    padding-top: 0;
    padding-bottom: 0;
}

.sticky .header7 .header-contain .icon-block ul.theme-color li {
    height: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    width: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.sticky .header7 .header-contain .brand-logo img {
    height: 45px;
}

.sticky.header-style2 .category-header7 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.sticky.header-style2 .category-header7 .category-contain .icon-block ul li svg {
    width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.sticky.header-style2 .category-header7 .brand-logo img {
    height: 40px;
}

.sticky .category-header-4 {
    background-color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
}

.sticky .category-header-4 .navbar-menu .menu-block .pixelstrap .light-menu-item {
    padding-top: 15px;
    padding-bottom: 15px;
}

.sticky .category-header-4 .navbar-menu .category-left .logo-block img {
    height: 40px;
}

.sticky .category-header-4 .navbar-menu .category-left .nav-block {
    display: none;
}

.sticky .category-header-4 .navbar-menu .category-left .nav-block:before {
    display: none;
}

.sticky .category-header-4 .navbar-menu .category-left .logo-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sticky .category-header-4 .navbar-menu .category-right .icon-block ul li svg {
    width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.sticky .category-header-4 .navbar-menu .input-block {
    display: none;
}

.sticky .category-header-4 .navbar-menu .category-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.sticky .category-header-4 .navbar-menu .category-right .gift-block {
    display: none;
}

.sticky .category-header-4 .navbar-menu .category-right .icon-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.sticky .category-header-4 .navbar-menu .category-right .icon-block ul li svg {
    fill: #444444;
}

.sticky .category-header-4 .navbar-menu .category-right .icon-block ul li .item-count-contain.item-whtie {
    background-color: #042c49;
    color: #ffffff;
}

.sticky .category-header-4 .navbar-menu .category-right .icon-block ul li.mobile-setting,
.sticky .category-header-4 .navbar-menu .category-right .icon-block ul li.mobile-search {
    display: inline-block;
}

.sticky .category-header-4 .navbar-menu .menu-block .pixelstrap .light-menu-item {
    color: #444444;
}

.sticky .category-header-4 .searchbar-input {
    display: block;
}

.sticky .layout-header3 .main-menu {
    padding-top: 5px;
    padding-bottom: 5px;
}

.sticky .layout-header3 .main-menu .item-count .item-count-contain {
    width: 16px;
    height: 16px;
    font-size: 10px;
}

.sticky .layout-header3 .main-menu .menu-left .nav-block .nav-left .navbar {
    padding: 12px 0;
}

.sticky .layout-header3 .main-menu .gift-dropdown.dropdown-menu {
    padding: 10px 5px 10px 5px;
}

.sticky .layout-header3 .main-menu .gift-dropdown.dropdown-menu .media .me-3 {
    width: 35px;
}

.sticky .layout-header3 .main-menu .gift-dropdown.dropdown-menu .media .me-3 img {
    height: 25px;
}

.sticky .layout-header3 .main-menu .gift-block {
    padding: 7px 20px;
}

.sticky .layout-header3 .main-menu .gift-block i {
    font-size: 25px;
}

.sticky .layout-header3 .main-menu .icon-block ul li.mobile-wishlist .item-count-contain {
    top: -9px;
}

.sticky .layout-header3 .main-menu .icon-block ul li svg {
    width: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.sticky .layout-header3 .main-menu .logo-block img {
    height: 40px;
}

.sticky .layout-header1 {
    padding: 0;
}

.sticky .layout-header1 .main-menu-block .menu-right .icon-nav ul li svg {
    width: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.sticky .layout-header1 .main-menu-block .brand-logo img {
    height: 40px;
}

.sticky+.theme-slider {
    padding-top: 107px;
}

.sticky .category-header-2 .navbar-menu .icon-block ul li svg {
    width: 20px;
    margin-left: 5px;
}

.sticky .category-header-2 .menu-block .dark-menu-item {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.sticky .category-header-2 .logo-block .brand-logo img {
    height: 40px;
}

.sticky .top-header {
    display: none;
}

.sticky .layout-header2 {
    display: none;
}

.sticky .category-header-2 {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #ffffff;
    border-bottom: 1px solid #dddddd;
}

.sticky .category-header-2 .navbar-menu .logo-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.sticky .category-header-2 .navbar-menu .nav-block {
    display: none;
}

.sticky .category-header-2 .navbar-menu .icon-block ul li:nth-child(n+2) {
    margin-left: unset;
}

.sticky .category-header-2 .navbar-menu .icon-block ul li.mobile-search,
.sticky .category-header-2 .navbar-menu .icon-block ul li.mobile-setting,
.sticky .category-header-2 .navbar-menu .icon-block ul li.mobile-cart {
    display: inline-block;
}

.sticky .category-header-2 .navbar-menu .icon-block ul li .cart-item {
    display: none;
}

.sticky .category-header-2 .navbar-menu .icon-block ul li svg {
    fill: #303030;
}

.sticky .category-header-2 .navbar-menu .icon-block ul li .item-count-contain {
    width: 15px;
    height: 15px;
}

.sticky .category-header-2 .navbar-menu .menu-block .pixelstrap .dark-menu-item {
    color: #303030;
}

.sticky .category-header-2 .navbar-menu .category-right {
    display: none;
}

.sticky .category-header-2 .searchbar-input {
    display: block;
}

.sticky .category-header {
    display: none;
}

.sticky .layout-header1 .main-menu-block .menu-right .icon-nav ul .mobile-search,
.sticky .layout-header1 .main-menu-block .menu-right .icon-nav ul .mobile-setting {
    display: inline-block;
}

.sticky .layout-header1 .main-menu-block .menu-right .icon-nav ul li .item-count-contain {
    width: 15px;
    height: 15px;
}

.sticky .layout-header1 .main-menu-block .menu-right .icon-nav ul li .cart-item {
    display: none;
}

.sticky .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block {
    padding: 0;
    margin-left: 0;
    background-color: transparent;
}

.sticky .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block svg {
    fill: #999999;
    width: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.sticky .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block.item-count .item-count-contain {
    left: unset;
    right: 1px;
    top: -8px;
}

.sticky .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block.item-count .item-count-contain.item-whtie {
    background-color: #042c49;
    color: #ffffff;
}

.sticky .layout-header1 .searchbar-input {
    display: block;
}

.sticky .layout-header3 .main-menu .icon-block ul li.mobile-setting {
    display: inline-block;
}

.sticky .layout-header3 .main-menu .icon-block ul li.mobile-wishlist .cart-item {
    display: none;
}

.sticky .layout-header4 {
    height: 0;
    padding: 0;
}

.sticky .top-header2 {
    display: none;
}

.sticky .header7 .header-contain .icon-block ul li.icon-desk-none {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.sticky.header-style2 .header7 {
    display: none;
}

.sticky.header-style2 .category-header7 {
    background-color: #ffffff;
}

.sticky.header-style2 .category-header7 .category-contain {
    height: auto;
}

.sticky.header-style2 .category-header7 .category-contain .category-left .logo-block .brand-logo {
    display: block;
}

.sticky.header-style2 .category-header7 .category-contain .category-left .header-category {
    display: none;
}

.sticky.header-style2 .category-header7 .category-contain .category-left .header-category3 {
    display: none;
}

.sticky.header-style2 .category-header7 .category-contain .category-right .menu-block ul li a {
    color: #444444;
}

.sticky.header-style2 .category-header7 .category-contain .icon-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.sticky.header-style2 .category-header7 .category-contain .icon-block ul li svg {
    fill: #444444;
}

.sticky.header-style2 .category-header7 .category-contain .store-locator {
    display: none;
}

.sticky.header-style2 .category-header7 .searchbar-input {
    display: block;
}

/*============================
  17. Media Banner start
==============================*/
.Jewellery-banner {
    height: 100%;
}

.Jewellery-banner .Jewellery-banner-contian {
    padding: 30px;
}

.Jewellery-banner h5 {
    color: #444444;
    /*text-transform: capitalize;*/
    font-weight: 700;
    font-size: 14px;
}

.Jewellery-banner h6 {
    color: #042c49;
    font-size: 31px;
    font-weight: 700;
    /*text-transform: capitalize;*/
    line-height: 1;
}

.layout-5 .media-banner.media-banner-1 {
    background-color: #f2f2f2;
}

.tab-product-main .tab-prodcut-contain ul li a {
    color: #ffffff;
}

.media-banner {
    padding: 30px;
    background-color: #f2f2f2;
    border-right: 1px solid #dddddd;
}

.media-banner .media-banner-box {
    margin-bottom: 20px;
}

.media-banner .media-banner-box:last-child {
    margin-bottom: 0;
}

.media-banner .media-banner-box .media-heading h5 {
    font-size: 18px;
    color: #444444;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0.5px;
}

.media-banner .media-banner-box .media-view h5 {
    /*text-transform: capitalize;*/
    color: #042c49;
    line-height: 18px;
}

.media-banner .media-banner-box .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.media-banner .media-banner-box .media img {
    border: 1px solid #dddddd;
    max-width: 84px;
}

.media-banner .media-banner-box .media .media-body .media-contant {
    margin-left: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100px;
    position: relative;
}

.media-banner .media-banner-box .media .media-body .media-contant .product-detail {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.media-banner .media-banner-box .media .media-body .media-contant .product-detail .rating i {
    color: #ffa800;
}

.media-banner .media-banner-box .media .media-body .media-contant .product-detail p {
    font-size: 14px;
    margin-bottom: 0;
    color: #444444;
    /*text-transform: capitalize;*/
}

.media-banner .media-banner-box .media .media-body .media-contant .product-detail h6 {
    color: #042c49;
    font-weight: 700;
    margin-top: 3px;
}

.media-banner .media-banner-box .media .media-body .media-contant .product-detail h6 span {
    color: #444444;
    text-decoration: line-through;
    font-weight: 500;
}

.media-banner .media-banner-box .media .media-body .media-contant .cart-info {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    visibility: hidden;
    opacity: 0;
}

.media-banner .media-banner-box .media .media-body .media-contant .cart-info button {
    padding: 0;
    background-color: transparent;
    line-height: 1;
    border: none;
}

.media-banner .media-banner-box .media .media-body .media-contant .cart-info button svg {
    width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #444444;
}

.media-banner .media-banner-box .media .media-body .media-contant .cart-info button i {
    color: #444444;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.media-banner .media-banner-box .media .media-body .media-contant .cart-info a {
    color: #444444;
}

.media-banner .media-banner-box .media .media-body .media-contant .cart-info a svg {
    width: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #444444;
}

.media-banner .media-banner-box .media .media-body .media-contant .cart-info a i {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.media-banner .media-banner-box .media .media-body .media-contant .cart-info a:nth-child(n+2) {
    margin-left: 5px;
}

.media-banner .media-banner-box .media:hover .media-body .media-contant .product-detail {
    opacity: 0;
    visibility: hidden;
}

.media-banner .media-banner-box .media:hover .media-body .media-contant .cart-info {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.media-banner .media-banner-box .media:hover .media-body .media-contant .cart-info button {
    -webkit-animation: fadeInUp 300ms ease-in-out;
    animation: fadeInUp 300ms ease-in-out;
}

.media-banner .media-banner-box .media:hover .media-body .media-contant .cart-info a:nth-child(2) {
    -webkit-animation: fadeInUp 600ms ease-in-out;
    animation: fadeInUp 600ms ease-in-out;
}

.media-banner .media-banner-box .media:hover .media-body .media-contant .cart-info a:nth-child(3) {
    -webkit-animation: fadeInUp 700ms ease-in-out;
    animation: fadeInUp 700ms ease-in-out;
}

.media-banner .media-banner-box .media:hover .media-body .media-contant .cart-info a:nth-child(4) {
    -webkit-animation: fadeInUp 900ms ease-in-out;
    animation: fadeInUp 900ms ease-in-out;
}

.media-banner.media-banner-1 {
    background-color: #eef3f4;
    margin: 0 8px;
}

.media-banner.media-banner-2 .media-banner-box {
    margin-bottom: 10px;
}

.media-banner.media-banner-2 .media-banner-box:last-child {
    margin-bottom: 0;
}

.media-banner.banner-inverse .media-banner-box .media .media-body .media-contant h6 {
    color: #ef9f77;
}

.media-banner.plrb-0 {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

/*============================
   18. Footer start
==============================*/
ul.sosiyal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-self: center;
}

ul.sosiyal li {
    width: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
    height: calc(28px + (35 - 28) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f7f7f7;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

ul.sosiyal li:nth-child(n+2) {
    margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
}

ul.sosiyal li a i {
    color: #444444;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}

ul.sosiyal li:hover {
    background-color: #ef9f77;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

ul.sosiyal li:hover i {
    color: #ffffff;
}

ul.sosiyal.sosiyal-inverse li:hover {
    background-color: #042c49;
}

.footer-title {
    margin-bottom: 30px;
    cursor: pointer;
}

.footer-title h5 {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
    color: #000000;
    font-family: "Raleway", sans-serif;
}

.footer-title .line {
    width: 50px;
    height: 2px;
    background-color: #042c49;
    margin-top: 10px;
}

.footer-title.mobile-title {
    display: none;
}

.footer-contant .footer-logo {
    margin-bottom: 20px;
}

.footer-contant .footer-logo img {
    width: auto;
    height: calc(30px + (66 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.footer-contant ul li {
    position: relative;
    line-height: 1.2;
    display: block;
    padding-bottom: 17px;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
    /*text-transform: capitalize;*/
    font-family: "Raleway", sans-serif;
    // letter-spacing: 0.03em;
}

.footer-contant ul li:last-child {
    margin-right: 0;
    padding-bottom: 0;
}

.footer-contant ul li a {
    color: #8d8d8d;
}

.footer-contant .contact-list li {
    padding-left: 25px;
}

.footer-contant .contact-list li:first-child {
    line-height: 1.8;
}

.footer-contant .contact-list li:first-child i {
    margin-top: 4px;
}

.footer-contant .contact-list li span {
    font-family: "PT Sans", sans-serif;
}

.footer-contant .contact-list li i {
    position: absolute;
    top: 0;
    left: 0;
}

.footer-contant .news-letter .input-group {
    /*text-transform: capitalize;*/
}

.footer-contant .news-letter .input-group .form-control {
    /*text-transform: capitalize;*/
    border-color: #dddddd;
    height: 42px;
    padding-left: 15px;
    padding-right: 15px;
}

.footer-contant .news-letter .input-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.footer-contant .news-letter .input-group .input-group-text {
    background-color: #ef9f77;
    color: #ffffff;
    font-weight: 700;
    border-color: #ef9f77;
}

.footer-contant .newsletter-second .form-group {
    margin-bottom: 15px;
}

.footer-contant .newsletter-second .form-group .form-control {
    border-color: #dddddd;
    /*text-transform: capitalize;*/
    height: 40px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.footer-contant .newsletter-second .form-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.footer-contant .newsletter-second .form-group .input-group-text {
    background-color: #042c49;
    border-color: #042c49;
    color: #ffffff;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.footer-contant .newsletter-second .form-group .btn {
    /*text-transform: capitalize;*/
}

.footer-contant ul.sosiyal {
    margin-top: 20px;
}

.footer-contant ul.sosiyal li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin-bottom: 0;
}

.footer-contant ul.paymant li {
    display: inline-block;
    margin-bottom: 0;
    padding-bottom: 0;
}

.footer-contant ul.paymant li img {
    width: calc(20px + (25 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.footer-contant ul.paymant li:nth-child(n+2) {
    margin-left: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.footer-contant p {
    line-height: 1.6;
    margin-bottom: 20px;
}

.footer1 {
    padding-top: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ffffff;
}

.footer1 .footer-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.footer1 .footer-main>div {
    max-width: 30%;
}

.subfooter {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #ffffff;
}

.subfooter .footer-left,
.subfooter .footer-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.subfooter .footer-left p {
    color: #8d8d8d;
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    letter-spacing: 0.05em;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.subfooter .footer-left a {
    color: #042c49
}

.subfooter .footer-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.subfooter .footer-right ul.payment li:nth-child(n+2) {
    margin-left: 10px;
}

.subfooter.dark-footer {
    background-color: #444444;
}

.subfooter.dark-footer .footer-left p {
    color: #ffffff;
}

.subfooter.light-footer {
    background-color: rgba(199, 109, 98, 0.05);
}

.subfooter.footer-border {
    border-top: 1px solid #dddddd;
}

.subscribe1 {
    padding-top: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(25px + (40 - 25) * ((100vw - 320px) / (1920 - 320)));
    background-color: rgba(199, 109, 98, 0.8);
}

.subscribe1 .subscribe-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.subscribe1 .subscribe-contain .subscribe-left .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.subscribe1 .subscribe-contain .subscribe-left .media svg {
    width: 50px;
    height: auto;
    fill: #ffffff;
}

.subscribe1 .subscribe-contain .subscribe-left .media .media-body {
    margin-left: 15px;
}

.subscribe1 .subscribe-contain .subscribe-left .media .media-body h6 {
    color: #ffffff;
    /*text-transform: capitalize;*/
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
}

.subscribe1 .subscribe-contain .subscribe-left .media .media-body h3 {
    color: #ffffff;
    text-transform: uppercase;
}

.subscribe1 .subscribe-contain .subscribe-right .input-group .form-control {
    height: calc(38px + (50 - 38) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    width: 400px;
    padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    border-color: #dddddd;
}

.subscribe1 .subscribe-contain .subscribe-right .input-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.subscribe1 .subscribe-contain .subscribe-right .input-group .input-group-text {
    background-color: #444444;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
    padding-left: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(10px + (30 - 10) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    border: none;
}

.subscribe1.block .subscribe-contain {
    display: grid;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

.subscribe1.block .subscribe-contain .subscribe-left .media {
    display: block;
}

.subscribe1.block .subscribe-contain .subscribe-left .media svg {
    margin-bottom: 10px;
}

.subscribe1.block .subscribe-contain .subscribe-left .media .media-body h3 {
    margin-bottom: 13px;
}

.subscribe1.block .subscribe-contain .subscribe-left .media .media-body h6 {
    margin-bottom: 13px;
}

.subscribe1.newsletter-inverse {
    background-color: rgba(239, 159, 119, 0.8);
}

.subscribe2 {
    padding-top: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
    background-color: #042c49;
}

.subscribe2 .subscribe-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.subscribe2 .subscribe-contain h2 {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Aclonica", sans-serif;
    margin-bottom: 12px;
    letter-spacing: 2px;
    margin-top: -3px;
}

.subscribe2 .subscribe-contain p {
    color: #ffffff;
    width: 700px;
    line-height: 1.6;
    margin-bottom: 25px;
    font-size: 16px;
}

.subscribe2 .subscribe-contain .form-control {
    padding-top: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
    /*text-transform: capitalize;*/
    border-radius: 10px;
    width: 450px;
    height: calc(40px + (58 - 40) * ((100vw - 320px) / (1920 - 320)));
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

.subscribe2 .subscribe-contain .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.subscribe2 .subscribe-contain ul {
    margin-bottom: 28px;
}

.subscribe2 .subscribe-contain ul li a {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #ef9f77;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.subscribe2 .subscribe-contain ul li:nth-child(n+2) {
    margin-left: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.subscribe2 .subscribe-contain .btn {
    text-transform: uppercase;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ef9f77;
}

.subscribe-block1>div:nth-child(n+2) {
    padding-left: 0;
}

.newslatter1 {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #042c49;
}

.newslatter1 .newslatter-right .form-group .form-control {
    border-color: #dddddd;
}

/*============================
   19.  Slider start
==============================*/
.theme-slider {
    line-height: 0;
}

.theme-slider .slider-banner {
    position: relative;
    overflow: hidden;
}

.theme-slider .slider-banner.p-left .slider-banner-contain {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.theme-slider .slider-banner.p-right .slider-banner-contain {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.theme-slider .slider-banner.p-center .slider-banner-contain {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.theme-slider .slider-banner.p-top .slider-banner-contain {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.theme-slider .slider-banner .slider-img {
    height: 455px;
    background-color: #eddbd1;
}

.theme-slider .slider-banner .slider-banner-contain {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 100px;
}

.theme-slider .slider-banner .slider-banner-contain h4 {
    font-size: 24px;
    color: #000000;
    /*text-transform: capitalize;*/
    line-height: 1;
    padding-bottom: 8px;
}

.theme-slider .slider-banner .slider-banner-contain h1 {
    font-size: 72px;
    color: #ef9f77;
    /*text-transform: capitalize;*/
    line-height: 1;
    padding-bottom: 12px;
}

.theme-slider .slider-banner .slider-banner-contain h2 {
    font-size: 36px;
    color: #777777;
    /*text-transform: capitalize;*/
    line-height: 1;
    padding-bottom: 16px;
}

.theme-slider .slider-banner .slider-banner-contain .btn-rounded {
    display: inline-block;
    letter-spacing: 1px;
    padding: 15px 35px;
    font-size: 18px;
}

.theme-slider .slider-banner .layout2-slide-1 li {
    position: absolute;
    bottom: -8px;
    right: 0;
}

.theme-slider .slider-banner .layout2-slide-2 li {
    position: absolute;
    bottom: 55px;
    right: 60px;
}

.theme-slider .slider-banner .layout2-slide-3 li {
    position: absolute;
    bottom: 35px;
    right: 55px;
}

.theme-slider .slider-banner .slide-center {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    height: 100% !important;
}

.theme-slider .slider-banner.slide-banner-1 .layout1-slide-1 li {
    position: absolute;
    bottom: -30px;
    left: -50px;
}

.theme-slider .slider-banner.slide-banner-1 .layout1-slide-1 li:nth-child(2) {
    right: 60px;
    left: unset;
}

.theme-slider .slider-banner.slide-banner-1 .layout1-slide-2 li {
    position: absolute;
    left: 50px;
}

.theme-slider .slider-banner.slide-banner-1 .layout1-slide-2 li:nth-child(2) {
    right: 20px;
    left: unset;
}

.theme-slider .slider-banner.slide-banner-1 .layout1-slide-3 li {
    position: absolute;
    bottom: -30px;
    left: -50px;
}

.theme-slider .slider-banner.slide-banner-1 .layout1-slide-3 li:nth-child(2) {
    right: 100px;
    bottom: -20px;
    left: unset;
}

.theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h1 {
    color: #042c49;
    font-weight: 400;
    text-transform: uppercase;
    padding-bottom: 2px;
}

.theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h1 span {
    color: #ef9f77;
    /*text-transform: capitalize;*/
    margin-left: 17px;
}

.theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h4 {
    font-size: 30px;
    color: #939b9e;
    font-weight: 400;
    padding-bottom: 20px;
}

.theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h2 {
    font-size: 36px;
    color: #000000;
    padding-bottom: 15px;
}

.theme-slider .slider-banner.slide-banner-1 .btn-normal {
    padding: 17px 35px;
    font-size: 18px;
}

.theme-slider .slider-banner.slide-banner-1 .slider-img {
    height: 495px;
    background-color: #d3ecf3;
    overflow: hidden;
}

.theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li {
    position: absolute;
    top: 0;
    left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li:last-child {
    right: 20px;
    left: unset;
}

.theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li {
    position: absolute;
    top: 0;
    left: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li:last-child {
    right: 65px;
    left: unset;
}

.theme-slider .slider-banner.slide-banner-2 .slider-img {
    height: 480px;
    background-color: #eeeeee;
}

.theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain {
    background-color: #ffffff;
    padding: 40px;
    text-align: center;
    min-width: 440px;
}

.theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain h4 {
    font-size: 18px;
    color: #444444;
    padding-bottom: 5px;
}

.theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain h1 {
    color: #ef9f77;
    padding-bottom: 21px;
}

.theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain span {
    display: block;
    background-color: #042c49;
    font-weight: 700;
    color: #ffffff;
    font-size: 36px;
    /*text-transform: capitalize;*/
    letter-spacing: 1px;
    line-height: 1.2;
    padding-top: 8px;
    padding-bottom: 10px;
    margin-bottom: 22px;
}

.theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain a {
    text-transform: uppercase;
    font-weight: 700;
    color: #ef9f77;
}

.theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain a i {
    margin-left: 15px;
}

.theme-slider .slider-banner.slide-banner-3 .layout4-slide-1 li {
    position: absolute;
    top: 0;
    right: 170px;
}

.theme-slider .slider-banner.slide-banner-3 .layout4-slide-2 li {
    position: absolute;
    top: 0;
    right: 180px;
}

.theme-slider .slider-banner.slide-banner-3 .layout4-slide-3 li {
    position: absolute;
    top: 0;
    right: 230px;
}

.theme-slider .slider-banner.slide-banner-3 .slider-img {
    height: 455px;
    background-color: #ffffff;
}

.theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h5 {
    font-size: 18px;
    color: #777777;
    font-weight: 400;
    /*text-transform: capitalize;*/
    padding-bottom: 6px;
}

.theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h3 {
    font-size: 42px;
    /*text-transform: capitalize;*/
    color: #042c49;
    font-weight: 400;
    padding-bottom: 10px;
}

.theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h1 {
    font-size: 52px;
    color: #ef9f77;
    padding-bottom: 27px;
    text-transform: uppercase;
}

.theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h2 {
    font-size: 24px;
    color: #444444;
    padding-bottom: 25px;
}

.theme-slider .slider-banner.slide-banner-3 .slider-banner-contain .btn-normal {
    /*text-transform: capitalize;*/
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding: 18px 45px;
}

.theme-slider .slider-banner.slide-banner-4 .layout5-slide-1 li {
    position: absolute;
    right: 100px;
    bottom: 0;
}

.theme-slider .slider-banner.slide-banner-4 .layout5-slide-2 li {
    position: absolute;
    right: 100px;
    bottom: 0;
}

.theme-slider .slider-banner.slide-banner-4 .layout5-slide-3 li {
    position: absolute;
    right: 100px;
    bottom: 0;
}

.theme-slider .slider-banner.slide-banner-4 .slider-img {
    height: 695px;
    background: -webkit-gradient(linear, left top, left bottom, from(#dfe9f3), to(#e7e7e7));
    background: linear-gradient(#dfe9f3, #e7e7e7);
}

.theme-slider .slider-banner.slide-banner-4 .slider-banner-contain {
    text-align: center;
}

.theme-slider .slider-banner.slide-banner-4 .slider-banner-contain h3 {
    color: #042c49;
    font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    /*text-transform: capitalize;*/
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
}

.theme-slider .slider-banner.slide-banner-4 .slider-banner-contain h3:after {
    position: absolute;
    top: 50%;
    left: 120%;
    height: 5px;
    width: 100px;
    content: '';
    background-color: #ef9f77;
}

.theme-slider .slider-banner.slide-banner-4 .slider-banner-contain h3:before {
    position: absolute;
    top: 50%;
    right: 120%;
    height: 5px;
    width: 100px;
    content: '';
    background-color: #ef9f77;
}

.theme-slider .slider-banner.slide-banner-4 .slider-banner-contain h1 {
    color: #ef9f77;
    /*text-transform: capitalize;*/
    margin-top: 10px;
    font-size: calc(24px + (72 - 24) * ((100vw - 320px) / (1920 - 320)));
}

.theme-slider .slider-banner.slide-banner-4 .slider-banner-contain h2 {
    color: #042c49;
    margin-top: 11px;
    font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.theme-slider .slider-banner.slide-banner-4 .slider-banner-contain .btn-normal {
    /*text-transform: capitalize;*/
    font-weight: 700;
    margin-top: 15px;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.theme-slider .slider-banner.slide-banner-5 .layout6-slide-1 li {
    position: absolute;
    bottom: 0;
    right: 50px;
    width: 450px;
}

.theme-slider .slider-banner.slide-banner-5 .layout6-slide-2 li {
    position: absolute;
    top: 60%;
    right: 17%;
    -webkit-animation: vegislide-move 2s linear infinite;
    animation: vegislide-move 2s linear infinite;
    width: calc(25px + (55 - 25) * ((100vw - 320px) / (1920 - 320)));
}

.theme-slider .slider-banner.slide-banner-5 .layout6-slide-2 li:nth-child(2) {
    right: 50%;
    -webkit-animation: vegislide-move 2.8s linear infinite;
    animation: vegislide-move 2.8s linear infinite;
    width: calc(20px + (55 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.theme-slider .slider-banner.slide-banner-5 .layout6-slide-2 li:nth-child(3) {
    top: unset;
    bottom: 10%;
    margin-right: 26%;
    -webkit-animation: vegislide-move 3s linear infinite;
    animation: vegislide-move 3s linear infinite;
    width: calc(20px + (55 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.theme-slider .slider-banner.slide-banner-5 .layout6-slide-2 li:nth-child(4) {
    top: unset;
    bottom: 20%;
    right: 40%;
    -webkit-animation: vegislide-move 3.5s linear infinite;
    animation: vegislide-move 3.5s linear infinite;
    width: calc(30px + (45 - 30) * ((100vw - 320px) / (1920 - 320)));
}

@-webkit-keyframes vegislide-move {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

@keyframes vegislide-move {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    50% {
        -webkit-transform: rotate(15deg);
        transform: rotate(15deg);
    }

    100% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.theme-slider .slider-banner.slide-banner-5 .slider-img {
    background-color: #e7e7e7;
}

.theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h5 {
    font-size: 18px;
    color: #777777;
    font-weight: 400;
    /*text-transform: capitalize;*/
    padding-bottom: 6px;
}

.theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h3 {
    font-size: 42px;
    /*text-transform: capitalize;*/
    color: #042c49;
    font-weight: 400;
    padding-bottom: 10px;
}

.theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h1 {
    font-size: 52px;
    color: #ef9f77;
    padding-bottom: 27px;
    text-transform: uppercase;
}

.theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h2 {
    font-size: 24px;
    color: #444444;
    padding-bottom: 25px;
}

.theme-slider .slider-banner.slide-banner-5 .slider-banner-contain .btn-rounded {
    /*text-transform: capitalize;*/
}

.theme-slider .slider-banner.slide-banner-5 .slider-banner-contain .btn-rounded:hover {
    background-color: #ef9f77;
}

.slider-layout-4 .slider-slide {
    max-width: calc(100% - 275px);
    margin-left: 275px;
}

.furniture-slide {
    position: relative;
    overflow: hidden;
}

.furniture-slide:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fcf0e9;
    z-index: -2;
}

.furniture-slide .slide-main {
    z-index: 1;
    overflow: hidden;
}

.furniture-slide .slide-main .slide-contain {
    height: calc(100vh - 225px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Rubik", sans-serif;
    padding-left: 15px;
    padding-right: 15px;
}

.furniture-slide .slide-main .slide-contain h2 {
    font-size: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 700;
    font-family: "Rubik", sans-serif;
    /*text-transform: capitalize;*/
    line-height: 1;
}

.furniture-slide .slide-main .slide-contain h3 {
    font-size: calc(16px + (45 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: #000000;
    font-weight: 400;
    /*text-transform: capitalize;*/
    margin-bottom: 5px;
}

.furniture-slide .slide-main .slide-contain h4 {
    font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: #ef9f77;
    font-weight: 500;
    margin-bottom: 15px;
}

.furniture-slide .slide-main .slide-contain h4 span {
    color: #000000;
    margin-left: 15px;
    text-decoration: line-through;
}

.furniture-slide .slide-main .slide-contain p {
    font-size: calc(14px + (19 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #999999;
    font-weight: 400;
    width: 600px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.furniture-slide .animat-block .animat1,
.furniture-slide .animat-block .animat2,
.furniture-slide .animat-block .animat3,
.furniture-slide .animat-block .animat4 {
    position: absolute;
    z-index: -2;
}

.furniture-slide .animat-block .animat1 {
    top: 5%;
    right: 5%;
    -webkit-animation: rotationxy 90s infinite linear;
    animation: rotationxy 90s infinite linear;
}

.furniture-slide .animat-block .animat2 {
    bottom: 15px;
    left: -21%;
    -webkit-animation: rotationxy 30s infinite linear;
    animation: rotationxy 30s infinite linear;
}

.furniture-slide .animat-block .animat3 {
    top: -66px;
    right: 42%;
    -webkit-animation: rotation1 8s infinite alternate linear;
    animation: rotation1 8s infinite alternate linear;
}

.furniture-slide .animat-block .animat4 {
    top: -60px;
    right: 43%;
    z-index: -2;
    -webkit-animation: rotation1 5s infinite alternate linear;
    animation: rotation1 5s infinite alternate linear;
}

.cosmetic-slide .btn-solid {
    font-size: 20px;
}

.cosmetic-slide .slide-main {
    position: relative;
    z-index: 1;
}

.cosmetic-slide .slide-main .slide-contain {
    height: calc(100vh - 148px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
}

.cosmetic-slide .slide-main .slide-contain h3 {
    font-size: calc(18px + (51 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #303030;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    /*text-transform: capitalize;*/
    margin-bottom: 17px;
}

.cosmetic-slide .slide-main .slide-contain h2 {
    font-size: calc(24px + (67 - 24) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 700;
    font-family: "Libre Baskerville", serif;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.cosmetic-slide .slide-main .slide-contain h4 {
    font-size: calc(18px + (35 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #303030;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    /*text-transform: capitalize;*/
    margin-bottom: 28px;
}

.cosmetic-slide .slide-main .animat-block .animat-img1,
.cosmetic-slide .slide-main .animat-block .animat-img2,
.cosmetic-slide .slide-main .animat-block .animat-img3 {
    position: absolute;
    z-index: -1;
}

.cosmetic-slide .slide-main .animat-block .animat-img1 {
    top: 40px;
    right: 100px;
}

.cosmetic-slide .slide-main .animat-block .animat-img2 {
    top: 30px;
    right: 50px;
}

.cosmetic-slide .slide-main .animat-block .animat-img3 {
    bottom: -10%;
    right: -3%;
}

.kids-slide {
    position: relative;
}

.kids-slide:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -1;
}

.kids-slide .slide-main {
    position: relative;
    overflow: hidden;
}

.kids-slide .slide-main .slide-contain {
    height: calc(100vh - 225px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}

.kids-slide .slide-main .slide-contain h4 {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 5px;
    color: #ef9f77;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.kids-slide .slide-main .slide-contain h2 {
    font-size: calc(24px + (50 - 24) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 800;
    color: #042c49;
    font-family: "Merriweather";
    margin-bottom: 15px;
}

.kids-slide .slide-main .slide-contain h3 {
    font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: #444444;
    font-weight: 400;
    font-family: "Balsamiq Sans", cursive;
    margin-bottom: 10px;
}

.kids-slide .slide-main .slide-contain p {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    width: 58%;
    line-height: 1.6;
    margin-bottom: 25px;
}

.kids-slide .animat-block .animat1,
.kids-slide .animat-block .animat2,
.kids-slide .animat-block .animat3,
.kids-slide .animat-block .animat4,
.kids-slide .animat-block .animat5,
.kids-slide .animat-block .animat6 {
    position: absolute;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    z-index: -1;
}

.kids-slide .animat-block .animat1 {
    bottom: 50px;
    left: 70px;
    animation: bounce1 5s infinite alternate-reverse;
}

.kids-slide .animat-block .animat2 {
    top: 40px;
    left: 40%;
    -webkit-animation: move2 9s linear infinite;
    animation: move2 9s linear infinite;
}

.kids-slide .animat-block .animat3 {
    top: 80px;
    right: 80px;
    -webkit-animation: move2 12s linear infinite;
    animation: move2 12s linear infinite;
}

.kids-slide .animat-block .animat4 {
    bottom: 100px;
    right: 30px;
    -webkit-animation: move2 15s linear infinite;
    animation: move2 15s linear infinite;
}

.kids-slide .animat-block .animat5 {
    bottom: -83px;
    left: 23%;
    animation: move3 35s linear infinite alternate-reverse;
}

.kids-slide .animat-block .animat6 {
    top: 0;
    left: -5%;
    right: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 120%;
    -webkit-animation: rotationxy 300s linear infinite;
    animation: rotationxy 300s linear infinite;
}

.backanimat {
    position: relative;
    -webkit-animation: backanimat 60s linear infinite;
    animation: backanimat 60s linear infinite;
    will-change: background-position;
    background-size: auto !important;
    overflow: hidden;
}

.tools-slide .slide-main {
    position: relative;
}

.tools-slide .slide-main .slide-contain {
    height: calc(100vh - 214px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: "Rubik", sans-serif;
    padding-left: 15px;
    padding-right: 15px;
}

.tools-slide .slide-main .slide-contain h2 {
    font-size: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.tools-slide .slide-main .slide-contain h3 {
    font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: #303030;
    font-weight: 400;
    /*text-transform: capitalize;*/
    margin-bottom: 15px;
}

.tools-slide .slide-main .slide-contain h4 {
    font-size: calc(18px + (45 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 700;
    margin-bottom: 15px;
}

.tools-slide .slide-main .slide-contain h4 span {
    font-size: 60%;
    text-decoration: line-through;
    color: #303030;
}

.tools-slide .slide-main .slide-contain p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #8a8a8a;
    font-weight: 500;
    width: 455px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.tools-slide .slide-main .animat-block .animat1 {
    position: absolute;
    top: 50px;
    bottom: 0;
    right: 10%;
    margin-top: auto;
    margin-bottom: auto;
}

.megastore-slide .slide-main .slide-contain {
    height: 459px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
}

.megastore-slide .slide-main .slide-contain h4 {
    font-size: calc(18px + (23 - 18) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px;
    color: #042c49;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    /*text-transform: capitalize;*/
    margin-bottom: 11px;
}

.megastore-slide .slide-main .slide-contain h2 {
    font-size: calc(28px + (55 - 28) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 2px;
    color: #ef9f77;
    font-weight: 700;
    font-family: "Rubik", sans-serif;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 14px;
}

.megastore-slide .slide-main .slide-contain h3 {
    font-size: calc(18px + (23 - 18) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px;
    color: #000000;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    /*text-transform: capitalize;*/
    margin-bottom: 24px;
}

.megastore-slide .mega-slide-block>div:first-child {
    padding-left: 275px;
}

.megastore-slide .mega-slide-block>div:first-child>div>div:nth-child(n+2) {
    padding-top: 15px;
}

.megastore-slide .mega-slide-block>div:first-child>div>div:last-child {
    padding-left: 0;
}

.megastore-slide .mega-slide-block>div:nth-child(n+2) {
    padding-left: 0;
}

.grocery-slide-block>div:nth-child(2) {
    padding-left: unset;
}

.grocery-slide {
    margin-top: 15px;
}

.grocery-slide .header-search3 {
    margin-bottom: 15px;
}

.grocery-slide .slide-main {
    position: relative;
    margin-left: 260px;
}

.grocery-slide .slide-main .slide-contain {
    height: 660px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
}

.grocery-slide .slide-main .slide-contain h3 {
    color: #444444;
    /*text-transform: capitalize;*/
    margin-bottom: 13px;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.grocery-slide .slide-main .slide-contain h2 {
    color: #ef9f77;
    font-size: calc(30px + (65 - 30) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 12px;
}

.grocery-slide .slide-main .slide-contain h4 {
    color: #444444;
    /*text-transform: capitalize;*/
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 25px;
}

.grocery-slide .slide-main .animat-block .animat1 {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2%;
    margin-top: auto;
    margin-bottom: auto;
}

.pets-slide .slide-main .slide-contain {
    height: calc(100vh - 215px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

.pets-slide .slide-main .slide-contain h3 {
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px;
    color: #444444;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    /*text-transform: capitalize;*/
    margin-bottom: 20px;
}

.pets-slide .slide-main .slide-contain h2 {
    font-size: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 2px;
    color: #042c49;
    font-family: "Aclonica", sans-serif;
    font-weight: 700;
    /*text-transform: capitalize;*/
    margin-bottom: 20px;
}

.pets-slide .slide-main .slide-contain .form-control {
    margin-bottom: 30px;
    padding-left: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 30px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    height: 60px;
    width: 500px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    /*text-transform: capitalize;*/
    border-color: #dddddd;
    -webkit-box-shadow: -3.009px 3.993px 6.5px rgba(199, 109, 98, 0.21);
    box-shadow: -3.009px 3.993px 6.5px rgba(199, 109, 98, 0.21);
}

.pets-slide .slide-main .slide-contain .form-control:focus {
    -webkit-box-shadow: -3.009px 3.993px 6.5px rgba(199, 109, 98, 0.21);
    box-shadow: -3.009px 3.993px 6.5px rgba(199, 109, 98, 0.21);
    outline: none;
    border-color: #dddddd;
}

.pets-slide .slide-main .slide-contain .btn {
    font-weight: 700;
    text-transform: uppercase;
}

.pets-slide .slide-main .animat-block .animat1,
.pets-slide .slide-main .animat-block .animat2 {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.pets-slide .slide-main .animat-block .animat1 {
    left: 30px;
}

.pets-slide .slide-main .animat-block .animat2 {
    right: 30px;
}

.farming-slide {
    position: relative;
}

.farming-slide .slide-main {
    position: relative;
    overflow: hidden;
}

.farming-slide .slide-main .slide-contain {
    height: calc(100vh - 148px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    padding-left: 15px;
    padding-right: 15px;
}

.farming-slide .slide-main .slide-contain h3 {
    font-size: calc(18px + (34 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    margin-bottom: 15px;
}

.farming-slide .slide-main .slide-contain h2 {
    font-size: calc(34px + (80 - 34) * ((100vw - 320px) / (1920 - 320)));
    color: #000000;
    font-family: "Aclonica", sans-serif;
    line-height: 1;
    margin-bottom: 15px;
    /*text-transform: capitalize;*/
}

.farming-slide .slide-main .slide-contain h4 {
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 700;
    margin-bottom: 25px;
}

.farming-slide .animat-block .animat1,
.farming-slide .animat-block .animat2,
.farming-slide .animat-block .animat3 {
    position: absolute;
}

.farming-slide .animat-block .animat1 {
    top: 0;
    right: 8%;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 2;
    width: 740px;
    height: auto;
}

.farming-slide .animat-block .animat2 {
    bottom: -10px;
    left: -27px;
}

.farming-slide .animat-block .animat3 {
    top: 32px;
    right: -55px;
}

.digitalmark-slide .slide-main {
    position: relative;
}

.digitalmark-slide .slide-main .slide-contain {
    height: calc(100vh - 214px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding-left: 15px;
    padding-right: 15px;
}

.digitalmark-slide .slide-main .slide-contain .sub-contain {
    width: 700px;
}

.digitalmark-slide .slide-main .slide-contain .sub-contain h2 {
    font-size: calc(20px + (56 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 500;
    font-family: "Rubik", sans-serif;
    margin-bottom: 13px;
}

.digitalmark-slide .slide-main .slide-contain .sub-contain p {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: #999999;
    font-family: "Poppins", sans-serif;
    width: 530px;
    margin-bottom: 27px;
}

.digitalmark-slide .slide-main .slide-contain .sub-contain .input-group {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #ffffff;
    width: 507px;
    border-radius: 32px;
    overflow: hidden;
}

.digitalmark-slide .slide-main .slide-contain .sub-contain .input-group .form-control {
    background-color: #ffffff;
    /*text-transform: capitalize;*/
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    padding-left: 30px;
    border: none;
    width: 73%;
    height: 66px;
}

.digitalmark-slide .slide-main .slide-contain .sub-contain .input-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    border: none;
}

.digitalmark-slide .slide-main .slide-contain .sub-contain .input-group .btn {
    border-radius: 30px;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    /*text-transform: capitalize;*/
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin-top: auto;
    margin-bottom: auto;
    padding: 17px 30px;
    z-index: 3;
}

.digitalmark-slide .slide-main .slide-contain .sub-contain .video-btn {
    position: absolute;
    top: 37%;
    left: 10%;
    display: none;
}

.digitalmark-slide .slide-main .slide-contain .sub-contain .video-btn i {
    width: 150px;
    height: 150px;
    font-size: 80px;
    background-color: #042c49;
    color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    padding-left: 10%;
    -webkit-box-shadow: 0 15px 15px 5px rgba(199, 109, 98, 0.2);
    box-shadow: 0 15px 15px 5px rgba(199, 109, 98, 0.2);
}

.digitalmark-slide .slide-main .animat-block .animat1,
.digitalmark-slide .slide-main .animat-block .animat2 {
    position: absolute;
}

.digitalmark-slide .slide-main .animat-block .animat1 {
    top: 14%;
    left: 35%;
    -webkit-box-shadow: 0 5px 5px 2px rgba(199, 109, 98, 0.2);
    box-shadow: 0 5px 5px 2px rgba(199, 109, 98, 0.2);
    border-radius: 50%;
    -webkit-animation: move2 30s linear infinite;
    animation: move2 30s linear infinite;
}

.digitalmark-slide .slide-main .animat-block .animat2 {
    left: 7%;
    bottom: 20%;
    -webkit-animation: move1 9s linear infinite alternate;
    animation: move1 9s linear infinite alternate;
}

/*============================
   20. Title css
==============================*/
.title1 {
    text-align: center;
    background-color: #ffffff;
    padding: 42px 0;
}

.title1 h4 {
    font-size: 18px;
    color: #444444;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.title1.title-big h4 {
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.title2 {
    padding-bottom: 15px;
    margin-bottom: 30px;
    text-align: center;
    background-color: #ffffff;
}

.title2 h4 {
    font-size: 18px;
    text-transform: uppercase;
    color: #444444;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    margin: 0 auto;
}

.title2 h4:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    height: 3px;
    width: 100%;
    max-width: 100%;
    background: #042c49;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.title2 h4:hover:before {
    width: 48px;
    max-width: 50%;
}

.title3 {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 30px;
}

.title3 h4 {
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 0.05em;
    margin-top: -4px;
}

.title3.title-big h4 {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.title4 {
    text-align: center;
    padding-top: calc(20px + (42 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(20px + (42 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.title4 h4 {
    font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #444444;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1;
}

.title4 h4 span {
    color: #042c49;
}

.title5 {
    text-align: center;
    background-color: #ffffff;
}

.title5 h4 {
    font-size: 36px;
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 0.05em;
}

.title5 p {
    color: #444444;
    margin-top: 10px;
    font-size: 18px;
}

.title6 {
    text-align: center;
    position: relative;
    padding: calc(18px + (42 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.title6 h4 {
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    display: inline-block;
    margin-bottom: 0;
    padding: 0 25px;
    text-transform: uppercase;
    position: relative;
    color: #444444;
}

.title6 h4:before,
.title6 h4:after {
    width: 10%;
    height: 2px;
    content: '';
    background: #444444;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: -30px;
}

.title6 h4:after {
    right: -30px;
    left: unset;
}

.title7 {
    text-align: center;
    padding-bottom: calc(20px + (42 - 20) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ffffff;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.title7 h3 {
    text-transform: uppercase;
    margin-bottom: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
}

.title7 .line {
    position: relative;
    width: 100%;
    height: 3px;
    background-color: #ef9f77;
}

.title7 .line:before {
    content: "";
    position: absolute;
    top: -4px;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #042c49;
    animation: moveboll 15s linear infinite alternate-reverse;
}

.title8 {
    text-align: center;
    position: relative;
    padding-bottom: 15px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 45px;
}

.title8 h4 {
    font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #444444;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1;
}

.title8:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 3px;
    width: 100%;
    max-width: 100%;
    background: #042c49;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.title8:hover:before {
    width: 50%;
    max-width: 50%;
}

.title2 {
    padding-bottom: 15px;
    margin-bottom: 30px;
    text-align: center;
    background-color: #ffffff;
}

.title2 h4 {
    font-size: 18px;
    text-transform: uppercase;
    color: #444444;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    margin: 0 auto;
}

.title2 h4:before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    height: 3px;
    width: 100%;
    max-width: 100%;
    background: #042c49;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.title2 h4:hover:before {
    width: 48px;
    max-width: 50%;
}

/*============================
  21. Tab-product css
==============================*/
.tab-product-main {
    background-color: #ffffff;
}

.tab-product-main .tab-prodcut-contain {
    text-align: center;
}

.tab-product-main .tab-prodcut-contain ul li {
    padding: 40px 0;
    position: relative;
}

.tab-product-main .tab-prodcut-contain ul li:nth-child(n+2) {
    margin-left: 40px;
}

.tab-product-main .tab-prodcut-contain ul li.current a {
    color: #042c49;
}

.tab-product-main .tab-prodcut-contain ul li.current:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    background-color: #042c49;
    width: 100%;
    content: '';
}

.tab-product-main .tab-prodcut-contain ul li a {
    color: #042c49;
    text-transform: uppercase;
    font-weight: 700;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.tab-product-main.tab-second {
    padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.tab-product-main.tab-second .tab-prodcut-contain ul li {
    border-radius: 5px;
    border: 1px solid #042c49;
    line-height: 1;
    padding: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.tab-product-main.tab-second .tab-prodcut-contain ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.tab-product-main.tab-second .tab-prodcut-contain ul li a svg {
    width: calc(20px + (35 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 10px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.tab-product-main.tab-second .tab-prodcut-contain ul li.current {
    border-color: #042c49;
    background-color: #042c49;
}

.tab-product-main.tab-second .tab-prodcut-contain ul li.current a {
    color: #ffffff;
}

.tab-product-main.tab-second .tab-prodcut-contain ul li.current a svg {
    fill: #ffffff;
}

.tab-product-main.tab-second .tab-prodcut-contain ul li.current:before {
    display: none;
}

.tab-product-main.tab-third .tab-prodcut-contain ul li.current:before {
    top: unset;
    bottom: 32%;
}

.tab-product-main.tab-four {
    background-color: transparent;
}

.tab-product-main.tab-four .tab-prodcut-contain ul li {
    padding: 0;
    margin: 0;
}

.tab-product-main.tab-four .tab-prodcut-contain ul li.current a {
    background-color: #042c49;
    color: #ffffff;
}

.tab-product-main.tab-four .tab-prodcut-contain ul li.current::before {
    display: none;
}

.tab-product-main.tab-four .tab-prodcut-contain ul li a {
    padding-top: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(8px + (15 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-left: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding-right: calc(12px + (30 - 12) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 30px;
    border: 1px solid #042c49;
    line-height: 1;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
    display: block;
}

.tab-product-main.tab-four .tab-prodcut-contain ul li:nth-child(n+2) {
    margin-left: calc(0px + (15 - 0) * ((100vw - 320px) / (1920 - 320)));
}

/*============================
   22.  Newsleatter css
==============================*/
.newsletter {
    background-color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 50px 0;
}

.newsletter .news-leble {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid #dddddd;
    padding-right: 35px;
}

.newsletter .news-leble svg {
    fill: #042c49;
    width: 50px;
    height: 50px;
}

.newsletter .news-leble .news-text {
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
    color: #444444;
    line-height: 1;
    font-weight: 700;
    margin-left: 25px;
}

.newsletter .news-leble .news-text span {
    display: block;
    margin-top: 6px;
}

.newsletter .subscribe-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.newsletter .subscribe-block .input-group input {
    border: none;
    width: 320px;
    background-color: transparent;
}

.newsletter .subscribe-block .input-group input::-webkit-input-placeholder {
    color: #e8ebf2;
    font-family: "Raleway", sans-serif;
    letter-spacing: 1px;
    font-size: 12px;
}

.newsletter .subscribe-block .input-group input::-moz-placeholder {
    color: #e8ebf2;
    font-family: "Raleway", sans-serif;
    letter-spacing: 1px;
    font-size: 12px;
}

.newsletter .subscribe-block .input-group input:-ms-input-placeholder {
    color: #e8ebf2;
    font-family: "Raleway", sans-serif;
    letter-spacing: 1px;
    font-size: 12px;
}

.newsletter .subscribe-block .input-group input::-ms-input-placeholder {
    color: #e8ebf2;
    font-family: "Raleway", sans-serif;
    letter-spacing: 1px;
    font-size: 12px;
}

.newsletter .subscribe-block .input-group input::placeholder {
    color: #e8ebf2;
    font-family: "Raleway", sans-serif;
    letter-spacing: 1px;
    font-size: 12px;
}

.newsletter .subscribe-block .input-group input:focus {
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.newsletter .subscribe-block .input-group span {
    background-color: transparent;
    border: none;
    padding: 0 45px;
}

.newsletter .subscribe-block .input-group span i {
    font-size: 20px;
    color: #ef9f77;
}

.newsletter .subscribe-block .btn-normal {
    text-transform: uppercase;
    padding: 18px 30px;
}

.newsletter.newsletter-inverse .subscribe-block .btn-normal {
    background-color: #ef9f77;
}

.newsletter.newsletter-inverse .news-leble svg {
    fill: #ef9f77;
}

/*=====================
  23.breadcrumb CSS start
==========================*/
.breadcrumb-main {
    background-color: #ffffff;
    padding: 50px 0;
}

.breadcrumb-main .breadcrumb-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
}

.breadcrumb-main .breadcrumb-contain h2 {
    color: #333333;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-size: calc(20px + (28 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.breadcrumb-main .breadcrumb-contain ul li {
    margin-right: 15px;
}

.breadcrumb-main .breadcrumb-contain ul li:last-child {
    margin-right: 0;
}

.breadcrumb-main .breadcrumb-contain ul li a {
    color: #333333;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
    font-weight: 700;
}

.breadcrumb-main .breadcrumb-contain ul li i {
    color: #333333;
    font-size: 17px;
    font-weight: 700;
}

/*=====================
  24. payment offer CSS start
==========================*/
.paymant-offer-mian {
    background-color: #ffffff;
    padding: 30px;
    margin-right: 15px;
    text-align: center;
}

.paymant-offer-mian img {
    margin: 0 auto;
}

.paymant-offer-mian h2 {
    color: #ef9f77;
    font-weight: 600;
    line-height: 1;
    margin-top: 25px;
    font-family: monospace;
}

.paymant-offer-mian h3 {
    /*text-transform: capitalize;*/
    line-height: 1;
    margin-top: 10px;
    color: #333333;
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    margin-bottom: 10px;
}

.paymant-offer-mian h4 {
    /*text-transform: capitalize;*/
    line-height: 1;
    margin-top: 10px;
    margin-bottom: 15px;
    font-weight: 500;
}

.paymant-offer-mian .payment-cod {
    padding: 8px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    /*text-transform: capitalize;*/
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    line-height: 1;
    color: #ef9f77;
    background-color: rgba(239, 159, 119, 0.1);
    font-weight: 700;
    border: 1px dashed;
    border-radius: 5px;
}

/*=====================
  25.Inner pages CSS start
==========================*/
.sitemap_page .title {
    color: #042c49;
    /*text-transform: capitalize;*/
    font-weight: 700;
}

.sitemap_page ul {
    padding-left: 10px;
}

.sitemap_page ul li {
    display: block;
    margin-bottom: 10px;
}

.sitemap_page ul li a {
    color: #444444;
    font-weight: 700;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    /*text-transform: capitalize;*/
}

.sitemap_page ul li a i {
    font-size: 10px;
}

.sitemap_page ul li a:hover {
    color: #042c49;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sitemap_page ul ul {
    padding-left: 15px;
    margin-top: 5px;
}

.sitemap_page ul ul li {
    margin-bottom: 0;
}

.sitemap_page ul ul li a {
    font-weight: 400;
    color: #333333;
    font-size: 14px;
}

.select_input select {
    padding: 13px 20px;
    margin-bottom: 25px;
}

.mb-3.theme-form .mb-5 {
    margin-bottom: 0 !important;
}

.template-password {
    background: url("/../assets/images/coming-soon.jpg");
    background-size: cover;
    height: 100vh;
    background-blend-mode: overlay;
    background-color: #82c2db;
}

.template-password #container {
    height: 100vh;
    max-width: 600px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.template-password #container>div {
    width: 100%;
}

.template-password #container #login {
    width: 100%;
    margin-bottom: 120px;
}

.template-password #container #login h2 {
    color: #042c49;
    font-size: 30px;
    font-weight: 600;
    margin-top: 50px;
    font-size: 300%;
}

.template-password #container #login .theme-form h3 {
    color: #333333;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 500;
}

.template-password #container #login .theme-form input {
    border-color: #dddddd;
    font-size: 12px;
    padding: 17px 25px;
    margin-bottom: 15px;
    height: inherit;
}

.template-password #container #login .theme-form input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.template-password #container #powered {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
}

.template-password #container #owner a {
    color: #042c49;
}

.template-password .btn-normal {
    text-transform: uppercase;
}

.review-page .comment-section {
    border-bottom: none !important;
}

.review-page .comment-section li:first-child {
    border-top: none;
    padding-top: 0;
}

.review-page .comment-section li:last-child {
    padding-bottom: 0;
}

.review-page .comnt-sec {
    margin-top: 10px;
}

.review-page .comnt-sec li {
    margin-right: 15px;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    border-top: none !important;
}

.review-page .comnt-sec li a {
    color: #777777;
    font-size: 16px;
}

.review-page .comnt-sec li a i {
    margin-right: 5px;
}

.error-section {
    padding: 130px 0;
    text-align: center;
}

.error-section h1 {
    font-size: 200px;
    font-weight: 900;
    margin: 0;
    line-height: 0.8;
    color: #444444;
    margin-top: -11px;
}

.error-section h2 {
    color: #333333;
    font-weight: 700;
    letter-spacing: 3px;
    margin: 40px 0;
    text-transform: uppercase;
}

.error-section .btn-normal {
    text-transform: uppercase;
}

.breadcrumb-section {
    background-color: #dddddd;
    padding: 30px 0;
}

.breadcrumb-section .page-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.breadcrumb-section .page-title h2 {
    font-size: 16px;
    margin-bottom: 0;
}

.breadcrumb-section .breadcrumb {
    background-color: transparent;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-transform: uppercase;
    font-size: 14px;
    color: #444444;
    font-weight: 600;
    margin: 0;
    padding-right: 0;
}

.breadcrumb-section .breadcrumb a {
    color: #444444;
    font-weight: 600;
}

.about-head {
    margin-bottom: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
}

.about-head h2 {
    color: #042c49;
    margin-bottom: 0;
}

.about-page p {
    margin-bottom: -7px;
}

.about-page .banner-section {
    border-radius: 5px;
    overflow: hidden;
}

.about-page h4 {
    margin-bottom: 10px;
    margin-top: 30px;
    /*text-transform: capitalize;*/
    font-weight: 600;
    line-height: 1.3;
    color: #333333;
}

.about-page p {
    line-height: 1.8;
    // letter-spacing: 0.03em;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.team {
    position: relative;
}

.team img {
    margin: 0 auto;
    padding: 0 10px;
}

.team .slick-prev,
.team .slick-next {
    position: absolute;
    top: -45px;
}

.team .slick-prev:before,
.team .slick-next:before {
    font: normal normal normal 30px/1 FontAwesome;
    color: #444444;
    opacity: 0.6;
}

.team .slick-prev {
    left: unset;
    right: 20px;
}

.team .slick-prev:before {
    content: "\f104";
}

.team .slick-next {
    right: 0;
}

.team .slick-next:before {
    content: "\f105";
}

.team h2 {
    color: #333333;
    padding-bottom: 15px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 10px;
}

.team h4,
.team h6 {
    text-align: center;
}

.team h4 {
    color: #042c49;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 5px;
}

.team h6 {
    margin-bottom: 0;
    color: #444444;
}

.collection-filter>div:nth-child(n +2) {
    margin-top: 30px;
}

.category-6 .col-xl-2 {
    padding-left: 10px;
    padding-right: 10px;
}

.category-6 .col-xl-2 .product .product-box .product-detail .icon-detail button {
    padding: 10px;
}

.category-6 .col-xl-2 .product .product-box .product-detail .icon-detail a i {
    padding: 10px;
}

.collection-filter-block .product-service .media {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eaeaea;
}

.collection-filter-block .product-service .media svg {
    height: 40px;
    width: 40px;
}

.collection-filter-block .product-service .media svg path {
    fill: #042c49;
}

.collection-filter-block .product-service .media .media-body {
    padding-left: 10px;
}

.collection-filter-block .product-service .media .media-body h4 {
    margin-bottom: 5px;
    /*text-transform: capitalize;*/
    color: #333333;
    letter-spacing: 0.05em;
}

.collection-filter-block .product-service .media .media-body p {
    margin-bottom: 0;
    /*text-transform: capitalize;*/
    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
}

.collection-filter-block .product-service .media:last-child {
    padding-bottom: 0;
}

.collection-collapse-block .collapse-block-title {
    position: relative;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    letter-spacing: 0.05em;
    line-height: 1;
}

.collection-collapse-block .collapse-block-title:after {
    position: absolute;
    display: block;
    top: 0;
    right: -3px;
    content: "\f0d7";
    font-family: FontAwesome;
    color: #333333;
}

.collection-collapse-block .collection-collapse-block-content .color-selector {
    margin-top: 20px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .form-check-input {
    height: 20px;
    width: 20px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 18px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li a {
    color: #777777;
    padding-bottom: 10px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:last-child a {
    padding-bottom: 0;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:first-child {
    margin-top: 30px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox {
    margin-bottom: 12px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox:first-child {
    margin-top: 20px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox:last-child {
    margin-bottom: -5px;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
    text-transform: uppercase;
    cursor: pointer;
    color: #777777;
    font-weight: 600;
    letter-spacing: 0.5px;
    padding-left: 5px;
    line-height: 1.7;
    margin-bottom: 0;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:checked {
    background-color: #042c49;
    border-color: #042c49;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:before {
    top: 1px;
    height: 15px;
    width: 15px;
    background: #ffffff;
    border: 1px solid #dddddd;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label:after {
    top: 1px;
    height: 15px;
    width: 15px;
    background-size: 65%;
}

.collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox .custom-control-input:checked~.custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
}

.color-selector ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    color: #777777;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.color-selector ul li:nth-child(n+2) {
    margin-top: 5px;
}

.color-selector ul li:nth-child(n+2)>div.active:after {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.color-selector ul li>div {
    height: 22px;
    width: 22px;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #dddddd;
}

.color-selector ul li>div.active {
    position: relative;
}

.color-selector ul li>div.active:after {
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
    top: 5px;
    right: 0;
    height: 15px;
    width: 15px;
    background-size: 70%;
    background-repeat: no-repeat;
    position: absolute;
}

.color-selector ul li .color-1 {
    background-color: #ffffff;
}

.color-selector ul li .color-2 {
    background-color: brown;
}

.color-selector ul li .color-3 {
    background-color: red;
}

.color-selector ul li .color-4 {
    background-color: purple;
}

.color-selector ul li .color-5 {
    background-color: teal;
}

.color-selector ul li .color-6 {
    background-color: pink;
}

.color-selector ul li .color-7 {
    background-color: coral;
}

.color-selector.inline ul li {
    display: inline-block;
}

.color-selector.inline ul li>div {
    margin-right: unset;
}

.color-selector.inline ul li:nth-child(n+2) {
    margin-top: 0;
    margin-left: 5px;
}

.collection-sidebar-banner {
    margin-top: 30px;
}

.collection-sidebar-banner img {
    width: 100%;
}

.top-banner-wrapper .top-banner-content h4 {
    font-weight: 600;
    color: #333333;
    margin-bottom: 15px;
    /*text-transform: capitalize;*/
}

.top-banner-wrapper .top-banner-content h5 {
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    letter-spacing: 1px;
    line-height: 1.2;
    margin-bottom: 10px;
}

.top-banner-wrapper .top-banner-content p {
    line-height: 1.5;
    color: #777777;
    margin-bottom: 0;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.product-wrapper-grid.list-view .product-wrap .product-info {
    text-align: left;
    -ms-flex-item-align: center;
    align-self: center;
    padding-left: 15px;
}

.product-wrapper-grid.list-view .product-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 0;
    background-color: #ffffff;
}

.product-wrapper-grid.list-view .product-box .product-imgbox,
.product-wrapper-grid.list-view .product-box .img-block {
    width: 25%;
}

.product-wrapper-grid.list-view .product-box .product-detail {
    padding-left: 15px;
    -ms-flex-item-align: center;
    align-self: center;
    text-align: left !important;
}

.product-wrapper-grid.list-view .product-box .product-detail.detail-center {
    padding: 0;
    padding-left: 25px;
    background-color: transparent;
    overflow: unset;
}

.product-wrapper-grid.list-view .product-box .product-detail .rating-star {
    margin-top: 0;
    margin-bottom: 10px;
}

.product-wrapper-grid.list-view .product-box .product-detail p {
    display: block !important;
    margin-bottom: 10px;
    line-height: 18px;
}

.product-wrapper-grid.list-view .product-box .product-detail .color-variant {
    padding-top: 10px;
}

.product-wrapper-grid.list-view .product-box .product-detail h6 {
    font-weight: 700;
    margin-bottom: 10px;
}

.product-wrapper-grid.list-view .product-box .product-detail .detail-title {
    opacity: 1 !important;
    padding-top: 0 !important;
    background-color: transparent;
}

.product-wrapper-grid.list-view .product-box .product-detail .icon-detail {
    position: relative;
    padding-top: 10px;
    left: unset;
    right: unset;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    top: 0;
    bottom: 0;
    opacity: 1;
}

.product-wrapper-grid.list-view .product-box .product-detail .icon-detail button {
    -webkit-animation: none;
    animation: none;
}

.product-wrapper-grid.list-view .product-box .product-detail .icon-detail button i {
    font-size: 14px;
}

.product-wrapper-grid.list-view .product-box .product-detail .icon-detail a i {
    font-size: 12px;
}

.product-wrapper-grid.list-view .product-box .product-detail .icon-detail a:nth-child(2) i {
    -webkit-animation: none;
    animation: none;
}

.product-wrapper-grid.list-view .product-box .product-detail .icon-detail a:nth-child(3) i {
    -webkit-animation: none;
    animation: none;
}

.product-wrapper-grid.list-view .product-box .product-detail .icon-detail a:nth-child(4) i {
    -webkit-animation: none;
    animation: none;
}

.collection-product-wrapper .product-top-filter {
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
}

.collection-product-wrapper .product-top-filter .product-filter-content,
.collection-product-wrapper .product-top-filter .popup-filter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.collection-product-wrapper .product-top-filter .product-filter-content .search-count,
.collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup,
.collection-product-wrapper .product-top-filter .popup-filter .search-count,
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    border-right: 1px solid #dddddd;
    padding: 20px;
    /*text-transform: capitalize;*/
    width: 35%;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.collection-product-wrapper .product-top-filter .product-filter-content .search-count h5,
.collection-product-wrapper .product-top-filter .product-filter-content .sidebar-popup h5,
.collection-product-wrapper .product-top-filter .popup-filter .search-count h5,
.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup h5 {
    margin-bottom: 0;
    color: #333333;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-view,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view {
    padding: 20px;
    width: 10%;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li {
    height: 100%;
    vertical-align: middle;
    cursor: pointer;
    color: #333333;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li:first-child,
.collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li:first-child {
    margin-right: 14px;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
    padding: 20px;
    width: 20%;
    border-right: 1px solid #dddddd;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li {
    margin-left: 10px;
}

.collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li img,
.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view ul li img {
    height: 18px;
    cursor: pointer;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    position: relative;
    width: 20%;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
    border: 0;
    padding: 20px;
    border-right: 1px solid #dddddd;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background: url("/../assets/images/dropdown.png") no-repeat 95%;
    color: #333333;
    font-weight: 700;
    letter-spacing: 0.05em;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select:focus,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select:focus,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select:focus,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select:focus {
    outline: unset;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select option,
.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select option,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select option,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select option {
    padding: 20px 0;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    width: 15%;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    right: 10px;
}

.collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
    border-right: none;
}

.collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter-toggle,
.collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter-toggle {
    background: url("/../assets/images/dropdown.png") no-repeat 95%;
    border-right: 1px solid #dddddd;
    padding: 20px;
    /*text-transform: capitalize;*/
    width: 35%;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    cursor: pointer;
}

.collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter-toggle h5,
.collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter-toggle h4,
.collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter-toggle h5,
.collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter-toggle h4 {
    margin-bottom: 0;
    color: #333333;
}

.collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter-toggle h4,
.collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter-toggle h4 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter-toggle h4 svg,
.collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter-toggle h4 svg {
    margin-right: 10px;
}

.collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter,
.collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter {
    position: absolute;
    top: 100%;
    left: 15px;
    width: 860px;
    padding: 30px;
    z-index: 1;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    display: none;
}

.collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter .horizontal-filter-contain,
.collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter .horizontal-filter-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 800px;
}

.collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter .horizontal-filter-contain .filter-group,
.collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter .horizontal-filter-contain .filter-group {
    padding-right: 20px;
    margin-right: 20px;
    width: 25%;
    border-right: 1px solid #e8ebf2;
}

.collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter .horizontal-filter-contain .filter-group:last-child,
.collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter .horizontal-filter-contain .filter-group:last-child {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
}

.collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter .btn,
.collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter .btn {
    float: right;
}

.collection-product-wrapper .product-top-filter .popup-filter {
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar {
    width: 3px;
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-track {
    background: #dddddd;
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb {
    background: #444444;
}

.collection-product-wrapper .product-top-filter .popup-filter ::-webkit-scrollbar-thumb:hover {
    background: #444444;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    position: relative;
    cursor: pointer;
    width: 20%;
    background: url("/../assets/images/dropdown.png") no-repeat 95%;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup a {
    font-size: 16px;
    color: #333333;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
    background-color: #8a8a8a;
    width: 270px;
    height: 600px;
    overflow-y: scroll;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-filter-block {
    border: none;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .theme-card {
    padding: 0 30px;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner {
    padding: 0 30px;
}

.collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter .collection-sidebar-banner img {
    width: 100%;
}

.collection-product-wrapper .product-top-filter .popup-filter .open-popup {
    display: none;
    position: absolute;
    z-index: 9;
    top: 75px;
    left: 0;
    min-width: 330px;
    border: 5px solid #f2f2f2;
}

.collection-product-wrapper .product-top-filter .popup-filter .open-popup.open {
    display: block;
}

.collection-product-wrapper .product-top-filter .popup-filter .open-popup .collection-filter-block {
    margin-bottom: 0;
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
    width: 20%;
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
    padding-right: 45px;
    font-size: 16px;
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    right: 35px;
}

.collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
    padding: 20px;
}

.collection-product-wrapper .product-top-filter .popup-filter .collection-view {
    width: 20%;
}

.collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
    right: 25px !important;
}

.collection-product-wrapper .product-wrapper-grid .product-box {
    margin-top: 30px;
    padding-right: 0;
}

.collection-product-wrapper .product-wrapper-grid .product-box .product-detail {
    padding-top: 0;
}

.collection-product-wrapper .product-wrapper-grid .product-box .product-detail p {
    display: none;
}

.collection-product-wrapper .product-wrapper-grid .product-box .product-detail .detail-title .detail-left .price-title {
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding-top: 5px;
}

.collection-product-wrapper .product-wrapper-grid .product-five {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 {
    padding-left: 10px;
    padding-right: 10px;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box {
    margin-top: 15px;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail .icon-detail a,
.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail .icon-detail button {
    width: 35px;
    height: 35px;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail .icon-detail a svg,
.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail .icon-detail button svg {
    width: 16px;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h6 {
    font-size: 13px;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail h4 {
    font-size: 18px;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .product-detail .rating i {
    padding-right: 0;
}

.collection-product-wrapper .product-wrapper-grid .col-lg-2 .product-box .img-wrapper .label-block .label3 {
    padding: 8px 4px;
    font-size: 12px;
}

.filter-slide {
    padding-top: 30px;
}

.filter-slide .irs {
    position: relative;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 12px;
    font-family: Arial, sans-serif;
}

.filter-slide .irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none !important;
}

.filter-slide .irs-bar {
    position: absolute;
    display: block;
    left: 0;
    width: 0;
}

.filter-slide .irs-shadow {
    position: absolute;
    display: none;
    left: 0;
    width: 0;
}

.filter-slide .irs-handle {
    position: absolute;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: default;
    z-index: 1;
}

.filter-slide .irs-handle.type_last {
    z-index: 2;
}

.filter-slide .irs-min {
    position: absolute;
    display: block;
    cursor: default;
    left: 0;
    display: none;
}

.filter-slide .irs-max {
    position: absolute;
    display: block;
    cursor: default;
    right: 0;
}

.filter-slide .irs-from {
    position: absolute;
    display: block;
    left: 0;
    cursor: default;
    white-space: nowrap;
}

.filter-slide .irs-from,
.filter-slide .irs-to {
    top: -10px;
    color: #ffffff;
    font-size: 14px;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #042c49;
    border-radius: 4px;
    font-weight: 700;
}

.filter-slide .irs-to {
    position: absolute;
    display: block;
    left: 0;
    cursor: default;
    white-space: nowrap;
}

.filter-slide .irs--flat .irs-to:before,
.filter-slide .irs--flat .irs-from:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #042c49;
}

.filter-slide .irs-single {
    position: absolute;
    display: block;
    top: -10px;
    left: 0;
    cursor: default;
    white-space: nowrap;
}

.filter-slide .irs-grid {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
}

.filter-slide .irs-grid-pol {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 8px;
    background: #000;
}

.filter-slide .irs-grid-pol.small {
    height: 4px;
}

.filter-slide .irs-grid-text {
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    padding: 0 3px;
    color: #000;
}

.filter-slide .irs-disable-mask {
    position: absolute;
    display: block;
    top: 0;
    left: -1%;
    width: 102%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
}

.filter-slide .lt-ie9 .irs-disable-mask {
    background: #000;
    filter: alpha(opacity=0);
    cursor: not-allowed;
}

.filter-slide .irs-disabled {
    opacity: 0.4;
}

.filter-slide .irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow: hidden;
    outline: none !important;
    z-index: -9999 !important;
    background: none !important;
    border-style: solid !important;
    border-color: transparent !important;
}

.filter-slide .irs--flat {
    height: 40px;
}

.filter-slide .irs--flat .irs-line {
    top: 25px;
    height: 5px;
    background-color: #f7f7f7;
    border-radius: 4px;
}

.filter-slide .irs--flat .irs-bar {
    top: 25px;
    height: 5px;
    background-color: #042c49;
}

.filter-slide .irs--flat .irs-bar--single {
    border-radius: 4px 0 0 4px;
}

.filter-slide .irs--flat .irs-shadow {
    height: 1px;
    bottom: 16px;
    background-color: #e1e4e9;
}

.filter-slide .irs--flat .irs-handle {
    top: 22px;
    width: 16px;
    height: 18px;
    background-color: transparent;
}

.filter-slide .irs--flat .irs-handle>i:first-child {
    position: absolute;
    display: block;
    top: -2px;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: -1px;
    background-color: #042c49;
    border: 4px solid #042c49;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}

.filter-slide .irs--flat .irs-handle.state_hover>i:first-child {
    background-color: #042c49;
}

.filter-slide .irs--flat .irs-min {
    top: 0;
    padding: 1px 3px;
    color: #999;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    background-color: #e1e4e9;
    border-radius: 4px;
}

.filter-slide .irs--flat .irs-max {
    top: 0;
    padding: 1px 3px;
    color: #999;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    background-color: #e1e4e9;
    border-radius: 4px;
    display: none;
}

.filter-slide .irs--flat .irs-single {
    color: #ffffff;
    font-size: 14px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #042c49;
    border-radius: 4px;
}

.filter-slide .irs--flat .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #042c49;
}

.filter-slide .irs--flat .irs-grid-pol {
    background-color: #e1e4e9;
}

.filter-slide .irs--flat .irs-grid-text {
    color: #999;
}

.filter-slide .irs--big {
    height: 55px;
}

.filter-slide .irs--big .irs-line {
    top: 33px;
    height: 12px;
    background-color: #ffffff;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(-50%, #ddd), color-stop(150%, white));
    background: linear-gradient(to bottom, #ddd -50%, white 150%);
    border: 1px solid #ccc;
    border-radius: 12px;
}

.filter-slide .irs--big .irs-bar {
    top: 33px;
    height: 12px;
    background-color: #92bce0;
    border: 1px solid #428bca;
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(30%, #428bca), to(#b9d4ec));
    background: linear-gradient(to bottom, #ffffff 0%, #428bca 30%, #b9d4ec 100%);
    -webkit-box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5);
    box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.5);
}

.filter-slide .irs--big .irs-bar--single {
    border-radius: 12px 0 0 12px;
}

.filter-slide .irs--big .irs-shadow {
    height: 1px;
    bottom: 16px;
    background-color: rgba(66, 139, 202, 0.5);
}

.filter-slide .irs--big .irs-handle {
    top: 25px;
    width: 30px;
    height: 30px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background-color: #cbcfd5;
    background: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(30%, #B4B9BE), to(white));
    background: linear-gradient(to bottom, white 0%, #B4B9BE 30%, white 100%);
    -webkit-box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2), inset 0 0 3px 1px white;
    border-radius: 30px;
}

.filter-slide .irs--big .irs-handle:hover {
    border-color: rgba(0, 0, 0, 0.45);
    background-color: #939ba7;
    background: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(30%, #919BA5), to(white));
    background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%);
}

.filter-slide .irs--big .irs-handle.state_hover {
    border-color: rgba(0, 0, 0, 0.45);
    background-color: #939ba7;
    background: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(30%, #919BA5), to(white));
    background: linear-gradient(to bottom, white 0%, #919BA5 30%, white 100%);
}

.filter-slide .irs--big .irs-min {
    top: 0;
    padding: 1px 5px;
    color: white;
    text-shadow: none;
    background-color: #9f9f9f;
    border-radius: 3px;
}

.filter-slide .irs--big .irs-max {
    top: 0;
    padding: 1px 5px;
    color: white;
    text-shadow: none;
    background-color: #9f9f9f;
    border-radius: 3px;
}

.filter-slide .irs--big .irs-from {
    color: white;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #428bca;
    background: -webkit-gradient(linear, left top, left bottom, from(#428bca), to(#3071a9));
    background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
    border-radius: 3px;
}

.filter-slide .irs--big .irs-to {
    color: white;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #428bca;
    background: -webkit-gradient(linear, left top, left bottom, from(#428bca), to(#3071a9));
    background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
    border-radius: 3px;
}

.filter-slide .irs--big .irs-single {
    color: white;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #428bca;
    background: -webkit-gradient(linear, left top, left bottom, from(#428bca), to(#3071a9));
    background: linear-gradient(to bottom, #428bca 0%, #3071a9 100%);
    border-radius: 3px;
}

.filter-slide .irs--big .irs-grid-pol {
    background-color: #428bca;
}

.filter-slide .irs--big .irs-grid-text {
    color: #428bca;
}

.filter-slide .irs--big.irs-with-grid {
    height: 70px;
}

.filter-slide .irs--modern {
    height: 55px;
}

.filter-slide .irs--modern .irs-line {
    top: 25px;
    height: 5px;
    background-color: #d1d6e0;
    background: -webkit-gradient(linear, left top, left bottom, from(#e0e4ea), to(#d1d6e0));
    background: linear-gradient(to bottom, #e0e4ea 0%, #d1d6e0 100%);
    border: 1px solid #a3adc1;
    border-bottom-width: 0;
    border-radius: 5px;
}

.filter-slide .irs--modern .irs-bar {
    top: 25px;
    height: 5px;
    background: #20b426;
    background: -webkit-gradient(linear, left top, left bottom, from(#20b426), to(#18891d));
    background: linear-gradient(to bottom, #20b426 0%, #18891d 100%);
}

.filter-slide .irs--modern .irs-bar--single {
    border-radius: 5px 0 0 5px;
}

.filter-slide .irs--modern .irs-shadow {
    height: 1px;
    bottom: 21px;
    background-color: rgba(209, 214, 224, 0.5);
}

.filter-slide .irs--modern .irs-handle {
    top: 37px;
    width: 12px;
    height: 13px;
    border: 1px solid #a3adc1;
    border-top-width: 0;
    -webkit-box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 3px 3px;
}

.filter-slide .irs--modern .irs-handle>i:nth-child(1) {
    position: absolute;
    display: block;
    top: -4px;
    left: 1px;
    width: 6px;
    height: 6px;
    border: 1px solid #a3adc1;
    background: white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.filter-slide .irs--modern .irs-handle>i:nth-child(2) {
    position: absolute;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    top: 0;
    left: 0;
    width: 10px;
    height: 12px;
    background: #e9e6e6;
    background: -webkit-gradient(linear, left top, left bottom, from(white), to(#e9e6e6));
    background: linear-gradient(to bottom, white 0%, #e9e6e6 100%);
    border-radius: 0 0 3px 3px;
}

.filter-slide .irs--modern .irs-handle>i:nth-child(3) {
    position: absolute;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    top: 3px;
    left: 3px;
    width: 4px;
    height: 5px;
    border-left: 1px solid #a3adc1;
    border-right: 1px solid #a3adc1;
}

.filter-slide .irs--modern .irs-handle:hover {
    border-color: #7685a2;
    background: #c3c7cd;
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(30%, #919ba5), to(#ffffff));
    background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%);
}

.filter-slide .irs--modern .irs-handle:hover>i:nth-child(1) {
    border-color: #7685a2;
}

.filter-slide .irs--modern .irs-handle:hover>i:nth-child(3) {
    border-color: #48536a;
}

.filter-slide .irs--modern .irs-handle.state_hover {
    border-color: #7685a2;
    background: #c3c7cd;
    background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), color-stop(30%, #919ba5), to(#ffffff));
    background: linear-gradient(to bottom, #ffffff 0%, #919ba5 30%, #ffffff 100%);
}

.filter-slide .irs--modern .irs-handle.state_hover>i:nth-child(1) {
    border-color: #7685a2;
}

.filter-slide .irs--modern .irs-handle.state_hover>i:nth-child(3) {
    border-color: #48536a;
}

.filter-slide .irs--modern .irs-min {
    top: 0;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    color: white;
    background-color: #d1d6e0;
    border-radius: 5px;
}

.filter-slide .irs--modern .irs-max {
    top: 0;
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    color: white;
    background-color: #d1d6e0;
    border-radius: 5px;
}

.filter-slide .irs--modern .irs-from {
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #20b426;
    color: white;
    border-radius: 5px;
}

.filter-slide .irs--modern .irs-from:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #20b426;
}

.filter-slide .irs--modern .irs-to {
    font-size: 10px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #20b426;
    color: white;
    border-radius: 5px;
}

.filter-slide .irs--modern .irs-to:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #20b426;
}

.filter-slide .irs--modern .irs-single {
    font-size: 14px;
    text-shadow: none;
    padding: 1px 5px;
    background-color: #20b426;
    color: white;
    border-radius: 5px;
}

.filter-slide .irs--modern .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #20b426;
}

.filter-slide .irs--modern .irs-grid {
    height: 25px;
}

.filter-slide .irs--modern .irs-grid-pol {
    background-color: #dedede;
}

.filter-slide .irs--modern .irs-grid-text {
    color: silver;
    font-size: 13px;
}

.filter-slide .irs--modern.irs-with-grid {
    height: 55px;
}

.filter-slide .irs--sharp {
    height: 50px;
    font-size: 12px;
    line-height: 1;
}

.filter-slide .irs--sharp .irs-line {
    top: 30px;
    height: 2px;
    background-color: black;
    border-radius: 2px;
}

.filter-slide .irs--sharp .irs-bar {
    top: 30px;
    height: 2px;
    background-color: #ee22fa;
}

.filter-slide .irs--sharp .irs-bar--single {
    border-radius: 2px 0 0 2px;
}

.filter-slide .irs--sharp .irs-shadow {
    height: 1px;
    bottom: 21px;
    background-color: rgba(0, 0, 0, 0.5);
}

.filter-slide .irs--sharp .irs-handle {
    top: 25px;
    width: 10px;
    height: 10px;
    background-color: #a804b2;
}

.filter-slide .irs--sharp .irs-handle>i:first-child {
    position: absolute;
    display: block;
    top: 100%;
    left: 0;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: #a804b2;
}

.filter-slide .irs--sharp .irs-handle:hover {
    background-color: black;
}

.filter-slide .irs--sharp .irs-handle:hover>i:first-child {
    border-top-color: black;
}

.filter-slide .irs--sharp .irs-handle.state_hover {
    background-color: black;
}

.filter-slide .irs--sharp .irs-handle.state_hover>i:first-child {
    border-top-color: black;
}

.filter-slide .irs--sharp .irs-min {
    color: white;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 4px;
    opacity: 0.4;
    background-color: #a804b2;
    border-radius: 2px;
}

.filter-slide .irs--sharp .irs-max {
    color: white;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 4px;
    opacity: 0.4;
    background-color: #a804b2;
    border-radius: 2px;
}

.filter-slide .irs--sharp .irs-from {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 4px;
    background-color: #a804b2;
    color: white;
    border-radius: 2px;
}

.filter-slide .irs--sharp .irs-from:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #a804b2;
}

.filter-slide .irs--sharp .irs-to {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 4px;
    background-color: #a804b2;
    color: white;
    border-radius: 2px;
}

.filter-slide .irs--sharp .irs-to:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #a804b2;
}

.filter-slide .irs--sharp .irs-single {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 4px;
    background-color: #a804b2;
    color: white;
    border-radius: 2px;
}

.filter-slide .irs--sharp .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #a804b2;
}

.filter-slide .irs--sharp .irs-grid {
    height: 25px;
}

.filter-slide .irs--sharp .irs-grid-pol {
    background-color: #dedede;
}

.filter-slide .irs--sharp .irs-grid-text {
    color: silver;
    font-size: 13px;
}

.filter-slide .irs--sharp.irs-with-grid {
    height: 57px;
}

.filter-slide .irs--round {
    height: 50px;
}

.filter-slide .irs--round .irs-line {
    top: 36px;
    height: 4px;
    background-color: #dee4ec;
    border-radius: 4px;
}

.filter-slide .irs--round .irs-bar {
    top: 36px;
    height: 4px;
    background-color: #006cfa;
}

.filter-slide .irs--round .irs-bar--single {
    border-radius: 4px 0 0 4px;
}

.filter-slide .irs--round .irs-shadow {
    height: 4px;
    bottom: 21px;
    background-color: rgba(222, 228, 236, 0.5);
}

.filter-slide .irs--round .irs-handle {
    top: 26px;
    width: 24px;
    height: 24px;
    border: 4px solid #006cfa;
    background-color: white;
    border-radius: 24px;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
    box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
}

.filter-slide .irs--round .irs-handle:hover {
    background-color: #f0f6ff;
}

.filter-slide .irs--round .irs-handle.state_hover {
    background-color: #f0f6ff;
}

.filter-slide .irs--round .irs-min {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.filter-slide .irs--round .irs-max {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.filter-slide .irs--round .irs-from {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: #006cfa;
    color: white;
    border-radius: 4px;
}

.filter-slide .irs--round .irs-from:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #006cfa;
}

.filter-slide .irs--round .irs-to {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: #006cfa;
    color: white;
    border-radius: 4px;
}

.filter-slide .irs--round .irs-to:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #006cfa;
}

.filter-slide .irs--round .irs-single {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: #006cfa;
    color: white;
    border-radius: 4px;
}

.filter-slide .irs--round .irs-single:before {
    position: absolute;
    display: block;
    content: "";
    bottom: -6px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -3px;
    overflow: hidden;
    border: 3px solid transparent;
    border-top-color: #006cfa;
}

.filter-slide .irs--round .irs-grid {
    height: 25px;
}

.filter-slide .irs--round .irs-grid-pol {
    background-color: #dedede;
}

.filter-slide .irs--round .irs-grid-text {
    color: silver;
    font-size: 13px;
}

.filter-slide .irs--round.irs-with-grid {
    height: 65px;
}

.filter-slide .irs--square {
    height: 50px;
}

.filter-slide .irs--square .irs-line {
    top: 31px;
    height: 4px;
    background-color: #dedede;
}

.filter-slide .irs--square .irs-bar {
    top: 31px;
    height: 4px;
    background-color: black;
}

.filter-slide .irs--square .irs-shadow {
    height: 2px;
    bottom: 21px;
    background-color: #dedede;
}

.filter-slide .irs--square .irs-handle {
    top: 25px;
    width: 16px;
    height: 16px;
    border: 3px solid black;
    background-color: white;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.filter-slide .irs--square .irs-handle:hover {
    background-color: #f0f6ff;
}

.filter-slide .irs--square .irs-handle.state_hover {
    background-color: #f0f6ff;
}

.filter-slide .irs--square .irs-min {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);
}

.filter-slide .irs--square .irs-max {
    color: #333;
    font-size: 14px;
    line-height: 1;
    top: 0;
    padding: 3px 5px;
    background-color: rgba(0, 0, 0, 0.1);
}

.filter-slide .irs--square .irs-from {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: black;
    color: white;
}

.filter-slide .irs--square .irs-to {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: black;
    color: white;
}

.filter-slide .irs--square .irs-single {
    font-size: 14px;
    line-height: 1;
    text-shadow: none;
    padding: 3px 5px;
    background-color: black;
    color: white;
}

.filter-slide .irs--square .irs-grid {
    height: 25px;
}

.filter-slide .irs--square .irs-grid-pol {
    background-color: #dedede;
}

.filter-slide .irs--square .irs-grid-text {
    color: silver;
    font-size: 11px;
}

.filter-slide .irs--square.irs-with-grid {
    height: 60px;
}

.filter-main-btn {
    display: none;
    margin-bottom: 20px;
    cursor: pointer;
}

.filter-main-btn span {
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    background-color: #042c49;
    padding: 5px 20px;
    color: #ffffff;
    border-radius: 3px;
}

.collection-mobile-back {
    padding: 15px 0;
    border-bottom: 1px solid #dddddd;
    display: none;
}

.collection-mobile-back span {
    text-transform: uppercase;
    font-size: 18px;
    color: #333333;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 0.05em;
}

.collection-mobile-back span i {
    font-size: 20px;
    font-weight: 700;
    margin-right: 5px;
}

.product-pagination {
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    margin-top: calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320)));
}

.product-pagination .pagination {
    border-radius: 0;
}

.product-pagination .pagination .page-item {
    border-radius: 0;
}

.product-pagination .pagination .page-item a {
    padding: 18px;
    border-bottom: 0;
    border-top: 0;
    color: #777777;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.product-pagination .pagination .page-item a:hover {
    color: #042c49;
    background-color: #ffffff;
    border-color: #dddddd;
    background-color: rgba(199, 109, 98, 0.1);
}

.product-pagination .pagination .page-item:last-child .page-link {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.product-pagination .pagination .page-item.active a {
    color: #042c49;
    background-color: #ffffff;
    border-color: #dddddd;
    background-color: rgba(199, 109, 98, 0.1);
}

.product-pagination .product-search-count-bottom {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    padding-right: 15px;
}

.product-pagination .product-search-count-bottom h5 {
    color: #333333;
    margin-bottom: 0;
    padding: 5px 0;
}

.bundle {
    margin-top: 10px;
}

.bundle .bundle_img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.bundle .bundle_img .img-box img {
    max-width: 115px;
    border: 1px solid #dddddd;
    padding: 2px;
}

.bundle .bundle_img .plus {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 7px;
    font-size: 22px;
}

.bundle .bundle_detail {
    margin-top: 15px;
}

.bundle .bundle_detail .price_product {
    color: #000000;
    padding-left: 3px;
    font-weight: 700;
}

.bundle .bundle_detail .btn-normal {
    padding: 13px 25px;
    text-transform: uppercase;
}

.theme_checkbox label {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /*text-transform: capitalize;*/
    color: #333333;
}

.theme_checkbox label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.theme_checkbox label input:checked~.checkmark:after {
    display: block;
}

.theme_checkbox label .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
}

.theme_checkbox label .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.theme-card {
    position: relative;
    background-color: #ffffff;
}

.theme-card input:focus,
.theme-card textarea:focus,
.theme-card select:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.theme-card.center-align {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.theme-card.center-align .offer-slider .product-box2 .media .media-body {
    margin-top: 0;
}

.theme-card.card-border {
    border: 1px solid #dddddd;
}

.theme-card.card-border h5 {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    margin-top: 0;
}

.theme-card.card-border .slick-prev,
.theme-card.card-border .slick-next {
    top: -40px;
}

.theme-card.card-border .slick-next {
    right: 30px;
}

.theme-card.card-border .slick-prev {
    right: 50px;
}

.theme-card.card-border .offer-slider {
    padding-top: 0;
}

.theme-card.card-border .offer-slider img {
    padding: 15px;
}

.theme-card.card-border .offer-slider .media .media-body a h6 {
    margin-right: 0;
}

.theme-card h5 {
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 10px;
    color: #333333;
    letter-spacing: 0.05em;
}

.theme-card h5.title-border {
    border-bottom: 1px solid #dddddd;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.theme-card .slick-prev,
.theme-card .slick-next {
    top: -28px;
}

.theme-card .slick-prev:before,
.theme-card .slick-next:before {
    font: normal normal normal 30px/1 FontAwesome;
    color: #333333;
    opacity: 0.6;
}

.theme-card .slick-next {
    right: 0;
}

.theme-card .slick-next:before {
    content: "\f105";
}

.theme-card .slick-prev {
    left: unset;
    right: 25px;
}

.theme-card .slick-prev:before {
    content: "\f104";
}

.theme-card .offer-slider img {
    height: 140px;
    padding: 15px 15px 0 0;
}

.theme-card .offer-slider>div .media:last-child .media-body {
    margin-top: 15px;
}

.theme-card .offer-slider>div .media:last-child img {
    margin-bottom: -15px;
}

.theme-card .offer-slider .media .media-body .rating {
    margin-top: 0;
}

.theme-card .offer-slider .media .media-body .rating i {
    padding-right: 3px;
}

.theme-card .offer-slider .media .media-body .rating i:nth-child(-n + 4) {
    color: #ffa800;
}

.theme-card .offer-slider .media .media-body .rating i:last-child {
    color: #dddddd;
}

.theme-card .offer-slider .media .media-body a h6 {
    margin-right: 61px;
    margin-top: 5px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 0;
    color: #777777;
}

.theme-card .offer-slider .media .media-body h4 {
    margin-top: 10px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 0;
}

.related-pro1 .product-box {
    padding-right: 0;
}

.addtocart_count {
    position: relative;
}

.addtocart_count button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.addtocart_count .product-box .product-detail {
    text-align: center;
}

.addtocart_count .product-box .product-detail .detail-title .detail-right {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.addtocart_count .product-box .cart-info {
    bottom: 40px;
    right: 10px;
}

.addtocart_count .product-box .cart-info a i {
    background-color: #e2e2e2;
    border-radius: 100%;
    margin: 10px 0;
    padding: 8px;
    font-size: 16px;
    color: #313131;
}

.addtocart_count .product-box .add-button {
    background-color: #ef9f77;
    color: #ffffff;
    text-align: center;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    /*text-transform: capitalize;*/
    width: 100%;
    padding: 5px 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border: none;
    cursor: pointer;
    font-weight: 700;
}

.addtocart_count .product-box:hover .cart-info a:nth-child(1) i {
    -webkit-animation: fadeInRight 300ms ease-in-out;
    animation: fadeInRight 300ms ease-in-out;
}

.addtocart_count .product-box:hover .add-button {
    bottom: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.addtocart_count .addtocart_btn {
    position: relative;
}

.addtocart_count .addtocart_btn .cart_qty {
    width: 100%;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box {
    position: absolute;
    bottom: 0;
    display: none;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group .form-control {
    width: 100%;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group .form-control:focus {
    border-color: #efefef;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button {
    background: #ef9f77;
    color: #ffffff;
    position: absolute;
    height: 100%;
    z-index: 9;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button.quantity-left-minus {
    left: 0;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button.quantity-left-minus:before {
    display: none;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button.quantity-right-plus {
    right: 0;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button.quantity-right-plus:before {
    display: none;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button i {
    color: #ffffff !important;
}

.addtocart_count .addtocart_btn .cart_qty.qty-box .input-group button i {
    color: #000000;
}

.addtocart_count .addtocart_btn .cart_qty.open {
    display: block;
}

.category-side .collection-collapse-block .collapse-block-title {
    margin-top: 20px;
}

.category-side .color-selector ul li {
    margin-bottom: 12px;
}

.metro-block .product-box .product-imgbox .product-detail {
    background-color: #ffffff;
    padding: 15px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    position: absolute;
    bottom: -100%;
    left: 0;
    right: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.metro-block .product-box .product-imgbox .product-detail h6 {
    color: #444444;
    /*text-transform: capitalize;*/
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.metro-block .product-box .product-imgbox .product-detail h4 {
    color: #333333;
}

.metro-block .product-box:hover .product-imgbox .product-detail {
    bottom: 15px;
}

.center-product .theme-card {
    border: 1px solid #dddddd;
    padding: 15px;
    margin-right: 15px;
}

.center-product .theme-card .offer-slider .media .media-body a h6 {
    margin-right: 0;
}

.collection .collection-block {
    text-align: center;
}

.collection .collection-block img,
.collection .collection-block .bg-size {
    margin-bottom: 20px;
}

.collection .collection-block .collection-content {
    text-align: center;
    padding: 15px;
    border: 1px solid #e8e8e8;
}

.collection .collection-block .collection-content h3 {
    margin-bottom: 10px;
    color: #333333;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 0.05em;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.collection .collection-block .collection-content h4 {
    color: #444444;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
}

.collection .collection-block .collection-content p {
    line-height: 1.5;
    margin-bottom: 15px;
}

.collection .collection-block .collection-content .btn-normal {
    padding: 10px 25px;
    text-transform: uppercase;
}

.faq-section .accordion-item p {
    font-size: 16px;
    line-height: 1.6;
}

.faq-section .accordion-item:nth-child(n+2) {
    margin-top: 15px;
}

.faq-section .accordion-item .accordion-header .accordion-button {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 600;
    background-color: #f1f4f7;
    border-bottom: 1px solid rgba(199, 109, 98, 0.2);
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.faq-section .accordion-item .accordion-header .accordion-button:after {
    content: "\f0d7";
    font-family: FontAwesome;
    background-image: none;
}

.faq-section .accordion-item .accordion-header .accordion-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.faq-section .accordion-item .accordion-collapse {
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.pwd-page {
    text-align: center;
}

.pwd-page h2 {
    margin-bottom: 25px;
    color: #333333;
    margin-top: -5px;
}

.pwd-page .theme-form input {
    border-color: #dddddd;
    font-size: 15px;
    padding: 15px 25px;
    margin-bottom: 15px;
    height: inherit;
    text-align: center;
}

.pwd-page .theme-form .btn-normal {
    margin: 0 auto;
}

.pwd-page .btn-normal {
    text-transform: uppercase;
}

.login-page h3 {
    margin-bottom: 20px;
    text-transform: uppercase;
    color: #333333;
    font-weight: 700;
    margin-top: -5px;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.login-page .theme-card {
    padding: 30px;
    background-color: #ffffff;
}

.login-page .theme-card .theme-form {
    padding: 30px;
    background-color: #f1f4f7;
}

.login-page .theme-card .theme-form label {
    /*text-transform: capitalize;*/
    color: #333333;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    line-height: 1;
}

.login-page .theme-card .theme-form input {
    padding: 12px 25px;
    border-radius: 0;
}

.login-page .theme-card .btn {
    text-transform: uppercase;
    padding: 14px 35px;
}

.login-page .authentication-right {
    height: calc(100% - 48px);
}

.login-page .authentication-right h6 {
    text-transform: uppercase;
    color: #333333;
    font-weight: 600;
    margin-bottom: 20px;
}

.login-page .authentication-right p {
    font-size: 14px;
    color: #777777;
    line-height: 2;
    margin-bottom: 1rem;
}

.search-block {
    padding: 0;
}

.search-block .form-header .input-group input {
    border-radius: 0 5px 5px 0;
    padding: 15px 20px;
}

.search-block .form-header .input-group i {
    padding-right: 10px;
}

.search-block .form-header .input-group .btn {
    z-index: 0;
    text-transform: uppercase;
}

.contact-page h3 {
    color: #333;
}

.contact-page .map .theme-card {
    padding: 30px;
    background-color: #f1f4f7;
    border: 30px solid #ffffff;
}

.contact-page .map iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.contact-page .contact-right {
    padding-top: 30px;
    padding-bottom: 30px;
}

.contact-page .contact-right ul li {
    position: relative;
    padding-left: 150px;
    margin-top: 45px;
    display: block;
}

.contact-page .contact-right ul li:first-child {
    margin-top: 0;
}

.contact-page .contact-right ul li .contact-icon {
    position: absolute;
    left: 0;
    text-align: center;
    width: 130px;
    border-right: 1px solid #dddddd;
}

.contact-page .contact-right ul li .contact-icon i {
    font-size: 24px;
    color: #042c49;
}

.contact-page .contact-right ul li .contact-icon h6 {
    text-transform: uppercase;
    padding-top: 5px;
    font-weight: 600;
    color: #042c49;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.contact-page .contact-right ul li p {
    margin-bottom: 0;
    line-height: 30px;
    color: #333333;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.contact-page .theme-form {
    padding: 30px;
    background-color: #f1f4f7;
    border: 30px solid #ffffff;
}

.contact-page .theme-form label {
    /*text-transform: capitalize;*/
    color: #333333;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
}

.contact-page .theme-form input {
    padding: 12px 25px;
    border-radius: 0;
    margin-bottom: 0;
}

.contact-page .theme-form textarea {
    padding: 17px 25px;
    margin-bottom: 30px;
    height: inherit;
}

.contact-page .form-control {
    border-radius: 0;
}

.contact-page .btn {
    text-transform: uppercase;
}

.qty-box .input-group {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #dddddd;
}

.qty-box .input-group .form-control {
    text-align: center;
    width: 80px;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    border-color: #dddddd;
    border-top: unset;
    border-bottom: unset;
}

.qty-box .input-group button {
    background-color: transparent;
    cursor: pointer;
    line-height: 1;
    background-color: #ffffff;
    font-family: themify;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
}

.qty-box .input-group button i {
    font-weight: 900;
    color: #444444;
    font-size: 16px;
}

.qty-box .input-group button:first-child:before {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

.qty-box .input-group button:last-child:before {
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}

.qty-box .input-group input::-webkit-outer-spin-button,
.qty-box .input-group input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.qty-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.qty-box .input-group {
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
    width: unset;
}

.qty-box .input-group .input-group-prepend button:focus {
    border-color: #dddddd;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.qty-box .input-group .form-control {
    border-color: #dddddd;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.order-history .responsive-data {
    display: none;
}

.order-history .responsive-data .price {
    margin-bottom: 2px;
}

.order-history .dark-data {
    color: #333333;
    font-weight: 600;
}

.order-history .btn-normal {
    text-transform: uppercase;
    padding: 13px 25px;
}

.cart-section .table tbody tr td .qty-box,
.wishlist-section .table tbody tr td .qty-box {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.cart-section .table tbody+tbody,
.wishlist-section .table tbody+tbody {
    border-top: none;
}

.cart-section .cart-buttons,
.wishlist-section .cart-buttons {
    padding-top: 35px;
    text-transform: uppercase;
}

.cart-section .cart-buttons>div:last-child,
.wishlist-section .cart-buttons>div:last-child {
    text-align: right;
    padding-right: 59px;
}

.cart-section .wishlist-buttons,
.wishlist-section .wishlist-buttons {
    padding-top: 35px;
    text-align: right;
    text-transform: uppercase;
}

.cart-section .wishlist-buttons:last-child a,
.wishlist-section .wishlist-buttons:last-child a {
    margin-left: 15px;
}

.cart-section .cart-table,
.wishlist-section .cart-table {
    overflow: hidden;
    margin-bottom: 0;
}

.cart-section .cart-table thead th,
.wishlist-section .cart-table thead th {
    border-bottom-width: 1px;
    font-weight: 900;
    color: #333333;
    text-transform: uppercase;
    font-size: 14px;
    border-top: 0;
    text-align: center;
    padding: 0 0.75rem 0.75rem 0.75rem;
    letter-spacing: 0.05em;
    line-height: 1;
    border-bottom: 1px solid #dddddd !important;
}

.cart-section tbody h2,
.wishlist-section tbody h2 {
    margin-bottom: 0;
}

.cart-section tbody tr td,
.wishlist-section tbody tr td {
    min-width: 210px;
    vertical-align: middle;
    color: #444444;
    border-top: 0;
    border-bottom: 1px solid #dddddd !important;
    text-align: center;
}

.cart-section tbody tr td a,
.cart-section tbody tr td p,
.wishlist-section tbody tr td a,
.wishlist-section tbody tr td p {
    color: #777777;
    font-weight: 400;
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    /*text-transform: capitalize;*/
    margin-bottom: 0;
}

.cart-section tbody tr td a img,
.cart-section tbody tr td p img,
.wishlist-section tbody tr td a img,
.wishlist-section tbody tr td p img {
    height: 90px;
}

.cart-section tbody tr td .td-color,
.wishlist-section tbody tr td .td-color {
    color: #042c49;
}

.cart-section tbody tr td h2,
.wishlist-section tbody tr td h2 {
    font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: #444444;
    font-weight: 400;
}

.cart-section tbody tr td .mobile-cart-content,
.wishlist-section tbody tr td .mobile-cart-content {
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 10px;
}

.cart-section tbody tr td .mobile-cart-content .qty-box,
.wishlist-section tbody tr td .mobile-cart-content .qty-box {
    border-radius: 5px;
}

.cart-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control,
.wishlist-section tbody tr td .mobile-cart-content .qty-box .input-group .form-control {
    width: 48px;
    padding: 6px;
}

.cart-section tbody tr td .mobile-cart-content .col-xs-3,
.wishlist-section tbody tr td .mobile-cart-content .col-xs-3 {
    -ms-flex-item-align: center;
    align-self: center;
    margin-left: 10px;
    margin-right: 10px;
}

.cart-section tfoot tr th,
.wishlist-section tfoot tr th {
    padding-top: 35px;
    text-align: right;
}

.cart-section tfoot tr td,
.wishlist-section tfoot tr td {
    padding-top: 35px;
    padding-bottom: 0;
    text-align: right;
    border: none;
    padding-right: 63px;
}

.cart-section tfoot tr td h2,
.wishlist-section tfoot tr td h2 {
    font-size: 24px;
    margin-bottom: 0;
}

.cart-section tfoot tr td:first-child,
.wishlist-section tfoot tr td:first-child {
    width: 85%;
    font-size: 15px;
    padding-right: 10px;
    /*text-transform: capitalize;*/
    font-weight: 700;
}

.blog-block>div {
    padding-left: 0;
    padding-right: 0;
}

.blog-block .blog-contain {
    margin-right: 15px;
    margin-left: 15px;
}

.blog-detail-page .blog-detail p:last-child {
    margin-bottom: 0;
}

.blog-detail-page .blog-detail img {
    margin-bottom: 30px;
}

.blog-detail-page .blog-detail h3 {
    color: #333333;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
    // letter-spacing: 0.03em;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.blog-detail-page .blog-detail p {
    color: #777777;
    line-height: 1.8;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.blog-detail-page .blog-detail .post-social {
    color: #444444;
    text-align: left;
    padding-bottom: 15px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 15px;
}

.blog-detail-page .blog-detail .post-social li {
    letter-spacing: 0.05em;
}

.blog-detail-page .blog-detail .post-social li+li {
    padding-left: 15px;
    margin-left: 15px;
    border-left: 1px solid #dddddd;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.blog-detail-page .blog-advance>div:last-child p:last-child {
    margin-bottom: 0;
}

.blog-detail-page .blog-advance img {
    margin-bottom: 15px;
}

.blog-detail-page .blog-advance ul {
    list-style-type: decimal;
    list-style-position: inside;
}

.blog-detail-page .blog-advance ul li {
    display: list-item;
    font-weight: 700;
    color: #333333;
    letter-spacing: 0.05em;
    font-size: 15px;
    margin-bottom: 11px;
}

.blog-detail-page .blog-advance ul li:last-child {
    margin-bottom: 0;
}

.blog-detail-page .blog-advance p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.5;
}

.blog-detail-page .blog-contact h2 {
    margin-bottom: 30px;
    margin-top: -5px;
    color: #333333;
}

.blog-detail-page .blog-contact .theme-form label {
    /*text-transform: capitalize;*/
    color: #333333;
    font-size: 16px;
    font-weight: 700;
}

.blog-detail-page .blog-contact .theme-form input,
.blog-detail-page .blog-contact .theme-form textarea {
    border-color: #dddddd;
    font-size: 14px;
    padding: 17px 25px;
    margin-bottom: 30px;
    letter-spacing: 0.05em;
}

.blog-detail-page .blog-contact .theme-form .btn {
    text-transform: uppercase;
}

.blog-detail-page .comment-section li {
    padding-bottom: 35px;
}

.blog-detail-page .comment-section li+li {
    padding-top: 35px;
    border-top: 1px solid #dddddd;
}

.blog-detail-page .comment-section li:last-child {
    padding-bottom: 0;
}

.blog-detail-page .comment-section li img {
    height: 65px;
    width: 65px;
    margin-right: 20px;
    border-radius: 100%;
}

.blog-detail-page .comment-section li h6 {
    color: #333333;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 5px;
}

.blog-detail-page .comment-section li h6 span {
    font-size: 13px;
    font-weight: 500;
    /*text-transform: capitalize;*/
    color: #444444;
    margin-left: 20px;
}

.blog-detail-page .comment-section li p {
    line-height: 1.8;
    margin-bottom: 0;
}

.creative-card {
    padding: 30px;
    background-color: #ffffff;
}

.creative-card.creative-inner {
    border-width: 15px;
}

.review-box {
    -webkit-box-shadow: 0 11px 35px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 11px 35px 2px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    border-radius: 5px;
    background-color: #ffffff;
}

.review-box .rating-star li i {
    color: #ffa800;
}

.review-box img {
    height: 60px;
    border-radius: 50%;
}

.review-box h5 {
    color: #042c49;
    font-weight: 600;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 15px;
}

.review-box p {
    line-height: 1.4;
    color: #444444;
    margin-top: 10px;
    margin-bottom: 10px;
    letter-spacing: 0.05rem;
    opacity: 0.4;
}

.review-box h6 {
    /*text-transform: capitalize;*/
    margin-top: 5px;
}

.review-block>div:nth-child(n+4) {
    margin-top: 20px;
}

.search-product>div:nth-child(n + 5) {
    margin-top: 30px;
}

.blog-page .order-sec {
    padding-left: 30px;
}

.blog-page .blog-sidebar h6 {
    margin-bottom: 0;
    line-height: 20px;
    color: #333333;
    font-weight: 700;
    letter-spacing: 0.05em;
}

.blog-page .blog-sidebar p {
    line-height: 20px;
    margin-bottom: 0;
    color: #777777;
}

.blog-page .blog-sidebar h4 {
    color: #333333;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 20px;
    letter-spacing: 1px;
}

.blog-page .blog-sidebar .theme-card {
    padding: 30px;
    background-color: #ffffff;
}

.blog-page .blog-sidebar .theme-card .recent-blog li .media img {
    width: 100px;
    margin-right: 15px;
}

.blog-page .blog-sidebar .theme-card .recent-blog li+li {
    margin-top: 30px;
}

.blog-page .blog-sidebar .theme-card .popular-blog li .blog-date {
    background-color: #042c49;
    height: 70px;
    width: 70px;
    margin: 0 15px 0 0;
    padding: 15px;
}

.blog-page .blog-sidebar .theme-card .popular-blog li .blog-date span {
    display: block;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
}

.blog-page .blog-sidebar .theme-card .popular-blog li+li {
    margin-top: 30px;
}

.blog-page .blog-sidebar .theme-card .popular-blog li p {
    margin-top: 10px;
}

.blog-page .blog-sidebar .theme-card+.theme-card {
    margin-top: 30px;
}

.blog-page .blog-media {
    margin-bottom: 30px;
    background-color: #ffffff;
    padding: 30px 15px;
}

.blog-page .blog-media:last-child {
    margin-bottom: 0;
}

.blog-page .blog-media .blog-left {
    overflow: hidden;
    position: relative;
}

.blog-page .blog-media .blog-left img {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.blog-page .blog-media .blog-left .date-label {
    background-color: #042c49;
    color: #ffffff;
    font-weight: 700;
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 10px 15px;
    line-height: 1;
}

.blog-page .blog-media .blog-right {
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.blog-page .blog-media .blog-right h6 {
    color: #042c49;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 700;
    letter-spacing: 0.05em;
}

.blog-page .blog-media .blog-right h4 {
    color: #333333;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 15px;
    /*text-transform: capitalize;*/
    letter-spacing: 0.05em;
    font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.blog-page .blog-media .blog-right p {
    line-height: 1.8;
    margin-bottom: 0;
    letter-spacing: 0.05em;
    color: #777777;
}

.blog-page .blog-media .blog-right ul {
    color: #444444;
    margin-bottom: 15px;
}

.blog-page .blog-media .blog-right ul li {
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 0.05em;
}

.blog-page .blog-media .blog-right ul li i {
    padding-right: 3px;
}

.blog-page .blog-media .blog-right ul li+li {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #dddddd;
}

.blog-page .blog-media:hover .blog-left img {
    -webkit-transform: translateX(20px) scale(1.1);
    transform: translateX(20px) scale(1.1);
}

.blog-page .blog-media.media-change {
    text-align: right;
}

.blog-page .blog-media.media-change>div:first-child {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}

.product-slick img,
.rtl-product-slick img,
.product-right-slick img,
.rtl-product-right-slick img {
    width: 100%;
}

.product-slick .slick-prev,
.product-slick .slick-next,
.rtl-product-slick .slick-prev,
.rtl-product-slick .slick-next,
.product-right-slick .slick-prev,
.product-right-slick .slick-next,
.rtl-product-right-slick .slick-prev,
.rtl-product-right-slick .slick-next {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    margin-top: -10px;
}

.product-slick .slick-prev:before,
.product-slick .slick-next:before,
.rtl-product-slick .slick-prev:before,
.rtl-product-slick .slick-next:before,
.product-right-slick .slick-prev:before,
.product-right-slick .slick-next:before,
.rtl-product-right-slick .slick-prev:before,
.rtl-product-right-slick .slick-next:before {
    font: normal normal normal 14px/1 FontAwesome;
    opacity: 1;
    color: black;
    background-color: #ffffff;
    border-radius: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-slick .slick-next,
.rtl-product-slick .slick-next,
.product-right-slick .slick-next,
.rtl-product-right-slick .slick-next {
    right: 1px;
}

.product-slick .slick-next:before,
.rtl-product-slick .slick-next:before,
.product-right-slick .slick-next:before,
.rtl-product-right-slick .slick-next:before {
    content: "\f105";
}

.product-slick .slick-prev,
.rtl-product-slick .slick-prev,
.product-right-slick .slick-prev,
.rtl-product-right-slick .slick-prev {
    left: 1px;
    z-index: 1;
}

.product-slick .slick-prev:before,
.rtl-product-slick .slick-prev:before,
.product-right-slick .slick-prev:before,
.rtl-product-right-slick .slick-prev:before {
    content: "\f104";
}

.product-slick .slick-slide>div,
.rtl-product-slick .slick-slide>div,
.product-right-slick .slick-slide>div,
.rtl-product-right-slick .slick-slide>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
}

.product-slick:hover .slick-prev,
.product-slick:hover .slick-next,
.rtl-product-slick:hover .slick-prev,
.rtl-product-slick:hover .slick-next,
.product-right-slick:hover .slick-prev,
.product-right-slick:hover .slick-next,
.rtl-product-right-slick:hover .slick-prev,
.rtl-product-right-slick:hover .slick-next {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product-slick:hover .slick-prev:before,
.product-slick:hover .slick-next:before,
.rtl-product-slick:hover .slick-prev:before,
.rtl-product-slick:hover .slick-next:before,
.product-right-slick:hover .slick-prev:before,
.product-right-slick:hover .slick-next:before,
.rtl-product-right-slick:hover .slick-prev:before,
.rtl-product-right-slick:hover .slick-next:before {
    opacity: 1;
}

.product-slick:hover .slick-next,
.rtl-product-slick:hover .slick-next,
.product-right-slick:hover .slick-next,
.rtl-product-right-slick:hover .slick-next {
    right: 20px;
}

.product-slick:hover .slick-prev,
.rtl-product-slick:hover .slick-prev,
.product-right-slick:hover .slick-prev,
.rtl-product-right-slick:hover .slick-prev {
    left: 20px;
}

.slider-nav .slick-slide>div,
.rtl-slider-nav .slick-slide>div,
.slider-right-nav .slick-slide>div,
.rtl-slider-right-nav .slick-slide>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 15px 15px 0 15px;
    border: 1px solid #f1f5f8;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0.5;
    overflow: hidden;
}

.slider-nav .slick-slide>div:hover,
.rtl-slider-nav .slick-slide>div:hover,
.slider-right-nav .slick-slide>div:hover,
.rtl-slider-right-nav .slick-slide>div:hover {
    border-color: #042c49;
    opacity: 1;
}

.slider-nav .slick-slide.slick-current>div,
.rtl-slider-nav .slick-slide.slick-current>div,
.slider-right-nav .slick-slide.slick-current>div,
.rtl-slider-right-nav .slick-slide.slick-current>div {
    border-color: #042c49;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 1;
}

.slider-nav .slick-slide.slick-active,
.rtl-slider-nav .slick-slide.slick-active,
.slider-right-nav .slick-slide.slick-active,
.rtl-slider-right-nav .slick-slide.slick-active {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.slider-right-nav .slick-slide:first-child>div,
.rtl-slider-right-nav .slick-slide:first-child>div {
    margin-top: 0;
}

.pro_sticky_info {
    padding: 20px;
    border: 1px solid #dddddd;
}

.is_stuck {
    margin-top: 120px;
}

.image-scroll div>div {
    padding-bottom: 20px;
}

.image-scroll div>div:last-child {
    padding: 0;
}

.size-box ul li {
    width: calc(40px + (45 - 40) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #dddddd;
    text-align: center;
}

.size-box ul li:nth-child(n+2) {
    margin-left: 5px;
}

.size-box ul li a {
    color: #444444;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
}

.size-box ul li.active {
    background-color: #dddddd;
}

.addeffect-section .error-message {
    display: none;
    color: red;
}

.addeffect-section.cartMove .error-message {
    display: block;
}

.addeffect-section.cartMove .size-box {
    -webkit-animation: shakeX 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: shakeX 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.added-notification {
    position: fixed;
    top: -60px;
    right: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #444444;
    border-radius: 3px;
    padding: 6px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 2px 1px #dddddd;
    box-shadow: 0 0 2px 1px #dddddd;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.added-notification img {
    width: 25px;
}

.added-notification h3 {
    color: #ffffff;
    /*text-transform: capitalize;*/
    font-size: 16px;
    margin-bottom: 0;
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 600;
}

.added-notification.show {
    z-index: 2;
    top: 30px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.color-selector ul {
    line-height: 1;
}

.color-selector ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.color-selector ul li>div {
    margin-right: 5px;
}

.color-selector ul li:nth-child(n+2) {
    margin-top: 5px;
}

.product-offer .offer-contain ul li {
    color: #444444;
    font-size: 14px;
    /*text-transform: capitalize;*/
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 15px;
}

.product-offer .offer-contain ul li .code-lable {
    color: #042c49;
    background-color: rgba(199, 109, 98, 0.08);
    border: 1px dashed #042c49;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 3px 10px;
    text-transform: uppercase;
    border-radius: 5px;
    margin-right: 10px;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
    -webkit-animation: headShake 2s ease-in-out infinite;
    animation: headShake 2s ease-in-out infinite;
}

.product-offer .offer-contain ul li h5 {
    font-weight: 600;
    font-size: 14px;
}

.product-offer .offer-contain ul li p {
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
    padding-top: 5px;
}

.product-offer .offer-contain .offer-sider {
    display: none;
}

.product-offer .offer-contain .show-offer {
    color: #042c49;
    /*text-transform: capitalize;*/
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
}

.product-offer .offer-contain .show-offer .less-offer {
    display: none;
}

.product-title {
    color: #333333;
    /*text-transform: capitalize;*/
    font-weight: 700;
    margin-bottom: 10px;
    line-height: 1;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.product-title i {
    color: #042c49;
    margin-right: 5px;
}

.product-title span {
    width: 100%;
    text-align: center;
    margin-bottom: 5px;
}

.product-title span a {
    color: #042c49;
    letter-spacing: 0.05em;
}

.product-right .pro-group {
    padding-bottom: 20px;
}

.product-right .pro-group:nth-child(n+2) {
    padding-top: 20px;
    border-top: 1px solid #dddddd;
}

.product-right .pro-group h2 {
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    line-height: 1.2em;
    color: #333333;
    letter-spacing: 1px;
    margin-bottom: 5px;
    /*text-transform: capitalize;*/
}

.product-right .pro-group ul.pro-price {
    font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #444444;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
}

.product-right .pro-group ul.pro-price span {
    text-decoration: line-through;
    color: #777777;
    font-size: 80%;
    font-weight: 500;
}

.product-right .pro-group ul.pro-price li {
    text-transform: uppercase;
}

.product-right .pro-group ul.pro-price li span {
    color: #8a8a8a;
    font-size: 80%;
}

.product-right .pro-group ul.pro-price li:nth-child(n+2) {
    margin-left: 5px;
}

.product-right .pro-group ul.pro-price li:last-child {
    color: #042c49;
    font-size: 80%;
}

.product-right .pro-group ul.best-seller {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-right .pro-group ul.best-seller li {
    font-size: 14px;
    background-color: #f1f1f1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    line-height: 1;
    /*text-transform: capitalize;*/
}

.product-right .pro-group ul.best-seller li:nth-child(n+2) {
    margin-left: 10px;
}

.product-right .pro-group ul.best-seller li svg {
    width: 24px;
    margin-right: 5px;
}

.product-right .pro-group ul.delivery-services {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-right .pro-group ul.delivery-services li {
    padding: 15px 25px;
    display: block;
    font-size: 14px;
    color: #444444;
    font-weight: 700;
    /*text-transform: capitalize;*/
    border: 1px solid #dddddd;
    text-align: center;
    line-height: 1;
}

.product-right .pro-group ul.delivery-services li:nth-child(n+2) {
    margin-left: 10px;
}

.product-right .pro-group ul.delivery-services li svg {
    width: calc(30px + (35 - 30) * ((100vw - 320px) / (1920 - 320)));
    fill: #042c49;
    margin-right: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
}

.product-right .pro-group ul.image-swatch li {
    width: 33px;
    height: 33px;
    overflow: hidden;
}

.product-right .pro-group ul.image-swatch li img {
    width: 33px;
    height: 33px;
    padding: 2px;
    border: 1px solid #dddddd;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product-right .pro-group ul.image-swatch li:nth-child(n+2) {
    margin-left: 5px;
}

.product-right .pro-group ul.image-swatch li.active img {
    border: 1px solid #042c49;
}

.product-right .pro-group .revieu-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 5px;
}

.product-right .pro-group .revieu-box ul li i {
    color: #ffa800;
}

.product-right .pro-group .revieu-box a {
    color: #042c49;
}

.product-right .pro-group .revieu-box span {
    display: inline-block;
    margin-left: 10px;
    /*text-transform: capitalize;*/
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.product-right .pro-group .size-box {
    margin-bottom: 15px;
}

.product-right .pro-group .qty-box {
    margin-bottom: 15px;
}

.product-right .pro-group .color-selector {
    margin-bottom: 15px;
}

.product-right .pro-group .product-buttons {
    text-transform: uppercase;
}

.product-right .pro-group .product-buttons a {
    padding: 13px 18px;
}

.product-right .pro-group .product-buttons a.cart-btn i {
    -webkit-animation: ring 4s .7s ease-in-out infinite;
    animation: ring 4s .7s ease-in-out infinite;
}

.product-right .pro-group .product-buttons a:last-child {
    margin-left: 10px;
}

.product-right .pro-group .delivery-detail .delivery-detail-contian {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 15px;
}

.product-right .pro-group .delivery-detail .delivery-detail-contian .input-group {
    border-radius: 5px;
    width: 270px;
    height: 48px;
}

.product-right .pro-group .delivery-detail .delivery-detail-contian .input-group .input-group-text {
    background-color: transparent;
}

.product-right .pro-group .delivery-detail .delivery-detail-contian .input-group .form-control {
    border-left: none;
    padding-left: 0;
}

.product-right .pro-group .delivery-detail .delivery-detail-contian .input-group .form-control:focus {
    border-color: #dddddd;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.product-right .pro-group .delivery-detail .delivery-detail-contian .btn {
    /*text-transform: capitalize;*/
    margin-left: 10px;
}

.product-right .pro-group .delivery-detail .delivery-lable {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f1f1f1;
    padding: 10px 12px;
    width: 380px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.product-right .pro-group .delivery-detail .delivery-lable svg {
    width: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    margin-right: 10px;
}

.product-right .pro-group .product-social li:nth-child(n+2) {
    margin-left: 5px;
}

.product-right .pro-group .product-social li a {
    width: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #dddddd;
    color: #999999;
    border-radius: 100%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.product-right .pro-group .product-social li a i {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.product-right .pro-group .product-social li:hover a {
    background-color: #042c49;
    border-color: #042c49;
    color: #ffffff;
}

.product-right .pro-group .timer {
    margin-top: 10px;
}

.product-right .pro-group .timer p {
    color: #444444;
}

.product-right .pro-group .timer p>span {
    border-radius: 50%;
    width: 70px;
    height: 70px;
    border: 1px solid #042c49;
}

.product-right .pro-group .timer p span {
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    line-height: 1;
    font-size: 20px;
    margin-right: unset;
}

.product-right .pro-group .timer p span:nth-child(n+2) {
    margin-left: 15px;
}

.product-right .pro-group .timer p span .timer-cal {
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
}

.product-right .payment-card-bottom {
    margin-top: 10px;
}

.product-right .payment-card-bottom ul li {
    padding-right: 10px;
}

.product-right.product-form-box {
    text-align: center;
    padding: 20px;
    background-color: #ffffff;
}

.product-right.product-form-box .pro-group .product-title.size-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.product-right.product-form-box .pro-group .qty-box {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.bottom-cart-sticky {
    position: fixed;
    bottom: -100px;
    left: 0;
    width: 100%;
    z-index: 9;
    background-color: #ffffff;
    padding: 12px 0;
    -webkit-box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.16);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.bottom-cart-sticky.open-cart {
    bottom: 0;
}

.bottom-cart-sticky .cart-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.bottom-cart-sticky .product-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 15px;
}

.bottom-cart-sticky .product-image img {
    width: 60px;
    height: 70px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: top;
    object-position: top;
}

.bottom-cart-sticky .product-image .content {
    margin-left: 12px;
}

.bottom-cart-sticky .product-image .content h5 {
    font-size: 18px;
    font-weight: 600;
    color: #444444;
    /*text-transform: capitalize;*/
}

.bottom-cart-sticky .product-image .content h6 {
    font-size: 15px;
    color: #444444;
    font-weight: 700;
}

.bottom-cart-sticky .product-image .content h6 del {
    margin-left: 7px;
    color: #999999;
    font-weight: 400;
}

.bottom-cart-sticky .product-image .content h6 span {
    color: #042c49;
    margin-left: 5px;
}

.bottom-cart-sticky .selection-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
}

.bottom-cart-sticky .selection-section .form-control {
    background-color: #f1f1f1;
    border: 1px solid #f1f1f1;
    text-transform: uppercase;
}

.bottom-cart-sticky .selection-section .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.bottom-cart-sticky .selection-section .form-group:nth-child(2) {
    margin-left: 40px;
}

.bottom-cart-sticky .btn {
    margin-left: 15px;
}

.product-side-tab {
    margin-top: 50px !important;
}

.single-product-tables {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20px;
}

.single-product-tables table {
    width: 20%;
}

.single-product-tables table tr {
    height: 35px;
}

.single-product-tables table tr td {
    color: #333333;
    letter-spacing: 0.05em;
}

.single-product-tables table tr td:first-child {
    font-weight: 600;
}

.single-product-tables.detail-section {
    margin-top: 0;
}

.single-product-tables.detail-section table {
    width: 55%;
}

.product-accordion .btn-link {
    color: #333333;
    /*text-transform: capitalize;*/
    font-weight: 700;
    letter-spacing: 0.05em;
    text-decoration: none;
}

.product-accordion .btn-link:hover {
    text-decoration: none;
}

.product-accordion .card {
    border: none;
}

.product-accordion .card-body {
    width: 100%;
    height: auto;
}

.product-accordion .card-header {
    padding: 5px 8px;
    border-bottom: 1px solide #dddddd;
}

.product-related h2 {
    color: #444444;
    padding-bottom: 20px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    margin-top: -4px;
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.rating {
    margin-top: 0;
}

.rating i:nth-child(-n + 4) {
    color: #ffa800;
}

.rating i:last-child {
    color: #dddddd;
}

.rating .three-star {
    padding-bottom: 5px;
}

.rating .three-star i {
    color: #acacac;
}

.rating .three-star i:nth-child(-n + 3) {
    color: #ffd200;
}

.tab-border {
    border: 1px solid #dddddd;
    margin: 0;
    padding: 8px 0;
}

.tab-border .nav-border {
    border-bottom: 0;
    border-right: 1px solid #dddddd;
}

.vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
    padding: 15px 20px;
}

.tab-product,
.product-full-tab {
    margin-top: 30px;
}

.tab-product.tab-exes,
.product-full-tab.tab-exes {
    margin-top: 50px;
}

.tab-product .nav-material.nav-tabs,
.product-full-tab .nav-material.nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.tab-product .nav-material.nav-tabs .nav-item .nav-link,
.product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
    color: #333333;
    text-align: center;
    padding: 0 15px 20px 15px;
    text-transform: uppercase;
    border: 0;
    background-color: transparent;
    font-weight: 700;
    letter-spacing: 0.05em;
}

.tab-product .nav-material.nav-tabs .nav-item .material-border,
.product-full-tab .nav-material.nav-tabs .nav-item .material-border {
    border-bottom: 2px solid #042c49;
    opacity: 0;
}

.tab-product .nav-material.nav-tabs .nav-link.active,
.product-full-tab .nav-material.nav-tabs .nav-link.active {
    color: #042c49;
}

.tab-product .nav-material.nav-tabs .nav-link.active~.material-border,
.product-full-tab .nav-material.nav-tabs .nav-link.active~.material-border {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 1;
}

.tab-product .theme-form input,
.product-full-tab .theme-form input {
    border-color: #dddddd;
    font-size: 15px;
    padding: 15px 25px;
    margin-bottom: 15px;
    height: inherit;
    text-align: left;
}

.tab-product .theme-form input:focus,
.product-full-tab .theme-form input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tab-product .theme-form .btn-solid,
.tab-product .theme-form .btn-outline,
.product-full-tab .theme-form .btn-solid,
.product-full-tab .theme-form .btn-outline {
    margin: 0 auto;
}

.tab-product .theme-form textarea,
.product-full-tab .theme-form textarea {
    border-color: #dddddd;
    font-size: 15px;
    padding: 17px 25px;
    margin-bottom: 15px;
    height: inherit;
}

.tab-product .theme-form textarea:focus,
.product-full-tab .theme-form textarea:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tab-product .tab-content.nav-material p,
.product-full-tab .tab-content.nav-material p {
    padding: 20px 20px 0 20px;
    margin-bottom: -8px;
    line-height: 2;
    letter-spacing: 0.05em;
}

.tab-product .tab-content.nav-material .media,
.product-full-tab .tab-content.nav-material .media {
    margin-top: 20px;
}

.tab-product .title,
.product-full-tab .title {
    padding-right: 45px;
    color: #042c49;
    padding-bottom: 20px;
}

.tab-product .theme-slider .slick-arrow,
.product-full-tab .theme-slider .slick-arrow {
    top: -45px;
    height: auto;
}

.tab-product .theme-slider .slick-arrow :before,
.product-full-tab .theme-slider .slick-arrow :before {
    color: #000000;
    font-size: 18px;
}

.tab-product .product-box,
.product-full-tab .product-box {
    position: relative;
    margin: 5px;
}

.tab-product .product-box:hover,
.product-full-tab .product-box:hover {
    -webkit-box-shadow: 0 0 12px 0 #dddddd;
    box-shadow: 0 0 12px 0 #dddddd;
}

.tab-product .product-box:hover .lbl-1,
.product-full-tab .product-box:hover .lbl-1 {
    opacity: 1;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.tab-product .product-box:hover .lbl-2,
.product-full-tab .product-box:hover .lbl-2 {
    opacity: 1;
    -webkit-animation: flipInY 1000ms ease-in-out;
    animation: flipInY 1000ms ease-in-out;
}

.tab-product .product-box:hover .color-variant li,
.product-full-tab .product-box:hover .color-variant li {
    opacity: 1 !important;
    -webkit-animation: fadeInUp 500ms ease-in-out;
    animation: fadeInUp 500ms ease-in-out;
}

.tab-product .product-box .img-block,
.product-full-tab .product-box .img-block {
    min-height: unset;
}

.tab-product .product-box .cart-info,
.product-full-tab .product-box .cart-info {
    position: absolute;
    padding: 10px 0;
    top: 25%;
    right: 15px;
    width: 40px;
    margin-right: 0;
}

.tab-product .product-box .cart-info i,
.product-full-tab .product-box .cart-info i {
    padding-right: 0;
}

.tab-product .product-box .cart-info a,
.tab-product .product-box .cart-info button,
.product-full-tab .product-box .cart-info a,
.product-full-tab .product-box .cart-info button {
    color: #333333;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    background-color: #ffffff;
    height: 35px;
    width: 35px;
    margin: 7px 0;
    border-radius: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 0;
    -webkit-box-shadow: 0 0 12px 0 #dddddd;
    box-shadow: 0 0 12px 0 #dddddd;
}

.tab-product .product-box .cart-info a :hover,
.tab-product .product-box .cart-info button :hover,
.product-full-tab .product-box .cart-info a :hover,
.product-full-tab .product-box .cart-info button :hover {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #042c49;
}

.tab-product .product-box .lbl-1,
.product-full-tab .product-box .lbl-1 {
    background-color: #042c49;
    padding: 2px 20px 2px 10px;
    display: inline-block;
    text-align: center;
    color: #ffffff;
    position: absolute;
    left: 0;
    top: 15px;
    font-size: 14px;
    line-height: 1.5;
    opacity: 0;
}

.tab-product .product-box .lbl-1:before,
.product-full-tab .product-box .lbl-1:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    border-top: 12px solid #042c49;
    border-bottom: 13px solid #042c49;
    border-right: 7px solid #ffffff;
}

.tab-product .product-box .lbl-2,
.product-full-tab .product-box .lbl-2 {
    font-size: 14px;
    top: 15px;
    position: absolute;
    right: 10px;
    color: #333333;
    font-weight: 600;
    /*text-transform: capitalize;*/
    opacity: 0;
}

.tab-product .product-box a,
.product-full-tab .product-box a {
    color: #0072bb;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
}

.tab-product .product-box .color-variant,
.product-full-tab .product-box .color-variant {
    position: absolute;
    top: -35px;
    width: 100%;
}

.tab-product .product-box .slick-slide img,
.product-full-tab .product-box .slick-slide img {
    display: block;
}

.tab-product .product-box .product-details,
.product-full-tab .product-box .product-details {
    position: relative;
}

.tab-product .product-box .product-details .color-variant,
.product-full-tab .product-box .product-details .color-variant {
    position: absolute;
    top: -35px;
    width: 100%;
}

.tab-product .product-box .product-details .color-variant li,
.product-full-tab .product-box .product-details .color-variant li {
    opacity: 0;
    display: inline-block;
    height: 15px;
    width: 15px;
    border-radius: 100%;
    margin: 0 3px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    cursor: pointer;
}

.tab-product .product-box .product-details h6,
.product-full-tab .product-box .product-details h6 {
    color: #333333;
    font-weight: 600;
    letter-spacing: 1px;
    /*text-transform: capitalize;*/
}

.tab-product .product-box .product-details .price,
.product-full-tab .product-box .product-details .price {
    padding-bottom: 10px;
    font-size: 16px;
    color: #042c49;
}

.product-full-tab {
    padding-top: 70px;
}

.product-load-more .col-grid-box {
    display: none;
}

.load-more-sec {
    margin-top: 40px;
    text-align: center;
}

.load-more-sec a {
    font-size: 18px;
    text-transform: uppercase;
    display: block;
    padding: 10px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    width: 100%;
    color: #042c49;
}

.product_image_4>div:nth-last-child(-n + 2) {
    margin-top: 25px;
}

.image-360 {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 35px;
    z-index: 8;
    width: calc(22px + (30 - 22) * ((100vw - 320px) / (1920 - 320)));
}

.product-image-360 {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;
}

.product-image-360 .nav_bar {
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -67.5px;
    z-index: 11;
    background-color: #ffffff;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

.product-image-360 .nav_bar a {
    display: inline-block;
    width: 45px;
    height: 45px;
    line-height: 48px;
    text-align: center;
    text-decoration: none;
    color: #444444;
}

.product-image-360 .product-images-item {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
}

.product-image-360 .product-images-item img.previous-image {
    visibility: hidden;
}

.product-image-360 .product-images-item img.current-image {
    visibility: visible;
}

.product-image-360 .product-images-item img {
    position: absolute;
    top: 50%;
    vertical-align: middle;
    height: auto;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 100%;
}

.product-image-360 .spinner {
    background: #ffffff;
    position: relative;
    z-index: 999;
    width: 800px;
    height: 90px;
    margin-top: 0 !important;
    display: inline-block;
}

.product-image-360 .spinner span {
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    display: block;
    margin: 0 auto;
    color: #000000;
    font-weight: bolder;
    background: rgba(255, 255, 255, 0.7);
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    margin-top: 20px;
}

.custom_stop {
    display: none !important;
}

.play-video .custom_play {
    display: none !important;
}

.play-video .custom_stop {
    display: inline-block !important;
}

.video-product video {
    width: 100%;
    height: 75vh;
}

.video-icon {
    position: relative;
}

.video-icon img {
    opacity: 0.8;
}

.video-icon i {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 24px;
}

.quick-view {
    width: 100%;
    max-width: 1080px;
    max-height: 600px;
    position: relative;
}

.checkout-page .checkout-title {
    margin-bottom: 25px;
}

.checkout-page .checkout-title h3 {
    color: #333333;
    font-weight: 700;
    font-size: 24px;
}

.checkout-page .checkout-form .check-out .form-group:last-child {
    margin-bottom: -5px;
}

.checkout-page .checkout-form .check-out .form-group:last-child label {
    margin-bottom: -5px;
}

.checkout-page .checkout-form .form-group {
    position: relative;
    margin-bottom: 25px;
}

.checkout-page .checkout-form .form-group h3 {
    color: #444444;
    font-weight: 700;
    margin-bottom: 30px;
    margin-top: 30px;
}

.checkout-page .checkout-form .form-group .field-label {
    line-height: 24px;
    /*text-transform: capitalize;*/
    color: #333333;
    margin-bottom: 10px;
    font-weight: 700;
}

.checkout-page .checkout-form .form-group .field-label span {
    font-size: 16px;
    color: #444444;
    font-weight: 600;
}

.checkout-page .checkout-form .form-group label {
    color: #333333;
}

.checkout-page .checkout-form select {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("/../assets/images/dropdown.png") no-repeat 95%;
}

.checkout-page .checkout-form input[type="text"],
.checkout-page .checkout-form input[type="email"],
.checkout-page .checkout-form input[type="password"],
.checkout-page .checkout-form input[type="tel"],
.checkout-page .checkout-form input[type="number"],
.checkout-page .checkout-form input[type="url"] {
    width: 100%;
    padding: 0 22px;
    height: 45px;
    border: 1px solid #dddddd;
}

.checkout-page .checkout-form select,
.checkout-page .checkout-form textarea {
    width: 100%;
    padding: 0 22px;
    height: 45px;
    background: #ffffff;
    border: 1px solid #dddddd;
}

.checkout-page .check-box {
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;
}

.checkout-page .check-box label {
    position: relative;
    top: -1px;
    font-weight: normal;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    color: #333333;
}

.checkout-page .lower-content {
    margin-top: 30px;
}

.checkout-page .lower-content .order-column {
    margin-bottom: 40px;
}

.order-box {
    position: relative;
    margin-bottom: 50px;
}

.order-box .title-box {
    position: relative;
    padding-bottom: 25px;
    color: #444444;
    font-weight: 600;
    font-size: 22px;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px;
}

.order-box .title-box span {
    position: relative;
    width: 35%;
    float: right;
    line-height: 1.2em;
}

.order-box .qty {
    position: relative;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;
}

.order-box .qty li {
    position: relative;
    display: block;
    font-size: 15px;
    color: #444444;
    line-height: 20px;
    margin-bottom: 20px;
}

.order-box .qty li span {
    float: right;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    font-weight: 400;
    width: 35%;
}

.order-box .sub-total {
    position: relative;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 30px;
}

.order-box .sub-total li {
    position: relative;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
    margin-bottom: 20px;
    width: 100%;
}

.order-box .sub-total li .count {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: #042c49;
    font-weight: 400;
    width: 35%;
    float: right;
}

.order-box .sub-total .shopping-option label {
    position: relative;
    font-size: 16px;
    line-height: 32px;
    padding-left: 10px;
    color: #444444;
}

.order-box .sub-total .shipping {
    width: 35%;
    float: right;
}

.order-box .total {
    position: relative;
    margin-bottom: 40px;
}

.order-box .total li {
    position: relative;
    display: block;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    margin-bottom: 10px;
    font-size: 18px;
}

.order-box .total li .count {
    position: relative;
    font-size: 18px;
    line-height: 20px;
    color: #042c49;
    font-weight: 400;
}

.order-box .total li span {
    float: right;
    font-size: 15px;
    line-height: 20px;
    color: #444444;
    font-weight: 400;
    width: 35%;
    display: block;
}

.payment-box {
    position: relative;
}

.payment-box .upper-box {
    position: relative;
}

.payment-box .btn-normal {
    text-transform: uppercase;
}

.payment-box .payment-options {
    position: relative;
    margin-top: 20px;
    margin-bottom: 30px;
}

.payment-box .payment-options li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
}

.payment-box .payment-options li .radio-option {
    position: relative;
}

.payment-box .payment-options li .radio-option label {
    position: relative;
    padding-left: 30px;
    /*text-transform: capitalize;*/
    color: #444444;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
}

.payment-box .payment-options li .radio-option input[type="radio"] {
    position: absolute;
    left: 0;
    top: 5px;
}

.payment-box .payment-options li .radio-option label .small-text {
    position: relative;
    display: none;
    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
    color: #666666;
    margin-top: 10px;
}

.payment-box .payment-options li .radio-option label img {
    position: relative;
    display: block;
    max-width: 100%;
    margin-left: -30px;
    margin-top: 5px;
}

.checkout-second .checkout-box {
    width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ffffff;
}

.checkout-second .checkout-box .checkout-header {
    text-align: center;
    padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-header h2 {
    text-transform: uppercase;
    color: #444444;
    font-weight: 700;
    margin-bottom: 5px;
    font-size: calc(16px + (28 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-header h4 {
    /*text-transform: capitalize;*/
    font-size: calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 300;
    padding-top: 5px;
}

.checkout-second .checkout-box .checkout-body .btn {
    /*text-transform: capitalize;*/
    padding: 13px 30px;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 3px;
    margin-top: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-body .previous {
    margin-right: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-body .checkout-form {
    position: relative;
}

.checkout-second .checkout-box .checkout-body .checkout-form .menu-bar {
    margin-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    overflow: hidden;
    padding-top: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f2f2f2;
    text-align: center;
}

.checkout-second .checkout-box .checkout-body .checkout-form .menu-bar .active {
    color: #042c49;
}

.checkout-second .checkout-box .checkout-body .checkout-form .menu-bar li {
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    color: #ef9f77;
}

.checkout-second .checkout-box .checkout-body .checkout-form .menu-bar li:nth-child(n+2) {
    margin-left: calc(10px + (70 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-body .checkout-form .menu-bar li .icon {
    width: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #ef9f77;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    margin-bottom: 5px;
}

.checkout-second .checkout-box .checkout-body .checkout-form .menu-bar li .icon svg {
    width: 42%;
    height: auto;
    fill: #ffffff;
}

.checkout-second .checkout-box .checkout-body .checkout-form .menu-bar li.active .icon {
    background-color: #042c49;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box {
    width: 100%;
    position: relative;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box:not(:first-of-type) {
    display: none;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card h3.form-title {
    /*text-transform: capitalize;*/
    margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group {
    margin-bottom: calc(12px + (16 - 12) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group label {
    font-weight: 700;
    color: #444444;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .form-control {
    border-color: #dddddd;
    height: calc(35px + (48 - 35) * ((100vw - 320px) / (1920 - 320)));
    padding-top: 0;
    padding-bottom: 0;
    padding-left: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .small-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .small-group select {
    -webkit-appearance: none;
    background-image: url("/../assets/images/checkout/dropdown.png");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 95%;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .small-group select:nth-child(n+2) {
    margin-left: 15px;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .small-group>div {
    width: 100%;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .small-group .small-sec {
    width: 300px;
    margin-left: 15px;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card ul.payment-info {
    margin-bottom: calc(10px + (15 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card ul.payment-info li {
    line-height: 1;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card ul.payment-info li:nth-child(n+2) {
    margin-left: calc(2px + (15 - 2) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card ul.payment-info li img {
    width: calc(52px + (60 - 52) * ((100vw - 320px) / (1920 - 320))) !important;
    height: auto;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .payment-success {
    text-align: center;
    padding-top: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(30px + (50 - 30) * ((100vw - 320px) / (1920 - 320)));
    background-color: #f2f2f2;
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .payment-success svg {
    width: calc(50px + (100 - 50) * ((100vw - 320px) / (1920 - 320)));
    fill: #4ead4e;
    margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .payment-success h2 {
    font-weight: 700;
    /*text-transform: capitalize;*/
    color: #4ead4e;
    line-height: 1;
}

.dashboard-left {
    background-color: #ffffff;
}

.dashboard-left .block-title h2 {
    font-size: 24px;
    padding-bottom: 20px;
}

.dashboard-left .block-content {
    padding: 15px;
}

.dashboard-left .block-content ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.dashboard-left .block-content ul li.active {
    color: #042c49;
}

.dashboard-left .block-content ul li.active a {
    color: #042c49;
}

.dashboard-left .block-content ul li:before {
    content: '\f105';
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 35px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    margin-top: 3px;
}

.dashboard-left .block-content ul li a {
    color: #333333;
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.dashboard-left .block-content ul li:hover {
    padding-left: 10px;
    color: #042c49;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.dashboard-left .block-content ul li:hover a {
    color: #042c49;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.padding-cls {
    padding-left: 50px;
    padding-right: 50px;
}

.dashboard-right {
    background-color: #ffffff;
}

.dashboard-right p {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 20px;
}

.dashboard-right .dashboard {
    padding: 30px;
}

.dashboard-right .dashboard .page-title h2 {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 15px;
    color: #333333;
    line-height: 1;
    font-weight: 600;
}

.dashboard-right .dashboard .welcome-msg p {
    margin-bottom: 0;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.6;
}

.dashboard .box-head h2 {
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin: 20px 0 0;
    /*text-transform: capitalize;*/
    color: #333333;
}

.dashboard .box a {
    color: #042c49;
    font-weight: 700;
}

.dashboard .box h6 {
    margin-bottom: 0;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.dashboard .box .box-title {
    border-bottom: 1px solid #dddddd;
    display: inline-block;
    margin-bottom: 5px;
    padding: 12px 0;
    position: relative;
    width: 100%;
}

.dashboard .box .box-title h3 {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    margin: 0;
    /*text-transform: capitalize;*/
    color: #333333;
    letter-spacing: 0.05em;
}

.dashboard .box .box-title>a {
    position: absolute;
    right: 0;
    top: 12px;
    color: #042c49;
    font-weight: 700;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.dashboard .box address {
    margin-bottom: 0;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.box-content h6 {
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1.8 !important;
    margin-bottom: 0;
}

.box-content a {
    color: #042c49;
    font-weight: 700;
    letter-spacing: 0.05em;
}

.account-sidebar {
    display: none;
    cursor: pointer;
    background-color: #042c49;
    color: #ffffff;
    width: 30%;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 5px;
}

.success-text {
    text-align: center;
}

.success-text i {
    font-size: 50px;
    color: #4ead4e;
}

.success-text h2 {
    text-transform: uppercase;
    font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: #333333;
    font-weight: 700;
}

.success-text p {
    /*text-transform: capitalize;*/
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.success-text.order-fail i {
    color: #042c49;
}

.order-success-sec h4 {
    font-weight: 700;
    /*text-transform: capitalize;*/
    color: #333333;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
    margin-bottom: 10px;
}

.order-success-sec .order-detail li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*text-transform: capitalize;*/
    font-size: 16px;
}

.order-success-sec .payment-mode p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
}

.delivery-sec {
    padding: 30px;
    text-align: center;
    margin-top: 20px;
    background-color: #eeeeee;
}

.delivery-sec h2,
.delivery-sec h3 {
    color: #000000;
    /*text-transform: capitalize;*/
}

.delivery-sec h2 {
    font-weight: 600;
    font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.delivery-sec h3 {
    font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
}

.product-order h3 {
    font-weight: 700;
    text-transform: uppercase;
    color: #333333;
    font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
}

.product-order .product-order-detail {
    margin-top: 15px;
}

.product-order .product-order-detail img {
    height: 130px;
}

.product-order .product-order-detail .order_detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-order .product-order-detail .order_detail h4 {
    margin-bottom: 5px;
    /*text-transform: capitalize;*/
    font-weight: 700;
    color: #333333;
}

.product-order .product-order-detail .order_detail h5 {
    /*text-transform: capitalize;*/
    margin-bottom: 0;
    font-weight: 500;
}

.product-order .total-sec {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    margin: 15px 0;
    padding: 15px 0;
}

.product-order .total-sec ul li {
    font-size: 18px;
    display: block;
    /*text-transform: capitalize;*/
    color: #333333;
}

.product-order .total-sec ul li span {
    float: right;
}

.product-order .final-total h3 {
    display: block;
    margin-bottom: 0;
    font-size: 24px;
}

.product-order .final-total h3 span {
    float: right;
}

.compare-section .compare-part {
    position: relative;
}

.compare-section .compare-part .close-btn {
    background-color: transparent;
    border: none;
    font-size: 25px;
    position: absolute;
    top: 0;
    right: 0;
}

.compare-section .compare-part .img-secton {
    padding: 25px 15px 15px;
    text-align: center;
}

.compare-section .compare-part .img-secton img,
.compare-section .compare-part .img-secton .bg-size {
    width: 50%;
    margin: 0 auto;
}

.compare-section .compare-part .img-secton a h5 {
    margin-bottom: 0;
    /*text-transform: capitalize;*/
    margin-top: 10px;
    color: #333333;
    font-weight: 600;
}

.compare-section .compare-part .img-secton h5 {
    margin-bottom: 0;
    line-height: 1.2;
    font-weight: 400;
}

.compare-section .compare-part .detail-part .title-detail {
    background-color: #f7f8fa;
    text-align: center;
    padding: 8px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
}

.compare-section .compare-part .detail-part .title-detail h5 {
    margin-bottom: 0;
    text-transform: uppercase;
    color: #333333;
    letter-spacing: 0.05em;
}

.compare-section .compare-part .detail-part .inner-detail {
    padding: 15px;
}

.compare-section .compare-part .detail-part .inner-detail p {
    margin-bottom: 0;
    line-height: 1.2;
    letter-spacing: 0.05em;
    text-align: center;
}

.compare-section .compare-part .btn-part {
    text-align: center;
    padding: 15px;
    border-top: 1px solid #dddddd;
}

.compare-section .compare-part .btn-part .btn-normal {
    padding: 13px 25px;
    text-transform: uppercase;
}

.compare-section .slick-slide>div {
    border: 1px solid #dddddd;
    border-left: none;
}

.compare-section .slick-slide:first-child {
    border-left: 1px solid #dddddd;
}

.compare-padding {
    padding-bottom: 50px;
}

.compare-padding h2 {
    margin-bottom: 20px;
}

.compare-label {
    position: absolute;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    top: 20px;
    left: 16px;
    letter-spacing: 0.1em;
    z-index: 1;
}

.compare-page .table-wrapper {
    padding-bottom: 0;
}

.compare-page .table-wrapper .table {
    border: 1px solid #dddddd;
    /*text-transform: capitalize;*/
    color: #333333;
    margin-bottom: 0;
}

.compare-page .table-wrapper .table .product-name {
    width: 15%;
}

.compare-page .table-wrapper .table .featured-image {
    width: 185px;
}

.compare-page .table-wrapper .table td {
    border-top: none;
}

.compare-page .table-wrapper .table thead,
.compare-page .table-wrapper .table tbody,
.compare-page .table-wrapper .table tfoot,
.compare-page .table-wrapper .table tr,
.compare-page .table-wrapper .table td,
.compare-page .table-wrapper .table th {
    border: 1px solid #dddddd !important;
}

.compare-page .table-wrapper .table thead .th-compare td {
    font-size: 14px;
    font-weight: 700;
    background: #f1f1f1;
    border-right: 1px solid #dddddd;
    letter-spacing: 0.05em;
    padding-left: 20px;
}

.compare-page .table-wrapper .table thead .th-compare th {
    text-align: left;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    padding-left: 20px;
}

.compare-page .table-wrapper .table thead .th-compare th .remove-compare {
    float: left;
    border: none;
    background: transparent;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
}

.compare-page .table-wrapper .table tbody tr th {
    background: #f1f1f1;
}

.compare-page .table-wrapper .table tbody tr td {
    border-right: 1px solid #dddddd;
    position: relative;
}

.compare-page .table-wrapper .table tbody tr th,
.compare-page .table-wrapper .table tbody tr td {
    padding: 15px;
    vertical-align: top;
    border-bottom: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
}

.compare-page .table-wrapper .table tbody tr .grid-link__title {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
}

.compare-page .table-wrapper .table tbody tr .product_price {
    margin: 15px 0;
}

.compare-page .table-wrapper .table tbody tr .product_price span.money {
    padding-left: 5px;
}

.compare-page .table-wrapper .table tbody tr p {
    color: #777777;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
}

.compare-page .table-wrapper .table tbody tr .add-to-cart,
.compare-page .table-wrapper .table tbody tr .select-option-cls {
    padding: 6px 10px;
}

.typography_section .typography-box {
    margin-bottom: 30px;
}

.typography_section .typography-box p {
    line-height: 22px;
}

.typography_section .typography-box .headings {
    margin-bottom: 20px;
    background-color: #e8e8e8;
    padding: 8px 12px;
}

.typography_section .typography-box .headings h3 {
    /*text-transform: capitalize;*/
    color: #2d2d2d;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 20px;
}

.typography_section .typography-box .headings span {
    color: #444444;
}

.typography_section .typography-box .headings span code {
    color: #444444;
}

.typography_section .typography-box .typo-content.heading_content h1,
.typography_section .typography-box .typo-content.heading_content h2,
.typography_section .typography-box .typo-content.heading_content h3,
.typography_section .typography-box .typo-content.heading_content h4,
.typography_section .typography-box .typo-content.heading_content h5,
.typography_section .typography-box .typo-content.heading_content h6 {
    font-weight: 400;
    color: black;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 10px;
}

.typography_section .typography-box .typo-content .sub-title {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 5px;
    margin-bottom: 8px;
    font-size: 18px;
    color: #444444;
    /*text-transform: capitalize;*/
}

.typography_section .typography-box .typo-content.product-pagination {
    border: none;
    margin: 0;
}

.typography_section .typography-box .typo-content.product-pagination .pagination .page-item {
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
}

.typography_section .typography-box .typo-content.product-pagination .pagination .page-item a {
    padding: 10px 14px;
}

.typography_section .typography-box .typo-content.input_button input {
    margin-bottom: 10px;
}

.typography_section .typography-box .typo-content.input_button label {
    font-size: 16px;
    /*text-transform: capitalize;*/
    padding-left: 5px;
}

.typography_section .typography-box .typo-content.loader-typo {
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.typography_section .typography-box .typo-content.loader-typo .pre-loader {
    position: relative;
    width: 50px;
    height: 50px;
    background-color: #042c49;
    border-radius: 50%;
}

.typography_section .typography-box .typo-content.loader-typo .pre-loader:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    border: 0 solid #dddddd;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: loading 1000ms ease-out forwards infinite;
    animation: loading 1000ms ease-out forwards infinite;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.typography_section .typography-box .typo-content .footer-social {
    margin-top: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.typography_section .typography-box:last-child {
    margin-bottom: 0;
}

.typography_section code {
    color: rgba(199, 109, 98, 0.6);
    background-color: #f7f7f7;
    padding: 3px;
    margin: 0 3px;
    border-radius: 2px;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    margin-top: unset;
}

.profile-section {
    background-color: #f5f5f5;
    border: 1px solid #dddddd;
    padding: 15px;
}

.profile-section .profile-detail .profile-name {
    font-weight: 700;
}

.profile-section .profile-detail p {
    margin-bottom: 8px;
}

.lookbook-section {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
}

.lookbook-section .lookbook-img>div+div img {
    margin-top: 25px;
}

.lookbook .lookbook-block {
    position: relative;
}

.lookbook .lookbook-block .lookbook-dot {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    width: 29px;
    height: 29px;
    line-height: 29px;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    top: 50%;
    left: 12%;
    background-color: #ffffff;
}

.lookbook .lookbook-block .lookbook-dot.dot2 {
    top: 70%;
    left: 27%;
}

.lookbook .lookbook-block .lookbook-dot.dot3 {
    top: 28%;
    left: 30%;
}

.lookbook .lookbook-block .lookbook-dot.dot4 {
    top: 67%;
    left: 8%;
}

.lookbook .lookbook-block .lookbook-dot.dot5 {
    top: 40%;
    left: 35%;
}

.lookbook .lookbook-block .lookbook-dot.dot6 {
    top: 80%;
    left: 58%;
}

.lookbook .lookbook-block .lookbook-dot.dot7 {
    top: 67%;
    left: 45%;
}

.lookbook .lookbook-block .lookbook-dot.dot8 {
    top: 43%;
    left: 39%;
}

.lookbook .lookbook-block .lookbook-dot.dot9 {
    top: 32%;
    left: 50%;
}

.lookbook .lookbook-block .lookbook-dot.dot10 {
    top: 60%;
    left: 50%;
}

.lookbook .lookbook-block .lookbook-dot.dot11 {
    top: 50%;
    left: 45%;
}

.lookbook .lookbook-block .lookbook-dot.dot12 {
    top: 75%;
    left: 45%;
}

.lookbook .lookbook-block .lookbook-dot.dot13 {
    top: 40%;
    left: 45%;
}

.lookbook .lookbook-block .lookbook-dot.dot14 {
    top: 76%;
    left: 45%;
}

.lookbook .lookbook-block .lookbook-dot.dot15 {
    top: 50%;
    left: 15%;
}

.lookbook .lookbook-block .lookbook-dot:before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    zoom: 1;
    z-index: -1;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.lookbook .lookbook-block .lookbook-dot span {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #333333;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox {
    visibility: hidden;
    top: -98px;
    left: 150%;
    position: absolute;
    width: 130px;
    background-color: #ffffff;
    -webkit-box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
    box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox img {
    margin-top: 0;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
    padding: 5px;
    text-align: center;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
    margin-bottom: 0;
    /*text-transform: capitalize;*/
    line-height: 20px;
    color: #333333;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5.title {
    font-weight: 700;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h6 {
    text-transform: uppercase;
    color: #042c49;
    margin-bottom: 0;
}

.lookbook .lookbook-block .lookbook-dot .dot-showbox:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 8px solid #dddddd;
    border-bottom: 8px solid transparent;
    top: 41%;
    left: -8px;
}

.lookbook .lookbook-block .lookbook-dot:hover {
    background-color: #042c49;
    -webkit-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
    z-index: 10;
}

.lookbook .lookbook-block .lookbook-dot:hover .dot-showbox {
    -webkit-animation: fadeInUp 400ms ease-in-out;
    animation: fadeInUp 400ms ease-in-out;
    visibility: visible;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.lookbook .lookbook-block .lookbook-dot:hover span {
    color: #ffffff;
}

.lookbook .lookbook-block .lookbook-dot:hover:before {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.portfolio-padding {
    padding-bottom: 40px;
}

.portfolio-section .isotopeSelector {
    margin-bottom: 30px;
}

.portfolio-section .portfolio-2 {
    margin-bottom: -20px;
}

.portfolio-section .portfolio-3 {
    margin-bottom: -5px;
}

.portfolio-section .article-title {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    color: white;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    text-align: center;
    float: left;
    font-size: 18px;
    line-height: 24px;
}

.portfolio-section .article-title a {
    color: white;
}

.portfolio-section .isotopeSelector {
    float: left;
    position: relative;
}

.portfolio-section .isotopeSelector .overlay {
    position: relative;
    overflow: hidden;
}

.portfolio-section .isotopeSelector img {
    width: 100%;
    height: auto;
}

.portfolio-section .isotopeSelector .product-box {
    padding-right: 0;
}

.portfolio-section .isotopeSelector img {
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.portfolio-section .isotopeSelector .overlay-background {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.portfolio-section .isotopeSelector .overlay-background i {
    position: absolute;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 0.4);
    padding: 10px;
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 22px;
    display: none;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    width: 40px;
    height: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.portfolio-section .isotopeSelector:hover img {
    -webkit-transform: scale(1.2) rotate(2deg);
    transform: scale(1.2) rotate(2deg);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.portfolio-section .isotopeSelector:hover .overlay-background {
    -webkit-transform: scale(1);
    transform: scale(1);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
    z-index: 1;
    -webkit-transition: opacity .3s linear;
    transition: opacity .3s linear;
}

.portfolio-section .isotopeSelector:hover .overlay-background i {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.portfolio-section .border-portfolio {
    margin: 3px;
    overflow: hidden;
}

.portfolio-section .border-portfolio:after {
    position: absolute;
    top: 10%;
    left: 10%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    -webkit-transform: translate(-20%, -20%);
    transform: translate(-20%, -20%);
    opacity: 0;
}

.portfolio-section .border-portfolio:before {
    position: absolute;
    bottom: 10%;
    right: 10%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    -webkit-transform: translate(10%, 10%);
    transform: translate(10%, 10%);
    opacity: 0;
}

.portfolio-section .border-portfolio:hover:after {
    -webkit-animation: portfolio-circle 0.5s ease;
    animation: portfolio-circle 0.5s ease;
}

.portfolio-section .border-portfolio:hover:before {
    -webkit-animation: portfolio-circle 0.8s ease;
    animation: portfolio-circle 0.8s ease;
}

@-webkit-keyframes portfolio-circle {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        width: 300px;
        height: 300px;
        opacity: 0;
    }
}

@keyframes portfolio-circle {
    0% {
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        width: 300px;
        height: 300px;
        opacity: 0;
    }
}

.portfolio-section #form1 {
    padding-bottom: 20px;
}

.portfolio-section .filter-button {
    padding: 15px 24px;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    border: none;
    background-color: #ffffff;
    margin: 0 5px;
    line-height: 1;
}

.portfolio-section .filter-button.active {
    background-color: #042c49;
    color: #ffffff;
}

.portfolio-section.fullwidth-portfolio .isotopeSelector {
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 14px;
}

.filter-section .filter-container {
    text-align: center;
    padding: 0 0 20px;
}

.filter-section .filter-container ul.filter>li {
    padding: 0 25px;
}

.filter-section .filter-container ul.filter>li>a {
    color: #042c49;
    font-size: 20px;
    line-height: 40px;
}

.filter-section .filter-container ul.filter>li>a:hover,
.filter-section .filter-container ul.filter>li>a:focus {
    text-decoration: none;
    color: #042c49;
    outline: none;
}

.filter-section .filter-container ul.filter>li.active {
    background-color: #042c49;
}

.filter-section .filter-container ul.filter>li.active a {
    color: #ffffff;
}

.filter-section .filter-container ul.filter>li span {
    color: #ddd;
    font-size: 20px;
    display: inline-block;
    margin: 0 5px;
}

.filter-section .filter-container .filter>li {
    padding: 0;
    margin: 0;
}

.filter-section .title1 .title-inner1 {
    margin-top: -5px;
}

.filter-section h1 {
    text-align: center;
}

.demo-colored-wrap {
    background: #ecf0f1;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

.mfp-title {
    display: none;
}

.offer-box {
    top: 50%;
    position: fixed;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: -415px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 9;
}

.offer-box img {
    margin-right: 18px;
}

.offer-box.toggle-cls {
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.offer-box .heading-right h3 {
    position: absolute;
    top: 50%;
    right: 0;
    width: 230px;
    height: 36px;
    line-height: 36px;
    letter-spacing: 5px;
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transform: translate(50%, -50%) rotate(-90deg);
    transform: translate(50%, -50%) rotate(-90deg);
    text-align: center;
    background-color: #042c49;
    margin-bottom: 0;
    cursor: pointer;
}

.theme-tab {
    position: relative;
}

.theme-tab.product.tab-abjust {
    margin-bottom: -15px;
}

.theme-tab .tab-content {
    display: none;
}

.theme-tab .tab-content[style="display: block;"] .product-box,
.theme-tab .tab-content[style="display: block;"] .tab-box,
.theme-tab .tab-content[style="display: block;"] .media-banner,
.theme-tab .tab-content[style="display: block;"] .digipro-box,
.theme-tab .tab-content[style="display: block;"] .product-box3,
.theme-tab .tab-content[style="display: block;"] .gallery-box {
    -webkit-animation: zoomIn 300ms ease-in-out;
    animation: zoomIn 300ms ease-in-out;
}

.theme-tab .tab-content .product-tab .tab-box {
    background-color: white;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: calc(25% - 10px);
    margin: 0 5px 10px;
}

.theme-tab .tab-content .product-tab .tab-box:nth-last-child(-n + 4) {
    margin: 0 5px 0;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    height: 250px;
    padding: 15px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating {
    margin-top: 0;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i {
    padding-right: 0;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i:nth-child(-n + 4) {
    color: #ffa800;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating i:last-child {
    color: #ffa800;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
    margin-right: 35px;
    line-height: 17px;
    margin-top: 5px;
    margin-bottom: 0;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body h4 {
    font-size: 20px;
    margin-top: 5px;
    font-weight: 700;
    color: #333333;
    margin-bottom: 0;
}

.theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    margin-right: 2px;
    margin-top: 20px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    margin-bottom: 0;
    cursor: pointer;
}

.theme-tab .tab-content .product-tab .tab-box:hover .product-box2 img {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.theme-tab .tab-title,
.theme-tab .tab-title2 {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: -6px;
}

.theme-tab .tab-title.media-tab li,
.theme-tab .tab-title2.media-tab li {
    padding-top: 40px;
    position: relative;
}

.theme-tab .tab-title.media-tab li.current::before,
.theme-tab .tab-title2.media-tab li.current::before {
    width: 100%;
    position: absolute;
    top: 6px;
    left: 0;
    background-color: #042c49;
    height: 5px;
    content: '';
}

.theme-tab .tab-title.media-tab .media-banner,
.theme-tab .tab-title2.media-tab .media-banner {
    margin-right: 15px;
}

.theme-tab .tab-title .current a,
.theme-tab .tab-title2 .current a {
    color: #042c49;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.theme-tab .tab-title li,
.theme-tab .tab-title2 li {
    padding-left: 25px;
    padding-right: 25px;
}

.theme-tab .tab-title a,
.theme-tab .tab-title2 a {
    color: #333333;
    text-transform: uppercase;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.theme-tab .tab-title2 {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: -8px;
}

.theme-tab .tab-title2:after {
    content: "";
    position: absolute;
    border: 1px solid #dddddd;
    width: 100%;
    left: 0;
    top: 17px;
    z-index: -1;
}

.theme-tab .tab-title2 .current {
    background-color: #f2f2f2;
}

.theme-tab .tab-title2 .current a {
    background-color: #f2f2f2;
}

.theme-secondtab .tab-bg {
    background-color: white;
    padding: 70px 50px 60px 50px;
}

.theme-secondtab .tab-bg .theme-tab .tab-title {
    margin-bottom: 30px;
}

.theme-secondtab .tab-bg .product-box {
    padding-right: 0;
}

.parallax {
    background-attachment: fixed;
}

.full-banner {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 190px;
    padding-bottom: 190px;
    background-position: center;
}

.full-banner.parallax-layout {
    padding-top: 350px;
    padding-bottom: 350px;
}

.full-banner.parallax-layout .banner-contain h4 {
    color: #f1f1f1;
}

.full-banner.banner-layout-3 .banner-contain .btn-normal {
    text-transform: uppercase;
}

.full-banner.banner-layout-3 .banner-contain h4 {
    color: #ffffff;
}

.full-banner.banner-layout-3 .banner-contain h3 {
    color: #ffffff;
    margin-top: 10px;
}

.full-banner.banner-layout-3 .banner-contain .color {
    color: #042c49;
    line-height: 1;
}

.full-banner.parallax {
    background-attachment: fixed;
}

.full-banner.p-left .banner-contain {
    float: left;
}

.full-banner.p-right .banner-contain {
    float: right;
}

.full-banner.p-center .banner-contain {
    left: 0;
    right: 0;
    margin: 0 auto;
}

.full-banner .banner-contain .btn-solid,
.full-banner .banner-contain .btn-outline {
    margin-top: 20px;
}

.full-banner .banner-contain h2 {
    font-size: calc(55px + (100 - 55) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    color: #042c49;
    text-transform: uppercase;
    margin-top: -15px;
}

.full-banner .banner-contain h3 {
    font-size: calc(36px + (60 - 36) * ((100vw - 320px) / (1920 - 320)));
    color: #333333;
    text-transform: uppercase;
    font-weight: 700;
}

.full-banner .banner-contain h4 {
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #333333;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    padding-top: 10px;
    margin-bottom: -5px;
    line-height: 1.3;
}

.parallax-cls section:nth-child(odd) .full-banner {
    background-position: right;
}

.parallax-cls section:nth-child(even) .full-banner {
    background-position: left;
}

.parallax-cls section .banner-contain {
    margin-top: -12px;
}

.pet-parallax {
    position: relative;
}

.pet-parallax .pet-decor {
    position: absolute;
    left: 110px;
    bottom: -164px;
}

.pet-parallax .banner-contain h4,
.pet-parallax .banner-contain h3,
.pet-parallax .banner-contain p {
    color: #212121;
}

.pet-parallax .banner-contain p {
    max-width: 75%;
    margin: 0 auto;
    line-height: 22px;
    font-size: 16px;
    letter-spacing: 0.04em;
}

.pet-parallax .banner-contain h4 {
    letter-spacing: 0.05em;
    padding-top: 0 !important;
    margin-bottom: 0;
    line-height: 1.3;
    margin-top: -7px;
}

.pet-parallax .banner-contain h3 {
    margin: 15px 0;
    font-size: 48px;
}

.pet-parallax .full-banner {
    padding-top: 130px;
    padding-bottom: 130px;
    background-blend-mode: overlay;
}

.advertise-section .full-banner {
    padding-bottom: 105px;
    padding-top: 105px;
    background-position: top;
}

.advertise-section .full-banner .banner-contain {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 40px 0;
    width: 90%;
    border: 3px solid #042c49;
}

.advertise-section .full-banner .banner-contain h2 {
    font-size: 75px;
}

.center-slider {
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
}

.about-text p {
    letter-spacing: 0.06em;
    text-align: center;
    margin-bottom: 50px;
}

.full-box .theme-card .offer-slider .product-box2 {
    overflow: hidden;
}

.theme-pannel-main {
    background-color: #ffffff;
    position: fixed;
    top: 30%;
    right: 0;
    z-index: 9;
    padding: 0 5px;
    -webkit-box-shadow: 0 0 7px 1px rgba(158, 158, 158, 0.2);
    box-shadow: 0 0 7px 1px rgba(158, 158, 158, 0.2);
}

.theme-pannel-main ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 12px;
    border-bottom: 1px solid #dadada;
}

.theme-pannel-main ul li span {
    padding: 0;
    text-transform: uppercase;
    color: #949494;
    font-weight: 700;
}

.theme-pannel-main ul li a {
    padding: 0;
}

.theme-pannel-main ul li:last-child {
    border-bottom: 0;
}

.color-picker {
    position: fixed;
    top: calc(30% + 110px);
    z-index: 9;
    padding: 0 5px;
    right: 0;
    -webkit-box-shadow: 0 0 7px 1px rgba(158, 158, 158, 0.2);
    box-shadow: 0 0 7px 1px rgba(158, 158, 158, 0.2);
    background-color: #ffffff;
    max-width: 150px;
}

.color-picker h4 {
    padding: 10px 8px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    font-size: 16px;
    text-transform: uppercase;
}

.color-picker .title {
    display: inline-block;
    position: absolute;
    left: -68px;
    width: 68px;
    -webkit-box-shadow: -3px 0 7px 1px rgba(158, 158, 158, 0.2);
    box-shadow: -3px 0 7px 1px rgba(158, 158, 158, 0.2);
    background-color: #ffffff;
    padding: 10px 14px;
}

.color-picker .colors {
    text-align: center;
}

.color-picker .colors li {
    height: 35px;
    margin: 3px;
    width: 35px;
    display: inline-block;
    position: relative;
}

.color-picker .colors li.active:before {
    content: "\e600";
    position: absolute;
    font-family: themify;
    color: #fff;
    font-size: 19px;
    top: 2px;
    left: 10px;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.color1 {
    background: linear-gradient(135deg, #ff6000 49%, #ff6000 50%, #ffa800 51%, #ffa800 100%);
}

.color2 {
    background: linear-gradient(135deg, #00baf2 49%, #00baf2 50%, #1c3481 51%, #1c3481 100%);
}

.color3 {
    background: linear-gradient(135deg, #f1bb3a 49%, #f1bb3a 50%, #0083c1 51%, #0083c1 100%);
}

.color4 {
    background: linear-gradient(135deg, #f15838 49%, #f15838 50%, #6465a5 51%, #6465a5 100%);
}

.color5 {
    background: linear-gradient(135deg, #2690e4 49%, #2690e4 50%, #252525 51%, #252525 100%);
}

.color6 {
    background: linear-gradient(135deg, #84b213 49%, #84b213 50%, #f97c2d 51%, #f97c2d 100%);
}

.color7 {
    background: linear-gradient(135deg, #042c49 49%, #042c49 50%, #ef9f77 51%, #ef9f77 100%);
}

.color8 {
    background: linear-gradient(135deg, #f78195 49%, #f78195 50%, #f97107 51%, #f97107 100%);
}

.color9 {
    background: linear-gradient(135deg, #35a7b1 49%, #35a7b1 50%, #f98925 51%, #f98925 100%);
}

.color10 {
    background: linear-gradient(135deg, #fb6436 49%, #fb6436 50%, #f98925 51%, #f98925 100%);
}

.color11 {
    background: linear-gradient(135deg, #4150b5 49%, #4150b5 50%, #e62a16 51%, #e62a16 100%);
}

.color12 {
    background: linear-gradient(135deg, #fa741b 49%, #fa741b 50%, #2fbb2f 51%, #2fbb2f 100%);
}

.color13 {
    background: linear-gradient(135deg, #a03b41 49%, #a03b41 50%, #f56449 51%, #f56449 100%);
}

.color14 {
    background: linear-gradient(135deg, #1e9a05 49%, #1e9a05 50%, #f3725c 51%, #f3725c 100%);
}

.color15 {
    background: linear-gradient(135deg, #3635aa 49%, #3635aa 50%, #46d8d5 51%, #46d8d5 100%);
}

/*=====================
  26.add to cart CSS start
==========================*/
.add_to_cart {
    position: fixed;
    background-color: #ffffff;
    z-index: 9999;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.add_to_cart ::-webkit-scrollbar {
    width: 3px;
    height: 7px;
}

.add_to_cart ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.add_to_cart ::-webkit-scrollbar-thumb {
    background: #dddddd;
}

.add_to_cart ::-webkit-scrollbar-thumb:hover {
    background: #000000;
}

.add_to_cart .cart-inner {
    background-color: #ffffff;
    z-index: 9;
    position: relative;
    top: 0;
}

.add_to_cart .cart-inner .cart_media {
    padding: 0 20px;
}

.add_to_cart .cart-inner .cart_media ul li .btn {
    /*text-transform: capitalize;*/
    font-size: 18px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product .addit-box {
    padding-top: 5px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product .qty-minus,
.add_to_cart .cart-inner .cart_media ul.cart_product .qty-plus {
    width: 25px;
    padding: 0;
    font-size: 10px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product .qty-adj {
    height: 25px;
    width: 35px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li {
    position: relative;
    width: 100%;
    border: 1px solid #dddddd;
    padding: 10px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media img {
    width: 83px;
    height: auto;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body h4 {
    color: #444444;
    /*text-transform: capitalize;*/
    margin-bottom: 5px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body h4 span {
    color: #333333;
    font-size: 16px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body h6 {
    color: #042c49;
    font-weight: 700;
    margin-bottom: 5px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body h6 span {
    color: #999999;
    text-decoration: line-through;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body .qty-box {
    margin-bottom: 5px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body .addit-box .pro-add svg {
    color: #444444;
    width: 14px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .close-circle {
    position: absolute;
    top: 10px;
    right: 10px;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .close-circle svg {
    width: 14px;
    color: #444444;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .close-circle i {
    color: #444444;
    font-size: 16px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.add_to_cart .cart-inner .cart_media ul.cart_product li .close-circle i:hover {
    color: #000000;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.add_to_cart .cart-inner .cart_media ul.cart_total {
    background-color: rgba(199, 109, 98, 0.05);
    padding: 15px;
}

.add_to_cart .cart-inner .cart_media ul.cart_total li {
    display: block;
    color: #444444;
    font-size: 16px;
    /*text-transform: capitalize;*/
    line-height: 1;
}

.add_to_cart .cart-inner .cart_media ul.cart_total li:nth-child(n+2) {
    margin-top: 10px;
}

.add_to_cart .cart-inner .cart_media ul.cart_total li span {
    float: right;
    text-transform: uppercase;
}

.add_to_cart .cart-inner .cart_media ul.cart_total li .total {
    border-top: 1px solid #dddddd;
    margin-top: 20px;
    padding-top: 15px;
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
}

.add_to_cart .cart-inner .cart_media ul.cart_total li .buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.add_to_cart .cart-inner .cart_top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 20px;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(199, 109, 98, 0.05);
}

.add_to_cart .cart-inner .cart_top h3 {
    font-size: 18px;
    color: #042c49;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
}

.add_to_cart .cart-inner .cart_top .close-cart {
    margin-left: auto;
}

.add_to_cart .cart-inner .cart_top .close-cart i {
    color: #042c49;
    font-size: 18px;
}

.add_to_cart .overlay {
    visibility: hidden;
    opacity: 0;
    background-color: #000000;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.add_to_cart.open-side {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.add_to_cart.open-side .overlay {
    opacity: 0.8;
    visibility: visible;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.add_to_cart.left {
    left: -320px;
    top: 0;
    height: 100vh;
    width: 320px;
}

.add_to_cart.left .cart-inner {
    height: 100vh;
    overflow: auto;
}

.add_to_cart.left.open-side {
    left: 0;
}

.add_to_cart.right {
    right: -320px;
    height: 100vh;
    width: 320px;
    top: 0;
}

.add_to_cart.right .cart-inner {
    height: 100vh;
    overflow: auto;
}

.add_to_cart.right.open-side {
    right: 0;
}

.add_to_cart.top,
.add_to_cart.bottom {
    width: 100%;
}

.add_to_cart.top .cart_media,
.add_to_cart.bottom .cart_media {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 20px;
}

.add_to_cart.top .cart_media ul.cart_product,
.add_to_cart.bottom .cart_media ul.cart_product {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 75%;
    overflow-y: auto;
}

.add_to_cart.top .cart_media ul.cart_product li,
.add_to_cart.bottom .cart_media ul.cart_product li {
    min-width: 245px;
    max-width: 280px;
}

.add_to_cart.top .cart_media ul.cart_product li:nth-child(n+2),
.add_to_cart.bottom .cart_media ul.cart_product li:nth-child(n+2) {
    margin-left: 15px;
}

.add_to_cart.top .cart_media .cart_total,
.add_to_cart.bottom .cart_media .cart_total {
    width: 24%;
}

.add_to_cart.top {
    top: -320px;
}

.add_to_cart.top.open-side {
    top: 0;
}

.add_to_cart.bottom {
    bottom: -320px;
}

.add_to_cart.bottom.open-side {
    bottom: 0;
}

.add_to_cart.left .cart-inner .cart_media ul.cart_product,
.add_to_cart.right .cart-inner .cart_media ul.cart_product {
    height: calc(100vh - 205px);
    overflow-y: auto;
}

.add_to_cart.left .cart-inner .cart_media ul.cart_product li:nth-child(n+2),
.add_to_cart.right .cart-inner .cart_media ul.cart_product li:nth-child(n+2) {
    margin-top: 15px;
}

.add_to_cart.left .cart-inner .cart_media .cart_total,
.add_to_cart.right .cart-inner .cart_media .cart_total {
    width: calc(320px - 40px);
    position: fixed;
    bottom: 0px;
    margin-bottom: 20px;
}

.add_to_cart .setting-block {
    padding-left: 20px;
    padding-right: 20px;
}

.add_to_cart .setting-block .form-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 44px;
    /*text-transform: capitalize;*/
    border: 1px solid #dddddd;
    background: url("/../assets/images/dropdown.png") no-repeat 95%;
    background-size: 12px;
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
}

/*=====================
  27.add to account bar start
==========================*/
.account-bar .theme-form {
    padding-left: 20px;
    padding-right: 20px;
}

.account-bar .theme-form .form-group {
    margin-bottom: calc(15px + (25 - 15) * ((100vw - 320px) / (1920 - 320)));
}

.account-bar .theme-form .form-group label {
    font-size: 18px;
    /*text-transform: capitalize;*/
    color: #444444;
    line-height: 1;
    font-weight: 700;
}

.account-bar .theme-form .form-group .form-control {
    border-radius: 5px;
    border-color: #dddddd;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
}

.account-bar .theme-form .form-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.account-bar .theme-form h5 {
    color: #042c49;
    /*text-transform: capitalize;*/
}

.account-bar .theme-form h6 {
    color: #444444;
    /*text-transform: capitalize;*/
    font-weight: 700;
    margin-top: 5px;
}

.account-bar .theme-form h6 span {
    color: #042c49;
    margin-left: 10px;
}

/*=====================
  28. Tab to top CSS start
==========================*/
.tap-top {
    position: fixed;
    bottom: 110px;
    right: 30px;
    z-index: 8;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
}

.tap-top svg {
    width: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
    fill: #042c49;
}

.tap-top.top-cls {
    opacity: 1;
    visibility: visible;
}

.tap-top:hover svg {
    -webkit-animation: fadeInUp 500ms ease-in-out;
    animation: fadeInUp 500ms ease-in-out;
}

/*=====================
   29. Modal CSS start
==========================*/
.theme-modal {
    padding-right: 0 !important;
}

.theme-modal .quick-view-img {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.theme-modal .modal-dialog .modal-content .modal-body {
    background-color: #ffffff;
    border-radius: 5px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
    background-color: #ffffff;
    padding: 25px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .btn-close {
    position: absolute;
    right: 17px;
    top: 15px;
    padding: 0;
    font-size: 16px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .btn-close span {
    color: #000000;
    font-size: 30px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h2 {
    border-bottom: 1px dashed #bfbfbf;
    text-align: center;
    margin-bottom: 15px;
    padding-bottom: 15px;
    font-weight: 700;
    font-size: 24px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content h4 {
    margin-bottom: 30px;
    text-align: center;
    color: #272727;
    font-weight: 400;
    /*text-transform: capitalize;*/
    line-height: 1.6;
    font-size: 14px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form {
    margin: 0 auto;
    text-align: center;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .form-control {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    font-size: 12px;
    height: 45px;
    border-radius: 0;
    width: 100%;
    letter-spacing: 0.05em;
    margin: 0 10px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .age-content form .form-control:focus {
    border-color: #dddddd;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content img {
    margin-bottom: 40px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
    margin-bottom: 15px;
    text-align: center;
    color: #333333;
    text-transform: uppercase;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.05em;
    font-size: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group {
    margin: 0 auto;
    text-align: center;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control {
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    font-size: 14px;
    max-width: 640px;
    left: 0;
    right: 0;
    margin: 0 auto;
    letter-spacing: 0.05em;
    margin-bottom: 15px;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #dddddd;
}

.theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .btn-normal {
    text-transform: uppercase;
}

.theme-modal .modal-dialog .quick-view-modal button.btn-close,
.theme-modal .modal-dialog .demo_modal button.btn-close {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 5px;
}

.theme-modal .modal-dialog .quick-view-modal .view-detail,
.theme-modal .modal-dialog .demo_modal .view-detail {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.theme-modal.demo-modal .modal-dialog .modal-content .modal-body {
    height: 75vh;
    overflow-y: scroll;
    padding: 25px;
    background-image: none !important;
}

.theme-modal.demo-modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar {
    width: 5px;
}

.theme-modal.demo-modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.theme-modal.demo-modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb {
    background: #042c49;
}

.theme-modal.demo-modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body {
    background-position: center;
    background-size: cover;
    background-blend-mode: overlay;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media a img {
    max-width: 150px;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body {
    padding: 0 15px;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 {
    font-size: 16px;
    color: #042c49;
    /*text-transform: capitalize;*/
    margin-bottom: 20px;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 i {
    margin-right: 8px;
    color: #ffffff;
    background: #19a340;
    font-size: 14px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 50%;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body .buttons {
    margin-bottom: 20px;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box {
    padding-left: 10px;
    padding-right: 10px;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .img-wrapper {
    -webkit-box-shadow: 0 0 5px rgba(221, 221, 221, 0.6);
    box-shadow: 0 0 5px rgba(221, 221, 221, 0.6);
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail {
    text-align: center;
    padding: 10px;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 {
    font-weight: 700;
    color: #444444;
    /*text-transform: capitalize;*/
    margin-bottom: 5px;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h4 {
    color: #ef9f77;
    font-size: 14px;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h4 span {
    color: #777;
    text-decoration: line-through;
    margin-left: 10px;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .product-upsell {
    margin: 20px 0 10px;
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .product-upsell h4 {
    font-weight: 600;
    margin-bottom: 20px;
    /*text-transform: capitalize;*/
    color: #444444;
    font-size: calc(18px + (20 - 18) * ((100vw - 320px) / (1920 - 320)));
}

.theme-modal.cart-modal .modal-dialog .modal-content .modal-body .product-upsell h4 span {
    color: #777;
    text-decoration: line-through;
    margin-left: 10%;
}

.theme-modal#exampleModal .modal-body {
    padding: 0;
}

.theme-modal#exampleModal .btn-close span {
    color: #042c49;
    font-size: 35px;
    text-shadow: none;
}

.theme-modal#exampleModal .modal-bg {
    background-color: #ffffff96 !important;
    padding: 0;
}

.theme-modal#exampleModal .close {
    position: absolute;
    right: 10px;
}

.theme-modal#exampleModal .newslatter-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(239, 159, 119, 0.05);
}

.theme-modal#exampleModal .newslatter-main>div {
    width: 100%;
    height: 400px;
}

.theme-modal#exampleModal .newslatter-main>div:last-child {
    background-repeat: no-repeat;
}

.theme-modal#exampleModal .offer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.theme-modal#exampleModal .offer-content>div {
    width: 100%;
    padding: 50px;
}

.theme-modal#exampleModal .offer-content p {
    text-align: center;
    color: #777777;
    line-height: 1.6;
    margin-bottom: 20px;
}

.theme-modal.exit-modal .modal-body {
    background-size: cover !important;
    background-position: center !important;
}

.theme-modal.exit-modal .modal-bg {
    background-color: #ffffffb3 !important;
}

.theme-modal.exit-modal .modal-content {
    border-radius: 10px;
}

.theme-modal.exit-modal .media .media-body h4 {
    font-size: 16px;
    line-height: 1.2;
    color: #333333;
    font-weight: 500;
}

.theme-modal.exit-modal .media .media-body h4 b {
    color: #042c49;
}

.theme-modal.exit-modal .media .media-body h5 {
    font-size: 18px;
    color: #bfbfbf;
    margin-bottom: 0;
    padding-bottom: 0;
}

.theme-modal.pro-edit-modal {
    z-index: 11111;
}

.theme-modal.pro-edit-modal .modal-dialog {
    width: 350px;
}

.theme-modal.pro-edit-modal .modal-dialog .modal-content .modal-body {
    padding: 20px;
}

.theme-modal.pro-edit-modal .modal-dialog .modal-content .modal-body .btn-close {
    position: absolute;
    top: 5px;
    right: 15px;
}

.theme-modal.pro-edit-modal .pro-group {
    text-align: center;
    margin-bottom: 15px;
}

.theme-modal.pro-edit-modal .pro-group .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.theme-modal.pro-edit-modal .pro-group .media .img-wraper img {
    width: auto;
    height: 150px;
}

.theme-modal.pro-edit-modal .pro-group .media .media-body {
    text-align: left;
}

.theme-modal.pro-edit-modal .pro-group .media .media-body h3 {
    color: #444444;
    /*text-transform: capitalize;*/
    font-size: 18px;
}

.theme-modal.pro-edit-modal .pro-group .media .media-body h6 {
    color: #042c49;
    font-size: 16px;
    font-weight: 700;
}

.theme-modal.pro-edit-modal .pro-group .media .media-body h6 span {
    color: #999999;
    text-decoration: line-through;
    margin-left: 5px;
}

.theme-modal.pro-edit-modal .pro-group h6.product-title {
    font-size: 16px;
    font-weight: 700;
    /*text-transform: capitalize;*/
    color: #444444;
    margin-bottom: 10px;
    text-align: center;
}

.theme-modal.pro-edit-modal .pro-group .qty-box {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.theme-modal.pro-edit-modal .pro-group .modal-btn .btn {
    /*text-transform: capitalize;*/
}

.theme-modal.pro-edit-modal .pro-group .modal-btn .btn:nth-child(n+2) {
    margin-left: 5px;
}

.theme-modal.blackfriday-modal {
    font-family: "Rubik", sans-serif;
}

.theme-modal.blackfriday-modal#exampleModal .btn-close {
    opacity: 1;
    z-index: 3;
    right: 15px;
    top: 10px;
    -webkit-filter: invert(1);
    filter: invert(1);
    position: absolute;
}

.theme-modal.blackfriday-modal#exampleModal .btn-close span {
    color: #ffffff;
    font-size: 30px;
}

.theme-modal.blackfriday-modal#exampleModal .modal-dialog {
    max-width: 800px;
}

.theme-modal.blackfriday-modal#exampleModal .modal-body {
    height: 460px;
    overflow: hidden;
    position: relative;
    background-color: #000000;
}

.theme-modal.blackfriday-modal#exampleModal .rainbow {
    width: 100%;
    height: 100%;
    -webkit-animation: o-rotate-360 linear 12s infinite;
    animation: o-rotate-360 linear 12s infinite;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.theme-modal.blackfriday-modal#exampleModal .rainbow span {
    display: block;
    width: 100%;
    height: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.theme-modal.blackfriday-modal#exampleModal .rainbow span:after {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 100%;
}

.theme-modal.blackfriday-modal#exampleModal .rainbow span:first-child {
    background-color: #ef9f77;
}

.theme-modal.blackfriday-modal#exampleModal .rainbow span:last-child:after {
    background-color: #ef9f77;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content {
    background-color: #000000;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: auto;
    width: calc(100% - 5px);
    height: calc(100% - 5px);
}

.theme-modal.blackfriday-modal#exampleModal .offer-content>div {
    padding: calc(10px + (50 - 10) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content h2 {
    font-size: calc(50px + (100 - 50) * ((100vw - 320px) / (1920 - 320)));
    color: #ffffff;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 10px;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content h2 span {
    font-size: 30%;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content .lable {
    background-color: #ef9f77;
    color: #ffffff;
    text-transform: uppercase;
    height: 40px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    padding-left: 20px;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content .lable:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #000000;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content .lable:before {
    content: "";
    position: absolute;
    right: -20px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #ef9f77;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content h3,
.theme-modal.blackfriday-modal#exampleModal .offer-content h4 {
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content h3 {
    font-size: calc(20px + (60 - 20) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
    margin-bottom: 10px;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content .timer p {
    margin-bottom: 15px;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content .timer span {
    background-color: rgba(239, 159, 119, 0.3);
    color: #ffffff;
    border-radius: 5px;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content .timer .timer-cal {
    background-color: transparent;
    color: #ffffff;
}

.theme-modal.blackfriday-modal#exampleModal .offer-content h4 {
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.theme-modal.cyber-monday#exampleModal .btn-close {
    opacity: 1;
    font-weight: 400;
    position: absolute;
    top: 15px;
    right: 15px;
}

.theme-modal.cyber-monday#exampleModal .btn-close span {
    color: #042c49;
}

.theme-modal.cyber-monday#exampleModal .offer-content {
    text-align: center;
    font-family: "Roboto", sans-serif;
}

.theme-modal.cyber-monday#exampleModal .offer-content>div {
    padding: calc(15px + (50 - 15) * ((100vw - 320px) / (1920 - 320)));
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.theme-modal.cyber-monday#exampleModal .offer-content h6 {
    /*text-transform: capitalize;*/
    color: #042c49;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    margin-bottom: 10px;
}

.theme-modal.cyber-monday#exampleModal .offer-content h2 {
    text-transform: uppercase;
    font-size: calc(50px + (95 - 50) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 900;
    line-height: 1;
    margin-bottom: 10px;
    color: #444444;
    font-style: italic;
}

.theme-modal.cyber-monday#exampleModal .offer-content h2 span {
    display: block;
}

.theme-modal.cyber-monday#exampleModal .offer-content h3 {
    /*text-transform: capitalize;*/
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    margin-bottom: 20px;
    color: #444444;
}

.theme-modal.cyber-monday#exampleModal .offer-content .btn {
    font-size: 14px;
    padding: 10px 15px;
}

.modal-v-sec.fade .modal-content .close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: 700;
    z-index: 9;
    color: #ffffff;
    opacity: 1;
}

.modal-v-sec.fade .modal-content iframe {
    width: 100%;
    height: calc(250px + (400 - 250) * ((100vw - 320px) / (1920 - 320)));
    border: transparent;
}

.modal-v-sec.fade .modal-dialog {
    max-width: 700px;
}

/*=====================
  30. Loader css start
==========================*/
.loader-wrapper {
    background-color: #ffffff;
    margin: 0;
    height: 100vh;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
}

.loader-wrapper img {
    max-height: 130px;
}

/*=====================
 31. masonory-banner css start
==========================*/
.masonory-banner .masonary-banner-main {
    position: relative;
}

.masonory-banner .masonary-banner-main .masonary-banner-contant {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    text-align: center;
    padding: 50px;
}

.masonory-banner .masonary-banner-main .masonary-banner-contant h2 {
    color: #ef9f77;
    text-transform: uppercase;
    margin-bottom: 15px;
    font-weight: 700;
}

.masonory-banner .masonary-banner-main .masonary-banner-contant h5 {
    color: #ef9f77;
    text-transform: uppercase;
    letter-spacing: 7px;
    background-color: #ffffff;
    padding: 10px 20px;
    font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.masonory-banner .masonary-banner-main .masonary-banner-contant .btn-rounded {
    text-transform: uppercase;
    margin-top: 25px;
}

.masonory-banner .masonary-banner-main .masonary-banner-contant .btn-rounded:hover {
    background-color: #ef9f77;
}

.masonory-banner .masonary-banner-main .masonary-banner-contant.p-top {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.masonory-banner .masonary-banner-main .masonary-banner-contant.py-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.masonory-banner .masonary-banner-main .masonary-banner-contant.p-bottom {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.masonory-banner .masonary-banner-main .masonary-banner-contant.p-left {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.masonory-banner .masonary-banner-main .masonary-banner-contant.p-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.masonory-banner .masonary-banner-main .masonary-banner-contant.p-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.masonory-banner .masonary-banner-main .masonory-banner-img {
    height: 450px;
}

.masonory-banner .masonary-banner-main .masonory-banner-img.masonory-img1 {
    height: 915px;
}

.masonory-banner .masonary-banner-main.banner-style1 .masonary-banner-contant h2 {
    color: #ffffff;
}

.masonary-banner-block>div:first-child {
    margin-bottom: 15px;
}

.masonory-p1>div:nth-child(n+2) {
    padding-left: unset;
}

.masonory-p1>div:nth-child(n+2)>div>div:nth-child(n+2) {
    margin-top: 15px;
}

.masonory-p1>div:last-child>div>div>div>div:nth-child(n+2) {
    padding-left: unset;
}

/*========================
 32. sale-banenr css start
==========================*/
.sale-banenr .sale-banenr-contain {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: calc(30px + (150 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(30px + (150 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.sale-banenr .sale-banenr-contain.p-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.sale-banenr .sale-banenr-contain.p-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.sale-banenr .sale-banenr-contain h4 {
    font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #ef9f77;
    font-weight: 400;
    font-family: "Courgette", cursive;
    /*text-transform: capitalize;*/
    margin-bottom: 18px;
}

.sale-banenr .sale-banenr-contain h2 {
    font-size: calc(30px + (81 - 30) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 11px;
}

.sale-banenr .sale-banenr-contain h3 {
    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 0.05em;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    /*text-transform: capitalize;*/
    margin-bottom: 20px;
}

.sale-banenr.banner-style1 h4 {
    font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: 10px;
}

.sale-banenr.banner-style1 h2 {
    font-size: calc(40px + (90 - 40) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    font-family: "Balsamiq Sans", cursive;
    /*text-transform: capitalize;*/
    line-height: 1;
    margin-bottom: 25px;
}

.sale-banenr.banner-style1 h3 {
    font-size: calc(16px + (25 - 16) * ((100vw - 320px) / (1920 - 320)));
    letter-spacing: 1px;
    font-weight: 600;
}

.sale-banenr.banner-style2 h4 {
    font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #ef9f77;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-bottom: 14px;
}

.sale-banenr.banner-style2 h2 {
    font-size: calc(28px + (65 - 28) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-family: "Days One", sans-serif;
    font-weight: 500;
    /*text-transform: capitalize;*/
    margin-bottom: 15px;
}

.sale-banenr.banner-style2 h3 {
    font-size: calc(16px + (30 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: #ef9f77;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}

/*========================
 33. video-banenr css start
==========================*/
.video-banner {
    position: relative;
}

.video-banner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.video-banner .video-banner-contain {
    height: 550px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

.video-banner .video-banner-contain a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
    height: calc(35px + (50 - 35) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ef9f77;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
}

.video-banner .video-banner-contain a i {
    font-size: calc(14px + (22 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #ffffff;
}

.video-banner .video-banner-contain h3 {
    /*text-transform: capitalize;*/
    color: #ffffff;
    font-size: calc(18px + (26 - 18) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 30px;
}

.video-banner .video-banner-contain h2 {
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 700;
    font-family: "Aclonica", sans-serif;
    font-size: calc(22px + (60 - 22) * ((100vw - 320px) / (1920 - 320)));
    margin-top: 25px;
}

/*========================
 34. our-gallery css start
==========================*/
.gallery-block>div {
    padding-right: 0;
}

.gallery-block>div:nth-child(n+2) {
    margin-top: 15px;
}

.our-gallery .gallery-box {
    position: relative;
    margin-right: 15px;
}

.our-gallery .gallery-box .gallery-contain {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(199, 109, 98, 0.5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden;
}

.our-gallery .gallery-box .gallery-contain i {
    font-size: calc(30px + (60 - 30) * ((100vw - 320px) / (1920 - 320)));
    color: #ffffff;
    opacity: 0.8;
}

.our-gallery .gallery-box:hover .gallery-contain {
    visibility: visible;
    opacity: 1;
}

/*========================
 35. our team css start
==========================*/
.team1 .team-box {
    background-color: #ffffff;
    margin-right: 15px;
}

.team1 .team-box .img-wrraper img {
    width: 100%;
}

.team1 .team-box .team-detail {
    padding: 20px;
}

.team1 .team-box .team-detail h3 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    margin-bottom: 8px;
}

.team1 .team-box .team-detail h5 {
    /*text-transform: capitalize;*/
    color: #000000;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.team1 .team-box .team-detail p {
    line-height: 1.6;
    color: #444444;
    margin-bottom: 15px;
}

.team1 .team-box .team-detail ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.team1 .team-box .team-detail ul li {
    width: calc(28px + (30 - 28) * ((100vw - 320px) / (1920 - 320)));
    height: calc(28px + (30 - 28) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.team1 .team-box .team-detail ul li:hover {
    background-color: #ef9f77;
}

.team1 .team-box .team-detail ul li a i {
    color: #ffffff;
    font-size: 90%;
}

.team1 .team-box .team-detail ul li:nth-child(n+2) {
    margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.team2 .team-box {
    background-color: #ffffff;
    margin-right: 15px;
}

.team2 .team-box .img-wrraper img {
    width: 100%;
}

.team2 .team-box .team-detail {
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
    border-bottom: 2px solid #042c49;
}

.team2 .team-box .team-detail h3 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    margin-bottom: 5px;
}

.team2 .team-box .team-detail h5 {
    /*text-transform: capitalize;*/
    color: #000000;
    margin-bottom: 10px;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.team2 .team-box .team-detail ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.team2 .team-box .team-detail ul li {
    width: calc(28px + (30 - 28) * ((100vw - 320px) / (1920 - 320)));
    height: calc(28px + (30 - 28) * ((100vw - 320px) / (1920 - 320)));
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.team2 .team-box .team-detail ul li:hover {
    background-color: #ef9f77;
}

.team2 .team-box .team-detail ul li a i {
    color: #ffffff;
    font-size: 90%;
    line-height: 1.8;
}

.team2 .team-box .team-detail ul li:nth-child(n+2) {
    margin-left: 5px;
}

/*========================
 36. counterbanner css start
==========================*/
.counter-banner {
    background-color: #042c49;
    padding-bottom: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-top: calc(30px + (100 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.counter-banner .counter-box {
    text-align: center;
    position: relative;
    z-index: 1;
}

.counter-banner .counter-box svg {
    width: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
    height: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
    fill: #ffffff;
    margin-bottom: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.counter-banner .counter-box h1 {
    color: #ffffff;
    font-size: calc(18px + (45 - 18) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
    font-family: "Aclonica", sans-serif;
}

.counter-banner .counter-box h1 span {
    margin-right: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.counter-banner .counter-box h3 {
    color: #ffffff;
    /*text-transform: capitalize;*/
    font-weight: 600;
    font-size: calc(14px + (24 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.counter-banner.counter-layer {
    position: relative;
}

.counter-banner.counter-layer:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.counter-banner.banner-style1 .counter-box h1 {
    color: #042c49;
}

.counter-banner.banner-style1 .counter-box h3 {
    color: #ef9f77;
}

/*============================
 37. pricing table css start
=============================*/
.price-block>div {
    padding-left: 0;
    padding-right: 0;
}

.pricing-table1 .pricing-box {
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: #ffffff;
    margin-right: 15px;
}

.pricing-table1 .pricing-box .pricing-header {
    background-color: #042c49;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));
    text-align: center;
}

.pricing-table1 .pricing-box .pricing-header h2 {
    text-transform: uppercase;
    font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
    color: #ffffff;
    font-weight: 700;
    line-height: 1;
}

.pricing-table1 .pricing-box .pricing-body {
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.pricing-table1 .pricing-box .pricing-body h1 {
    text-transform: uppercase;
    color: #042c49;
    text-align: center;
    font-family: "Aclonica", sans-serif;
    font-size: calc(30px + (55 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: rgba(199, 109, 98, 0.1);
}

.pricing-table1 .pricing-box .pricing-body h1 sub {
    font-size: 28%;
}

.pricing-table1 .pricing-box .pricing-body ul {
    margin-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.pricing-table1 .pricing-box .pricing-body ul li {
    display: block;
    padding-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    padding-left: 25px;
    position: relative;
    border-bottom: 1px solid #dddddd;
}

.pricing-table1 .pricing-box .pricing-body ul li::before {
    content: "\f00c";
    position: absolute;
    top: 0;
    left: 0;
    font-family: FontAwesome;
    color: #042c49;
}

.pricing-table1 .pricing-box .pricing-body ul li a {
    color: #444444;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    line-height: 1;
}

.pricing-table1 .pricing-box .pricing-body ul li:last-child {
    margin-bottom: unset;
}

.pricing-table1 .pricing-box .pricing-body .btn {
    margin-top: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
}

.pricing-table2 .pricing-box {
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: #ffffff;
    margin-right: 15px;
}

.pricing-table2 .pricing-box .pricing-header {
    background-color: #042c49;
    text-align: center;
    padding-top: calc(15px + (35 - 15) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(15px + (35 - 15) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    z-index: 1;
}

.pricing-table2 .pricing-box .pricing-header:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

.pricing-table2 .pricing-box .pricing-header h3 {
    color: #ffffff;
    font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
}

.pricing-table2 .pricing-box .pricing-header h2 {
    text-transform: uppercase;
    font-size: calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)));
    color: #ffffff;
    font-weight: 700;
    line-height: 1;
    font-weight: 700;
    font-family: "Aclonica", sans-serif;
}

.pricing-table2 .pricing-box .pricing-body {
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.pricing-table2 .pricing-box .pricing-body ul li {
    display: block;
    padding-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    padding-left: 25px;
    position: relative;
    border-bottom: 1px solid #dddddd;
}

.pricing-table2 .pricing-box .pricing-body ul li::before {
    content: "\f00c";
    position: absolute;
    top: 0;
    left: 0;
    font-family: FontAwesome;
    color: #042c49;
}

.pricing-table2 .pricing-box .pricing-body ul li a {
    color: #444444;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 800;
    line-height: 1;
}

.pricing-table2 .pricing-box .pricing-body ul li:last-child {
    margin-bottom: unset;
}

.pricing-table2 .pricing-box .pricing-body .btn {
    margin-top: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
}

.pricing-table3 .pricing-box {
    border: 1px solid #dddddd;
    border-radius: 5px;
    background-color: #ffffff;
    margin-right: 15px;
    margin-left: 15px;
}

.pricing-table3 .pricing-box .pricing-header {
    text-align: center;
    padding: calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ef9f77;
}

.pricing-table3 .pricing-box .pricing-header svg {
    width: calc(40px + (60 - 40) * ((100vw - 320px) / (1920 - 320)));
    fill: rgba(255, 255, 255, 0.7);
    margin-bottom: 5px;
}

.pricing-table3 .pricing-box .pricing-header h2 {
    text-transform: uppercase;
    font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: rgba(255, 255, 255, 0.7);
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.pricing-table3 .pricing-box .pricing-header h4 {
    color: #000000;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
}

.pricing-table3 .pricing-box .pricing-body {
    padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.pricing-table3 .pricing-box .pricing-body h3 {
    text-align: center;
    color: #ef9f77;
    line-height: 1;
    font-size: calc(30px + (40 - 30) * ((100vw - 320px) / (1920 - 320)));
}

.pricing-table3 .pricing-box .pricing-body h3 span {
    font-size: 42%;
}

.pricing-table3 .pricing-box .pricing-body h4 {
    text-align: center;
    color: #000000;
    /*text-transform: capitalize;*/
}

.pricing-table3 .pricing-box .pricing-body ul {
    padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
}

.pricing-table3 .pricing-box .pricing-body ul li {
    display: block;
    padding-top: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(8px + (10 - 8) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
    text-align: center;
    line-height: 1;
}

.pricing-table3 .pricing-box .pricing-body ul li:nth-child(odd) {
    background-color: rgba(241, 241, 241, 0.5);
}

.pricing-table3 .pricing-box .pricing-body ul li a {
    color: #444444;
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 500;
    line-height: 1;
}

.pricing-table3 .pricing-box .pricing-body ul li:last-child {
    margin-bottom: unset;
}

.pricing-table3 .pricing-box .pricing-body .btn {
    margin-top: calc(18px + (25 - 18) * ((100vw - 320px) / (1920 - 320)));
    text-transform: uppercase;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-color: #ef9f77;
}

.pricing-table3 .pricing-box .pricing-body .btn:hover {
    background-color: #ef9f77;
}

/*============================
 38. app donload  css start
=============================*/
.app-download .app-download-main .app-download-contian {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    height: calc(200px + (500 - 200) * ((100vw - 320px) / (1920 - 320)));
}

.app-download .app-download-main .app-download-contian h2 {
    font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1920 - 320)));
    color: #042c49;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    margin-bottom: 14px;
}

.app-download .app-download-main .app-download-contian h3 {
    font-size: calc(14px + (27 - 14) * ((100vw - 320px) / (1920 - 320)));
    color: #444444;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    /*text-transform: capitalize;*/
    margin-bottom: 23px;
}

.app-download .app-download-main .app-download-contian .btn {
    text-transform: uppercase;
}

/*============================
 39. key-feature  css start
=============================*/
.key-block>div {
    padding-left: 8px;
    padding-right: 8px;
}

.key-block>div:nth-child(n+7) {
    margin-top: 15px;
}

.key-feature .key-feature-contain {
    border-radius: 5px;
    overflow: hidden;
    margin-right: 15px;
}

/*===========
 40. invoice
=============*/
.invoice-popup {
    position: relative;
    background-color: #ffffff;
    min-height: 680px;
    padding: 30px;
}

table.invoice-table {
    width: 100%;
    border-collapse: collapse;
}

table.invoice-table td {
    padding: 15px;
    font-weight: 700;
}

table.invoice-table td h3 {
    font-weight: 700;
    font-size: 16px;
}

table.invoice-table th {
    padding: 15px;
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
}

.print-bar {
    margin-top: 30px;
}

.print-bar button {
    line-height: 1;
    border: none;
    background-color: rgba(199, 109, 98, 0.8);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.print-bar button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    outline: none;
}

.print-bar button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    outline: none;
}

.print-bar button:hover {
    background-color: #042c49;
}

.print-bar .printbar-left button {
    background-color: rgba(199, 109, 98, 0.5);
}

.print-bar .printbar-left button:hover {
    background-color: #042c49;
}

.print-bar .printbar-right {
    text-align: right;
}

.theme-invoice {
    padding-top: 30px;
    padding-bottom: 30px;
}

.theme-invoice .invoice-popup .invoice-header {
    padding-bottom: 30px;
}

.theme-invoice .invoice-popup .invoice-header .header-right {
    float: right;
    margin-bottom: 5px;
}

.theme-invoice .invoice-popup .invoice-header .header-right ul li {
    display: block;
}

.theme-invoice .invoice-popup .invoice-header .header-right ul li h2 {
    color: #042c49;
    font-weight: 700;
    font-size: 24px;
}

.theme-invoice .invoice-popup .invoice-breadcrumb {
    margin-bottom: 30px;
    padding: 20px;
    background-color: rgba(199, 109, 98, 0.05);
}

.theme-invoice .invoice-popup .invoice-breadcrumb ul li {
    display: block;
    color: #042c49;
    font-weight: 600;
}

.theme-invoice .invoice-popup .invoice-breadcrumb ul li span {
    color: #444444;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 400;
}

.theme-invoice .invoice-popup .invoice-breadcrumb .breadcrumb-right {
    float: right;
}

.theme-invoice .invoice-popup table.invoice-table td h3 {
    color: #000000;
    /*text-transform: capitalize;*/
}

.theme-invoice .invoice-popup table.invoice-table th {
    background-color: rgba(238, 238, 238, 0.3);
    color: #444444;
    text-transform: uppercase;
}

.theme-invoice .invoice-popup table.invoice-table tbody tr td {
    border: 2px solid #ffffff;
    background-color: rgba(238, 238, 238, 0.3);
}

.theme-invoice .invoice-popup table.invoice-table tfoot tr:first-child td {
    padding-bottom: 0;
    padding-top: 30px;
}

.theme-invoice .invoice-popup table.invoice-table tfoot tr:last-child td:nth-child(n+2) {
    background-color: rgba(199, 109, 98, 0.1);
    color: #042c49;
}

.theme-invoice .invoice-popup .print-bar {
    margin-top: 30px;
}

.invoice-second {
    padding-top: 30px;
    padding-bottom: 30px;
}

.invoice-second .invoice-popup .invoice-header {
    border-bottom: 2px solid rgba(199, 109, 98, 0.5);
    padding-bottom: 20px;
}

.invoice-second .invoice-popup .invoice-header .header-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.invoice-second .invoice-popup .invoice-header .header-right ul {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.invoice-second .invoice-popup .invoice-header .header-right ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid rgba(199, 109, 98, 0.5);
    padding-right: 10px;
}

.invoice-second .invoice-popup .invoice-header .header-right ul li:nth-child(n+2) {
    margin-top: 5px;
}

.invoice-second .invoice-popup .invoice-header .header-right ul li span {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(199, 109, 98, 0.5);
    margin-right: 10px;
}

.invoice-second .invoice-popup .invoice-header .header-right ul li span i {
    color: #ffffff;
    font-size: 20px;
}

.invoice-second .invoice-popup .invoice-breadcrumb {
    padding-top: 20px;
    padding-bottom: 20px;
}

.invoice-second .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li {
    display: block;
}

.invoice-second .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li h5 {
    color: #444444;
    text-transform: uppercase;
}

.invoice-second .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li h3 {
    color: #042c49;
    text-transform: uppercase;
    font-size: 22px;
}

.invoice-second .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li h4 {
    font-size: 14px;
    color: #444444;
    font-weight: 400;
    margin-top: 7px;
}

.invoice-second .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li h4 span {
    font-weight: 600;
}

.invoice-second .invoice-popup .invoice-breadcrumb .breadcrumb-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.invoice-second .invoice-popup .invoice-breadcrumb .breadcrumb-right ul li {
    display: block;
}

.invoice-second .invoice-popup .invoice-breadcrumb .breadcrumb-right ul li h4 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 7px;
}

.invoice-second .invoice-popup .invoice-breadcrumb .breadcrumb-right ul li h4 span {
    color: #042c49;
    font-weight: 700;
    font-size: 16px;
    padding-right: 3px;
}

.invoice-second .invoice-popup table.invoice-table td {
    font-weight: 700;
}

.invoice-second .invoice-popup table.invoice-table td h3 {
    color: #000000;
}

.invoice-second .invoice-popup table.invoice-table th {
    background-color: rgba(199, 109, 98, 0.8);
    color: #ffffff;
    text-transform: uppercase;
}

.invoice-second .invoice-popup table.invoice-table tbody tr td {
    border: 2px solid #ffffff;
    background-color: rgba(199, 109, 98, 0.05);
    /*text-transform: capitalize;*/
}

.invoice-second .invoice-popup table.invoice-table tfoot tr:first-child td {
    padding-bottom: 0;
    padding-top: 30px;
}

.invoice-second .invoice-popup table.invoice-table tfoot tr:last-child td:nth-child(n+2) {
    background-color: rgba(199, 109, 98, 0.8);
    color: #ffffff;
}

.invoice-third {
    padding-top: 30px;
    padding-bottom: 30px;
}

.invoice-third .invoice-popup {
    padding: 0;
}

.invoice-third .invoice-popup .invoice-header {
    padding: 30px;
    background-color: rgba(199, 109, 98, 0.05);
}

.invoice-third .invoice-popup .invoice-header .header-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.invoice-third .invoice-popup .invoice-header .header-right ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.invoice-third .invoice-popup .invoice-header .header-right ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 2px solid #042c49;
}

.invoice-third .invoice-popup .invoice-header .header-right ul li:nth-child(n+2) {
    margin-left: 15px;
}

.invoice-third .invoice-popup .invoice-header .header-right ul li span {
    width: 45px;
    height: 45px;
    background-color: #042c49;
    display: inline-grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 10px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 15px;
}

.invoice-third .invoice-popup .invoice-header .header-right ul li span i {
    font-size: 18px;
    color: #ffffff;
}

.invoice-third .invoice-popup .invoice-header .header-right ul li h6 {
    font-size: 14px;
}

.invoice-third .invoice-popup .invoice-breadcrumb {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-top: 30px;
}

.invoice-third .invoice-popup .invoice-breadcrumb ul li {
    display: block;
}

.invoice-third .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li {
    position: relative;
    font-size: 16px;
    font-weight: 500;
}

.invoice-third .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li h2 {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 20px;
    color: #042c49;
}

.invoice-third .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li:nth-child(n+2) {
    margin-top: 5px;
    padding-left: 25px;
}

.invoice-third .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li svg {
    width: 16px;
    fill: #042c49;
    position: absolute;
    top: 4px;
    left: 0;
}

.invoice-third .invoice-popup .invoice-breadcrumb .breadcrumb-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.invoice-third .invoice-popup .invoice-breadcrumb .breadcrumb-right ul li {
    font-size: 14px;
}

.invoice-third .invoice-popup .invoice-breadcrumb .breadcrumb-right ul li span {
    color: #042c49;
    font-weight: 700;
    font-size: 16px;
}

.invoice-third .invoice-popup .table-responsive-md {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}

.invoice-third .invoice-popup .table-responsive-md table.invoice-table td {
    font-weight: 600;
}

.invoice-third .invoice-popup .table-responsive-md table.invoice-table td h3 {
    color: #000000;
}

.invoice-third .invoice-popup .table-responsive-md table.invoice-table th {
    background-color: #eeeeee;
    text-transform: uppercase;
}

.invoice-third .invoice-popup .table-responsive-md table.invoice-table tbody td {
    border: 1px solid #eeeeee;
    /*text-transform: capitalize;*/
}

.invoice-third .invoice-popup .table-responsive-md table.invoice-table tfoot tr:last-child td:nth-child(n+2) {
    background-color: rgba(199, 109, 98, 0.8);
    color: #ffffff;
}

.invoice-four {
    padding-top: 30px;
    padding-bottom: 30px;
}

.invoice-four .invoice-popup .invoice-header .header-left .brand-logo {
    margin-bottom: 10px;
}

.invoice-four .invoice-popup .invoice-header .header-left .invoice-to ul li {
    display: block;
}

.invoice-four .invoice-popup .invoice-header .header-left .invoice-to ul li h2 {
    /*text-transform: capitalize;*/
    color: #444444;
}

.invoice-four .invoice-popup .invoice-header .header-left .invoice-to ul li h3 {
    font-size: 20px;
    text-transform: uppercase;
    color: #042c49;
}

.invoice-four .invoice-popup .invoice-header .header-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.invoice-four .invoice-popup .invoice-header .header-right ul {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.invoice-four .invoice-popup .invoice-header .header-right ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid rgba(199, 109, 98, 0.5);
    padding-right: 10px;
}

.invoice-four .invoice-popup .invoice-header .header-right ul li:nth-child(n+2) {
    margin-top: 5px;
}

.invoice-four .invoice-popup .invoice-header .header-right ul li span {
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(199, 109, 98, 0.5);
    margin-right: 10px;
}

.invoice-four .invoice-popup .invoice-header .header-right ul li span i {
    color: #ffffff;
    font-size: 20px;
}

.invoice-four .invoice-popup .invoice-header .header-contain {
    background-color: rgba(199, 109, 98, 0.08);
}

.invoice-four .invoice-popup .invoice-header .header-contain .invoice-text {
    font-size: 45px;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    background-color: #ffffff;
    color: rgba(199, 109, 98, 0.5);
    margin-right: 120px;
    margin-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
}

.invoice-four .invoice-popup .invoice-breadcrumb {
    margin-top: 30px;
}

.invoice-four .invoice-popup .invoice-breadcrumb .invoice-contain ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.invoice-four .invoice-popup .invoice-breadcrumb .invoice-contain ul li {
    width: 25%;
    /*text-transform: capitalize;*/
    font-weight: 600;
    border: 1px solid rgba(238, 238, 238, 0.8);
}

.invoice-four .invoice-popup .invoice-breadcrumb .invoice-contain ul li h6 {
    background-color: rgba(238, 238, 238, 0.8);
    font-size: 16px;
    font-weight: 700;
}

.invoice-four .invoice-popup .invoice-breadcrumb .invoice-contain ul li h6,
.invoice-four .invoice-popup .invoice-breadcrumb .invoice-contain ul li h5 {
    padding: 12px;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-table {
    margin-top: 30px;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-table th {
    background-color: rgba(199, 109, 98, 0.8);
    color: #ffffff;
    text-transform: uppercase;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-table td {
    border: 1px solid rgba(199, 109, 98, 0.8);
}

.invoice-four .invoice-popup .table-responsive-md .invoice-table td h3 {
    /*text-transform: capitalize;*/
}

.invoice-four .invoice-popup .table-responsive-md .invoice-table p {
    font-weight: 200;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-total {
    margin-top: 30px;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-total ul li {
    display: block;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-total .total-left ul li h4 {
    text-transform: uppercase;
    color: #042c49;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-total .total-left ul li h3 {
    font-size: 16px;
    color: #444444;
    /*text-transform: capitalize;*/
}

.invoice-four .invoice-popup .table-responsive-md .invoice-total .total-right ul li {
    color: #444444;
    font-weight: 700;
    /*text-transform: capitalize;*/
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-total .total-right ul li span {
    float: right;
    font-weight: 400;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-total .total-right ul li:nth-child(2) {
    border-top: 1px solid #eeeeee;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-total .total-right ul li:last-child {
    background-color: rgba(199, 109, 98, 0.8);
    color: #ffffff;
    font-weight: 700;
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.invoice-four .invoice-popup .table-responsive-md .invoice-total .total-right ul li:last-child span {
    font-weight: 700;
    text-transform: uppercase;
}

.invoice-five {
    padding-top: 30px;
    padding-bottom: 30px;
}

.invoice-five .invoice-popup {
    padding: 0;
    border-top: 30px solid rgba(199, 109, 98, 0.8);
    border-bottom: 30px solid rgba(199, 109, 98, 0.8);
}

.invoice-five .invoice-popup .invoice-header {
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 5px solid rgba(199, 109, 98, 0.8);
}

.invoice-five .invoice-popup .invoice-header .header-left .brand-logo {
    margin-bottom: 10px;
}

.invoice-five .invoice-popup .invoice-header .header-left .invoice-to ul li {
    display: block;
}

.invoice-five .invoice-popup .invoice-header .header-left .invoice-to ul li h3 {
    font-size: 20px;
    text-transform: uppercase;
    color: #042c49;
}

.invoice-five .invoice-popup .invoice-header .header-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.invoice-five .invoice-popup .invoice-header .header-right ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.invoice-five .invoice-popup .invoice-header .header-right ul li span {
    width: 40px;
    height: 40px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(199, 109, 98, 0.8);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 10px;
}

.invoice-five .invoice-popup .invoice-header .header-right ul li span i {
    font-size: 18px;
    color: #ffffff;
}

.invoice-five .invoice-popup .invoice-header .header-right ul li:nth-child(n+2) {
    margin-top: 5px;
}

.invoice-five .invoice-popup .invoice-breadcrumb {
    padding-left: 30px;
    padding-right: 30px;
}

.invoice-five .invoice-popup .invoice-breadcrumb ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.invoice-five .invoice-popup .invoice-breadcrumb ul li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: rgba(199, 109, 98, 0.08);
    padding: 30px;
    width: 25%;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.invoice-five .invoice-popup .invoice-breadcrumb ul li h6 {
    /*text-transform: capitalize;*/
}

.invoice-five .invoice-popup .invoice-breadcrumb ul li h5 {
    color: #042c49;
    margin-top: 5px;
}

.invoice-five .invoice-popup .invoice-breadcrumb ul li:nth-child(n+2) {
    margin-left: 15px;
}

.invoice-five .invoice-popup .table-responsive-md {
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.invoice-five .invoice-popup .table-responsive-md table.invoice-table th {
    background-color: rgba(199, 109, 98, 0.8);
    color: #ffffff;
    text-transform: uppercase;
}

.invoice-five .invoice-popup .table-responsive-md table tbody tr:nth-child(even) {
    background-color: rgba(199, 109, 98, 0.05);
}

.invoice-five .invoice-popup .table-responsive-md table tbody td h3 {
    /*text-transform: capitalize;*/
}

.invoice-five .invoice-popup .table-responsive-md table tfoot tr td:nth-child(n+2) {
    background-color: rgba(199, 109, 98, 0.8);
    color: #ffffff;
}

/**=================
  Ajax Search start
====================**/
.ajax-search.form_search {
    overflow: unset;
}

.ajax-search .tt-menu {
    display: block;
    width: 100%;
    background-color: #fff;
    border: unset !important;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    max-height: 350px;
    overflow: auto;
    top: 55px !important;
    border-radius: 5px;
    padding: 8px 0;
}

.ajax-search .tt-suggestion {
    padding: 0px 14px;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;
}

.ajax-search .man-section {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ajax-search .image-section {
    padding: 5px 0;
}

.ajax-search .image-section img {
    width: 50px;
    -webkit-filter: invert(0) !important;
    filter: invert(0) !important;
}

.ajax-search .description-section {
    width: 80%;
}

.ajax-search .description-section h4 {
    margin: 0px;
    padding: 0px 7px;
    font-size: 17px;
    color: #222222;
    margin-top: 0;
    margin-bottom: 2px;
    /*text-transform: capitalize;*/
}

.ajax-search .description-section span {
    padding: 7px;
    font-size: 16px;
    color: #a09999;
}

/**=====================
    cookiebar css start
==========================**/
.cookie-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: fixed;
    bottom: -100px;
    left: 0;
    width: 100%;
    background-color: #2d2a25;
    padding: 10px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 9;
}

.cookie-bar p {
    margin-bottom: 0;
    color: white;
}

.cookie-bar .btn {
    margin-left: 20px;
}

.cookie-bar.show {
    bottom: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.cookie-bar.left-bottom {
    width: 270px;
    display: block;
    text-align: center;
    left: 30px;
    padding: 35px 25px;
    -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.12), 0 15px 12px rgba(0, 0, 0, 0.13);
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.12), 0 15px 12px rgba(0, 0, 0, 0.13);
    background-color: white;
    bottom: 20px;
    display: none;
    -webkit-animation: fadeInUp 1s;
    animation: fadeInUp 1s;
}

.cookie-bar.left-bottom svg {
    width: 70px;
    margin-bottom: 10px;
}

.cookie-bar.left-bottom p {
    margin-bottom: 14px;
    color: #777;
    line-height: 20px;
}

.cookie-bar.left-bottom .btn {
    margin-left: 5px;
    margin-right: 5px;
}

.cookie-bar.left-bottom .btn-close {
    position: absolute;
    right: 13px;
    top: 7px;
}

.cookie-bar.left-bottom .btn-close i {
    color: #777;
}

.cookie-bar.left-bottom.show {
    display: block;
}

.cookie-bar.right {
    left: unset;
    right: 30px;
}

/**===========================
    order tracking css start
==============================**/
.order-tracking h4.tracking-title {
    /*text-transform: capitalize;*/
    color: #444444;
    margin-bottom: 10px;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
}

.order-tracking .order-tracking-contain .tracking-group {
    padding-bottom: 15px;
}

.order-tracking .order-tracking-contain .tracking-group:nth-child(n+2) {
    border-top: 1px solid #dddddd;
    padding-top: 15px;
}

.order-tracking .order-tracking-contain .tracking-group .delevery-code {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.order-tracking .order-tracking-contain .tracking-group .delevery-code h4 {
    /*text-transform: capitalize;*/
    color: #444444;
    font-weight: 700;
}

.order-tracking .order-tracking-contain .tracking-group .delevery-code .btn {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li {
    padding: 10px;
    border: 1px solid #dddddd;
    width: 100%;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li:nth-child(n+2) {
    margin-top: 15px;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media img {
    height: 200px;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body {
    margin-left: 10px;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body h3 {
    /*text-transform: capitalize;*/
    color: #444444;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    margin-bottom: 5px;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body h4 {
    color: #042c49;
    margin-bottom: 5px;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body h4 span {
    color: #acacac;
    text-decoration: line-through;
    margin-left: 10px;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body h6 {
    margin-bottom: 5px;
    /*text-transform: capitalize;*/
    color: #444444;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body .qty-box {
    margin-bottom: 5px;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .pro-add {
    position: absolute;
    top: 0px;
    right: 0px;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .pro-add a {
    color: #444444;
}

.order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .pro-add a svg {
    width: 18px;
}

.order-tracking .order-tracking-sidebar .coupan-block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dddddd;
}

.order-tracking .order-tracking-sidebar .coupan-block h5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #444444;
}

.order-tracking .order-tracking-sidebar .coupan-block h5 svg {
    width: 18px;
    margin-right: 10px;
}

.order-tracking .order-tracking-sidebar .coupan-block .btn {
    /*text-transform: capitalize;*/
    padding: 7px 18px;
    font-size: 14px;
    font-weight: 400;
}

.order-tracking .order-tracking-sidebar ul li {
    display: block;
    /*text-transform: capitalize;*/
    font-size: 16px;
    line-height: 1;
}

.order-tracking .order-tracking-sidebar ul li:nth-child(n+2) {
    padding-top: 15px;
}

.order-tracking .order-tracking-sidebar ul li span {
    float: right;
}

.order-tracking .order-tracking-sidebar ul li .total {
    line-height: 1;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #dddddd;
    font-weight: 700;
}

.order-tracking .order-tracking-box {
    padding: calc(20px + (30 - 20) * ((100vw - 320px) / (1920 - 320)));
    background-color: #ffffff;
}

.order-tracking .order-tracking-box .btn {
    /*text-transform: capitalize;*/
}

.order-tracking .order-tracking-box .form-control {
    border-color: #dddddd;
    font-size: 14px;
}

.order-tracking .order-tracking-box .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.order-tracking .order-tracking-box .form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.order-tracking .order-tracking-box .form-group .form-control {
    width: 100%;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
    /*text-transform: capitalize;*/
    border-color: #dddddd;
}

.order-tracking .order-tracking-box .form-group .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.order-tracking .order-tracking-box .form-group .form-control:nth-child(n+2) {
    margin-left: 15px;
}

.order-tracking .order-tracking-box .form-group ul .btn {
    padding: 8px 10px;
    font-weight: 400;
}

.order-tracking .order-tracking-box .form-group ul.addresh-locat {
    margin-bottom: 10px;
}

.order-tracking .order-tracking-box .form-group ul.addresh-locat li {
    /*text-transform: capitalize;*/
    font-weight: 700;
}

.order-tracking .order-tracking-box .form-group ul.addresh-locat li:nth-child(n+2) {
    margin-left: 10px;
}

.order-tracking .order-tracking-box .card {
    border-color: rgba(199, 109, 98, 0.1);
}

.order-tracking .order-tracking-box .card:nth-child(n+2) {
    margin-top: 15px;
}

.order-tracking .order-tracking-box .card .card-header {
    padding: 0;
    border-color: rgba(199, 109, 98, 0.1);
}

.order-tracking .order-tracking-box .card .card-header .payment-toggle {
    text-transform: uppercase;
    color: #042c49;
    font-weight: 700;
    text-decoration: none;
    padding: 15px;
    font-size: 14px;
    background-color: rgba(199, 109, 98, 0.1);
    border-radius: 5px;
    width: 100%;
    text-align: left;
    line-height: 1;
}

.order-tracking .order-tracking-box .paymant-collapce {
    padding: 30px;
}

.order-tracking .order-tracking-box .paymant-collapce.show {
    border-bottom: 1px solid rgba(199, 109, 98, 0.1);
}

.order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li>div:nth-child(n+2) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: auto;
}

.order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li:nth-child(n+2) {
    margin-top: 10px;
}

.order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li img {
    width: 45px;
}

.order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li span {
    margin-left: 15px;
    /*text-transform: capitalize;*/
    font-weight: 700;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
}

.order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li .form-control {
    width: 250px;
    border: 1px solid #dddddd;
    border-radius: 5px;
    height: 40px;
    padding-left: 15px;
    padding-right: 15px;
    /*text-transform: capitalize;*/
}

.order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li .form-control:focus {
    border-color: #dddddd;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li .btn {
    margin-left: 15px;
}

.order-tracking .order-tracking-box .paymant-collapce ul.bank-pay {
    margin-bottom: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li {
    display: block;
    /*text-transform: capitalize;*/
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    font-weight: 700;
    text-align: center;
    width: 25%;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 5px;
    background-color: rgba(199, 109, 98, 0.05);
}

.order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li img {
    width: 50px;
}

.order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li span {
    display: block;
    line-height: 1;
    margin-top: 10px;
    color: #444444;
}

.order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li:nth-child(n+2) {
    margin-left: 30px;
}

.order-tracking .order-tracking-box .paymant-collapce select {
    text-transform: uppercase;
}

.order-tracking .order-tracking-box .paymant-collapce .cash-pay .form-control {
    width: 320px;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;
}

.order-tracking .order-tracking-box .paymant-collapce .cash-pay .btn {
    margin-top: 15px;
}

.order-tracking .order-tracking-box .paymant-collapce .cash-pay .errorcaptcha {
    color: #ff0000;
    font-size: 12px;
    display: none;
}

.order-tracking .order-tracking-box .paymant-collapce .cash-pay .successmessage {
    color: #4ead4e;
    font-size: 18px;
    margin-bottom: 15px;
    display: none;
}

.order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 15px;
}

.order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox .captchaimagecode {
    width: 150px;
}

.order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox .captchaimagecode canvas {
    width: 100%;
}

.order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox .reloadbtncapcha {
    margin-left: 10px;
}

.order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox .reloadbtncapcha svg {
    width: 18px;
    fill: #000000;
}

.order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox .reloadbtncapcha:hover {
    -webkit-animation: rotationxy 2s 0.5s alternate ease 1;
    animation: rotationxy 2s 0.5s alternate ease 1;
}

.order-tracking fieldset>.btn {
    margin-top: 20px;
}

.order-tracking #msform fieldset:not(:first-of-type) {
    display: none;
}

.order-tracking #progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    background-color: #fff;
    width: 100%;
    padding: 30px;
}

.order-tracking #progressbar li {
    font-size: 12px;
    position: relative;
    text-align: center;
}

.order-tracking #progressbar li:nth-child(n+2) {
    margin-left: 130px;
}

.order-tracking #progressbar li:last-child {
    z-index: 1;
    width: auto;
}

.order-tracking #progressbar li .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(199, 109, 98, 0.5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    z-index: 2;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.order-tracking #progressbar li .icon svg {
    width: 50%;
    fill: #ffffff;
}

.order-tracking #progressbar li span {
    color: #444444;
    font-weight: 700;
    /*text-transform: capitalize;*/
    font-size: 16px;
}

.order-tracking #progressbar li.active:after,
.order-tracking #progressbar li.active:before {
    background-color: #042c49;
    width: 100%;
    border-radius: 50px;
}

.order-tracking #progressbar li.active .icon {
    background-color: #042c49;
}

.order-tracking #progressbar li.active span {
    color: #042c49;
}

/*=====================
    27.Menu css start
==========================*/
.sm,
.sm ul,
.sm li {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: normal;
    direction: ltr;
    text-align: left;
}

.sm {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: static;
    z-index: 1;
}

.sm ul {
    display: none;
}

.sm li {
    position: relative;
}

.sm>li>h1 {
    margin: 0;
    padding: 0;
}

.sm>li>h2 {
    margin: 0;
    padding: 0;
}

.sm>li>h3 {
    margin: 0;
    padding: 0;
}

.sm>li>h4 {
    margin: 0;
    padding: 0;
}

.sm>li>h5 {
    margin: 0;
    padding: 0;
}

.sm>li>h6 {
    margin: 0;
    padding: 0;
}

.sm a {
    position: relative;
    display: block;
}

.sm a.disabled {
    cursor: default;
}

.sm * {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.sm *::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.sm *::after {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.sm::after {
    content: "";
    display: block;
    height: 0;
    font: 0/0 serif;
    clear: both;
    overflow: hidden;
}

.menu-banner {
    margin-top: 20px;
}

.toggle-nav {
    position: relative;
    cursor: pointer;
}

.toggle-nav i {
    font-size: 24px;
    color: #ce7e75;
}

.mega-menu-banner:hover:before {
    display: none;
}

.new-tag {
    padding-left: 3px;
    color: #042c49;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
}

.up-text {
    position: relative;
}

.up-text span {
    position: absolute;
    top: -5px;
    right: -10px;
    color: #042c49;
    font-size: 12px;
    font-weight: 700;
}

.pixelstrap.sm-vertical a .sub-arrow {
    margin-top: -22px;
    right: 7px;
}

.pixelstrap ul {
    border-radius: 0;
}

.pixelstrap.light-font-menu li>a {
    color: #444444;
}

.pixelstrap a,
.pixelstrap a:hover,
.pixelstrap a:active {
    padding-right: 35px;
    color: #444444;
    font-size: 14px;
    font-weight: 700;
    line-height: 23px;
    text-decoration: none;
    text-transform: uppercase;
    padding-top: 22px;
    padding-bottom: 22px;
    letter-spacing: 0.07em;
}

.pixelstrap a:hover {
    color: #042c49;
}

.pixelstrap a.disabled {
    color: #efd6d3;
}

.pixelstrap a .sub-arrow {
    position: absolute;
    top: 50%;
    margin-top: -17px;
    left: auto;
    right: 14px;
    width: 34px;
    height: 34px;
    overflow: hidden;
    font: bold 16px/34px monospace !important;
    text-align: center;
    text-shadow: none;
    border-radius: 4px;
}

.pixelstrap>li:last-child>a,
.pixelstrap>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul {
    border-radius: 0 0 0 0;
}

.pixelstrap>li:last-child>a.highlighted,
.pixelstrap>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>a.highlighted,
.pixelstrap>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>ul>li:last-child>*:not(ul) a.highlighted {
    border-radius: 0;
}

.pixelstrap ul {
    background: #fff;
}

.pixelstrap ul ul {
    background: rgba(202, 116, 106, 0.04);
}

.pixelstrap ul a,
.pixelstrap ul a:hover,
.pixelstrap ul a:focus,
.pixelstrap ul a:active {
    background: transparent;
    color: #444444 !important;
    font-size: 14px;
    text-shadow: none;
}

.pixelstrap ul a.current {
    background: #042c49;
    color: #fff;
}

.pixelstrap ul a.disabled {
    color: #b3b3b3;
}

.pixelstrap ul ul a,
.pixelstrap ul ul a:hover,
.pixelstrap ul ul a:focus,
.pixelstrap ul ul a:active {
    border-left: 16px solid transparent;
}

.pixelstrap ul ul ul a,
.pixelstrap ul ul ul a:hover,
.pixelstrap ul ul ul a:focus,
.pixelstrap ul ul ul a:active {
    border-left: 24px solid transparent;
}

.pixelstrap ul ul ul ul a,
.pixelstrap ul ul ul ul a:hover,
.pixelstrap ul ul ul ul a:focus,
.pixelstrap ul ul ul ul a:active {
    border-left: 32px solid transparent;
}

.pixelstrap ul ul ul ul ul a,
.pixelstrap ul ul ul ul ul a:hover,
.pixelstrap ul ul ul ul ul a:focus,
.pixelstrap ul ul ul ul ul a:active {
    border-left: 40px solid transparent;
}

.pixelstrap ul li {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.pixelstrap ul li:first-child {
    border-top: 0;
}

.pixelstrap.sm-horizontal .mega {
    position: unset !important;
}

.pixelstrap.sm-horizontal .mega .full-mega-menu.center-mega-menu {
    margin-left: 0 !important;
}

.pixelstrap.sm-horizontal .mega ul {
    margin-left: 0 !important;
    max-width: -webkit-max-content;
    max-width: -moz-max-content;
    max-width: max-content;
}

.pixelstrap .icon-trend {
    color: #042c49;
}

.pixelstrap .full-mega-menu,
.pixelstrap .clothing-menu {
    max-width: inherit !important;
    padding: 20px 0px 20px 5px;
}

.pixelstrap .full-mega-menu a,
.pixelstrap .clothing-menu a {
    padding: 0;
    position: relative;
}

.pixelstrap .full-mega-menu a h6,
.pixelstrap .clothing-menu a h6 {
    font-size: 14px;
    text-align: center;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-weight: 400;
    /*text-transform: capitalize;*/
    color: #222222;
}

.pixelstrap .full-mega-menu a img,
.pixelstrap .clothing-menu a img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
}

.pixelstrap .full-mega-menu a:hover,
.pixelstrap .clothing-menu a:hover {
    padding: 0;
}

.pixelstrap .full-mega-menu a:hover:before,
.pixelstrap .clothing-menu a:hover:before {
    width: 50px;
    height: 2px;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
}

.pixelstrap .full-mega-menu a:hover h6,
.pixelstrap .clothing-menu a:hover h6 {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.pixelstrap .link-section .menu-secon-title h5 {
    padding-top: 10px;
}

.pixelstrap .link-section h5 {
    font-weight: 700;
    /*text-transform: capitalize;*/
    font-size: 14px;
    margin-bottom: 10px;
    color: #444444;
}

.pixelstrap .link-section ul {
    position: relative;
    display: block;
    padding: 0;
}

.pixelstrap .link-section ul li a {
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 14px;
    padding-left: 0;
}

.pixelstrap .full-mega-menu {
    width: 100% !important;
    border-radius: 0;
    max-width: 1110px !important;
    left: 50% !important;
    margin-right: -50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.pixelstrap .full-mega-menu .mega-box .link-section ul li a:hover,
.pixelstrap .full-mega-menu .mega-box .link-section ul li a:focus {
    padding-top: 3px;
    padding-bottom: 3px;
}

.pixelstrap .full-mega-menu .img-section img {
    margin-top: 15px;
}

.pixelstrap .clothing-menu {
    width: 815px !important;
    padding: 15px 30px 25px !important;
    background-color: #ffffff;
}

.pixelstrap .clothing-menu li img {
    padding: 20px 0 10px 0;
}

.pixelstrap .clothing-menu .link-section h5 {
    margin-top: 15px;
}

.pixelstrap .clothing-menu .link-section ul {
    background: none;
}

.pixelstrap .mega-menu ul {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.pixelstrap li a {
    position: relative;
}

.pixelstrap li .label-nav {
    position: absolute;
    background-color: #042c49;
    padding: 2px 7px;
    color: #ffffff;
    top: 7px;
    text-transform: uppercase;
    font-size: 9px;
    left: 0;
    line-height: 1.3;
}

.pixelstrap li .label-nav:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #042c49;
    top: 15px;
    left: 5px;
}

.pixelstrap li .label-nav.label-red {
    background-color: #042c49;
}

.pixelstrap li .label-nav.label-red:before {
    border-top: 5px solid #042c49;
}

.sm-vertical {
    background-color: #ffffff;
}

.sidenav {
    height: 100vh;
    width: 300px;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: -300px;
    background-color: #ffffff;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sidenav .sidebar-back {
    padding: 20px;
    font-size: 18px;
    color: #444444;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 20px;
    cursor: pointer;
}

.sidenav nav {
    background-color: #ffffff;
    z-index: 9;
    position: relative;
    height: 100vh;
    top: 0;
    overflow-y: auto;
}

.sidenav.open-side {
    left: 0;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sidenav.open-side .sidebar-overlay {
    opacity: 0.8;
    visibility: visible;
}

.sidenav .sidebar-overlay {
    visibility: hidden;
    opacity: 0;
    background-color: #212331;
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: all 1s;
    transition: all 1s;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    font-size: 36px;
    margin-left: 50px;
    margin-top: 50px;
    color: #444444;
}

.sidenav .pixelstrap li a {
    padding: 10px 50px 20px 40px;
}

.sidenav .pixelstrap li ul li a {
    padding: 5px 35px;
}

.sidenav .pixelstrap li ul li a:active,
.sidenav .pixelstrap li ul li a:focus,
.sidenav .pixelstrap li ul li a.highlighted {
    padding: 5px 35px;
}

.sidenav .pixelstrap li .clothing-menu li a {
    padding: 5px 0 !important;
}

.sidenav .pixelstrap li .clothing-menu li a:focus {
    font-size: 14px;
}

.sidenav .pixelstrap li .clothing-menu li a:hover {
    font-size: 14px;
    padding: 5px 0;
}

.sidenav .pixelstrap li .clothing-menu li a:focus,
.sidenav .pixelstrap li .clothing-menu li a:hover {
    padding: 5px 0 !important;
}

.mega-box .link-section .demo ul li a {
    position: relative;
}

.mega-box .link-section .demo ul li a:hover,
.mega-box .link-section .demo ul li a:focus {
    padding-top: 3px;
    padding-bottom: 3px;
}

.mega-box .link-section .demo .new {
    position: absolute;
    top: -8px;
    right: 0px;
    color: white;
    background-color: #042c49;
    padding: 2px 7px;
    text-transform: uppercase;
    font-size: 9px;
    line-height: 1.3;
}

.mega-box .link-section .demo .title {
    border-bottom: 2px solid red !important;
}

.rtl .sidenav .pixelstrap li {
    text-align: right;
}

.rtl .link-section {
    float: right;
}

.rtl .pixelstrap li .label-nav {
    left: unset;
}

.rtl .mega-box .link-section .demo ul li a span img {
    right: unset;
    left: -120px;
}

@media (min-width: 1200px) {
    .toggle-nav {
        display: none;
    }

    .sm-vertical li ul li a .sub-arrow {
        margin-top: -15px !important;
    }

    .mobile-back {
        display: none !important;
    }

    .pixelstrap ul {
        position: absolute;
        width: 14em !important;
    }

    .pixelstrap li {
        position: relative;
        float: left;
    }

    .pixelstrap li:before {
        content: "";
        position: absolute;
    }

    .pixelstrap.sm-rtl li {
        float: right;
    }

    .pixelstrap ul li,
    .pixelstrap.sm-rtl ul li,
    .pixelstrap.sm-vertical li {
        float: none;
    }

    .pixelstrap a {
        white-space: nowrap;
    }

    .pixelstrap ul a,
    .pixelstrap.sm-vertical a {
        white-space: normal;
    }

    .pixelstrap .sm-nowrap>li>a,
    .pixelstrap .sm-nowrap>li> :not(ul) a {
        white-space: nowrap;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .pixelstrap .sm-nowrap>li>a:hover,
    .pixelstrap .sm-nowrap>li> :not(ul) a:hover {
        letter-spacing: 1.5px;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    .pixelstrap .sm-nowrap>li>a.highlighted,
    .pixelstrap .sm-nowrap>li> :not(ul) a.highlighted {
        white-space: nowrap;
        color: #444444;
    }

    .sm-horizontal .mega-menu li a {
        padding: 0;
    }

    .sm-horizontal .mega-menu li a:focus,
    .sm-horizontal .mega-menu li a:active {
        padding: 0;
    }

    .pixelstrap.sm-vertical ul a,
    .pixelstrap.sm-vertical ul a:hover,
    .pixelstrap.sm-vertical ul a:focus,
    .pixelstrap.sm-vertical ul a:active,
    .pixelstrap.sm-vertical ul a.highlighted {
        padding: 9px 23px;
    }

    .pixelstrap ul {
        -webkit-box-shadow: 0 0 1px 0 #ebebeb;
        box-shadow: 0 0 1px 0 #ebebeb;
    }

    .pixelstrap.sm-horizontal .mega {
        position: unset !important;
    }

    .pixelstrap.sm-horizontal .mega.product>div {
        border: 1px solid #f1f1f1;
    }

    .pixelstrap.sm-horizontal .mega ul {
        margin-left: 0 !important;
    }

    .pixelstrap.sm-horizontal .mega ul ul {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .pixelstrap .full-mega-menu,
    .pixelstrap .clothing-menu {
        -webkit-box-shadow: 0 0 1px 0 #ebebeb;
        box-shadow: 0 0 1px 0 #ebebeb;
    }

    .pixelstrap .full-mega-menu a,
    .pixelstrap .clothing-menu a {
        padding: 0;
        position: relative;
    }

    .pixelstrap .sm-horizontal .full-mega-menu {
        position: absolute;
        left: unset !important;
        right: 0;
    }

    .pixelstrap.sm-vertical .full-mega-menu .mega-box {
        max-width: 100%;
        padding: 0 20px;
        margin-bottom: 20px;
    }

    .pixelstrap.sm-vertical .full-mega-menu .mega-box .mega-img,
    .pixelstrap.sm-vertical .full-mega-menu .mega-box .menu-banner {
        display: none;
    }

    .pixelstrap.sm-vertical .full-mega-menu .mega-box ul a:hover {
        padding-left: 5px;
    }

    .pixelstrap .full-mega-menu.center-mega-menu {
        left: 50% !important;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .pixelstrap .full-mega-menu.layout-3 {
        margin-left: -100px !important;
        -webkit-transform: unset !important;
        transform: unset !important;
    }

    .pixelstrap .full-mega-menu ul {
        background-color: transparent;
        border: 0;
    }

    .pixelstrap .full-mega-menu .mega-box {
        max-width: 16.666%;
    }

    .pixelstrap .full-mega-menu .mega-box.product {
        max-width: 33%;
    }

    .pixelstrap .category-menu.center-menu {
        left: 50% !important;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .pixelstrap a .sub-arrow::before {
        content: "\f107";
        font-family: FontAwesome;
        position: absolute;
    }

    .pixelstrap ul {
        padding: 15px 0px 15px 0px;
        background: #ffffff;
        border: 5px solid #f2f2f2;
    }

    .pixelstrap ul ul {
        background: white;
        border: 5px solid #f2f2f2;
    }

    .pixelstrap ul a {
        position: relative;
    }

    .pixelstrap ul a:before {
        position: absolute;
        bottom: 8px;
        content: "";
        height: 5px;
        width: 0;
        background-color: #042c49;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
        opacity: 0.12;
    }

    .pixelstrap ul a,
    .pixelstrap ul a:hover,
    .pixelstrap ul a:focus,
    .pixelstrap ul a:active,
    .pixelstrap ul a.highlighted {
        border: 0 !important;
        background: transparent;
        color: #000000;
        border-radius: 0 !important;
        font-size: 14px;
        font-weight: 400;
        /*text-transform: capitalize;*/
        padding: 5px 25px;
    }

    .pixelstrap ul a:hover:before,
    .pixelstrap ul a:hover:hover:before,
    .pixelstrap ul a:focus:hover:before,
    .pixelstrap ul a:active:hover:before,
    .pixelstrap ul a.highlighted:hover:before {
        width: 50px;
        height: 7px;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;
    }

    .pixelstrap ul a .sub-arrow:before {
        content: "\f105";
        font-family: FontAwesome;
        position: absolute;
    }

    .pixelstrap ul li {
        border: 0;
    }

    .pixelstrap .scroll-up,
    .pixelstrap .scroll-down {
        position: absolute;
        display: none;
        visibility: hidden;
        overflow: hidden;
        background: #fff;
        height: 20px;
    }

    .pixelstrap .scroll-up-arrow,
    .pixelstrap .scroll-down-arrow {
        position: absolute;
        top: -2px;
        left: 50%;
        margin-left: -8px;
        width: 0;
        height: 0;
        overflow: hidden;
        border-width: 8px;
        border-style: dashed dashed solid dashed;
        border-color: transparent transparent #c05c4f transparent;
    }

    .pixelstrap .scroll-down-arrow {
        top: 6px;
        border-style: solid dashed dashed dashed;
        border-color: #c05c4f transparent transparent transparent;
    }

    .pixelstrap.sm-rtl.sm-vertical a .sub-arrow:before {
        content: "\f105";
        font-family: FontAwesome;
        position: absolute;
    }

    .pixelstrap.sm-rtl>li:first-child {
        border-left: 1px solid #c05c4f;
    }

    .pixelstrap.sm-rtl>li:last-child {
        border-left: 0;
    }

    .pixelstrap.sm-rtl ul a .sub-arrow {
        right: auto;
        left: 10px;
    }

    .pixelstrap.sm-vertical a {
        padding: 9px 23px;
    }

    .pixelstrap.sm-vertical a .sub-arrow:before {
        content: "\f104";
        font-family: FontAwesome;
        position: absolute;
    }

    .pixelstrap.sm-vertical>li {
        border-left: 0 !important;
    }

    .pixelstrap.sm-vertical>li ul a .sub-arrow:before {
        content: "\f105";
    }

    .main-nav-center>nav {
        text-align: center;
    }

    .main-nav-center .sm {
        display: inline-block;
        vertical-align: middle;
    }

    .main-nav-center .sm>li .label-nav {
        top: 3px;
    }

    .main-nav-center .sm>li>a {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .layout3-menu .pixelstrap a {
        /*text-transform: capitalize;*/
        padding-right: 34px;
        letter-spacing: 0.04em;
    }

    .layout3-menu .pixelstrap .sub-arrow {
        right: 12px;
    }

    .rtl .pixelstrap .category-menu {
        width: 698px !important;
    }

    .rtl .pixelstrap .category-menu.center-menu {
        left: 0 !important;
        -webkit-transform: unset;
        transform: unset;
    }

    .rtl .pixelstrap .full-mega-menu {
        right: auto !important;
    }

    .rtl .mega .full-mega-menu {
        float: left !important;
        left: 50% !important;
        right: auto !important;
    }

    .rtl .mega .full-mega-menu.center-mega-menu {
        margin-right: 0 !important;
        margin-left: 0 !important;
        left: unset !important;
        right: 50% !important;
    }

    .rtl .layout3-menu .light-font-menu li>a,
    .rtl .layout3-menu .light-font-menu li>a:hover,
    .rtl .layout3-menu .light-font-menu li>a:active {
        padding-right: 0;
        padding-left: 28px;
    }

    .rtl .layout3-menu .light-font-menu .sub-arrow {
        left: -5px;
        right: auto;
    }

    .rtl .sm-horizontal .mega ul {
        float: right;
    }

    .rtl .sm-horizontal .mega ul li {
        text-align: right;
    }

    .rtl .sm-horizontal ul li {
        text-align: right;
    }

    .rtl .sm-horizontal ul li a .sub-arrow {
        left: 15px;
        right: auto;
    }

    .rtl .pixelstrap .full-mega-menu.layout-3 {
        margin-right: -100px !important;
    }

    .rtl .light-font-menu .mega ul {
        float: right;
        left: unset !important;
        right: 0 !important;
    }

    .rtl .sidenav .pixelstrap li ul li a:hover,
    .rtl .sidenav .pixelstrap li ul li a:focus {
        padding: 5px 35px;
    }

    .rtl .sidenav .pixelstrap li ul li a:before {
        right: 35px;
    }

    .rtl .sidenav .pixelstrap li a .sub-arrow {
        right: 7px;
        left: unset;
    }

    .rtl .sidenav .pixelstrap .mega-menu li ul li a:before {
        right: 0;
    }

    .rtl .sidenav .sidebar-back i {
        float: left;
    }

    .rtl header.header-5 .top-header .header-dropdown>li:last-child {
        padding: 15px 25px;
        padding-left: 0;
    }
}

@media (max-width: 1440px) {
    .pixelstrap .category-menu {
        width: 698px !important;
    }

    .pixelstrap .full-mega-menu.layout-3 {
        margin-left: 0 !important;
    }

    .rtl .pixelstrap .mega .full-mega-menu {
        margin-left: 0 !important;
        margin-right: 162px !important;
    }

    .rtl .pixelstrap .mega .full-mega-menu.layout-3 {
        margin-right: 0 !important;
    }
}

@media (max-width: 1367px) {
    .pixelstrap .full-mega-menu.layout-3 {
        width: 1079px !important;
    }

    .pixelstrap a {
        font-size: 14px;
    }

    .pixelstrap a:hover,
    .pixelstrap a:active {
        font-size: 14px;
    }
}

@media (max-width: 1199px) {
    .pixelstrap .full-mega-menu {
        width: unset !important;
        border-radius: 0;
        max-width: unset !important;
        left: unset !important;
        margin-right: unset;
        -webkit-transform: unset;
        transform: unset;
    }

    .menu-banner {
        margin-top: 0px;
    }

    .mega-box .link-section .demo ul li a span img {
        top: 30px;
        right: -60px;
    }

    .sm-horizontal {
        position: fixed;
        background-color: #fff;
        width: 300px;
        border-radius: 0;
        border: 1px solid #eee;
        top: 0;
        height: 100vh;
        right: -300px;
        z-index: 99;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        overflow: scroll;
    }

    .sm-horizontal .mobile-back {
        padding: 12px;
        font-size: 18px;
        color: #444444;
        font-weight: 700;
        text-transform: uppercase;
        border-bottom: 1px solid #dddddd;
        cursor: pointer;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .mega-box.col {
        -ms-flex-preferred-size: unset;
        flex-basis: unset;
    }

    .pixelstrap .feature-menu.product-menu {
        width: 100% !important;
    }

    .pixelstrap .mega {
        position: unset !important;
    }

    .pixelstrap .mega .full-mega-menu {
        margin-left: 0 !important;
    }

    .pixelstrap .mega ul {
        margin-left: 0 !important;
    }

    .pixelstrap .full-mega-menu,
    .pixelstrap .clothing-menu {
        width: 100% !important;
        padding: 0 30px !important;
    }

    .pixelstrap a.highlighted .sub-arrow:before {
        content: '-';
    }

    .pixelstrap a .sub-arrow {
        right: 0;
    }

    .pixelstrap a .sub-arrow:before {
        content: '+';
    }

    .pixelstrap>li>a {
        font-weight: 700;
    }

    .pixelstrap>li>a:hover,
    .pixelstrap>li>a:focus,
    .pixelstrap>li>a:active {
        font-weight: 700;
    }

    .pixelstrap .link-section .menu-title h5 {
        cursor: pointer;
    }

    .pixelstrap .link-section .menu-title.active .according-menu {
        font: bold 16px/34px monospace !important;
    }

    .pixelstrap .link-section .menu-title.active .according-menu:before {
        content: "-";
        position: absolute;
        right: -18px;
    }

    .pixelstrap .link-section .menu-title .according-menu {
        font: bold 16px/34px monospace !important;
    }

    .pixelstrap .link-section .menu-title .according-menu:before {
        content: "+";
        position: absolute;
        right: -18px;
    }

    .pixelstrap .link-section .menu-content a {
        /*text-transform: capitalize;*/
    }

    .pixelstrap .link-section ul {
        background-color: unset;
    }

    .pixelstrap .link-section h5 {
        font-weight: 700;
    }

    .pixelstrap .full-mega-menu .mega-box {
        border-right: none;
        margin-bottom: 0;
    }

    .pixelstrap .category-menu a img {
        width: 30% !important;
    }

    .pixelstrap .clothing-menu {
        background: none;
    }

    .pixelstrap li .label-nav {
        left: 15px;
        top: -8px;
    }

    .pixelstrap li a {
        padding: 10px 15px;
        font-size: 14px;
    }

    .pixelstrap li a:hover {
        padding: 10px 15px;
    }

    .pixelstrap li ul li a {
        padding: 5px 35px;
        font-size: 14px;
        color: #444444;
        font-weight: 400;
        /*text-transform: capitalize;*/
    }

    .pixelstrap li ul li a:hover {
        color: #042c49 !important;
        font-size: 14px;
        padding: 5px 35px;
        /*text-transform: capitalize;*/
    }

    .pixelstrap .category-menu a:hover img {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    .pixelstrap.sm-vertical a .sub-arrow {
        margin-top: -18px;
    }

    .pixelstrap .mega ul {
        max-width: 100%;
    }

    .pixelstrap ul li {
        border-top: none;
    }

    .sidenav {
        overflow-y: auto;
    }

    .sidenav .pixelstrap li ul li a {
        padding: 5px 45px;
    }

    .sidenav .pixelstrap li ul li a:hover,
    .sidenav .pixelstrap li ul li a.highlighted {
        padding: 5px 45px;
    }

    .sidenav .pixelstrap li a {
        padding: 10px 25px;
    }

    .sidenav .pixelstrap li a:hover {
        padding: 10px 25px;
    }

    .main-menu.border-section {
        border: none;
    }

    .main-menu .menu-left .main-menu-right .toggle-nav {
        position: absolute;
        z-index: 2;
        right: 145px;
        top: 6px;
    }

    .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
        font-size: 18px;
        padding-left: 5px;
        text-transform: uppercase;
    }

    .main-nav-center .toggle-nav {
        position: absolute;
        z-index: 99;
        right: 175px;
        top: -105px;
    }

    .layout3-menu .main-menu .menu-left .main-menu-right .toggle-nav {
        right: unset;
        top: -1px;
    }

    .rtl .mega-box .link-section .demo ul li a span img {
        left: -70px;
    }

    .rtl .sm li {
        text-align: right;
    }

    .rtl .pixelstrap a .sub-arrow {
        right: unset;
        left: 0;
    }

    .rtl .pixelstrap li .label-nav {
        left: unset;
        right: 15px;
    }

    .rtl .pixelstrap li .label-nav:before {
        left: unset;
        right: 5px;
    }
}

@media (max-width: 991px) {
    .top-header .header-contact {
        display: none;
    }
}

@media (min-width: 578px) {

    .main-menu .menu-right .icon-nav .mobile-search i,
    .main-menu .menu-right .icon-nav .mobile-cart i,
    .main-menu .menu-right .icon-nav .mobile-setting i {
        display: none;
    }

    .main-menu .menu-right .icon-nav .mobile-search .close-circle i,
    .main-menu .menu-right .icon-nav .mobile-cart .close-circle i,
    .main-menu .menu-right .icon-nav .mobile-setting .close-circle i {
        display: block;
    }

    .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
        display: inline-block;
    }
}

@media (max-width: 577px) {
    .top-header {
        height: 0;
    }

    .main-nav-center .toggle-nav {
        right: 15px;
        top: -85px;
    }

    .header-5 .top-header .header-dropdown .mobile-wishlist img,
    .header-5 .top-header .header-dropdown .mobile-account img {
        -webkit-filter: brightness(5);
        filter: brightness(5);
    }

    .header-5 .main-menu .brand-logo {
        margin: 0;
        left: 0;
        -webkit-transform: unset;
        transform: unset;
    }

    .top-header .header-dropdown .mobile-account,
    .top-header .header-dropdown .mobile-wishlist {
        position: fixed;
        bottom: 20px;
        right: 32%;
        font-size: 0;
        padding: 0;
        z-index: 9;
    }

    .top-header .header-dropdown .mobile-account i,
    .top-header .header-dropdown .mobile-wishlist i {
        font-size: 22px;
        font-family: themify;
        color: #ffffff;
    }

    .top-header .header-dropdown .mobile-account i:before,
    .top-header .header-dropdown .mobile-wishlist i:before {
        content: "\e602";
    }

    .top-header .header-dropdown .mobile-account .onhover-show-div,
    .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
        top: unset;
        bottom: 42px;
        right: 0;
    }

    .top-header .header-dropdown .mobile-account:hover i,
    .top-header .header-dropdown .mobile-wishlist:hover i {
        color: #ffffff;
    }

    .top-header .header-dropdown .mobile-wishlist {
        right: 64%;
    }

    .top-header .header-dropdown .mobile-wishlist i {
        font-family: themify;
        color: #ffffff !important;
    }

    .top-header .header-dropdown .mobile-wishlist i:before {
        content: "\e634";
    }

    .main-menu .menu-left.category-nav-right .navbar {
        padding-left: 0 !important;
    }

    .main-menu .menu-left .navbar {
        padding: 30px 45px 30px 0 !important;
    }

    .main-menu .menu-right .icon-nav .mobile-setting,
    .main-menu .menu-right .icon-nav .mobile-cart,
    .main-menu .menu-right .icon-nav .mobile-search {
        position: fixed;
        bottom: 20px;
        right: 16%;
        font-size: 0;
        padding: 0;
    }

    .main-menu .menu-right .icon-nav .mobile-setting .show-div,
    .main-menu .menu-right .icon-nav .mobile-cart .show-div,
    .main-menu .menu-right .icon-nav .mobile-search .show-div {
        top: -58px;
        left: 0;
    }

    .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
    .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
    .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart {
        top: unset;
        bottom: 42px;
        left: -118px !important;
    }

    .main-menu .menu-right .icon-nav .mobile-setting i {
        display: inline-block;
        font-size: 22px;
        color: #ffffff;
    }

    .main-menu .menu-right .icon-nav .mobile-setting>div img {
        display: none;
    }

    .main-menu .menu-right .icon-nav .mobile-setting .setting {
        top: unset;
        bottom: 42px;
        left: unset !important;
    }

    .main-menu .menu-right .icon-nav .mobile-cart {
        right: 48%;
    }

    .main-menu .menu-right .icon-nav .mobile-cart .close-circle i {
        font-size: 14px;
    }

    .main-menu .menu-right .icon-nav .mobile-cart i {
        display: inline-block;
        font-size: 22px;
        color: #ffffff;
    }

    .main-menu .menu-right .icon-nav .mobile-cart>div img {
        display: none;
    }

    .main-menu .menu-right .icon-nav .mobile-search {
        right: 80%;
        z-index: 1;
    }

    .main-menu .menu-right .icon-nav .mobile-search i {
        display: inline-block;
        font-size: 22px;
        color: #ffffff;
    }

    .main-menu .menu-right .icon-nav .mobile-search img {
        display: none;
    }

    .main-menu .menu-right .icon-nav .mobile-search .show-div .form-group {
        margin-bottom: 0;
    }

    .main-menu .menu-right .icon-nav .mobile-search .show-div .form-inline button i {
        font-size: 14px;
    }

    .rtl .main-nav-center .toggle-nav {
        right: unset;
        left: 15px;
    }

    .rtl .main-menu .menu-right .icon-nav .mobile-setting,
    .rtl .main-menu .menu-right .icon-nav .mobile-cart,
    .rtl .main-menu .menu-right .icon-nav .mobile-search {
        right: unset;
        left: 16%;
    }

    .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div,
    .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div,
    .rtl .main-menu .menu-right .icon-nav .mobile-search .show-div {
        left: unset;
        right: 0;
    }

    .rtl .main-menu .menu-right .icon-nav .mobile-setting .show-div.shopping-cart,
    .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart,
    .rtl .main-menu .menu-right .icon-nav .mobile-search .show-div.shopping-cart {
        left: unset !important;
        right: -118px !important;
    }

    .rtl .main-menu .menu-right .icon-nav .mobile-setting .setting {
        left: unset !important;
    }

    .rtl .main-menu .menu-right .icon-nav .mobile-cart {
        right: unset;
        left: 48%;
    }

    .rtl .main-menu .menu-right .icon-nav .mobile-search {
        right: unset;
        left: 80%;
    }

    .rtl .main-menu .menu-left .navbar {
        padding: 30px 0 30px 25px !important;
    }

    .rtl .top-header .header-dropdown .mobile-account,
    .rtl .top-header .header-dropdown .mobile-wishlist {
        right: unset;
        left: 32%;
    }

    .rtl .top-header .header-dropdown .mobile-account .onhover-show-div,
    .rtl .top-header .header-dropdown .mobile-wishlist .onhover-show-div {
        right: 0;
    }

    .rtl .top-header .header-dropdown .mobile-wishlist {
        right: unset;
        left: 64%;
    }
}

/*=====================
    Rtl start
==========================*/
body.rtl {
    direction: rtl;
}

.rtl {
    /*==================
      invoice template
    ===================*/
    /*============
      cookee bar
    ==============*/
    /*============
      inner pages
    ==============*/
    /*=====================
    responsive rtl start
==========================*/
}

.rtl h1,
.rtl h2,
.rtl h3,
.rtl h4,
.rtl h5,
.rtl h6,
.rtl p {
    text-align: right;
}

.rtl .ms-3 {
    margin-left: unset;
    margin-right: 1rem !important;
}

.rtl .float-end {
    float: left !important;
}

.rtl .product-notification .btn-close {
    left: 13px;
    right: unset;
}

.rtl .slick-slider {
    direction: ltr;
}

.rtl .slick-slide {
    float: left;
}

.rtl .product-notification .media .me-2 {
    margin-right: 0 !important;
    margin-left: 0.5rem;
}

.rtl .product-notification .media .media-body {
    text-align: right;
}

.rtl ul {
    -webkit-padding-start: 0;
}

.rtl .pixelstrap ul {
    left: unset !important;
    right: unset;
}

.rtl .slider-layout-4 .slider-slide {
    margin-left: 0;
    margin-right: 275px;
}

.rtl .layout-4-collection>div:first-child {
    margin-left: 0;
    margin-right: 270px;
}

.rtl .me-3 {
    margin-right: 0 !important;
    margin-left: 5px;
}

.rtl .input-group-prepend {
    margin-right: 0;
}

.rtl .offset-md-3,
.rtl .offset-lg-2,
.rtl .offset-xl-3,
.rtl .offset-lg-3,
.rtl .offset-md-2,
.rtl .offset-lg-4 {
    margin: 0 auto;
}

.rtl .title1 h1,
.rtl .title1 h2,
.rtl .title1 h3,
.rtl .title1 h4,
.rtl .title1 h5,
.rtl .title1 h6,
.rtl .title2 h1,
.rtl .title2 h2,
.rtl .title2 h3,
.rtl .title2 h4,
.rtl .title2 h5,
.rtl .title2 h6,
.rtl .title3 h1,
.rtl .title3 h2,
.rtl .title3 h3,
.rtl .title3 h4,
.rtl .title3 h5,
.rtl .title3 h6,
.rtl .title4 h1,
.rtl .title4 h2,
.rtl .title4 h3,
.rtl .title4 h4,
.rtl .title4 h5,
.rtl .title4 h6 {
    text-align: center;
}

.rtl .setting-box {
    direction: ltr;
}

.rtl .setting-box h1,
.rtl .setting-box h2,
.rtl .setting-box h3,
.rtl .setting-box h4,
.rtl .setting-box h5,
.rtl .setting-box h6,
.rtl .setting-box p {
    text-align: left;
}

.rtl .brand-logo img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.rtl [dir="rtl"] .slick-slide {
    float: left;
}

.rtl .effect-cls:before,
.rtl .effect-cls:after {
    right: 0;
}

.rtl .cart-section .wishlist-buttons,
.rtl .wishlist-section .wishlist-buttons {
    text-align: left;
}

.rtl .category-block .category-details h6 {
    text-align: center;
}

.rtl .offer-slider .slick-slide>div {
    direction: rtl;
}

.rtl .tools-parallax-product .tools-description .tools-form .search-button {
    text-align: right;
}

.rtl .typo-content.typo-buttons {
    text-align: right;
}

.rtl .typography_section .typography-box .headings {
    text-align: right;
}

.rtl .typography_section .typography-box ul,
.rtl .typography_section .typography-box ol,
.rtl .typography_section .typography-box dl {
    text-align: right;
}

.rtl .typography_section .typography-box ol {
    -webkit-padding-start: 0px;
    padding-inline-start: 0px;
}

.rtl .typography_section .typography-box .typo-content.input_button {
    text-align: right;
}

.rtl .absolute_banner .collection-banner .absolute-contain h3,
.rtl .absolute_banner .collection-banner .absolute-contain h4 {
    text-align: center;
}

.rtl .absolute-product .product-box .product-detail .rating,
.rtl .absolute-product .product-box .product-detail h4,
.rtl .absolute-product .product-box .product-detail h6,
.rtl .absolute-product .product-box .product-detail .color-variant {
    text-align: center;
}

.rtl .product-box,
.rtl .product-wrap {
    position: relative;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    vertical-align: middle;
}

.rtl .product-box .img-wrapper .front,
.rtl .product-wrap .img-wrapper .front {
    text-align: right;
    left: unset;
    right: 0;
}

.rtl .product-box .img-wrapper .back,
.rtl .product-wrap .img-wrapper .back {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    text-align: right;
    left: unset;
    right: 0;
}

.rtl .product-box:hover .img-wrapper .back,
.rtl .product-wrap:hover .img-wrapper .back {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}

.rtl .box-product .full-box .theme-card .offer-slider .product-box2 .media {
    padding-right: 0;
    padding-left: 15px;
}

.rtl .box-product .full-box .theme-card .offer-slider .product-box2 .media img {
    padding: 0 0 0 15px;
}

.rtl .box-product .full-box .theme-card .offer-slider .product-box2 .cart-bottom {
    text-align: right;
}

.rtl .form_search button {
    left: 5px;
    right: unset;
}

.rtl .j-box .product-box .product-detail {
    padding-left: 0;
    padding-right: 15px;
}

.rtl .gym-product .product-box .product-detail h6,
.rtl .gym-product .product-box .product-detail h4 {
    text-align: center;
}

.rtl .search-overlay>div .closebtn {
    right: unset;
    left: 25px;
}

.rtl .pwd-page h2 {
    text-align: center;
}

.rtl .product-box .color-variant,
.rtl .product-wrap .color-variant {
    -webkit-padding-start: 0;
}

.rtl .product-box .product-info h4,
.rtl .product-box .product-info h6,
.rtl .product-wrap .product-info h4,
.rtl .product-wrap .product-info h6 {
    text-align: center;
}

.rtl .product-box .product-detail .rating,
.rtl .product-wrap .product-detail .rating {
    text-align: right;
}

.rtl .product-box .product-detail .color-variant,
.rtl .product-wrap .product-detail .color-variant {
    text-align: right;
    padding: 0;
}

.rtl .product-box .product-detail .color-variant li:first-child,
.rtl .product-wrap .product-detail .color-variant li:first-child {
    margin-right: 0;
}

.rtl .product-box .img-block .label-wrapper .label1,
.rtl .product-wrap .img-block .label-wrapper .label1 {
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.rtl .product-box .img-block .label-wrapper .label2,
.rtl .product-wrap .img-block .label-wrapper .label2 {
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.rtl .product-box .img-wrapper .label-block .label3,
.rtl .product-wrap .img-wrapper .label-block .label3 {
    left: unset;
    right: 7px;
}

.rtl .product-box .img-wrapper .label-block .label4,
.rtl .product-wrap .img-wrapper .label-block .label4 {
    left: 7px;
    right: unset;
}

.rtl .product-box .cart-detail,
.rtl .product-wrap .cart-detail {
    left: 20px;
    right: unset;
}

.rtl .product-box .cart-info.cart-wrap,
.rtl .product-wrap .cart-info.cart-wrap {
    left: 10px;
    right: unset;
}

.rtl .product-box:hover .cart-wrap button,
.rtl .product-wrap:hover .cart-wrap button {
    -webkit-animation: fadeInLeft 300ms ease-in-out;
    animation: fadeInLeft 300ms ease-in-out;
}

.rtl .product-box:hover .cart-wrap a:nth-child(2) i,
.rtl .product-wrap:hover .cart-wrap a:nth-child(2) i {
    -webkit-animation: fadeInLeft 500ms ease-in-out;
    animation: fadeInLeft 500ms ease-in-out;
}

.rtl .product-box:hover .cart-wrap a:nth-child(3) i,
.rtl .product-wrap:hover .cart-wrap a:nth-child(3) i {
    -webkit-animation: fadeInLeft 700ms ease-in-out;
    animation: fadeInLeft 700ms ease-in-out;
}

.rtl .product-box:hover .cart-wrap a:nth-child(4) i,
.rtl .product-wrap:hover .cart-wrap a:nth-child(4) i {
    -webkit-animation: fadeInLeft 1000ms ease-in-out;
    animation: fadeInLeft 1000ms ease-in-out;
}

.rtl .product-box:hover .cart-detail button,
.rtl .product-wrap:hover .cart-detail button {
    -webkit-animation: fadeInLeft 300ms ease-in-out;
    animation: fadeInLeft 300ms ease-in-out;
}

.rtl .product-box:hover .cart-detail a:nth-child(2) i,
.rtl .product-wrap:hover .cart-detail a:nth-child(2) i {
    -webkit-animation: fadeInLeft 500ms ease-in-out;
    animation: fadeInLeft 500ms ease-in-out;
}

.rtl .product-box:hover .cart-detail a:nth-child(3) i,
.rtl .product-wrap:hover .cart-detail a:nth-child(3) i {
    -webkit-animation: fadeInLeft 700ms ease-in-out;
    animation: fadeInLeft 700ms ease-in-out;
}

.rtl .product-box:hover .cart-detail a:nth-child(4) i,
.rtl .product-wrap:hover .cart-detail a:nth-child(4) i {
    -webkit-animation: fadeInLeft 1000ms ease-in-out;
    animation: fadeInLeft 1000ms ease-in-out;
}

.rtl .subscribe h4 {
    text-align: center;
}

.rtl .subscribe h4 i {
    padding-left: 5px;
    padding-right: 0;
}

.rtl .subscribe2 .subscribe-contain h2 {
    text-align: center;
}

.rtl .subscribe2 .subscribe-contain p {
    text-align: center;
}

.rtl .subscribe2 .subscribe-contain ul li:nth-child(n+2) {
    margin-right: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
    margin-left: unset;
}

.rtl .service-block svg {
    margin-left: 12px;
    margin-right: 0;
}

.rtl .service-block+.service-block {
    border-right: 1px solid #dddddd;
    border-left: none;
}

.rtl .blog-details h4,
.rtl .blog-details h6 {
    text-align: center;
}

.rtl .blog-details a p {
    text-align: center;
}

.rtl .instagram h2 {
    text-align: center;
}

.rtl .category-block .category-details h5 {
    text-align: center;
}

.rtl .category-bg .contain-block h6 {
    text-align: center;
}

.rtl .about-text p {
    text-align: center;
}

.rtl .full-banner.p-left .banner-contain {
    float: right;
}

.rtl .full-banner.p-right .banner-contain {
    float: right;
}

.rtl .full-banner.p-center .banner-contain {
    left: 0;
    right: 0;
    margin: 0 auto;
}

.rtl .full-banner.p-center .banner-contain h3,
.rtl .full-banner.p-center .banner-contain h4 {
    text-align: center;
}

.rtl .full-banner.p-center .banner-contain .btn-solid,
.rtl .full-banner.p-center .banner-contain .btn-outline {
    float: unset;
}

.rtl .full-banner.text-center .banner-contain h2,
.rtl .full-banner.text-center .banner-contain h3,
.rtl .full-banner.text-center .banner-contain h4 {
    text-align: center;
}

.rtl .full-banner.text-left .banner-contain h2,
.rtl .full-banner.text-left .banner-contain h3,
.rtl .full-banner.text-left .banner-contain h4 {
    text-align: right;
}

.rtl .full-banner.text-right .banner-contain h2,
.rtl .full-banner.text-right .banner-contain h3,
.rtl .full-banner.text-right .banner-contain h4 {
    text-align: right;
}

.rtl .theme-card .slick-prev {
    left: 0;
    right: unset;
}

.rtl .theme-card .slick-next {
    right: unset;
    left: 25px;
}

.rtl .theme-card .offer-slider img {
    padding: 15px 0 15px 15px;
}

.rtl .theme-card .offer-slider .media .media-body a h6 {
    margin-left: 61px;
    margin-right: 0;
}

.rtl .theme-card .offer-slider .media .media-body .rating {
    text-align: right;
}

.rtl .theme-card .offer-slider .media .media-body .rating i {
    padding-left: 5px;
}

.rtl .theme-card.card-border h5 {
    padding-left: 0;
    padding-right: 50px;
}

.rtl .theme-card.card-border .slick-prev {
    left: 30px;
}

.rtl .theme-card.card-border .slick-next {
    left: 50px;
}

.rtl .theme-card.card-border .offer-slider img {
    padding: 15px 30px 15px 15px;
}

.rtl .theme-card.card-border .offer-slider .slick-slide>div {
    direction: rtl;
}

.rtl .background .contain-bg h4 {
    text-align: center;
}

.rtl .center-slider:hover .slick-next {
    left: 20px;
    right: unset;
}

.rtl .center-slider:hover .slick-prev {
    left: unset;
    right: 20px;
}

.rtl .theme-tab .tab-title {
    -webkit-padding-start: 0;
}

.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
    padding: 15px;
}

.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .rating {
    text-align: right;
}

.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
    margin-left: 35px;
    margin-right: 0;
}

.rtl .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant {
    text-align: right;
    -webkit-padding-start: 0;
}

.rtl .theme-tab .media-banner .media-banner-box .media .media-body {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
}

.rtl .theme-tab .media-banner .media-banner-box .media .media-body .media-contant {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.rtl .beauty-about .about-text p {
    text-align: right;
}

.rtl .timer {
    padding-left: 0;
}

.rtl .timer span .padding-l {
    padding-left: 0;
    padding-right: 22px;
}

.rtl .category-m .category-wrapper h4 {
    text-align: center;
}

.rtl .category-m .category-wrapper .category-link {
    -webkit-padding-start: 0;
}

.rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body a h6 {
    margin-right: 0;
    margin-left: 20px;
}

.rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant {
    text-align: right;
    -webkit-padding-start: 0;
}

.rtl .compare-page .table-wrapper .table tbody tr th,
.rtl .compare-page .table-wrapper .table tbody tr td {
    text-align: right;
}

.rtl .compare-page .table-wrapper .table thead .th-compare td {
    text-align: right;
}

.rtl .compare-page .table-wrapper .table thead .th-compare th .remove-compare {
    float: right;
}

.rtl .product-wrap .product-detail .rating,
.rtl .product-wrap .product-detail h4,
.rtl .product-wrap .product-detail h6,
.rtl .product-wrap .product-detail .color-variant,
.rtl .product-wrap .product-info .rating,
.rtl .product-wrap .product-info h4,
.rtl .product-wrap .product-info h6,
.rtl .product-wrap .product-info .color-variant {
    text-align: center;
}

.rtl .product-wrap .product-detail .color-variant li,
.rtl .product-wrap .product-info .color-variant li {
    margin: 0 2px;
}

.rtl .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .media img.me-3 {
    margin-right: unset !important;
    margin-left: 1rem !important;
}

.rtl .main-menu .menu-right .icon-nav .onhover-div .show-div.shopping-cart li .close-circle {
    right: unset;
    left: 0;
}

.rtl .main-menu .menu-left .navbar i {
    float: unset;
}

.rtl .sitemap_page ul {
    text-align: right;
}

.rtl .team .slick-next {
    right: unset;
    left: 20px;
}

.rtl .team .slick-prev {
    right: unset;
    left: 0;
}

.rtl .team h4,
.rtl .team h6 {
    text-align: center;
}

.rtl .blog-page .blog-sidebar .theme-card .recent-blog {
    text-align: right;
}

.rtl .blog-page .blog-sidebar .theme-card .recent-blog li .media img {
    margin-right: 0;
    margin-left: 10px;
}

.rtl .blog-page .blog-sidebar .theme-card .popular-blog li .blog-date {
    margin: 0 0 0 15px;
}

.rtl .blog-page .blog-media .blog-right ul {
    text-align: right;
}

.rtl .blog-page .blog-media .blog-right ul li+li {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #dddddd;
    padding-left: 0;
    margin-left: 0;
    border-left: none;
}

.rtl .blog-page .blog-media.media-change .blog-right h4,
.rtl .blog-page .blog-media.media-change .blog-right ul,
.rtl .blog-page .blog-media.media-change .blog-right p {
    text-align: left;
}

.rtl .blog-detail-page .blog-detail .post-social {
    text-align: right;
}

.rtl .blog-detail-page .blog-detail .post-social li+li {
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #dddddd;
    padding-left: 0;
    margin-left: 0;
    border-left: none;
}

.rtl .blog-detail-page .blog-advance ul {
    text-align: right;
}

.rtl .blog-detail-page .comment-section li img {
    margin-right: 0;
    margin-left: 20px;
}

.rtl .blog-detail-page .blog-contact .theme-form label,
.rtl .blog-detail-page .blog-contact .theme-form .btn-solid,
.rtl .blog-detail-page .blog-contact .theme-form .btn-outline {
    float: right;
}

.rtl .cart-section tbody tr td h2,
.rtl .cart-section tbody tr td p,
.rtl .wishlist-section tbody tr td h2,
.rtl .wishlist-section tbody tr td p {
    text-align: center;
}

.rtl .cart-section tfoot tr td:first-child,
.rtl .wishlist-section tfoot tr td:first-child {
    padding-right: 0;
    padding-left: 10px;
    text-align: left;
}

.rtl .cart-section .cart-buttons>div:last-child,
.rtl .wishlist-section .cart-buttons>div:last-child {
    text-align: left;
    padding-left: 59px;
    padding-right: 0;
}

.rtl .cart-section .cart-buttons>div:first-child .btn-solid,
.rtl .cart-section .cart-buttons>div:first-child .btn-outline,
.rtl .wishlist-section .cart-buttons>div:first-child .btn-solid,
.rtl .wishlist-section .cart-buttons>div:first-child .btn-outline {
    float: right;
}

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li:first-child {
    margin-right: 0;
    margin-left: 14px;
}

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
    border-right: none;
    border-left: 1px solid #dddddd;
    padding-right: 0;
    padding-left: 20px;
}

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select {
    border-right: none;
    border-left: 1px solid #dddddd;
    padding: 20px;
    background: url("/../assets/images/dropdown.png") no-repeat 5%;
}

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
    border-right: none;
    border-left: 1px solid #dddddd;
}

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
    padding: 20px;
    background: url("/../assets/images/dropdown.png") no-repeat 5%;
}

.rtl .collection-product-wrapper .product-top-filter .popup-filter .open-popup {
    left: unset;
    right: 0;
}

.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .search-count {
    border-right: none;
    border-left: 1px solid #dddddd;
}

.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view select,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup select,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .search-count select {
    border-right: none;
}

.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul {
    float: right;
}

.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li:first-child {
    margin-right: 0;
    margin-left: 14px;
}

.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    background: url("/../assets/images/dropdown.png") no-repeat 5%;
}

.rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
    background: url("/../assets/images/dropdown.png") no-repeat 5%;
}

.rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
    text-align: right;
    background: url("/../assets/images/dropdown.png") no-repeat 5%;
}

.rtl .collection-product-wrapper .product-top-filter .product-filter-content .collection-view,
.rtl .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
    text-align: right;
}

.rtl .metro-block .product-box .product-imgbox .product-detail {
    text-align: center;
}

.rtl .product-wrapper-grid.list-view .product .product-box .product-detail.detail-center {
    padding-right: 25px;
    padding-left: 0;
}

.rtl .qty-box .input-group button:last-child {
    border-left: 1px solid #dddddd;
}

.rtl .product-pagination .product-search-count-bottom {
    padding-right: 0;
    padding-left: 15px;
}

.rtl .product-pagination .pagination .page-item a i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rtl .custom-control-label:after {
    right: -1.5rem;
    left: unset;
}

.rtl .contact-page .theme-form label {
    float: right;
}

.rtl .contact-page .theme-form .btn-solid,
.rtl .contact-page .theme-form .btn-outline {
    float: right;
}

.rtl .contact-page .contact-right ul li {
    padding-right: 150px;
    padding-left: 0;
}

.rtl .contact-page .contact-right ul li .contact-icon {
    left: unset;
    border-left: 1px solid #dddddd;
    border-right: 0;
    right: 0;
}

.rtl .contact-page .contact-right ul li .contact-icon h6 {
    text-align: center;
}

.rtl .faq-section .accordion-item .accordion-header .accordion-button:after {
    right: unset;
    left: 0;
    margin-right: auto;
    margin-left: unset;
}

.rtl .login-page .theme-card {
    text-align: right;
}

.rtl .login-page .theme-card .theme-form label {
    float: right;
}

.rtl .register-page .theme-card .theme-form label {
    float: right;
}

.rtl .pwd-page h3 {
    text-align: center;
}

.rtl .pwd-page .form-group {
    text-align: center;
}

.rtl .image-swatch {
    text-align: right;
}

.rtl .product-title i {
    margin-left: 5px;
    margin-right: unset;
}

.rtl .product-right .pro-group ul.pro-price li:last-child {
    margin-right: 5px;
    margin-left: unset;
}

.rtl .product-right .pro-group ul.best-seller li:nth-child(n+2) {
    margin-right: 10px;
    margin-left: unset;
}

.rtl .product-right .pro-group ul.best-seller li svg {
    margin-left: 5px;
    margin-right: unset;
}

.rtl .product-right .pro-group ul.delivery-services li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 10px;
}

.rtl .product-right .pro-group .revieu-box span {
    margin-left: unset;
    margin-right: 10px;
}

.rtl .product-right .pro-group .timer p span:nth-child(n+2) {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .product-right .pro-group .product-buttons a:last-child {
    margin-right: 10px;
    margin-left: unset;
}

.rtl .product-right .pro-group .delivery-detail .delivery-detail-contian .input-group .form-control {
    border-left: 1px solid #dddddd;
    border-right: unset;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.rtl .product-right .pro-group .delivery-detail .delivery-detail-contian .input-group .input-group-text {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rtl .product-right .pro-group .delivery-detail .delivery-detail-contian .btn {
    margin-right: 10px;
}

.rtl .product-right .pro-group .delivery-detail .delivery-lable svg {
    margin-left: 10px;
    margin-right: unset;
}

.rtl .product-right .pro-group .product-social li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 5px;
}

.rtl .product-right.product-form-box .product-title {
    text-align: center;
}

.rtl .product-right.product-form-box .timer p {
    text-align: center;
}

.rtl .product-offer .offer-contain ul li .code-lable {
    margin-left: 10px;
    margin-right: unset;
}

.rtl .color-selector ul li>div {
    margin-left: 5px;
    margin-right: unset;
}

.rtl .single-product-tables {
    text-align: right;
}

.rtl .single-product-tables.detail-section {
    text-align: right;
}

.rtl .tab-product .theme-form input,
.rtl .product-full-tab .theme-form input {
    text-align: right;
}

.rtl .tab-product .tab-content .theme-form .media .ms-3,
.rtl .product-full-tab .tab-content .theme-form .media .ms-3 {
    margin-left: 0 !important;
    margin-right: 1rem !important;
}

.rtl .theme-form {
    text-align: right;
}

.rtl .collection .collection-block .collection-content h3,
.rtl .collection .collection-block .collection-content h4,
.rtl .collection .collection-block .collection-content p {
    text-align: center;
}

.rtl .product-right-slick .slick-next,
.rtl .product-slick .slick-next {
    right: unset;
    left: 1px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rtl .product-right-slick .slick-prev,
.rtl .product-slick .slick-prev {
    left: unset;
    right: 1px;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rtl .product-right-slick:hover .slick-next,
.rtl .product-slick:hover .slick-next {
    right: unset;
    left: 20px;
}

.rtl .product-right-slick:hover .slick-prev,
.rtl .product-slick:hover .slick-prev {
    left: unset;
    right: 20px;
}

.rtl .collection-wrapper .product-right .product-buttons {
    text-align: center;
}

.rtl .collection-wrapper .product-right .size-box {
    text-align: center;
}

.rtl .collection-wrapper .product-right .size-box ul li {
    margin-right: 0;
    margin-left: 10px;
}

.rtl .collection-wrapper .product-right .product-description h6 span {
    float: left;
}

.rtl .collection-mobile-back span i {
    float: left;
}

.rtl .collection-collapse-block .collapse-block-title:after {
    left: -3px;
    right: unset;
}

.rtl .collection-filter-block .product-service .media .media-body {
    padding-right: 10px;
    padding-left: 0;
}

.rtl .collection-sidebar-banner {
    text-align: right;
}

.rtl .tab-border .nav-border {
    border-right: 0;
    border-left: 1px solid #dddddd;
}

.rtl .product-pagination .pagination .page-item.active a {
    border-right: none;
}

.rtl .collection-collapse-block .collection-collapse-block-content .color-selector ul {
    text-align: right;
}

.rtl .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox {
    text-align: right;
    padding-right: unset;
}

.rtl .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox .form-check-input {
    float: right;
    margin-left: 5px;
    margin-right: unset;
}

.rtl .theme-modal .modal-dialog .quick-view-modal button.btn-close {
    left: 10px;
    right: unset;
}

.rtl .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .btn-close {
    left: 17px;
    right: unset;
}

.rtl .custom-control-label:before {
    right: -1.5rem;
    left: 0;
}

.rtl .product-wrapper-grid.list-view .product-box .product-detail {
    padding-right: 15px;
    padding-left: 0;
}

.rtl .main-menu .menu-right {
    float: left;
}

.rtl .search-overlay>div .overlay-content button {
    right: unset;
    left: 10px;
}

.rtl .dark-layout h2 {
    text-align: center;
}

.rtl .dark-layout .footer-contant p {
    text-align: center;
}

.rtl .subscribe-form.classic-form .form-control {
    margin-left: 0;
    margin-right: 15px;
}

.rtl .rtl-text .product-right .color-variant,
.rtl .rtl-text .product-right .size-box,
.rtl .rtl-text .product-right .product-buttons,
.rtl .rtl-text .product-right .border-product {
    text-align: right;
}

.rtl .search-block .form-header .input-group i {
    padding-right: 0;
    padding-left: 10px;
}

.rtl .payment-box .payment-options li .radio-option {
    text-align: right;
}

.rtl .payment-box .payment-options li .radio-option label {
    padding-right: 30px;
    padding-left: 0;
}

.rtl .payment-box .payment-options li .radio-option input[type="radio"] {
    right: 0;
    left: unset;
}

.rtl .paymant-offer-mian h2,
.rtl .paymant-offer-mian h3,
.rtl .paymant-offer-mian h4 {
    text-align: center;
}

.rtl .bottom-cart-sticky .product-image .content {
    margin-right: 12px;
    margin-left: unset;
}

.rtl .bottom-cart-sticky .btn {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .bottom-cart-sticky .selection-section .form-group:nth-child(2) {
    margin-right: 40px;
    margin-left: unset;
}

.rtl .order-box .total li .count {
    text-align: right;
}

.rtl .order-box .sub-total .shipping {
    text-align: right;
    float: left;
}

.rtl .order-box .sub-total .shopping-option label {
    float: unset;
}

.rtl .order-box .sub-total li .count {
    text-align: right;
}

.rtl .order-box .qty li span {
    text-align: right;
}

.rtl .order-box .title-box span {
    text-align: right;
}

.rtl .error-section h1,
.rtl .error-section h2 {
    text-align: center;
}

.rtl .dashboard .box .box-title>a {
    right: unset;
    left: 0;
}

.rtl .dashboard .box address {
    text-align: right;
}

.rtl .success-text h2,
.rtl .success-text p {
    text-align: center;
}

.rtl .product-order .product-order-detail {
    text-align: right;
}

.rtl .product-order .final-total h3 span {
    float: left;
}

.rtl .product-order .total-sec ul li span {
    float: left;
}

.rtl .delivery-sec h2,
.rtl .delivery-sec h3 {
    text-align: center;
}

.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 {
    text-align: center;
}

.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6,
.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h4 {
    text-align: center;
}

.rtl .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .product-upsell h4 {
    text-align: center;
}

.rtl .add_to_cart .cart-inner .cart_top .close-cart {
    margin-right: auto;
    margin-left: unset;
}

.rtl .add_to_cart .cart-inner .cart_media .close-circle {
    left: 0;
    right: unset;
}

.rtl .add_to_cart .cart-inner .cart_media ul.cart_total li .total h5 span {
    float: left;
}

.rtl .add_to_cart .cart-inner .cart_media ul.cart_total li span {
    float: left;
}

.rtl .add_to_cart.top .cart-inner .cart_media ul.cart_product li:nth-child(n+2),
.rtl .add_to_cart .bottom .cart-inner .cart_media ul.cart_product li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .payment-box .payment-options li .radio-option label img {
    margin-right: -30px;
    margin-left: 0;
}

.rtl .checkout-page .checkout-form select {
    background: url("/../assets/images/dropdown.png") no-repeat 3%;
}

.rtl .checkout-second .checkout-box .checkout-header h2 {
    text-align: center;
}

.rtl .checkout-second .checkout-box .checkout-header h4 {
    text-align: center;
}

.rtl .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box {
    text-align: right;
}

.rtl .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .small-group .small-sec {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .small-group select {
    background-position: 5%;
}

.rtl .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .small-group select:nth-child(n+2) {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .payment-success h2 {
    text-align: center;
}

.rtl .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card ul.payment-info li:nth-child(n+2) {
    margin-left: unset;
    margin-right: calc(2px + (15 - 2) * ((100vw - 320px) / (1920 - 320)));
}

.rtl .checkout-second .checkout-box .checkout-body .checkout-form .menu-bar li:nth-child(n+2) {
    margin-left: unset;
    margin-right: calc(10px + (70 - 10) * ((100vw - 320px) / (1920 - 320)));
}

.rtl .checkout-second .checkout-box .checkout-body .previous {
    margin-right: unset;
    margin-left: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.rtl .compare-section .compare-part .detail-part .title-detail h5 {
    text-align: center;
}

.rtl .dashboard-left .block-content ul li:hover {
    padding-right: 10px;
    padding-left: 0;
}

.rtl .j-box .product-box .cart-info.cart-wrap,
.rtl .j-box .product-wrap .cart-info.cart-wrap {
    left: 0;
    right: 0;
}

.rtl .j-box .product-box .cart-info a {
    border-right: 1px solid #dddddd;
    border-left: none;
}

.rtl .product-m .slick-slide>div {
    direction: rtl;
}

.rtl .detail-cannabis .detail_section h4,
.rtl .detail-cannabis .detail_section p {
    text-align: center;
}

.rtl .tools-grey .product-box.product-wrap .cart-info.cart-wrap {
    left: 0;
    right: 0;
}

.rtl .tab-left .theme-tab .left-side .tab-title {
    margin-left: 100px;
    text-align: right;
    margin-right: 0;
}

.rtl .review-page .comnt-sec {
    text-align: right;
}

.rtl .review-page .comnt-sec li {
    margin-right: 0;
    margin-left: 15px;
}

.rtl .review-page .comnt-sec li a i {
    margin-right: 0;
    margin-left: 5px;
}

.rtl .review-box h5,
.rtl .review-box p,
.rtl .review-box h6 {
    text-align: center;
}

.rtl .template-password #container #login h2 {
    text-align: center;
}

.rtl .template-password #container #login .theme-form {
    text-align: center;
}

.rtl .template-password #container #login .theme-form h3 {
    text-align: center;
}

.rtl .template-password #container #powered p {
    text-align: center;
}

.rtl .ribbon-1 {
    right: 14px;
    left: unset;
}

.rtl .theme-modal.demo-modal .modal-dialog .modal-content .modal-body .demo-section .demo-effects>div .demo-text h4 {
    text-align: center;
}

.rtl .product .product-box .product-detail.product-detail2 {
    text-align: center;
}

.rtl .product .product-box .product-detail.product-detail2 .color-variant {
    text-align: center;
}

.rtl .digipro-box .product-detail {
    direction: rtl;
}

.rtl .cosmetic-slide .slide-main .slide-contain h3,
.rtl .cosmetic-slide .slide-main .slide-contain h2,
.rtl .cosmetic-slide .slide-main .slide-contain h4 {
    text-align: center;
}

.rtl .furniture-slide .slide-main .slide-contain h3,
.rtl .furniture-slide .slide-main .slide-contain h4,
.rtl .furniture-slide .slide-main .slide-contain h2,
.rtl .furniture-slide .slide-main .slide-contain p {
    text-align: left;
}

.rtl .farming-slide .slide-main .slide-contain h3,
.rtl .farming-slide .slide-main .slide-contain h2,
.rtl .farming-slide .slide-main .slide-contain h4 {
    text-align: center;
}

.rtl .megastore-slide .slide-main .slide-contain h4,
.rtl .megastore-slide .slide-main .slide-contain h2,
.rtl .megastore-slide .slide-main .slide-contain h3 {
    text-align: left;
}

.rtl .megastore-slide .mega-slide-block>div:first-child {
    padding-right: 275px;
    padding-left: 15px;
}

.rtl .megastore-slide .mega-slide-block>div:first-child>div>div:last-child {
    padding-right: unset;
    padding-left: 15px;
}

.rtl .megastore-slide .mega-slide-block>div:nth-child(n+2) {
    padding-left: 15px;
    padding-right: unset;
}

.rtl .digitalmark-slide .slide-main .slide-contain {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.rtl .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group .btn {
    left: 10px;
    right: unset;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.rtl .gift-block-main .gift-block .gift-offer {
    margin-right: 10px;
    margin-left: unset;
}

.rtl .product-box3 .media .media-body {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
}

.rtl .product-box3 .product-detail {
    padding-right: 20px;
    padding-left: unset;
    text-align: right;
}

.rtl .product-box3 .product-detail .rating {
    text-align: right;
}

.rtl .sale-banenr .sale-banenr-contain.p-left {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.rtl .sale-banenr .sale-banenr-contain.p-right {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.rtl .sale-banenr .sale-banenr-contain.text-center h4 {
    text-align: center;
}

.rtl .sale-banenr .sale-banenr-contain.text-center h3 {
    text-align: center;
}

.rtl .theme-slider .slider-banner .slider-banner-contain h4,
.rtl .theme-slider .slider-banner .slider-banner-contain h2,
.rtl .theme-slider .slider-banner .slider-banner-contain h5,
.rtl .theme-slider .slider-banner .slider-banner-contain h3,
.rtl .theme-slider .slider-banner .slider-banner-contain h1 {
    text-align: left;
}

.rtl .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain h4,
.rtl .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain h1 {
    text-align: center;
}

.rtl .kids-slide .slide-main .slide-contain h4,
.rtl .kids-slide .slide-main .slide-contain h2,
.rtl .kids-slide .slide-main .slide-contain h3,
.rtl .kids-slide .slide-main .slide-contain p {
    text-align: left;
}

.rtl .tools-slide .slide-main .slide-contain h2,
.rtl .tools-slide .slide-main .slide-contain h4,
.rtl .tools-slide .slide-main .slide-contain h3,
.rtl .tools-slide .slide-main .slide-contain p {
    text-align: left;
}

.rtl .pets-slide .slide-main .slide-contain h3 {
    text-align: center;
}

.rtl .grocery-slide .slide-main .slide-contain h3,
.rtl .grocery-slide .slide-main .slide-contain h2,
.rtl .grocery-slide .slide-main .slide-contain h4 {
    text-align: center;
}

.rtl .header-category .category-toggle i {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .header-category2 ul.collapse-category li a .icon {
    margin-left: 15px;
    margin-right: unset;
}

.rtl .header-category ul.collapse-category li a img {
    margin-left: 10px;
    margin-right: unset;
}

.rtl .header-category3 ul.collapse-category {
    text-align: right;
}

.rtl .header-category3 ul li a span:before {
    left: 15px;
    right: unset;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.rtl .header-category3 ul .categoryone .collapse-two {
    left: unset;
    right: 100%;
}

.rtl .header-category3 ul .categoryone .collapse-two .collapse-third {
    left: unset;
    right: 100%;
}

.rtl .header-category3 ul .categoryone .collapse-mega {
    left: unset;
    right: 100%;
}

.rtl .grocery-slide .slide-main {
    margin-left: unset;
    margin-right: 260px;
}

.rtl .product .product-box .product-detail .detail-title {
    direction: rtl;
}

.rtl .product .product-box .product-detail .detail-title .detail-right {
    text-align: left;
}

.rtl .product .product-box .product-detail .detail-title .detail-left {
    text-align: right;
}

.rtl .product .product-box .product-detail.detail-center .detail-title .detail-right .price {
    margin-right: 10px;
    margin-left: 0;
}

.rtl .product .product-box .product-detail.detail-center1 h6 {
    text-align: center;
}

.rtl .product .product-box .product-detail h3 {
    text-align: center;
}

.rtl .product .product-box .product-detail h5 {
    text-align: center;
}

.rtl .masonory-banner .masonary-banner-main .masonary-banner-contant h2,
.rtl .masonory-banner .masonary-banner-main .masonary-banner-contant h5 {
    text-align: center;
}

.rtl .masonory-banner .masonary-banner-main .masonary-banner-contant .masonary-banner-subcontant {
    margin-left: 70px;
    margin-right: 0;
    padding: 20px;
}

.rtl .masonory-banner .masonary-banner-main .masonary-banner-contant.p-right {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.rtl .masonory-p1>div:nth-child(n+2) {
    padding-right: unset;
    padding-left: 15px;
}

.rtl .masonory-p1>div:last-child>div>div>div>div:nth-child(n+2) {
    padding-left: 15px;
    padding-right: unset;
}

.rtl .discount-banner .discount-banner-contain h2 {
    text-align: center;
}

.rtl .discount-banner .discount-banner-contain h1 {
    text-align: center;
}

.rtl .contact-banner .contact-banner-contain .contact-banner-img img {
    margin-left: 25px;
    margin-right: 0;
}

.rtl .contact-banner .contact-banner-contain .contact-banner-img img:hover {
    -webkit-animation: fade_out;
    animation: fade_out;
}

.rtl .contact-banner .contact-banner-contain h3 {
    margin-right: 0;
    margin-left: 25px;
}

.rtl .media-banner .media-banner-box .media .media-body {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
}

.rtl .media-banner .media-banner-box .media .media-body .media-contant {
    text-align: right;
    margin-left: 0;
    margin-right: 15px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.rtl .media-banner .media-banner-box .media .media-body .media-contant .cart-info {
    right: 0;
    left: unset;
}

.rtl .media-banner.media-banner-1 .media-banner-box .media .media-body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

.rtl .media-banner.media-banner-1 .media-banner-box .media .media-body .media-contant {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.rtl .media-banner.banner-inverse .media-banner-box .media .media-body {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
}

.rtl .media-banner.banner-inverse .media-banner-box .media .media-body .media-contant {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.rtl .gift-dropdown.dropdown-menu .media .me-3 {
    margin-right: 0;
    margin-left: 15px;
}

.rtl .collection-banner .collection-banner-main .collection-banner-contain h1,
.rtl .collection-banner .collection-banner-main .collection-banner-contain h2,
.rtl .collection-banner .collection-banner-main .collection-banner-contain h3,
.rtl .collection-banner .collection-banner-main .collection-banner-contain h4,
.rtl .collection-banner .collection-banner-main .collection-banner-contain h5,
.rtl .collection-banner .collection-banner-main .collection-banner-contain h6 {
    text-align: right;
}

.rtl .collection-banner .collection-banner-main .collection-banner-contain .shop {
    text-align: right;
}

.rtl .collection-banner .collection-banner-main .collection-banner-contain.text-center h3,
.rtl .collection-banner .collection-banner-main .collection-banner-contain.text-center h4,
.rtl .collection-banner .collection-banner-main .collection-banner-contain.text-center .shop {
    text-align: center;
}

.rtl .collection-banner .collection-banner-main .collection-img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.rtl .collection-banner .collection-banner-main.banner-3 h4 {
    text-align: center;
}

.rtl .collection-banner .collection-banner-main.banner-5 .collection-banner-contain h3,
.rtl .collection-banner .collection-banner-main.banner-5 .collection-banner-contain .shop,
.rtl .collection-banner .collection-banner-main.banner-5 .collection-banner-contain h4 {
    text-align: center;
}

.rtl .collection-banner .collection-banner-main.text-center h3,
.rtl .collection-banner .collection-banner-main.text-center h4,
.rtl .collection-banner .collection-banner-main.text-center .shop {
    text-align: center;
}

.rtl .collection-banner .collection-p2>div:first-child>div>div:first-child {
    padding-right: 15px;
    padding-left: unset;
}

.rtl .collection-banner .collection-p2>div:last-child {
    padding-right: unset;
    padding-left: 15px;
}

.rtl .collection-banner .collection-p3>div:first-child {
    padding-left: unset;
    padding-right: 15px;
}

.rtl .collection-banner .collection-p3>div:last-child>div>div:first-child {
    padding-left: unset;
    padding-right: 15px;
}

.rtl .collection-banner .collection-p5>div>div>div:nth-child(n+2) {
    padding-right: unset;
    padding-left: 15px;
}

.rtl .collection3>div:last-child {
    padding-left: 0;
    padding-right: 0;
}

.rtl .collection4>div:last-child {
    padding-left: 15px;
    padding-right: 0;
}

.rtl .collection4>div:first-child {
    padding-right: 15px;
}

.rtl .layout-3-collection>div:first-child {
    padding-right: 15px;
    padding-left: unset;
}

.rtl .contact-banner2 .subscribe-box .btn {
    margin-left: auto;
}

.rtl .subscribe-block1>div:nth-child(n+2) {
    padding-left: 15px;
    padding-right: unset;
}

.rtl .team1 .team-box .team-detail {
    text-align: right;
}

.rtl .team1 .team-box .team-detail ul {
    direction: rtl;
}

.rtl .team1 .team-box .team-detail ul li:nth-child(n+2) {
    margin-right: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
    margin-left: unset;
}

.rtl .team2 .team-box .team-detail h3 {
    text-align: center;
}

.rtl .team2 .team-box .team-detail h5 {
    text-align: center;
}

.rtl .box-category .box-category-contain h4 {
    text-align: center;
}

.rtl .category1 .category-box .category-details h4 {
    text-align: center;
}

.rtl .category2 .category-box .category-details h6 {
    text-align: center;
}

.rtl .category3 .category-box .category-detail h4 {
    text-align: center;
}

.rtl .category4 .category-box .category-detail {
    text-align: right;
}

.rtl .category4 .category-box .category-detail ul li {
    padding-right: 15px;
}

.rtl .category4 .category-box .category-detail ul li::before {
    left: unset;
    right: 0;
}

.rtl .category5 .category-box .contain-wrraper h3 {
    text-align: center;
}

.rtl .category5 .category-box .contain-wrraper p {
    text-align: center;
}

.rtl .blog .blog-contain .blog-img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.rtl .blog .blog-contain .blog-details {
    text-align: right;
}

.rtl .blog .blog-contain .blog-details h4 {
    text-align: right !important;
}

.rtl .blog .blog-contain .blog-details-2 ul {
    text-align: right;
    direction: rtl;
}

.rtl .blog .blog-contain .blog-details-2 ul li a i {
    margin-right: 0;
    margin-left: 10px;
}

.rtl .blog .blog-contain .blog-details-2 ul li:first-child {
    margin-right: 0;
    margin-left: 30px;
}

.rtl .blog .blog-contain .blog-details-2 ul.blog-date li i {
    margin-left: 10px;
    margin-right: unset;
}

.rtl .blog .blog-contain .blog-details-2 .btn {
    margin-left: auto;
}

.rtl .blog .blog-contain .blog-details-2.text-center h4 {
    text-align: center;
}

.rtl .blog .blog-contain .blog-details-2.text-center p {
    text-align: center;
}

.rtl .blog .blog-contain .blog-details-2.text-center ul {
    text-align: center;
}

.rtl .blog .blog-contain .blog-label {
    left: unset;
    right: 0;
}

.rtl .blog .blog-contain .blog-label1 {
    left: unset;
    right: 10px;
}

.rtl .services .service-block .media svg {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-right: 0;
}

.rtl .services1 .services-box .media .media-body {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
    margin-left: unset;
    margin-right: 15px;
    text-align: right;
}

.rtl .services1 .services-box .media .media-body h4 {
    text-align: right;
}

.rtl .services1 .services-box .media .media-body p {
    text-align: right;
}

.rtl .services1.block .services-box .media .media-body {
    margin-right: unset;
}

.rtl .services1.block .services-box .media .media-body h4 {
    text-align: center;
}

.rtl .services1.block .services-box .media .media-body p {
    text-align: center;
}

.rtl .services1.border-services .services-box .media .icon-wrraper svg {
    margin-left: unset;
}

.rtl .services1.border-services .services-box .media .media-body {
    text-align: center;
}

.rtl .services2 .services-box svg {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin-left: calc(10px + (25 - 10) * ((100vw - 320px) / (1920 - 320)));
    margin-right: unset;
}

.rtl .video-banner .video-banner-contain h3 {
    text-align: center;
}

.rtl .video-banner .video-banner-contain h2 {
    text-align: center;
}

.rtl .testimonial .testimonial-contain .media {
    direction: rtl;
}

.rtl .testimonial .testimonial-contain .media .testimonial-img img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.rtl .testimonial .testimonial-contain .media .media-body {
    margin: 20px 40px 0 0;
}

.rtl .testimonial.block .testimonial-contain .media .media-body {
    margin-right: 0;
}

.rtl .testimonial.block .testimonial-contain .media .media-body h5 {
    text-align: center;
}

.rtl .testimonial.block .testimonial-contain .media .media-body p {
    text-align: center;
}

.rtl .testimonial2 .testimonial-box .testimonial-detail p,
.rtl .testimonial2 .testimonial-box h3,
.rtl .testimonial2 .testimonial-box h6 {
    text-align: center;
}

.rtl .testimonial3 .testimonial-box .testimonial-detail h3 {
    text-align: center;
}

.rtl .testimonial3 .testimonial-box .testimonial-detail h6 {
    text-align: center;
}

.rtl .testimonial3 .testimonial-box .testimonial-detail p {
    text-align: center;
}

.rtl .testimonial4 .testimonial-box .testimonial-detail h3 {
    text-align: center;
}

.rtl .testimonial4 .testimonial-box .testimonial-detail h6 {
    text-align: center;
}

.rtl .testimonial4 .testimonial-box .testimonial-detail p {
    text-align: center;
}

.rtl .testimonial5 .testimonial-detail h3 {
    text-align: center;
}

.rtl .testimonial5 .testimonial-detail h4 {
    text-align: center;
}

.rtl .testimonial5 .testimonial-detail p {
    text-align: center;
}

.rtl .testimonial6 .testimonial-box .testimonial-detail h3 {
    text-align: center;
}

.rtl .testimonial6 .testimonial-box .testimonial-detail h6 {
    text-align: center;
}

.rtl .testimonial6 .testimonial-box .testimonial-detail p {
    text-align: center;
}

.rtl .key-feature .key-feature-box .key-feature-detail h4 {
    text-align: center;
}

.rtl .tab-product-main.tab-four .tab-prodcut-contain ul li:nth-child(n+2) {
    margin-left: unset;
    margin-right: calc(0px + (15 - 0) * ((100vw - 320px) / (1920 - 320)));
}

.rtl .tab-product-main.tab-second .tab-prodcut-contain ul li a svg {
    margin-left: 15px;
    margin-right: unset;
}

.rtl .tab-product-main .tab-prodcut-contain ul li:nth-child(n+2) {
    margin-right: 40px;
    margin-left: unset;
}

.rtl .newsletter .news-leble {
    padding-right: 0;
    padding-left: 35px;
    border-left: 1px solid #dddddd;
    border-right: 0;
}

.rtl .newsletter .news-leble .news-text {
    margin-left: 0;
    margin-right: 25px;
}

.rtl .instagram .insta-contant .insta-sub-contant .insta-title h4 span {
    margin-left: 10px;
    margin-right: 0;
}

.rtl .instagram .insta-contant1 .insta-sub-contant1 .insta-title h4 {
    text-align: center;
}

.rtl .instagram .insta-contant1 .insta-sub-contant1 .insta-title h4 span {
    margin-left: 10px;
    margin-right: 0;
}

.rtl .instagram .insta-contant2 .insta-sub-contant2 .insta-title h4 span {
    margin-left: 10px;
    margin-right: 0;
}

.rtl .breadcrumb-main .breadcrumb-contain ul li {
    margin-right: 15px;
}

.rtl .breadcrumb-main .breadcrumb-contain ul li:first-child {
    margin-right: 0;
}

.rtl .breadcrumb-main .breadcrumb-contain h2 {
    text-align: center;
}

.rtl .title1.text-left {
    padding-left: unset;
    padding-right: 50px;
}

.rtl .hot-deal.hotdeal-two .hot-deal-contain .rating {
    text-align: right;
}

.rtl .hot-deal.hotdeal-first .hot-deal-contain .rating,
.rtl .hot-deal.hotdeal-third .hot-deal-contain .rating {
    text-align: right;
}

.rtl .hot-deal .hot-deal-contain .btn {
    margin-left: auto;
}

.rtl .hot-deal .hot-deal-contain .hot-deal-center .btn {
    margin-left: auto;
}

.rtl .hot-deal .hot-deal-contain .hotdeal-right-nav img {
    margin-right: auto;
    margin-left: unset;
}

.rtl .hot-deal .hot-deal-contain,
.rtl .hot-deal .hot-deal-contain1 {
    direction: rtl;
}

.rtl .hot-deal .hot-deal-contain .hot-deal-center .rating,
.rtl .hot-deal .hot-deal-contain1 .hot-deal-center .rating {
    text-align: right;
}

.rtl .hot-deal .hot-deal-contain .hot-deal-center .price,
.rtl .hot-deal .hot-deal-contain1 .hot-deal-center .price {
    text-align: right;
}

.rtl .hot-deal .hot-deal-contain .hot-deal-center .timer span:first-child,
.rtl .hot-deal .hot-deal-contain1 .hot-deal-center .timer span:first-child {
    margin-right: 0;
}

.rtl .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hotdeal-right-nav-1 img:last-child {
    margin: unset;
}

.rtl .hot-deal .slick-prev {
    left: 25px;
    right: unset;
}

.rtl .hot-deal .slick-next {
    left: 50px;
    right: unset;
}

.rtl .hot-1>div:first-child {
    padding-left: 0;
}

.rtl .hot-1>div:last-child {
    padding-right: 0;
}

.rtl .hot-4:first-child .pe-lg-0 {
    padding-left: 0;
    padding-right: 15px;
}

.rtl .hot-4:first-child:last-child .p-l-md-0 {
    padding-right: 0;
    padding-left: 15px;
}

.rtl .hotdeal-second .hotdeal-box .img-wrapper {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}

.rtl .hotdeal-second .hotdeal-box .hotdeal-contain ul {
    text-align: right;
}

.rtl .hotdeal-second .hotdeal-box .hotdeal-contain .timer2 p {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.rtl .hotdeal-second .hotdeal-box .hotdeal-contain .btn {
    margin-left: auto;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.rtl .Jewellery-banner h6 {
    text-align: center;
}

.rtl .layout-3-hotdeal>div:first-child {
    padding-right: 15px;
    padding-left: 0;
}

.rtl .layout-3-hotdeal>div:nth-child(2) {
    padding-right: 15px;
    padding-left: 0;
}

.rtl .layout-3-hotdeal>div:nth-child(4) {
    padding-left: 15px;
    padding-right: 0;
}

.rtl .top-header .top-header-right .language-block {
    margin-left: 0;
    margin-right: 35px;
}

.rtl .top-header .top-header-right .language-block .language-dropdown .language-dropdown-click i {
    margin-left: 0;
    margin-right: 10px;
}

.rtl .top-header .top-header-right .language-block .curroncy-dropdown {
    margin-left: 0;
    margin-right: 30px;
}

.rtl .top-header .top-header-right .language-block .curroncy-dropdown .curroncy-dropdown-click i {
    margin-right: 10px;
    margin-left: 0;
}

.rtl .top-header .top-header-right .top-menu-block ul li:first-child {
    margin-right: 0;
}

.rtl .top-header .top-header-right .top-menu-block ul li:last-child {
    margin-right: 20px;
}

.rtl .top-header .top-header-left .app-link ul li {
    margin-right: 10px;
    margin-left: 0;
}

.rtl .top-header .top-header-left .shpping-order {
    margin-left: 35px;
    margin-right: 0;
}

.rtl .top-header2 ul li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .top-header2 ul li a i {
    margin-left: 10px;
    margin-right: unset;
}

.rtl .top-header2 .top-header-left {
    text-align: right;
}

.rtl .top-header2 .top-header-right {
    text-align: left;
}

.rtl .gift-block-main .gift-toggle .grif-icon {
    margin-left: 20px;
    margin-right: unset;
}

.rtl .header-search3 .icon-block ul li {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .header7 .header-contain .logo-block .mobilecat-toggle {
    margin-right: unset;
    margin-left: 15px;
}

.rtl .header7 .header-contain .icon-block ul.rounde-icon li:nth-child(n+2) {
    margin-left: unset;
    margin-right: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.rtl .header7 .header-contain .icon-block ul.theme-color li:nth-child(n+2) {
    margin-right: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
    margin-left: unset;
}

.rtl .header7 .header-contain .icon-block .toggle-nav {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .header-category .category-toggle i {
    margin-left: 15px;
    margin-right: unset;
}

.rtl .header-category ul.collapse-category li {
    text-align: right;
}

.rtl .header-category2 {
    text-align: right;
}

.rtl .header-category2 .category-toggle i {
    margin-left: 15px;
    margin-right: unset;
}

.rtl .header-search .input-group .input-group-text:first-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
}

.rtl .header-search .input-group .input-group-text:last-child {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rtl .header-search .input-group .input-group-text select {
    background: url("/../assets/images/dropdown1.png") no-repeat 14%;
    background-size: 18px;
    padding-right: 30px;
    padding-left: 64px;
}

.rtl .header-search.search-style2 .input-group .input-group-text select {
    background: url("/../assets/images/dropdown2.png") no-repeat 14%;
    background-size: 18px;
}

.rtl .header-search2 .input-group:first-child .input-group-text {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
}

.rtl .header-search2 .input-group:last-child .input-group-text {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.rtl .header-search3 .input-group .form-control {
    border-right: 1px solid #dddddd;
    border-left: none;
}

.rtl .header-search3 .input-group .input-group-text {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}

.rtl .grocery-slide-block>div:nth-child(2) {
    padding-right: unset;
    padding-left: 15px;
}

.rtl .layout-header2 .main-menu-block .brand-logo {
    margin-left: 45px;
    margin-right: unset;
}

.rtl .layout-header2 .main-menu-block .icon-block {
    margin-right: 30px;
    margin-left: unset;
}

.rtl .layout-header2 .main-menu-block .icon-block ul li .cart-block .cart-item {
    margin-right: 10px;
    margin-left: unset;
}

.rtl .layout-header2 .main-menu-block .icon-block ul li .item-count-contain {
    left: unset;
    right: 0;
}

.rtl .layout-header2 .main-menu-block .menu-nav {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .category-header-2 .navbar-menu .category-right .gift-block .gift-offer {
    margin-right: 10px;
    margin-left: unset;
}

.rtl .category-header-2 .navbar-menu .category-right .contact-block span {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .category-header-2 .navbar-menu .icon-block ul li .cart-item {
    text-align: right;
    margin-left: auto;
    margin-right: 10px;
}

.rtl .category-header-2 .navbar-menu .icon-block ul li .item-count-contain {
    left: unset;
    right: 0;
}

.rtl .category-header-2 .navbar-menu .nav-block .nav-left .navbar h5 {
    margin-right: 16px;
    margin-left: unset;
}

.rtl .category-header-2 .navbar-menu .nav-block .nav-left .nav-cat {
    text-align: right;
}

.rtl .category-header-2 .navbar-menu .nav-block .nav-left .nav-cat li img {
    margin-left: 10px;
    margin-right: unset;
}

.rtl .layout-header1 .main-menu-block .menu-right .icon-nav ul li .cart-item {
    margin-left: unset;
    margin-right: 10px;
    text-align: right;
}

.rtl .layout-header1 .main-menu-block .menu-right .icon-nav ul li .item-count-contain {
    left: unset;
    right: 13px;
}

.rtl .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block {
    margin-right: 20px;
    margin-left: unset;
}

.rtl .layout-header1 .main-menu-block .menu-right .toggle-nav {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .layout-header1 .main-menu-block .menu-left .sm-nav-block {
    margin-right: unset;
    margin-left: 15px;
}

.rtl .layout-header3 .main-menu .gift-block .gift-offer {
    margin-left: unset;
    margin-right: 10px;
}

.rtl .layout-header3 .main-menu .icon-block {
    text-align: right;
}

.rtl .layout-header3 .main-menu .icon-block ul li.mobile-wishlist .item-count-contain {
    left: unset;
    right: 14px;
}

.rtl .layout-header3 .main-menu .icon-block ul li.mobile-wishlist .cart-item {
    margin-right: 5px;
    margin-left: unset;
}

.rtl .layout-header3 .main-menu .icon-block .toggle-nav-desc {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .layout-header3 .main-menu .menu-left .nav-block .nav-left .navbar h5 {
    margin-right: 16px;
    margin-left: unset;
}

.rtl .layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat {
    text-align: right;
}

.rtl .layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat li img {
    margin-left: 10px;
    margin-right: unset;
}

.rtl .layout-header3 .main-menu .menu-left .sm-nav-block {
    margin-left: 15px;
    margin-right: unset;
}

.rtl .category-header-4 .navbar-menu .category-left .nav-block {
    margin-right: unset;
}

.rtl .category-header-4 .navbar-menu .category-left .nav-block:before {
    left: unset;
    right: -100%;
}

.rtl .category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat {
    text-align: right;
}

.rtl .category-header-4 .navbar-menu .category-left .nav-block .nav-left .navbar h5 {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .category-header-4 .navbar-menu .category-left .sm-nav-btn {
    margin-left: 15px;
    margin-right: unset;
}

.rtl .category-header-4 .navbar-menu .category-right .icon-block .toggle-nav {
    margin-right: 15px;
}

.rtl .category-header-4 .navbar-menu .category-right .gift-block .gift-offer {
    margin-right: 10px;
    margin-left: unset;
}

.rtl .category-header-4 .navbar-menu .input-block {
    margin-right: 15px;
}

.rtl .layout-header4 .icon-block ul li {
    text-align: right;
}

.rtl .layout-header4 .icon-block ul li:nth-child(n+2) {
    margin-right: 25px;
    margin-left: unset;
}

.rtl .layout-header4 .icon-block ul li .item-count-contain {
    left: unset;
    right: 0;
}

.rtl .layout-header4 .icon-block ul li a .cart-item {
    margin-left: unset;
    margin-right: 10px;
}

.rtl .layout-header4 .contact-block .desc-nav-block ul li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 30px;
}

.rtl .layout-header4 .contact-block .desc-nav-block ul li .contact-item {
    text-align: right;
    margin-right: 10px;
    margin-left: unset;
}

.rtl .category-header .navbar-menu .category-left .nav-block {
    margin-left: 10px;
    margin-right: unset;
}

.rtl .category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat {
    text-align: right;
    margin-left: 10px;
    margin-right: unset;
}

.rtl .category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li img {
    margin-left: 10px;
    margin-right: unset;
}

.rtl .category-header .navbar-menu .category-left .nav-block .nav-left .navbar h5 {
    margin-right: 15px !important;
}

.rtl .category-header .navbar-menu .category-right .gift-block {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .category-header .navbar-menu .category-right .gift-block .gift-offer {
    margin-right: 10px;
    margin-left: unset;
}

.rtl .category-header .navbar-menu .category-right .contact-block span {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .category-header7 .category-contain .icon-block .toggle-nav {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .sticky .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block {
    margin-right: unset;
}

.rtl .full-banner.p-left {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.rtl .subscribe1 .subscribe-contain .subscribe-left .media .media-body {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .subscribe1 .subscribe-contain .subscribe-right .input-group .form-control {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
}

.rtl .subscribe1 .subscribe-contain .subscribe-right .input-group .input-group-text {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rtl .subscribe1.block .subscribe-contain .subscribe-left .media .media-body h6 {
    text-align: center;
}

.rtl .subscribe1.block .subscribe-contain .subscribe-left .media .media-body h3 {
    text-align: center;
}

.rtl .app-download .app-download-main .app-download-contian h2,
.rtl .app-download .app-download-main .app-download-contian h3 {
    text-align: center;
}

.rtl ul.sosiyal li:nth-child(n+2) {
    margin-left: unset;
    margin-right: calc(5px + (10 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.rtl .footer-title .according-menu:before {
    right: unset;
    left: 15px;
}

.rtl .footer-title.active .according-menu:before {
    right: unset;
    left: 15px;
}

.rtl .footer-contant .footer-logo {
    text-align: right;
}

.rtl .footer-contant ul {
    text-align: right;
}

.rtl .footer-contant ul li {
    text-align: right;
}

.rtl .footer-contant ul.paymant li:nth-child(n+2) {
    margin-left: unset;
    margin-right: calc(5px + (15 - 5) * ((100vw - 320px) / (1920 - 320)));
}

.rtl .footer-contant .contact-list li {
    padding-left: unset;
    padding-right: 25px;
}

.rtl .footer-contant .contact-list li i {
    left: unset;
    right: 0;
}

.rtl .footer-contant .news-letter .input-group .form-control {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
}

.rtl .footer-contant .news-letter .input-group .input-group-text {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rtl .footer-contant .newsletter-second .form-group .form-control {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
    border-left: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rtl .footer-contant .newsletter-second .form-group .input-group-text {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.rtl .footer1 .footer-main>div {
    text-align: right;
}

.rtl .subfooter .footer-right ul.payment li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 10px;
}

.rtl .theme-slider .custom-container .px-abjust {
    margin-left: 0;
    margin-right: 270px;
    padding-right: 10px;
    padding-left: 10px;
}

.rtl .theme-slider .custom-container .offer-banner {
    padding-left: 15px !important;
    padding-right: 0 !important;
}

.rtl .collection-banner .collection .ps-md-0 {
    padding-left: 15px !important;
}

.rtl .collection-banner .collection .p-r-md-0 {
    margin-left: 0;
    margin-right: 270px;
    padding-right: 10px;
    padding-left: 0;
}

.rtl .ps-md-0,
.rtl .px-md-0 {
    padding-right: 0 !important;
}

.rtl .tap-top {
    right: unset;
    left: 30px;
}

.rtl .counter-banner .counter-box h1 {
    text-align: center;
}

.rtl .counter-banner .counter-box h3 {
    text-align: center;
}

.rtl .pricing-table1 .pricing-box .pricing-header h2 {
    text-align: center;
}

.rtl .pricing-table1 .pricing-box .pricing-body {
    direction: rtl;
}

.rtl .pricing-table1 .pricing-box .pricing-body ul li {
    padding-right: 25px;
    padding-left: unset;
    text-align: right;
}

.rtl .pricing-table1 .pricing-box .pricing-body ul li::before {
    right: 0;
    left: unset;
}

.rtl .pricing-table2 .pricing-box .pricing-header h3 {
    text-align: center;
}

.rtl .pricing-table2 .pricing-box .pricing-header h2 {
    text-align: center;
}

.rtl .pricing-table2 .pricing-box .pricing-body {
    direction: rtl;
}

.rtl .pricing-table2 .pricing-box .pricing-body ul li {
    padding-right: 25px;
    padding-left: unset;
    text-align: right;
}

.rtl .pricing-table2 .pricing-box .pricing-body ul li::before {
    right: 0;
    left: unset;
}

.rtl .pricing-table3 .pricing-box {
    direction: rtl;
}

.rtl .pricing-table3 .pricing-box .pricing-header h2 {
    text-align: center;
}

.rtl .pricing-table3 .pricing-box .pricing-header h4 {
    text-align: center;
}

.rtl .pricing-table3 .pricing-box .pricing-body li {
    padding-right: 25px;
    padding-left: unset;
    text-align: right;
}

.rtl .pricing-table3 .pricing-box .pricing-body li::before {
    right: 0;
    left: unset;
}

.rtl table.invoice-table th {
    text-align: right;
}

.rtl table.invoice-table td {
    text-align: right;
}

.rtl .print-bar .printbar-right {
    text-align: left;
}

.rtl .print-bar .printbar-left {
    text-align: right;
}

.rtl .theme-invoice .invoice-popup .invoice-header .header-right {
    float: left;
}

.rtl .theme-invoice .invoice-popup .invoice-header .header-right ul li h2 {
    text-align: left;
}

.rtl .theme-invoice .invoice-popup .invoice-header .header-left {
    text-align: right;
}

.rtl .theme-invoice .invoice-popup .invoice-breadcrumb .breadcrumb-right {
    float: left;
}

.rtl .theme-invoice .invoice-popup .invoice-breadcrumb .breadcrumb-left {
    float: right;
}

.rtl .invoice-second .invoice-popup .invoice-header .header-left {
    text-align: right;
}

.rtl .invoice-second .invoice-popup .invoice-header .header-right ul li {
    padding-right: unset;
    padding-left: 10px;
}

.rtl .invoice-second .invoice-popup .invoice-header .header-right ul li span {
    margin-right: unset;
    margin-left: 10px;
}

.rtl .invoice-third .invoice-popup .invoice-header .header-right ul li span {
    margin-left: 10px;
    margin-right: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: unset;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
}

.rtl .invoice-third .invoice-popup .invoice-header .header-right ul li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .invoice-third .invoice-popup .invoice-breadcrumb .breadcrumb-left {
    text-align: right;
}

.rtl .invoice-third .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li:nth-child(n+2) {
    padding-right: 25px;
    padding-left: unset;
}

.rtl .invoice-third .invoice-popup .invoice-breadcrumb .breadcrumb-left ul li svg {
    left: unset;
    right: 0;
}

.rtl .invoice-four .invoice-popup .invoice-header .header-right ul li {
    padding-right: unset;
    padding-left: 10px;
}

.rtl .invoice-four .invoice-popup .invoice-header .header-right ul li span {
    margin-right: unset;
    margin-left: 10px;
}

.rtl .invoice-four .invoice-popup .invoice-header .header-left {
    text-align: right;
}

.rtl .invoice-four .invoice-popup .invoice-header .header-contain .invoice-text {
    margin-left: 120px;
    margin-right: auto;
}

.rtl .invoice-four .invoice-popup .table-responsive-md .invoice-total .offset-8 {
    margin-right: 66.66667%;
    margin-left: unset;
}

.rtl .invoice-five .invoice-popup .invoice-header .header-right ul li span {
    margin-right: unset;
    margin-left: 10px;
    border-top-left-radius: unset;
    border-bottom-right-radius: unset;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.rtl .invoice-five .invoice-popup .invoice-header .header-left {
    text-align: right;
}

.rtl .invoice-five .invoice-popup .invoice-breadcrumb ul li {
    border-top-right-radius: 30px;
    border-top-left-radius: unset;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: unset;
}

.rtl .invoice-five .invoice-popup .invoice-breadcrumb ul li:nth-child(n+2) {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .cookie-bar .btn {
    margin-left: unset;
    margin-right: 20px;
}

.rtl .cookie-bar.left-bottom p,
.rtl .cookie-bar-right-bottom p {
    text-align: center;
}

.rtl .order-tracking #progressbar li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 130px;
}

.rtl .order-tracking #progressbar li:last-child {
    margin-left: unset;
}

.rtl .order-tracking .order-tracking-box .form-group .form-control:nth-child(n+2) {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .order-tracking .order-tracking-box .form-group ul.addresh-locat li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 10px;
}

.rtl .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li>div:nth-child(n+2) {
    margin-right: auto;
    margin-left: unset;
}

.rtl .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li .btn {
    margin-left: unset;
    margin-right: 15px;
}

.rtl .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li span {
    margin-right: 15px;
    margin-left: unset;
}

.rtl .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li:nth-child(n+2) {
    margin-left: unset;
    margin-right: 30px;
}

.rtl .order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox .reloadbtncapcha {
    margin-left: unset;
    margin-right: 10px;
}

.rtl .order-tracking .order-tracking-sidebar .coupan-block h5 svg {
    margin-left: 10px;
    margin-right: unset;
}

.rtl .order-tracking .order-tracking-sidebar ul li span {
    float: left;
}

.rtl .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body {
    margin-right: 10px;
    margin-left: unset;
}

.rtl .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .pro-add {
    right: unset;
    left: 0;
}

@media (max-width: 1679px) {
    .rtl .gift-dropdown.dropdown-menu {
        right: unset;
        left: 0 !important;
    }
}

@media (max-width: 1470px) {

    .rtl .cart-section .cart-buttons>div:last-child,
    .rtl .wishlist-section .cart-buttons>div:last-child {
        padding-left: 41px;
    }

    .rtl .collection-product-wrapper .product-filter-content .collection-view {
        border-left: none;
        border-right: 1px solid #dddddd;
    }

    .rtl .collection-product-wrapper .product-filter-content .product-page-filter {
        border-left: 1px solid #dddddd;
        border-right: none;
    }

    .rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
        border-right: 1px solid #dddddd;
        padding-right: 20px;
    }

    .rtl .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5 {
        text-align: center;
    }

    .rtl .product-right .product-icon .product-social li {
        padding-left: 10px;
    }

    .rtl .left-sidebar_space {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (max-width: 1199px) {
    .rtl .layout-header2 .main-menu-block .menu-nav {
        margin-left: unset;
    }

    .rtl .layout-header2 .main-menu-block .sm-nav-block {
        margin-right: unset;
        margin-left: 15px;
    }

    .rtl .layout-header2 .main-menu-block .brand-logo {
        margin-left: unset;
    }

    .rtl .megastore-slide .mega-slide-block>div:first-child {
        padding-right: 15px;
    }

    .rtl .megastore-slide .mega-slide-block>div:nth-child(n+2) {
        padding-right: 15px;
    }

    .rtl .grocery-slide .slide-main {
        margin-right: unset;
    }

    .rtl .collection-banner .collection .p-r-md-0 {
        margin-right: 0;
    }

    .rtl .collection-banner .collection-p6>div:nth-child(n+2) {
        padding-right: unset;
        padding-left: 15px;
    }

    .rtl .slider-layout-4 .slider-slide {
        margin-right: 0;
    }

    .rtl .layout-4-collection>div:first-child {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 15px;
    }

    .rtl .theme-slider .custom-container .px-abjust {
        margin-right: 0;
    }

    .rtl .hot-4:first-child .pe-lg-0 {
        padding-left: 15px;
    }

    .rtl .cart-section .cart-buttons>div:last-child,
    .rtl .wishlist-section .cart-buttons>div:last-child {
        padding-left: 17px;
    }

    .rtl .tab-border .nav-border {
        border-left: none;
    }

    .rtl .collection-product-wrapper .product-filter-content .collection-view {
        padding-right: 20px !important;
    }

    .rtl .tab-left .theme-tab .left-side .tab-title {
        text-align: center;
        margin-left: 0;
    }

    .rtl .product .product-box .product-detail .detail-title .detail-right .price {
        margin-left: 0;
        margin-right: 10px;
    }

    .rtl .masonory-banner .masonary-banner-main .masonary-banner-contant .masonary-banner-subcontant {
        margin-left: 30px;
        margin-right: 0;
    }

    .rtl .masonory-p1>div:first-child {
        padding-right: 15px;
    }

    .rtl .masonory-p1>div:nth-child(n+3) {
        padding-right: 15px;
    }

    .rtl .product-right.product-form-box .pro-group .timer p>span:nth-child(n+2) {
        margin-right: 5px;
        margin-left: unset;
    }

    .rtl .product-right.product-form-box .pro-group .size-box ul li {
        margin-left: unset;
    }

    .rtl .product-right.product-form-box .pro-group .color-selector ul li>div {
        margin-left: unset;
    }

    .rtl .add_to_cart.top .cart-inner .cart_media ul.cart_product li:nth-child(n+2),
    .rtl .add_to_cart .bottom .cart-inner .cart_media ul.cart_product li:nth-child(n+2) {
        margin-left: unset;
        margin-right: unset;
    }
}

@media (max-width: 991px) {
    .rtl .tools-parallax-product .tools-description .tools-form .search-button {
        text-align: center;
    }

    .rtl .tools-service .service-block+.service-block {
        border-right: none;
    }

    .rtl .testimonial .testimonial-slider .slick-track .slick-slide:nth-child(even) .media {
        border-right: none;
        padding-right: 0;
    }

    .rtl .contact-page .contact-right ul li {
        padding-right: 0;
    }

    .rtl .contact-page .contact-right ul li .contact-icon {
        border-left: none;
    }

    .rtl .contact-page .contact-right ul li p {
        text-align: center;
    }

    .rtl .rtl-text .product-right .color-variant {
        text-align: center;
    }

    .rtl .rtl-text .product-right .size-box {
        text-align: center;
    }

    .rtl .rtl-text .product-right .product-buttons {
        text-align: center;
    }

    .rtl .rtl-text .product-right .border-product {
        text-align: center;
    }

    .rtl .product-accordion .card-header h5 {
        text-align: center;
    }

    .rtl .image-swatch {
        text-align: center;
    }

    .rtl .product-right h4,
    .rtl .product-right h2,
    .rtl .product-right h3,
    .rtl .product-right p,
    .rtl .product-right .product-title {
        text-align: center;
    }

    .rtl .product-right .detail-section,
    .rtl .product-right .product-icon {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .rtl .product-right .size-text {
        text-align: right;
    }

    .rtl .product-right .product-description .qty-box {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .rtl .product-right .payment-card-bottom {
        text-align: center;
    }

    .rtl .product-right .rating {
        text-align: center;
    }

    .rtl .product-right .pro-group .product-title.size-text {
        text-align: right;
    }

    .rtl .single-product-tables table tr td {
        text-align: center;
    }

    .rtl .tab-product {
        padding-top: 30px;
    }

    .rtl .collection-product-wrapper .product-filter-content .product-page-filter {
        border-left: none;
    }

    .rtl .collection-product-wrapper .product-filter-content .collection-view {
        padding-right: 20px !important;
    }

    .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select,
    .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
    .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select,
    .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
        padding: 21px 34px 21px 34px;
    }

    .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
    .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
        border-right: 1px solid #dddddd;
    }

    .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
        border-right: none;
    }

    .rtl .collection-product-wrapper .product-top-filter .popup-filter .search-count h5 {
        text-align: center;
    }

    .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select,
    .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
        padding: 10px 34px 10px 34px;
    }

    .rtl .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
        background: none #042c49;
    }

    .rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
        margin-left: 5px;
        margin-right: unset;
    }

    .rtl .full-box .theme-card .offer-slider .product-box2 .media .media-body .rating i {
        padding: 0;
    }

    .rtl .testimonial .testimonial-contain .media .media-body {
        margin-right: 0;
    }

    .rtl .testimonial .testimonial-contain .media .media-body p,
    .rtl .testimonial .testimonial-contain .media .media-body h5 {
        text-align: center;
    }

    .rtl .collection-banner .collection-p6>div:nth-child(n+3) {
        padding-right: 15px;
    }

    .rtl .collection-layout3>div:nth-child(2) {
        padding-right: unset;
        padding-left: 15px;
    }

    .rtl .collection5>div:last-child {
        padding-left: 15px;
        padding-right: unset;
    }

    .rtl .subscribe1 .subscribe-contain .subscribe-left .media .media-body h6 {
        text-align: center;
    }

    .rtl .subscribe1 .subscribe-contain .subscribe-left .media .media-body h3 {
        text-align: center;
    }

    .rtl .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hotdeal-right-nav-1 img:last-child {
        margin: auto;
    }

    .rtl .hot-deal .hot-deal-contain .hot-deal-center h5,
    .rtl .hot-deal .hot-deal-contain .hot-deal-center .rating,
    .rtl .hot-deal .hot-deal-contain .hot-deal-center p,
    .rtl .hot-deal .hot-deal-contain .hot-deal-center .price,
    .rtl .hot-deal .hot-deal-contain .hot-deal-center .timer {
        text-align: center;
    }

    .rtl .hot-deal .hot-deal-contain.deal-abjust .hot-deal-subcontain>div:nth-child(3) {
        position: relative;
        right: unset;
        bottom: unset;
    }

    .rtl .hot-deal .hot-deal-contain .hot-deal-subcontain>div:nth-child(3) {
        position: relative;
        right: unset;
        bottom: unset;
    }

    .rtl .deal-banner .deal-banner-containe h2 {
        text-align: center;
    }

    .rtl .deal-banner .deal-banner-containe h1 {
        text-align: center;
    }

    .rtl .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li:nth-child(n+2) {
        margin-right: 15px;
    }

    .rtl .blog-page .blog-media.media-change .blog-right h4,
    .rtl .blog-page .blog-media.media-change .blog-right ul,
    .rtl .blog-page .blog-media.media-change .blog-right p {
        text-align: right;
    }
}

@media (max-width: 768px) {
    .rtl .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block {
        margin-right: 0;
    }

    .rtl .furniture-slide .slide-main .slide-contain h2,
    .rtl .furniture-slide .slide-main .slide-contain h4,
    .rtl .furniture-slide .slide-main .slide-contain h3,
    .rtl .furniture-slide .slide-main .slide-contain p {
        text-align: center;
    }

    .rtl .digitalmark-slide .slide-main .slide-contain .sub-contain h2,
    .rtl .digitalmark-slide .slide-main .slide-contain .sub-contain p {
        text-align: center;
    }

    .rtl .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group {
        margin-left: auto;
        margin-right: auto;
    }

    .rtl .kids-slide .slide-main .slide-contain h4,
    .rtl .kids-slide .slide-main .slide-contain h2,
    .rtl .kids-slide .slide-main .slide-contain h3,
    .rtl .kids-slide .slide-main .slide-contain p {
        text-align: center;
    }

    .rtl .tools-slide .slide-main .slide-contain h2,
    .rtl .tools-slide .slide-main .slide-contain h3,
    .rtl .tools-slide .slide-main .slide-contain h4,
    .rtl .tools-slide .slide-main .slide-contain p {
        text-align: center;
    }

    .rtl .grocery-slide .slide-main .slide-contain h3,
    .rtl .grocery-slide .slide-main .slide-contain h4 {
        text-align: center;
    }

    .rtl .megastore-slide .slide-main .slide-contain h4,
    .rtl .megastore-slide .slide-main .slide-contain h2,
    .rtl .megastore-slide .slide-main .slide-contain h3 {
        text-align: center;
    }
}

@media (max-width: 767px) {
    .rtl .megastore-slide .mega-slide-block>div:first-child>div>div:last-child {
        padding-right: 15px;
    }

    .rtl .service-block svg {
        margin-right: 0;
    }

    .rtl .service-block+.service-block {
        border-right: 0;
    }

    .rtl .service-block .media .media-body h4,
    .rtl .service-block .media .media-body p {
        text-align: center;
    }

    .rtl .collection-product-wrapper .product-pagination .theme-paggination-block nav {
        border-right: none;
        border-left: 1px solid #dddddd;
    }

    .rtl .collection-product-wrapper .product-pagination .product-search-count-bottom {
        padding-left: 5px;
        padding-right: 5px;
    }

    .rtl .full-box .theme-card .offer-slider .sec-1 .product-box2+.product-box2 {
        border-left: none;
        border-right: none;
    }

    .rtl .tools-grey .product-box .cart-info .mobile-quick-view {
        margin-left: 0;
        margin-right: 5px;
    }

    .rtl .layout-3-collection>div:first-child {
        padding-left: 15px;
    }

    .rtl .collection-banner .collection-p2>div:first-child>div>div:first-child {
        padding-left: 15px;
    }

    .rtl .collection-banner .collection-p2>div:last-child {
        padding-right: 15px;
    }

    .rtl .collection-banner .collection-p3>div:first-child {
        padding-left: 15px;
    }

    .rtl .collection-banner .collection-p3>div:last-child>div>div:first-child {
        padding-left: 15px;
    }

    .rtl .collection-banner .collection-p5>div>div>div:nth-child(n+2) {
        padding-right: 15px;
    }

    .rtl .collection-banner .collection-p6>div:nth-child(n+2) {
        padding-right: 15px;
    }

    .rtl .contact-banner .contact-banner-contain .contact-banner-img img {
        margin-left: 0;
    }

    .rtl .contact-banner .contact-banner-contain h3 {
        text-align: center;
        margin-left: 0;
    }

    .rtl .contact-banner .contact-banner-contain h2 {
        text-align: center;
    }

    .rtl .contact-banner .contact-banner-contain .contact-banner-img img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    .rtl .banner-3-padding>div:last-child {
        padding-right: 0 !important;
    }

    .rtl .subscribe-block1>div:nth-child(n+2) {
        padding-right: 15px;
    }

    .rtl .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .rtl .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center .rating,
    .rtl .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center h5,
    .rtl .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center p,
    .rtl .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center .price {
        text-align: center;
    }

    .rtl .product-box3 .product-detail {
        padding-right: unset;
    }

    .rtl .bottom-cart-sticky .selection-section .form-group:nth-child(2) {
        margin-right: 15px;
    }
}

@media (max-width: 577px) {
    .rtl .blog-detail-page .blog-detail .post-social li+li {
        padding-right: 0;
        margin-right: 0;
        border-right: none;
    }

    .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
        border-right: 1px solid #dddddd;
    }

    .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
    .rtl .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
        padding: 10px 34px 10px 34px;
    }

    .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
        border-left: none;
    }

    .rtl .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before {
        left: 25px;
    }

    .rtl .main-menu .menu-right .icon-nav .mobile-cart .show-div.shopping-cart {
        left: unset !important;
        right: -118px !important;
    }

    .rtl .main-menu .menu-right .icon-nav .onhover-div .show-div.setting {
        right: unset;
        left: 0 !important;
    }
}

@media (max-width: 575px) {
    .rtl .masonory-banner .masonary-banner-main .masonary-banner-contant .masonary-banner-subcontant {
        margin-left: 0;
    }

    .rtl .masonory-p1>div:nth-child(n+2) {
        padding-right: 15px;
    }

    .rtl .masonory-p1>div:last-child>div>div:nth-child(n+2)>div>div:last-child {
        padding-right: 15px;
    }

    .rtl .order-tracking #progressbar li:nth-child(n+2) {
        margin-right: 30px;
    }
}

@media (max-width: 480px) {
    .rtl .subscribe1 .subscribe-contain .subscribe-right .input-group .form-control {
        border-radius: 5px;
    }

    .rtl .subscribe1 .subscribe-contain .subscribe-right .input-group .input-group-text {
        border-radius: 5px;
    }

    .rtl .theme-tab .tab-title2 {
        -webkit-padding-start: 0;
    }

    .rtl .blog-page .blog-media .blog-right ul li+li {
        padding-right: 0;
        margin-right: 0;
        border-right: 0;
    }

    .rtl .blog-detail-page .comment-section li p,
    .rtl .blog-detail-page .comment-section li h6 {
        text-align: center;
    }

    .rtl .product-right .product-icon .product-social li {
        padding-left: 16px;
    }

    .rtl .product-right .pro-group .timer p span:nth-child(n+2) {
        margin-right: 5px;
    }

    .rtl .product-right .pro-group ul.best-seller li:nth-child(n+2) {
        margin-right: unset;
    }

    .rtl .product-right .pro-group ul.delivery-services li:nth-child(n+2) {
        margin-right: unset;
    }

    .rtl .tab-product .nav-material.nav-tabs .nav-item .nav-link {
        text-align: right;
    }

    .rtl .tab-product .nav-material.nav-tabs .nav-item .nav-link,
    .rtl .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
        padding: 15px;
    }

    .rtl .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body h3,
    .rtl .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body h4,
    .rtl .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body h6 {
        text-align: center;
    }
}

@media (max-width: 420px) {

    .rtl .full-box .center-slider .offer-slider .product-box .product-detail .rating,
    .rtl .full-box .center-slider .offer-slider .product-box .product-detail h4,
    .rtl .full-box .center-slider .offer-slider .product-box .product-detail h6,
    .rtl .full-box .center-slider .offer-slider .product-box .product-detail .color-variant {
        text-align: right !important;
    }
}

@media (max-width: 360px) {
    .rtl .product-right .product-icon .product-social li {
        padding-left: 7px;
    }

    .rtl .banner-timer .timer {
        padding-left: 0;
        padding-right: 20px;
    }

    .rtl .banner-timer .timer span {
        width: 50px;
    }

    .rtl .order-tracking .order-tracking-box .form-group .form-control:nth-child(n+2) {
        margin-right: unset;
    }
}

@media (min-width: 768px) {
    .p-r-md-0 {
        padding-right: 0;
    }

    .p-l-md-0 {
        padding-left: 0;
    }
}

@media (min-width: 1470px) {
    .custom-container {
        max-width: 1440px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

}

@media (min-width: 1680px) {
    .custom-container {
        max-width: 1650px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .container {
        max-width: 1110px;
    }

}

@media (max-width: 1679px) {
    .category-header .navbar-menu .category-right .contact-block span {
        font-size: 0;
    }

    .gift-dropdown.dropdown-menu {
        width: 300px;
        right: -80px;
        left: unset !important;
    }

    .layout-header1~.category-header .gift-dropdown.dropdown-menu {
        right: 20px;
    }

    .category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li img {
        height: 43.5px;
    }

    .theme-slider .offset-xl-2 {
        margin-left: 270px;
        max-width: 64.5%;
    }

    .theme-slider .slider-banner .layout2-slide-1 li {
        width: 600px;
    }

    .theme-slider .slider-banner .layout2-slide-2 li {
        width: 400px;
    }

    .theme-slider .slider-banner .layout2-slide-3 li {
        width: 400px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-1 li {
        right: 50px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-2 li {
        right: 70px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-3 li {
        right: 70px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li {
        width: 350px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li:last-child {
        width: 400px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li {
        left: 40px;
        width: 300px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li:last-child {
        right: 40px;
        width: 350px;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-1 li {
        width: 50%;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-2 li {
        width: 45%;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-3 li {
        width: 50%;
    }

    .collection-banner .offset-xl-2 {
        margin-left: 270px;
        max-width: 31.2%;
    }

    .offer-banner-img {
        height: 100%;
        background-color: #042c49;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain {
        padding: 50px;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain h3 {
        font-size: 22px;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain h4 {
        font-size: 30px;
    }

    .collection-banner .collection-banner-main.banner-8 .collection-banner-contain h3 {
        font-size: 18px;
    }

    .collection-banner .collection-banner-main.banner-8 .collection-banner-contain h1 {
        font-size: 40px;
    }

    .collection-banner .collection-banner-main.banner-8 .collection-banner-contain h6 {
        display: none;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-img {
        height: 190px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-img {
        height: 395px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-img {
        height: 190px;
    }

    .collection-banner .collection-banner-main .collection-img {
        height: 235px;
    }

    .collection-banner .collection-banner-main.banner-style5 .collection-banner-contain h3 {
        margin-bottom: 11px;
    }

    .collection-banner .collection-banner-main.banner-style5 .collection-banner-contain h4 {
        letter-spacing: 0.05em;
        margin-bottom: 11px;
    }

    .collection-banner .collection-banner-main.banner-style7 .collection-banner-contain {
        background-color: rgba(0, 0, 0, 0.2);
    }

    .collection-banner .collection-banner-main.banner-style7 .collection-banner-contain h3 {
        color: #ffffff;
    }

    .collection-banner .collection-banner-main.banner-style7 .collection-banner-contain h4 {
        color: #ffffff;
    }

    .collection-banner .collection-banner-main.banner-style8 .collection-banner-contain {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .collection-banner .collection-banner-main.banner-style8 .collection-banner-contain h3 {
        color: #042c49;
    }

    .collection-banner .collection-banner-main.banner-style8 .collection-banner-contain h4 {
        color: #ef9f77;
    }

    .farming-slide .animat-block .animat1 {
        width: 640px;
        height: auto;
    }

    .tools-slide .slide-main .slide-contain p {
        width: 365px;
    }

    .tools-slide .slide-main .animat-block .animat1 {
        width: 640px;
    }

    .grocery-slide .slide-main .slide-contain h3 {
        margin-bottom: 16px;
    }

    .grocery-slide .slide-main .slide-contain h2 {
        margin-bottom: 13px;
    }

    .grocery-slide .slide-main .animat-block .animat1 {
        width: 550px;
    }

    .hotdeal-second .hotdeal-box .img-wrapper a {
        height: 276px;
    }

    .hotdeal-second .hotdeal-box .hotdeal-contain ul {
        margin-bottom: 13px;
    }

    .hotdeal-second .hotdeal-box .hotdeal-contain .timer2 {
        margin-bottom: 20px;
    }

    .instagram .insta-contant1 .insta-sub-contant1 .insta-title {
        padding: 18px 65px;
    }

    .icon-lable {
        width: 15px;
        height: 15px;
        font-size: 10px;
    }

    /*.top-header .top-header-right .top-menu-block {
    display: none; }*/
    .category-header .navbar-menu .category-left {
        width: 60%;
    }

    .category-header .navbar-menu .category-left .input-block {
        width: 100%;
    }

    .category-header .navbar-menu .category-right .gift-block {
        margin-left: 20px;
    }

    /* .category-header-2 .navbar-menu .category-right .contact-block {
    display: none; }*/
    .layout-header3 .main-menu .btn-group {
        display: none;
    }

    .category-header-4 .navbar-menu .category-right {
        display: none;
    }

    .category-header-4 .navbar-menu .category-right .btn-group {
        display: none;
    }

    .category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat li {
        padding-bottom: 17px;
    }

    .layout-4-collection>div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
        flex: 0 0 40%;
        max-width: 40%;
    }

    .layout-4-collection>div:last-child {
        display: none;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-img {
        height: 580px;
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant {
        padding: 60px 20px;
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant .masonary-banner-subcontant {
        margin-right: 20px;
    }

    .hot-4>div:first-child {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 19%;
        flex: 0 0 19%;
        max-width: 19%;
    }

    .hot-4>div:nth-child(2) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 62%;
        flex: 0 0 62%;
        max-width: 62%;
    }

    .hot-4>div:last-child {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 19%;
        flex: 0 0 19%;
        max-width: 19%;
    }

    .hot-4 .hot-deal .title1 {
        padding-top: 35px;
        padding-left: 35px !important;
    }

    .hot-4 .hot-deal .hot-deal-contain {
        padding: 35px;
    }

    .hot-4 .media-banner {
        padding: 20px;
    }

    .product-wrapper-grid.list-view .product-box .product-imgbox {
        width: 50%;
    }
}

@media (max-width: 1470px) {
    .custom-container {
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }

    .theme-slider .offset-xl-2 {
        max-width: 64%;
    }

    .cosmetic-slide .slide-main .slide-contain {
        height: 78vh;
    }

    .cosmetic-slide .slide-main .animat-block .animat-img1 {
        width: 40px;
    }

    .cosmetic-slide .slide-main .animat-block .animat-img2 {
        width: 30px;
    }

    .cosmetic-slide .slide-main .animat-block .animat-img3 {
        width: 200px;
    }

    .furniture-slide .slide-main .slide-contain h2 {
        margin-bottom: 10px;
    }

    .furniture-slide .slide-main .slide-contain h3 {
        margin-bottom: 5px;
    }

    .furniture-slide .slide-main .slide-contain p {
        width: 465px;
        margin-bottom: 20px;
    }

    .furniture-slide .animat-block .animat1 {
        width: 530px;
    }

    .furniture-slide .animat-block .animat2 {
        width: 215px;
    }

    .furniture-slide .animat-block .animat3 {
        width: 56px;
        top: -20px;
    }

    .furniture-slide .animat-block .animat4 {
        width: 52px;
        top: -40px;
    }

    .farming-slide .slide-main .slide-contain h3 {
        margin-bottom: 10px;
    }

    .farming-slide .animat-block .animat1 {
        width: 550px;
        right: 6%;
    }

    .farming-slide .animat-block .animat2 {
        height: 140px;
    }

    .farming-slide .animat-block .animat3 {
        height: 160px;
    }

    .digitalmark-slide .slide-main {
        background-position: 42% !important;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain {
        width: 43%;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain p {
        width: 100%;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain h2 {
        margin-bottom: 15px;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group {
        width: 400px;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group .form-control {
        height: 60px;
        padding-left: 23px;
        width: 75%;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group .btn {
        padding: 13px 20px;
    }

    .digitalmark-slide .slide-main .animat-block .animat1 {
        width: 40px;
        left: 28%;
        top: 12%;
    }

    .digitalmark-slide .slide-main .animat-block .animat2 {
        width: 70px;
        left: 3%;
    }

    .kids-slide .slide-main .slide-contain p {
        width: 45%;
    }

    .kids-slide .animat-block .animat1 {
        width: 350px;
    }

    .kids-slide .animat-block .animat2 {
        width: 60px;
    }

    .kids-slide .animat-block .animat3 {
        width: 80px;
    }

    .kids-slide .animat-block .animat4 {
        width: 80px;
    }

    .kids-slide .animat-block .animat5 {
        bottom: -65px;
        width: 120px;
    }

    .tools-slide .slide-main .animat-block .animat1 {
        right: 30px;
    }

    .pets-slide .slide-main .slide-contain .form-control {
        width: 420px;
    }

    .pets-slide .slide-main .animat-block .animat1 {
        width: 400px;
        left: 15px;
    }

    .pets-slide .slide-main .animat-block .animat2 {
        width: 380px;
        right: 15px;
    }

    .collection-banner .offset-xl-2 {
        max-width: 30.66%;
    }

    .grocery-slide .slide-main .animat-block .animat1 {
        width: 450px;
    }

    .megastore-slide .slide-main .slide-contain h4 {
        margin-bottom: 14px;
    }

    .megastore-slide .slide-main .slide-contain h3 {
        margin-bottom: 25px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center p {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer {
        margin-top: 20px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer p {
        margin-top: 0;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .btn {
        margin-top: 22px;
    }

    .instagram .insta-contant1 .insta-sub-contant1 .insta-title {
        padding: 15px 50px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain {
        padding: 30px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain h3 {
        font-size: 25px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain h4 {
        font-size: 25px;
    }

    .collection-banner .collection-banner-main.banner-1 .collection-banner-contain h3 {
        font-size: 22px;
        margin-bottom: 5px;
    }

    .collection-banner .collection-banner-main.banner-1 .collection-banner-contain h4 {
        font-size: 32px;
    }

    .collection-banner .collection-banner-main.banner-1 .collection-banner-contain .shop {
        margin-top: 25px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain {
        padding: 15px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain h3 {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain h4 {
        font-size: 16px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain .shop {
        margin-top: 10px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain .shop a {
        font-size: 12px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-banner-contain h3 {
        font-size: 30px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-banner-contain h4 {
        margin-top: 10px;
        margin-bottom: 50px;
        font-size: 16px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-banner-contain h3 {
        font-size: 18px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-banner-contain h4 {
        font-size: 22px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-img {
        height: 240px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-banner-contain h3 {
        font-size: 30px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-banner-contain h4 {
        font-size: 60px;
    }

    .layout-4-collection>div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 39.5%;
        flex: 0 0 39.5%;
        max-width: 39.5%;
    }

    .collection-layout1>div:first-child {
        max-width: 31.5%;
    }

    .collection-layout1>div:nth-child(2) {
        max-width: 31%;
    }

    .sale-banenr .sale-banenr-contain h2 {
        margin-bottom: 17px;
    }

    .sale-banenr.banner-style1 h2 {
        margin-bottom: 15px;
    }

    .sale-banenr.banner-style1 h3 {
        margin-bottom: 15px;
    }

    .app-download .app-download-main .app-download-contian h2 {
        margin-bottom: 17px;
    }

    .app-download .app-download-main .app-download-contian h3 {
        margin-bottom: 25px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain {
        min-width: 320px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain span {
        font-size: 22px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-img {
        height: 350px;
    }

    .offer-banner {
        display: none;
    }

    .theme-slider .offset-xl-2 {
        max-width: calc(100% - 270px);
    }

    .category-header .navbar-menu .category-right .contact-block span {
        font-size: 0;
        margin-left: 0;
    }

    .category-header .navbar-menu .category-right .gift-block {
        margin-left: 45px;
    }

    .layout-header1 .main-menu-block .menu-left .brand-logo img {
        height: 50px;
    }

    .category-header-2 .navbar-menu .logo-block .brand-logo img {
        height: 50px;
    }

    .layout-header2 .main-menu-block .brand-logo img {
        height: 65px;
        width: auto;
    }

    .layout-header3 .main-menu .input-block .input-box {
        width: 450px;
    }

    .layout-header3 .main-menu .input-block .input-box .big-deal-form .input-group {
        height: 50px;
    }

    .layout-header3 .main-menu .input-block .input-box .big-deal-form .input-group select {
        background: url("/../assets/images/layout-1/drop-1.png") no-repeat scroll 20px 18px;
    }

    .layout-header3 .main-menu .input-block .input-box .big-deal-form .input-group span {
        display: none;
    }

    .category-header-4 .navbar-menu .input-block {
        width: 500px;
    }

    .category-header-4 .navbar-menu .category-left .logo-block img {
        height: 45px;
    }

    .header-search {
        width: 640px;
    }

    .header-search .input-group {
        height: 50px;
    }

    .header-search .input-group .form-control,
    .header-search .input-group .typeahead {
        height: 50px;
    }

    .header-search .input-group .input-group-text select {
        padding-right: 30px;
        padding-left: 15px;
    }

    .header-search.search-style2 .input-group .input-group-text select {
        background: url("/../assets/images/dropdown2.png") no-repeat 90%;
        background-size: 18px;
    }

    .header-search.search-style2 .input-group .twitter-typeahead {
        width: calc(100% - 220px) !important;
    }

    .header-search2 {
        width: 640px;
    }

    .header-search2 .input-group {
        height: 50px;
    }

    .layout-header4 .logo-block img {
        height: 50px;
    }

    .header7 .header-contain .logo-block .brand-logo img {
        height: 50px;
        width: auto;
    }

    .category-header7 .category-contain .category-left .brand-logo img {
        width: auto;
        height: 50px;
    }

    .services1.border-services .services-box {
        margin-right: 8px;
        margin-left: 8px;
    }

    .services1 .service-block>div {
        padding-right: 8px;
        padding-left: 8px;
    }

    .services1.style2 .services-box .media .icon-wrraper {
        margin-bottom: 17px;
    }

    .video-banner .video-banner-contain {
        height: 480px;
    }

    .product-block2>div {
        padding-left: 8px;
        padding-right: 8px;
    }

    .product-block2>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .digipro-box {
        margin-left: 8px;
        margin-right: 8px;
    }

    .testimonial-block>div {
        padding-right: 8px;
        padding-left: 8px;
    }

    .testimonial6 .testimonial-box {
        margin-right: 8px;
        margin-left: 8px;
    }

    .price-block>div {
        padding-right: 8px;
        padding-left: 8px;
    }

    .pricing-table3 .pricing-box {
        margin-left: 8px;
        margin-right: 8px;
    }

    .blog-block>div {
        padding-right: 8px;
        padding-left: 8px;
    }

    .blog-block .blog-contain {
        margin-left: 8px;
        margin-right: 8px;
    }

    .theme-modal.blackfriday-modal#exampleModal .modal-body {
        height: 400px;
    }

    .theme-modal.blackfriday-modal#exampleModal .modal-dialog {
        max-width: 700px;
    }

    .theme-modal.cyber-monday#exampleModal .modal-dialog {
        max-width: 620px;
    }

    /*=====================
     Inner pages CSS
==========================*/
    .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
        width: 100%;
        border-left: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        text-align: center;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
        width: 20%;
        padding-right: 0 !important;
        border-left: 1px solid #dddddd;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
        padding: 20px !important;
        padding-left: 0 !important;
        width: 20%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li:last-child {
        display: none;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
        border-right: 1px solid #dddddd;
        width: 30%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
        width: 30%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter-toggle {
        width: 15%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .collection-view {
        width: 15%;
        border-left: none;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .collection-grid-view {
        width: 10%;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .collection-view {
        width: 10%;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
        width: 25%;
    }

    .collection-product-wrapper .product-wrapper-grid .col-lg-3 .product-box .product-detail .icon-detail a,
    .collection-product-wrapper .product-wrapper-grid .col-lg-3 .product-box .product-detail .icon-detail button {
        width: 35px;
        height: 35px;
    }

    .collection-product-wrapper .product-wrapper-grid .col-lg-3 .product-box .product-detail .icon-detail a svg,
    .collection-product-wrapper .product-wrapper-grid .col-lg-3 .product-box .product-detail .icon-detail button svg {
        width: 16px;
    }

    .cart-section .cart-buttons>div:last-child {
        padding-right: 38px;
    }

    .cart-section tbody tr td {
        min-width: 175px;
    }

    .cart-section tbody tr td .qty-box .input-group .form-control {
        width: 75px;
    }

    .cart-section tfoot tr td {
        padding-right: 41px;
    }

    .product-right .product-icon .product-social li {
        padding-right: 10px;
    }
}

@media (max-width: 1366px) {
    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-3 li:nth-child(2) {
        right: 100px;
        bottom: -90px;
        left: unset;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-1 li:nth-child(2) img,
    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-2 li:nth-child(2) img,
    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-3 li:nth-child(2) img {
        margin: 0 0 0 auto;
    }

    .theme-slider .slider-banner.slide-banner-1 div li img {
        width: 60%;
    }

    .theme-slider .slider-banner.slide-banner-5 .layout6-slide-1 li {
        width: 400px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-1 li {
        width: 415px;
        right: 20px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-2 li {
        width: 300px;
        right: 20px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-3 li {
        width: 350px;
        right: 20px;
    }

    .hot-deal.hotdeal-third .hot-deal-contain .hotdeal-right-slick .img-wrraper>div {
        height: 360px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer span {
        font-size: 22px;
        min-width: 55px;
    }

    .hot-4 .media-banner .media-banner-box .media .media-body .media-contant {
        height: 87px;
    }

    .hot-4 .media-banner .media-banner-box .media img {
        border: 1px solid #ddd;
        width: 60px;
    }

    .hot-4 .hot-deal .title1 {
        padding-top: 20px;
        padding-left: 20px !important;
    }

    .hot-4 .hot-deal .hot-deal-contain {
        padding: 20px;
    }

    .layout-4.hot-deal .hot-deal-contain .hotdeal-right-nav img {
        height: 100px;
    }

    .layout-4.hot-deal .hot-deal-contain .hot-deal-center {
        margin-left: 0;
        height: auto;
    }

    .Jewellery-banner .Jewellery-banner-contian {
        padding: 15px;
    }

    .Jewellery-banner h6 {
        font-size: 25px;
    }

    .layout-3-hotdeal .media-banner .media-banner-box .media .media-body .media-contant .rating i {
        font-size: 11px;
    }

    .layout-3-hotdeal .media-banner .media-banner-box .media img {
        width: 52px;
    }

    .layout-3-hotdeal .hot-deal .hot-deal-contain1 {
        padding: 21px;
    }

    .layout-3-hotdeal .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center .rating {
        margin: 0 auto;
    }

    .layout-3-hotdeal .media-banner {
        padding: 25px;
    }

    .cart-hover-div:hover .cart-show-div.right {
        right: 0;
    }

    .cart-hover-div .cart-show-div.right {
        right: 0;
    }

    .collection-banner .offset-xl-2 {
        max-width: 29.66%;
    }

    .layout-4-collection>div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 38%;
        flex: 0 0 38%;
        max-width: 38%;
    }

    .collection-layout1>div:first-child {
        max-width: 29.5%;
    }

    .collection-layout1>div:nth-child(2) {
        max-width: 29.5%;
    }

    .offer-banner {
        display: none;
    }

    .category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li {
        padding-bottom: 7px;
    }

    .category-header-2 .navbar-menu .nav-block {
        min-width: 220px;
    }

    .category-header-2 .navbar-menu .nav-block .nav-left {
        width: 220px;
    }

    .category-header-2 .navbar-menu .icon-block ul li:nth-child(n+2) {
        margin-left: 5px;
    }

    .category-header-2 .category-right .gift-block {
        width: auto;
    }

    .category-header-2 .category-right .gift-block .gift-offer {
        display: none;
    }

    .category-header-2 .navbar-menu .category-right .gift-block {
        width: auto;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain {
        padding: 30px;
    }

    .collection-banner .collection-banner-main .collection-img {
        height: 200px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-img {
        height: 175px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-img {
        height: 365px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-img {
        height: 175px;
    }

    .full-banner {
        padding: 150px 0;
    }

    .top-header .top-header-right .language-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    /* .top-header .top-header-right .top-menu-block {
    display: none; }*/
    .top-header .top-header-left .shpping-order {
        margin-right: 18px;
    }

    .category-header .navbar-menu .category-right .contact-block i {
        display: none;
    }

    .category-header .navbar-menu .category-right .gift-block {
        margin-left: 40px;
        width: auto;
    }

    .category-header-4 .navbar-menu .category-left .nav-block {
        margin-right: 20px;
    }

    .category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat li {
        padding-bottom: 9px;
    }

    .category-header-4 .navbar-menu .input-block {
        width: 370px;
    }

    .category-header-4 .navbar-menu .input-block .input-box .big-deal-form .input-group select {
        display: none;
    }

    .category-header-4 .navbar-menu .category-right .gift-block {
        display: none;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-img {
        height: 400px;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-img {
        height: 500px;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-banner-contain h3:before {
        width: 70px;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-banner-contain h3:after {
        width: 70px;
    }

    /*=====================
     Inner pages CSS
==========================*/
    .product-right .timer p span {
        min-width: 65px;
    }

    .creative-card {
        border-width: 25px;
        padding: 25px;
    }

    .blog-page .blog-media {
        padding: 25px 15px;
        border-width: 25px;
    }

    .blog-page .blog-media .blog-right h4 {
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .blog-page .blog-media .blog-right h6 {
        margin-bottom: 5px;
    }

    .blog-page .blog-media .blog-right ul {
        margin-bottom: 10px;
    }

    .blog-page .blog-media .blog-right p {
        line-height: 1.4;
    }

    .blog-page .blog-sidebar .theme-card {
        border-width: 25px;
        padding: 25px;
    }

    .theme-card {
        border-width: 25px;
        padding: 25px;
    }

    .theme-card .offer-slider .media .media-body h4 {
        margin-top: 10px;
    }

    .theme-card .offer-slider .media .media-body a h6 {
        margin-right: 0px;
    }
}

@media (max-width: 1200px) {
    .collection-banner .offset-xl-2 {
        max-width: 26.66%;
    }

    .collection-banner .pl-md-0 {
        padding-right: 0;
    }

    .layout-4-collection>div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 38%;
        flex: 0 0 38%;
        max-width: 38%;
    }

    .collection-banner .collection-banner-main .collection-banner-contain {
        padding: 25px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain h3 {
        font-size: 20px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain h4 {
        font-size: 20px;
    }

    .collection-banner .collection-banner-main.banner-1 .collection-banner-contain .shop {
        margin-top: 10px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-img {
        height: 200px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-banner-contain h3 {
        font-size: 20px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-banner-contain h4 {
        font-size: 40px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-banner-contain .btn-normal {
        padding: 10px 30px;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain h3 {
        font-size: 18px;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain h4 {
        font-size: 22px;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain .shop {
        margin-top: 15px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-img {
        height: 275px;
    }

    .theme-slider .slider-banner .slider-banner-contain {
        padding: 0 70px;
    }

    .theme-slider .slider-banner .slider-banner-contain h4 {
        font-size: 18px;
    }

    .theme-slider .slider-banner .slider-banner-contain h1 {
        font-size: 45px;
    }

    .theme-slider .slider-banner .slider-banner-contain h2 {
        font-size: 24px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h1 {
        padding-bottom: 5px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h4 {
        font-size: 20px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h2 {
        font-size: 25px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h5 {
        font-size: 16px;
        padding-bottom: 0;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h3 {
        font-size: 32px;
        padding-bottom: 5px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h1 {
        font-size: 42px;
        padding-bottom: 15px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h2 {
        padding-bottom: 18px;
        font-size: 18px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h5 {
        font-size: 16px;
        padding-bottom: 0;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h3 {
        font-size: 32px;
        padding-bottom: 5px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h1 {
        font-size: 42px;
        padding-bottom: 15px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h2 {
        padding-bottom: 18px;
        font-size: 18px;
    }
}

@media (max-width: 1199px) {
    .sticky .header7 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .sticky .header7 .sm-horizontal .mobile-back {
        padding: 20px;
    }

    .layout-header2 {
        background-color: rgba(0, 186, 242, 0.05);
    }

    .layout-header2 .main-menu-block .sm-nav-block .nav-slide li .nav-sm-back {
        border-bottom: 0;
        padding: 1px;
    }

    .sticky .layout-header1 {
        padding: 10px 0;
    }

    .product-right .product-icon .wishlist-btn i {
        padding-left: 15px;
        margin-left: 15px;
    }

    .product-right.product-form-box .pro-group .timer p>span {
        font-size: 16px;
        width: 60px;
        height: 60px;
    }

    .product-right.product-form-box .pro-group .timer p>span .timer-cal {
        font-size: 12px;
    }

    .product-right.product-form-box .pro-group .timer p>span:nth-child(n+2) {
        margin-left: 5px;
    }

    .product-right.product-form-box .pro-group .size-box ul li:nth-child(n+2) {
        margin-left: unset;
    }

    .product-right.product-form-box .pro-group .color-selector.inline ul li:nth-child(n+2) {
        margin-left: 0;
    }

    .product-right .timer p span {
        min-width: 47px;
    }

    .tab-product.tab-exes {
        margin-top: 40px !important;
    }

    .tab-product-main .tab-prodcut-contain ul li:nth-child(n+2) {
        margin-left: 30px;
    }

    .mor-slide-open img {
        display: none;
    }

    .new-tag {
        position: absolute;
        top: -5px;
    }

    .menu-banner {
        display: none;
    }

    .searchbar-input .input-group .twitter-typeahead {
        width: calc(100% - 88px);
    }

    .searchbar-input .input-group .input-group-text:first-child {
        padding-left: 15px;
    }

    .searchbar-input .input-group .input-group-text:last-child {
        padding-right: 15px;
    }

    .searchbar-input .input-group .input-group-text svg {
        width: 18px;
    }

    .category-header-2 {
        height: 0;
    }

    .category-header-2 .navbar-menu .menu-block .pixelstrap .dark-menu-item {
        color: #444444;
        padding: 10px 15px;
    }

    .category-header-2 .navbar-menu .category-right,
    .category-header-2 .navbar-menu .nav-block,
    .category-header-2 .navbar-menu .icon-block {
        display: none;
    }

    .category-header-2 .navbar-menu .menu-block .toggle-nav {
        display: none;
    }

    .category-header-2 .searchbar-input {
        display: none;
    }

    .layout-header2 .main-menu-block .header-left,
    .layout-header2 .main-menu-block .header-right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .layout-header2 .main-menu-block .sm-nav-block {
        display: block;
        margin-right: 15px;
    }

    .layout-header2 .main-menu-block .menu-nav {
        display: block;
        margin-left: 15px;
    }

    .layout-header2 .main-menu-block .brand-logo {
        margin-right: unset;
    }

    .layout-header2 .main-menu-block .brand-logo img {
        height: 45px;
    }

    .layout-header2 .main-menu-block .input-block {
        display: none;
    }

    .layout-header2 .main-menu-block .icon-block {
        margin-left: unset;
    }

    .layout-header2 .main-menu-block .icon-block ul li {
        display: inline-block;
    }

    .layout-header2 .main-menu-block .icon-block ul li svg {
        width: 24px;
    }

    .layout-header2 .main-menu-block .icon-block ul li .item-count-contain {
        top: -14px;
        left: 12px;
        width: 15px;
        height: 15px;
    }

    .layout-header2 .main-menu-block .icon-block ul li .cart-block .cart-item h5 {
        font-size: 12px;
    }

    .layout-header2 .searchbar-input {
        display: block;
    }

    .category-header {
        display: none;
    }

    .layout-header1 .main-menu-block .menu-block .toggle-nav {
        display: none;
    }

    .layout-header1 .main-menu-block .menu-right .icon-nav ul li.mobile-search,
    .layout-header1 .main-menu-block .menu-right .icon-nav ul li.mobile-setting {
        display: inline-block;
    }

    .layout-header1 .main-menu-block .menu-left .brand-logo img {
        height: 45px;
    }

    .layout-header1 .searchbar-input {
        display: block;
    }

    .layout-header3 .main-menu .menu-nav .toggle-nav-desc {
        display: none;
    }

    .layout-header3 .main-menu .icon-block .toggle-nav-desc {
        display: block;
    }

    .category-header-4 {
        background-color: #ffffff;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .category-header-4 .navbar-menu .category-left {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .category-header-4 .navbar-menu .category-left .sm-nav-btn {
        display: block;
    }

    .category-header-4 .navbar-menu .category-left .logo-block {
        display: block;
    }

    .category-header-4 .navbar-menu .category-left .logo-block img {
        height: 40px;
    }

    .category-header-4 .navbar-menu .category-left .nav-block {
        display: none;
    }

    .category-header-4 .navbar-menu .category-right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .category-header-4 .navbar-menu .category-right .icon-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .category-header-4 .navbar-menu .category-right .icon-block ul li svg {
        fill: #444444;
    }

    .category-header-4 .navbar-menu .category-right .icon-block ul li .item-count-contain {
        top: -5px;
        right: -3px;
    }

    .category-header-4 .navbar-menu .category-right .icon-block ul li .item-count-contain.item-whtie {
        background-color: #042c49;
        color: #ffffff;
    }

    .category-header-4 .navbar-menu .menu-block .pixelstrap .light-menu-item {
        padding: 10px 15px;
        color: #444444;
    }

    .layout-header4 {
        height: 0;
        padding: 0;
    }

    .layout-header4 .logo-block,
    .layout-header4 .icon-block {
        display: none;
    }

    .layout-header4 .contact-block .desc-nav-block {
        display: none;
    }

    .category-header7 .category-contain .category-left .logo-block .brand-logo img {
        height: 45px;
    }

    .sticky .layout-header2 {
        display: block;
    }

    .sticky .layout-header2 .searchbar-input {
        display: block;
    }

    .sticky .category-header-2 {
        padding: 0;
    }

    .sticky .category-header-2 .searchbar-input {
        display: none;
    }

    .sticky .category-header-2 .navbar-menu .logo-block {
        display: none;
    }

    .sticky.header-style2 .category-header7 .category-contain .category-left .header-category {
        display: block;
    }

    .layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide>li:nth-last-child(2) {
        border: 0;
        padding: 0;
    }

    .hot-4>div:nth-child(2) {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .hot-4>div:last-child,
    .hot-4>div:first-child {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: calc(50% - 7.5px);
        margin-bottom: 15px;
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .hot-4>div:last-child {
        margin-left: 15px;
    }

    .theme-slider .offset-xl-2 {
        max-width: 75%;
        margin: 0;
    }

    .theme-slider .slider-banner .layout2-slide-1 li {
        width: 550px;
    }

    .theme-slider .slider-banner .layout2-slide-2 li {
        width: 340px;
    }

    .theme-slider .slider-banner .layout2-slide-3 li {
        width: 380px;
    }

    .theme-slider .slider-banner.slide-banner-5 .layout6-slide-1 li {
        width: 350px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li {
        width: 250px;
        left: 0;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li:last-child {
        width: 250px;
        right: 0;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li {
        width: 240px;
        left: 20px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li:last-child {
        width: 250px;
        right: 20px;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-1 li {
        width: 55%;
        right: 50px;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-2 li {
        right: 50px;
        width: 50%;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-3 li {
        right: 50px;
        width: 55%;
    }

    .farming-slide .slide-main .slide-contain {
        height: 64vh;
    }

    .farming-slide .slide-main .slide-contain h4 {
        margin-bottom: 20px;
    }

    .farming-slide .slide-main .animat-block .animat1 {
        width: 560px;
    }

    .farming-slide .slide-main .animat-block .animat2 {
        height: 100px;
    }

    .farming-slide .slide-main .animat-block .animat3 {
        height: 130px;
    }

    .tools-slide .slide-main .slide-contain {
        height: 68vh;
    }

    .tools-slide .slide-main .animat-block .animat1 {
        width: 580px;
    }

    .pets-slide .slide-main .slide-contain {
        height: 60vh;
    }

    .pets-slide .slide-main .slide-contain h3 {
        margin-bottom: 15px;
    }

    .pets-slide .slide-main .slide-contain h2 {
        margin-bottom: 15px;
    }

    .pets-slide .slide-main .slide-contain .form-control {
        width: 350px;
        height: 52px;
        margin-bottom: 20px;
    }

    .pets-slide .slide-main .animat-block .animat1 {
        width: 310px;
    }

    .pets-slide .slide-main .animat-block .animat2 {
        width: 310px;
    }

    .megastore-slide .slide-main .slide-contain {
        height: 68vh;
    }

    .megastore-slide .mega-slide-block>div:first-child {
        padding-left: 15px;
    }

    .megastore-slide .mega-slide-block>div:nth-child(n+2) {
        margin-top: 15px;
        padding-left: 15px;
    }

    .grocery-slide .slide-main {
        margin-left: unset;
    }

    .grocery-slide .slide-main .slide-contain {
        height: 68vh;
    }

    .grocery-slide .slide-main .slide-contain h3 {
        margin-bottom: 12px;
    }

    .grocery-slide .slide-main .slide-contain h2 {
        margin-bottom: 9px;
    }

    .grocery-slide .slide-main .slide-contain h4 {
        margin-bottom: 20px;
    }

    .header-category ul.collapse-category {
        position: fixed;
        width: 300px;
        border: 1px solid #dddddd;
        top: 0;
        height: 100vh;
        left: -300px;
        z-index: 99;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        overflow: scroll;
        margin-top: unset;
    }

    .header-category ul.collapse-category.hide {
        display: block;
    }

    .header-category ul.collapse-category.open {
        left: 0;
    }

    .header-category ul.collapse-category li a img {
        width: 30px;
        height: 30px;
    }

    .header-category ul.collapse-category li.back-btn {
        display: block;
    }

    .header-category2 .category-toggle {
        display: none;
    }

    .header-category2 ul.collapse-category {
        position: fixed;
        width: 300px;
        border: 1px solid #dddddd;
        top: 0;
        height: 100vh;
        left: -300px;
        z-index: 99;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        overflow: scroll;
        margin-top: unset;
    }

    .header-category2 ul.collapse-category.hide {
        display: block;
    }

    .header-category2 ul.collapse-category.open {
        left: 0;
    }

    .header-category2 ul.collapse-category li.back-btn {
        display: block;
        margin-bottom: 20px;
    }

    .header-category3 .category-toggle {
        display: none;
    }

    .header-category3 ul li a {
        font-size: 18px;
        font-weight: 600;
    }

    .header-category3 ul li a span:before {
        content: "+";
    }

    .header-category3 ul li a span.active:before {
        content: "-";
    }

    .header-category3 ul.collapse-category {
        position: fixed;
        width: 300px;
        border: 1px solid #dddddd;
        top: 0;
        height: 100vh;
        z-index: 99;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease;
        overflow: scroll;
        margin-top: unset;
        overflow: scroll;
    }

    .header-category3 ul.collapse-category.hide {
        display: block;
    }

    .header-category3 ul.collapse-category.open {
        left: 0;
    }

    .header-category3 ul.collapse-category li {
        width: 100%;
    }

    .header-category3 ul.collapse-category li.back-btn {
        display: block;
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .header-category3 ul.collapse-category li ul li:first-child a {
        padding-top: 8px;
    }

    .header-category3 ul.collapse-category>li ul li:last-child a {
        padding-bottom: 8px;
    }

    .header-category3 ul .categoryone .collapse-two {
        position: unset;
        opacity: 1;
        visibility: visible;
        border: none;
        padding-left: 15px;
    }

    .header-category3 ul .categoryone .collapse-two ul li a {
        font-weight: 400;
    }

    .header-category3 ul .categoryone .collapse-two .categorytwo .collapse-third {
        position: unset;
        opacity: 1;
        visibility: visible;
        border: none;
    }

    .header-category3 ul .categoryone .collapse-mega {
        position: unset;
        display: block;
        opacity: 1;
        visibility: visible;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 15px;
        border: none;
    }

    .header-category3 ul .categoryone .collapse-mega .mega-box h5 {
        position: relative;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 0;
    }

    .header-category3 ul .categoryone .collapse-mega .mega-box h5 span.sub-arrow:before {
        content: "+";
        position: absolute;
        top: 8px;
        right: 15px;
        color: #444444;
        font-size: 20px;
        font-family: FontAwesome;
    }

    .header-category3 ul .categoryone .collapse-mega .mega-box h5 span.sub-arrow.active:before {
        content: "-";
    }

    .header-category3 ul .categoryone .collapse-mega .mega-box ul {
        display: none;
    }

    .header-category3 ul .categoryone .collapse-mega .mega-box ul.open {
        display: block;
    }

    .header-category3 ul .categoryone .collapse-mega .mega-box ul li:first-child a {
        padding-top: 8px;
    }

    .header-category3 ul .categoryone .collapse-mega .mega-box ul li:last-child a {
        padding-bottom: 8px;
    }

    .mobilecat-toggle {
        display: block;
    }

    .header7 .header-contain .logo-block .brand-logo img {
        height: 45px;
    }

    .header7 .header-contain .icon-block ul li.icon-lg-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .header7 .header-contain .icon-block.icon-lg-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .header7 .header-contain .menu-block ul li a.dark-menu-item {
        font-size: 16px;
    }

    .header7 .header-contain .gift-block {
        display: none;
    }

    .category-header7 {
        background-color: transparent;
    }

    .category-header7 .category-contain {
        height: 0;
    }

    .category-header7 .category-contain .category-right .toggle-nav {
        display: none;
    }

    .category-header7 .category-contain .category-right .menu-block ul li a {
        color: #444444;
        padding: 10px 15px;
    }

    .category-header7 .category-contain .gift-block-main {
        display: none;
    }

    .header-category .category-toggle {
        display: none;
    }

    .header-search {
        width: 480px;
    }

    .header-search2 {
        width: 480px;
    }

    .masonary-banner-block1 .col-sm-6.pl-3 {
        padding-right: 0;
    }

    .gutter-15 .masonary-banner-block2 .col-sm-6 {
        padding-left: 0;
    }

    .masonary-banner-block.masonary-inner1>div:last-child {
        padding-left: 0;
    }

    .layout-3-hotdeal .hot-deal .hot-deal-contain1 {
        margin-top: 15px;
    }

    .layout-3-hotdeal .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center .timer span {
        font-size: 14px;
    }

    .layout-3-hotdeal>div:nth-child(2) {
        padding-right: 15px;
    }

    .layout-3-hotdeal>div:nth-child(4) {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
        padding: 0;
        padding-left: 15px;
    }

    .collection-banner-main.p-top.banner-6 .collection-img {
        background-size: contain !important;
        background-position: right top !important;
        background-repeat: no-repeat !important;
        background-color: #ffffff !important;
    }

    .layout-4-collection>div:first-child {
        padding-right: 0;
    }

    .video-banner .video-banner-contain {
        height: 390px;
    }

    .video-banner .video-banner-contain h3 {
        margin-top: 20px;
    }

    .video-banner .video-banner-contain h2 {
        margin-top: 21px;
    }

    .hotdeal-second .hotdeal-box {
        display: block;
    }

    .hotdeal-second .hotdeal-box .img-wrapper {
        width: 100%;
    }

    .hotdeal-second .hotdeal-box .hotdeal-contain {
        width: 100%;
    }

    .testimonial6 .testimonial-box .img-wrapper {
        margin-bottom: 16px;
    }

    .testimonial6 .testimonial-box .testimonial-detail h6 {
        margin-bottom: 11px;
    }

    .testimonial6 .testimonial-box .testimonial-detail p {
        margin-bottom: 15px;
    }

    .subscribe-block1>div:nth-child(n+3) {
        padding-left: 15px;
    }

    .subscribe-block1>div:last-child {
        margin-top: 15px;
    }

    .contact-banner2 .subscribe-box h2 {
        margin-bottom: 5px;
    }

    .contact-banner2 .subscribe-box p {
        margin-bottom: 12px;
    }

    .contact-banner2 .subscribe-box .form-control {
        margin-bottom: 19px;
    }

    .brand-third {
        display: block;
    }

    .brand-third .brand-box {
        padding: unset;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: auto;
    }

    .sale-banenr .sale-banenr-contain h4 {
        margin-bottom: 15px;
    }

    .sale-banenr .sale-banenr-contain h2 {
        margin-bottom: 10px;
    }

    .sale-banenr .sale-banenr-contain h3 {
        margin-bottom: 20px;
    }

    .sale-banenr.banner-style1 .sale-banenr-contain h4 {
        margin-bottom: 15px;
    }

    .sale-banenr.banner-style1 .sale-banenr-contain h2 {
        margin-bottom: 20px;
    }

    .app-download .app-download-main .app-download-contian h2 {
        margin-bottom: 18px;
    }

    .layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide li.mor-slide-open ul li a {
        display: inline-block;
    }

    .collection-banner .pl-md-0 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .collection-banner .offset-xl-2 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .offer-banner {
        display: inline-block;
    }

    .collection-banner .offset-xl-2 {
        margin-left: 0;
    }

    .offer-banner-img img {
        height: 400px;
        width: 100%;
    }

    .collection-layout1>div {
        max-width: 33.333% !important;
    }

    .collection-banner .collection-banner-main.banner-13 .collection-img {
        height: 280px;
    }

    .collection-banner .collection-banner-main.banner-14 .collection-img {
        height: 575px;
    }

    .collection-banner .collection-banner-main.banner-16 .collection-img {
        height: 575px;
    }

    .collection-banner .collection-banner-main.banner-17 .collection-img {
        height: 260px;
    }

    .section-big-pt-space {
        padding-top: 40px;
    }

    .section-big-pb-space {
        padding-bottom: 40px;
    }

    .section-big-py-space {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .section-big-mb-space {
        margin-bottom: 40px;
    }

    .section-big-mt-space {
        margin-top: 40px;
    }

    .section-big-my-space {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .collection-banner .collection-banner-main .collection-img {
        height: 170px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-img {
        height: 140px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-img {
        height: 295px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-img {
        height: 140px;
    }

    .collection-banner .collection-banner-main.banner-12 .collection-img {
        height: 250px;
    }

    .collection-banner .collection-banner-main.banner-style3 .collection-banner-contain h3 {
        letter-spacing: 1px;
        margin-bottom: 11px;
    }

    .collection-banner .collection-banner-main.banner-style3 .collection-banner-contain h4 {
        margin-bottom: 11px;
    }

    .collection-banner .collection-p6>div:nth-child(n+2) {
        margin-top: 0;
        padding-left: 0;
    }

    .layout-4-collection>div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .layout-4-collection>div:first-child {
        margin-left: 0;
    }

    .layout-4-collection>div:nth-child(2) {
        padding-right: 15px;
        padding-left: 15px;
    }

    .discount-banner {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .title1 {
        padding: 30px 0;
    }

    .title3 {
        padding-top: 30px;
    }

    .title8 {
        margin-bottom: 40px;
    }

    .product .product-box .product-imgbox .product-icon.icon-inline button {
        margin: 0 3px;
    }

    .product .product-box .product-imgbox .product-icon.icon-inline a {
        margin: 0 3px;
    }

    .product .product-box .product-imgbox .product-icon button {
        width: 40px;
        height: 40px;
    }

    .product .product-box .product-imgbox .product-icon a {
        width: 40px;
        height: 40px;
    }

    .product .product-box .product-detail .detail-title .detail-left {
        float: unset;
    }

    .product .product-box .product-detail .detail-title .detail-right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        float: unset;
    }

    .product .product-box .product-detail .detail-title .detail-right .price {
        margin-left: 5px;
    }

    .product .product-box .product-detail.product-detail2 .color-variant {
        padding-top: 0;
    }

    .no-slider .product-box {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: calc(33.33% - 30px);
        margin: 0 15px 30px;
    }

    .no-slider.five-product .product-box {
        width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
        flex: 0 0 33.33%;
        max-width: calc(33.33% - 30px);
        margin: 0 15px 30px;
    }

    .no-slider.five-product .product-box:nth-last-child(-n+5) {
        margin: 0 15px 30px;
    }

    .no-slider.five-product .product-box:nth-last-child(-n+3) {
        margin: 0 15px 0;
    }

    .hot-deal .hotdeal-block1>div:nth-child(2) {
        padding-left: 15px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain h5,
    .hot-deal.hotdeal-third .hot-deal-contain h5 {
        margin-bottom: 5px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .rating,
    .hot-deal.hotdeal-third .hot-deal-contain .rating {
        margin-bottom: 8px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain p,
    .hot-deal.hotdeal-third .hot-deal-contain p {
        margin-bottom: 8px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .price,
    .hot-deal.hotdeal-third .hot-deal-contain .price {
        margin-bottom: 10px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .timer,
    .hot-deal.hotdeal-third .hot-deal-contain .timer {
        margin-bottom: 17px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center {
        margin-left: 0px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer {
        margin-top: 20px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer span {
        padding: 15px;
        font-size: 20px;
        margin: 0 3px;
        min-width: 35px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer span>span {
        margin-left: 0;
    }

    .hot-deal .hot-deal-contain .hot-deal-center h6 {
        margin: 15px 0;
    }

    .hot-deal .hot-deal-contain .hotdeal-right-nav img {
        height: 105px;
    }

    .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center .rating {
        margin: 8px 0;
    }

    .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center h5 {
        font-size: 14px;
        line-height: 1.4;
    }

    .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center p {
        margin: 15px 0;
    }

    .media-banner {
        padding: 30px 20px;
    }

    .slider-layout-4 .slider-slide {
        max-width: 100%;
        margin-left: 0;
    }

    .grocery-slide {
        padding-top: 0;
    }

    .grocery-slide .header-search3 {
        display: none;
    }

    .grocery-slide-block>div:nth-child(2)>div>div {
        padding: 0;
    }

    .cosmetic-slide .slide-main .slide-contain {
        height: 68vh;
    }

    .cosmetic-slide .slide-main .slide-contain h3 {
        margin-bottom: 12px;
    }

    .cosmetic-slide .slide-main .slide-contain h2 {
        margin-bottom: 6px;
    }

    .cosmetic-slide .slide-main .slide-contain h4 {
        margin-bottom: 20px;
    }

    .cosmetic-slide .slide-main .animat-block .animat-img3 {
        width: 180px;
    }

    .furniture-slide .slide-main .slide-contain {
        height: 65vh;
    }

    .furniture-slide .slide-main .slide-contain h2 {
        margin-bottom: 5px;
    }

    .furniture-slide .slide-main .slide-contain p {
        margin-bottom: 15px;
        width: 465px;
    }

    .furniture-slide .animat-block .animat1 {
        width: 465px;
    }

    .furniture-slide .animat-block .animat2 {
        width: 190px;
    }

    .digitalmark-slide .slide-main {
        background-position: 30% !important;
    }

    .digitalmark-slide .slide-main .slide-contain {
        height: 66vh;
    }

    .kids-slide .slide-main .slide-contain {
        height: 65vh;
    }

    .kids-slide .slide-main .slide-contain h2 {
        margin-bottom: 10px;
    }

    .kids-slide .slide-main .slide-contain p {
        margin-bottom: 20px;
    }

    .kids-slide .animat-block .animat1 {
        width: 305px;
    }

    .kids-slide .animat-block .animat2 {
        width: 40px;
    }

    .kids-slide .animat-block .animat3 {
        width: 60px;
    }

    .kids-slide .animat-block .animat4 {
        width: 60px;
    }

    .kids-slide .animat-block .animat5 {
        width: 90px;
        bottom: -50px;
    }

    .theme-slider .slider-banner .slider-img {
        height: 400px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-img {
        height: 400px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain {
        padding: 30px;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-img {
        height: 450px;
    }

    .layout-header1 .main-menu-block .menu-left .sm-nav-block {
        display: block;
    }

    .layout-header1 .main-menu-block .menu-right .icon-nav {
        margin-left: 0;
    }

    .layout-header1 .main-menu-block .menu-right .toggle-block {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        margin-left: 15px;
    }

    .layout-header1 .main-menu-block .menu-right .menu-banner {
        display: none;
    }

    .category-header .navbar-menu .category-left {
        width: 53%;
    }

    .category-header .navbar-menu .category-left .nav-block {
        display: none;
    }

    .category-header .navbar-menu .category-left .input-block .input-box .big-deal-form .input-group {
        height: 50px;
    }

    .category-header .navbar-menu .category-left .input-block .input-box .big-deal-form .input-group select {
        background: url("/../assets/images/layout-1/drop-1.png") no-repeat scroll 20px 19px;
    }

    .category-header .navbar-menu .category-right .contact-block {
        padding: 15px 0;
    }

    .layout-header3 .main-menu .menu-nav .toggle-nav {
        display: none;
    }

    .layout-header3 .main-menu .menu-left .nav-block {
        display: none;
    }

    .layout-header3 .main-menu .menu-left .sm-nav-block {
        display: block;
    }

    .newsletter .subscribe-block .input-group span {
        padding: 0 25px;
    }

    .newsletter .subscribe-block .input-group input {
        width: 220px;
    }

    .subscribe2 .subscribe-contain h2 {
        margin-bottom: 5px;
    }

    .subscribe2 .subscribe-contain p {
        margin-bottom: 17px;
    }

    .subscribe2 .subscribe-contain .form-control {
        margin-bottom: 23px;
    }

    .subscribe2 .subscribe-contain ul {
        margin-bottom: 21px;
    }

    .breadcrumb-main {
        padding: 50px 0;
    }

    .theme-invoice .invoice-popup .invoice-header .header-left .brand-logo img {
        height: 50px;
    }

    .theme-modal.blackfriday-modal#exampleModal .modal-dialog {
        max-width: 550px;
    }

    /*=====================
   Inner pages CSS
==========================*/
    .checkout-second .checkout-box {
        width: 990px;
    }

    .theme-card .offer-slider img {
        height: 130px;
        padding-right: 10px;
    }

    .theme-card .offer-slider .media .media-body .rating i {
        padding-right: 1px;
    }

    .tab-product .theme-form input,
    .tab-product .theme-form textarea,
    .product-full-tab .theme-form input,
    .product-full-tab .theme-form textarea {
        padding: 10px 15px;
    }

    .cart-section tbody tr td,
    .wishlist-section tbody tr td {
        min-width: 186px;
    }

    .blog-detail-page .comment-section li {
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .product-wrapper-grid.list-view .product-box .img-wrapper,
    .product-wrapper-grid.list-view .product-box .img-block {
        width: 50%;
    }

    .video-product video {
        height: 60vh;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
        width: 100%;
        border-left: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
        width: 20%;
        padding-right: 0 !important;
        border-left: 1px solid #dddddd;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
        padding: 20px !important;
        padding-left: 0 !important;
        width: 20%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view ul li:last-child {
        display: none;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
        width: 50%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
        width: 50%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter-toggle {
        width: 20%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .product-page-filter {
        width: 25%;
    }

    .cart-section tbody tr td {
        min-width: 140px;
    }

    .cart-section tbody tr td .qty-box .input-group .form-control {
        width: 52px;
    }

    .cart-section tfoot tr td {
        padding-right: 25px;
    }

    .collection-collapse-block .collection-collapse-block-content .collection-brand-filter .category-list li:first-child {
        margin-top: 15px;
    }

    .collection-filter-block .product-service .media .media-body h4 {
        font-size: 16px;
    }

    .product-right h2 {
        font-size: 20px;
    }

    .search-product>div:nth-child(n + 4) {
        margin-top: 30px;
    }

    .blog-page .blog-media {
        margin-bottom: 20px;
    }

    .blog-page .blog-media .blog-right {
        display: block;
        margin-top: 15px;
    }

    .blog-page .blog-media .blog-right p {
        line-height: 1.3;
    }

    .blog-page .blog-media.media-change {
        text-align: left;
    }

    .blog-page .blog-media.media-change>div:first-child {
        -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
        order: unset;
    }

    .tab-product .tab-content.nav-material .single-product-tables table {
        width: 50%;
    }

    .product-description-box .border-product {
        padding-top: 10px;
        padding-bottom: 15px;
    }

    .product-description-box .product-icon .product-social li {
        padding-right: 4px;
    }

    .product-description-box .product-icon .wishlist-btn span {
        padding-left: 4px;
    }

    .product-form-box .border-product {
        padding-top: 10px;
        padding-bottom: 15px;
    }

    .product-form-box .timer span {
        min-width: 48px;
    }

    .product-accordion .single-product-tables table {
        width: 70%;
    }

    .tab-product .flex-column {
        -webkit-box-orient: unset !important;
        -webkit-box-direction: unset !important;
        -ms-flex-direction: unset !important;
        flex-direction: unset !important;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .tab-product .nav-material.nav-tabs {
        border-bottom: 1px solid #dddddd;
        border-right: 0;
    }

    .tab-product .nav-material.nav-tabs .nav-item .nav-link.active {
        background-color: unset;
    }

    .tab-border {
        padding-top: 0;
    }

    .about-text p {
        margin-bottom: 25px;
    }

    .tools-parallax-product.full-banner {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    .tools-parallax-product .tools-description h3 {
        font-size: 17px;
    }

    .full-box .theme-card .offer-slider img {
        padding: 15px 0 15px 0;
    }

    .full-box .theme-card .offer-slider .product-box2 .media img {
        height: 230px;
    }

    .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant {
        margin-top: 5px;
    }

    .full-box .theme-card .offer-slider .product-box2 .media .media-body .color-variant li {
        margin-top: 5px;
    }

    .order-tracking #progressbar li .icon {
        width: 40px;
        height: 40px;
    }

    .add_to_cart.top,
    .add_to_cart.bottom {
        left: -320px;
        top: 0;
        height: 100vh;
        width: 320px;
    }

    .add_to_cart.top .cart-inner,
    .add_to_cart.bottom .cart-inner {
        height: 100vh;
        overflow: auto;
    }

    .add_to_cart.top .cart-inner .cart_top,
    .add_to_cart.bottom .cart-inner .cart_top {
        padding: 20px !important;
        margin-bottom: 20px;
    }

    .add_to_cart.top .cart-inner .cart_media,
    .add_to_cart.bottom .cart-inner .cart_media {
        padding: 0 20px !important;
        display: block;
    }

    .add_to_cart.top .cart-inner .cart_media ul.cart_product,
    .add_to_cart.bottom .cart-inner .cart_media ul.cart_product {
        padding: 0;
        width: 100%;
        display: block;
        height: calc(100vh - 335px);
        overflow-y: auto;
        bottom: 20px;
    }

    .add_to_cart.top .cart-inner .cart_media ul.cart_product li,
    .add_to_cart.bottom .cart-inner .cart_media ul.cart_product li {
        min-width: 100%;
    }

    .add_to_cart.top .cart-inner .cart_media ul.cart_product li:nth-child(n+2),
    .add_to_cart.bottom .cart-inner .cart_media ul.cart_product li:nth-child(n+2) {
        margin-left: unset;
        margin-top: 15px;
    }

    .add_to_cart.top .cart-inner .cart_media ul.cart_total,
    .add_to_cart.bottom .cart-inner .cart_media ul.cart_total {
        width: calc(320px - 40px);
        position: fixed;
        bottom: 20px;
    }

    .add_to_cart.top .cart-inner .cart_media ul.cart_total li .total,
    .add_to_cart.bottom .cart-inner .cart_media ul.cart_total li .total {
        margin-top: 20px;
    }

    .add_to_cart.top .cart-inner .cart_media .cart_total,
    .add_to_cart.bottom .cart-inner .cart_media .cart_total {
        width: 100%;
    }

    .add_to_cart.top.open-side,
    .add_to_cart.bottom.open-side {
        left: 0;
    }

    .masonory-banner .masonary-banner-main .masonory-banner-img {
        height: 350px;
    }

    .masonory-banner .masonary-banner-main .masonory-banner-img.masonory-img1 {
        height: 715px;
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant {
        padding: 40px 20px;
    }

    .masonary-banner-block1>div:first-child {
        margin-bottom: 12px;
    }

    .masonory-p1>div:first-child {
        padding-right: unset;
    }

    .masonory-p1>div:nth-child(n+2) {
        padding-left: 15px;
    }

    .masonory-p1>div:nth-child(n+3) {
        margin-top: 15px;
    }

    .portfolio-section .portfolio-2 {
        margin-bottom: -30px;
    }

    .portfolio-section .portfolio-3 {
        margin-bottom: -15px;
    }
}

@media (max-width: 1024px) {
    .farming-slide .slide-main .animat-block .animat1 {
        width: 520px;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain {
        width: 47%;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group {
        width: 340px;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group .form-control {
        height: 52px;
        width: 69%;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .btn {
        padding: 10px 17px;
    }

    .creative-card.creative-inner {
        padding: 10px;
    }
}

@media (max-width: 992px) {
    .sticky .header7 .sm-horizontal .mobile-back {
        padding: 18px;
    }

    .product .product-box .product-imgbox .new-label {
        padding: 2px;
    }
}

@media (max-width: 991px) {
    .gift-dropdown.dropdown-menu {
        width: 300px;
        right: -80px;
        left: 0 !important;
    }

    .theme-slider .slider-banner.slide-banner-1 div li img {
        width: 50%;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-1 li:nth-child(2) {
        right: 20px;
        left: unset;
        top: 0;
    }

    .theme-slider .slider-banner .layout2-slide-1 li {
        width: 450px;
    }

    .theme-slider .slider-banner .layout2-slide-2 li {
        width: 300px;
        bottom: 20px;
        right: 10px;
    }

    .theme-slider .slider-banner .layout2-slide-3 li {
        width: 300px;
        bottom: 20px;
        right: 10px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li {
        width: 200px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li:last-child {
        width: 200px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li {
        width: 220px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li:last-child {
        width: 220px;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-banner-contain {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-1 li {
        width: 60%;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-2 li {
        width: 60%;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-3 li {
        width: 65%;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-3 li:nth-child(2) {
        right: 30px;
    }

    .cosmetic-slide .slide-main .slide-contain {
        height: 55vh;
    }

    .cosmetic-slide .slide-main .slide-contain h2 {
        margin-bottom: 9px;
    }

    .cosmetic-slide .slide-main .slide-contain h4 {
        margin-bottom: 19px;
    }

    .cosmetic-slide .slide-main .animat-block .animat-img1 {
        width: 32px;
    }

    .cosmetic-slide .slide-main .animat-block .animat-img2 {
        width: 22px;
    }

    .cosmetic-slide .slide-main .animat-block .animat-img3 {
        width: 140px;
    }

    .furniture-slide .slide-main .slide-contain {
        height: 54vh;
    }

    .furniture-slide .slide-main .slide-contain p {
        width: 345px;
    }

    .furniture-slide .animat-block .animat1 {
        width: 410px;
    }

    .furniture-slide .animat-block .animat2 {
        width: 150px;
    }

    .furniture-slide .animat-block .animat3 {
        width: 43px;
    }

    .furniture-slide .animat-block .animat4 {
        width: 40px;
    }

    .farming-slide .slide-main .slide-contain {
        height: 52vh;
    }

    .farming-slide .slide-main .slide-contain h2 {
        margin-bottom: 12px;
    }

    .farming-slide .slide-main .animat-block .animat1 {
        width: 335px;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain {
        width: 52%;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain h2 {
        margin-bottom: 17px;
    }

    .kids-slide .slide-main .slide-contain {
        height: 59vh;
    }

    .kids-slide .animat-block .animat6 {
        left: -12%;
    }

    .tools-slide .slide-main .slide-contain {
        height: 57vh;
    }

    .tools-slide .slide-main .slide-contain h3 {
        margin-bottom: 10px;
    }

    .tools-slide .slide-main .slide-contain p {
        width: 300px;
    }

    .tools-slide .slide-main .animat-block .animat1 {
        width: 410px;
    }

    .pets-slide .slide-main .slide-contain .form-control {
        width: 320px;
    }

    .pets-slide .slide-main .animat-block .animat1 {
        width: 280px;
    }

    .pets-slide .slide-main .animat-block .animat2 {
        width: 280px;
    }

    .grocery-slide .slide-main .slide-contain {
        height: 58vh;
    }

    .grocery-slide .slide-main .animat-block .animat1 {
        right: 0;
    }

    .megastore-slide .slide-main .slide-contain {
        height: 52vh;
    }

    .sale-banenr .sale-banenr-contain h4 {
        margin-bottom: 10px;
    }

    .sale-banenr .sale-banenr-contain h2 {
        margin-bottom: 10px;
    }

    .sale-banenr .sale-banenr-contain h3 {
        margin-bottom: 15px;
    }

    .sale-banenr.banner-style1 .sale-banenr-contain h4 {
        margin-bottom: 10px;
    }

    .sale-banenr.banner-style1 .sale-banenr-contain h2 {
        margin-bottom: 15px;
    }

    .app-download .app-download-main .app-download-contian h2 {
        margin-bottom: 11px;
    }

    .app-download .app-download-main .app-download-contian h3 {
        margin-bottom: 16px;
    }

    .app-download .app-download-main .app-download-contian .btn {
        padding: 12px 30px;
    }

    .item-count .item-count-contain {
        width: 15px;
        height: 15px;
        font-size: 10px;
    }

    .layout-header2 .main-menu-block .cart-block ul li .item-count-contain {
        top: -10px;
        left: 9px;
    }

    .layout-header2 .main-menu-block .icon-block ul li .cart-block .cart-item {
        display: none;
    }

    .layout-header1~.category-header .gift-dropdown.dropdown-menu {
        left: unset !important;
    }

    .header7 .header-contain .logo-block .brand-logo img {
        height: 40px;
    }

    .header7 .header-contain .icon-block ul li.icon-md-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .header-search {
        display: none;
    }

    .header-search2 {
        display: none;
    }

    .layout-header1 .main-menu-block .menu-left .brand-logo img {
        height: 40px;
    }

    .layout-header2 .main-menu-block .brand-logo img {
        height: 40px;
    }

    .layout-header3 .main-menu .input-block {
        display: none;
    }

    .layout-header3 .main-menu .icon-block ul li.mobile-search {
        display: inline-block;
    }

    .layout-header3 .main-menu .icon-block ul li.mobile-wishlist .item-count-contain {
        top: 0;
    }

    .category-header-4 .navbar-menu .input-block {
        display: none;
    }

    .category-header-4 .navbar-menu .category-right .icon-block ul li.mobile-search {
        display: inline-block;
    }

    .category-header-4 .navbar-menu .category-left .logo-block img {
        height: 35px;
    }

    .category-header-4 .searchbar-input {
        display: block;
    }

    .category-header7 .category-contain .category-left .logo-block .brand-logo img {
        height: 40px;
    }

    .header-style2 .searchbar-input {
        display: block;
    }

    .header-style2 .category-header7 .searchbar-input {
        display: none;
    }

    .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center {
        display: block;
        text-align: left;
    }

    .hot-4>div:last-child,
    .hot-4>div:first-child {
        max-width: calc(50%);
        margin-left: 0;
    }

    .layout-3-hotdeal .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center .rating {
        margin: 20px auto;
    }

    .hotdeal-right-nav {
        display: block;
    }

    .hot-deal .hot-deal-contain .hot-deal-subcontain>div:nth-child(3) {
        position: absolute;
        bottom: 50px;
        right: 5px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }

    .hot-1>div:first-child {
        padding-left: 0;
    }

    .hot-1>div:last-child {
        padding-left: 0;
        padding-right: 0;
        margin-top: 15px;
    }

    .hot-2>div:nth-child(2) {
        margin-bottom: 15px;
    }

    .hot-deal .hot-deal-heading {
        margin-bottom: 10px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .hotdeal-right-slick .img-wrraper>div {
        height: 330px;
    }

    .hot-deal.hotdeal-two .hot-deal-contain {
        padding: 25px;
    }

    .hot-deal.hotdeal-two .hot-deal-contain .hot-deal-subcontain>div:nth-child(3) {
        position: unset;
        margin-top: 0;
    }

    .hot-deal.hotdeal-two .hot-deal-contain .btn {
        padding: 10px 20px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center h5 {
        margin-bottom: 5px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center p {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer {
        margin-top: 12px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .btn {
        margin-top: 17px;
    }

    .spc-responsive {
        margin-top: 20px;
    }

    .offer-banner-img img {
        height: 350px;
    }

    .offer-banner .banner-contain h5 {
        font-size: 13px;
        margin-bottom: 10px;
    }

    .breadcrumb-main {
        padding: 40px 0;
    }

    .footer1 .footer-main>div {
        max-width: 50%;
        width: 50%;
    }

    .footer1 .footer-main>div:nth-child(n+3) {
        margin-top: 30px;
    }

    .cookie-bar.left-bottom {
        display: none;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
    }

    .cookie-bar.left-bottom svg {
        display: none;
    }

    .btn-rounded,
    .btn-white,
    .btn-normal,
    .btn-solid {
        padding: 12px 40px;
    }

    .btn-rounded.btn-md {
        padding: 10px 25px;
    }

    .subscribe1 .subscribe-contain {
        display: block;
        text-align: center;
    }

    .subscribe1 .subscribe-contain .subscribe-left .media {
        display: block;
    }

    .subscribe1 .subscribe-contain .subscribe-left .media svg {
        margin-bottom: 5px;
    }

    .subscribe1 .subscribe-contain .subscribe-left .media .media-body {
        margin-left: unset;
    }

    .subscribe1 .subscribe-contain .subscribe-left .media .media-body h6 {
        margin-bottom: 5px;
    }

    .subscribe1 .subscribe-contain .subscribe-left .media .media-body h3 {
        margin-bottom: 10px;
    }

    .subscribe1 .subscribe-contain .subscribe-right .input-group {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .subscribe1 .subscribe-contain .subscribe-right .input-group .form-control {
        width: 300px;
    }

    .subscribe2 .subscribe-contain p {
        width: 100%;
    }

    .instagram .insta-contant .insta-sub-contant .insta-title {
        padding: 15px 25px;
    }

    .instagram .insta-contant1 .insta-sub-contant1 .insta-title {
        padding: 15px 30px;
    }

    .services .service-block>div:last-child .media {
        margin-left: 24px;
    }

    .services .service-block>div:first-child {
        padding-bottom: 0;
    }

    .services .service-block>div:nth-child(2) {
        padding-bottom: 0;
    }

    .brand-panel .brand-panel-box .brand-panel-contain ul li:first-child {
        display: block;
        text-align: center;
        border: 0;
        border-bottom: 2px solid #042c49;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 10px;
        font-size: 16px;
    }

    .brand-panel .brand-panel-box .brand-panel-contain ul li:nth-child(2) {
        display: none;
    }

    .testimonial .testimonial-contain {
        padding: 30px 0;
    }

    .testimonial .testimonial-contain .media {
        display: block;
    }

    .testimonial .testimonial-contain .media .testimonial-img {
        margin: 0 auto;
    }

    .testimonial .testimonial-contain .media .media-body {
        margin-left: 0;
        text-align: center;
    }

    .testimonial5 .testimonial-detail {
        width: 100%;
    }

    .testimonial6 .testimonial-box .img-wrapper {
        margin-bottom: 10px;
    }

    .testimonial6 .testimonial-box .testimonial-detail h6 {
        margin-bottom: 6px;
    }

    .testimonial6 .testimonial-box .testimonial-detail p {
        margin-bottom: 10px;
    }

    .deal-banner {
        padding: 30px 0;
    }

    .deal-banner .deal-banner-containe {
        text-align: center;
        height: auto;
    }

    .deal-banner .deal-banner-containe h2 {
        margin-bottom: 15px;
    }

    .deal-banner .deal-banner-containe h1 {
        margin-bottom: 17px;
    }

    .deal-banner .deal-banner-containe .deal-btn {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .collection5>div {
        margin-top: 15px;
    }

    .collection5>div:last-child {
        padding-left: 0;
        padding-right: 15px;
    }

    .collection5>div:first-child {
        margin-bottom: 0;
    }

    .collection-banner .collection-banner-main .collection-img {
        height: 140px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain {
        padding: 20px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain h3 {
        font-size: 16px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain h4 {
        font-size: 16px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain .shop {
        margin-top: 10px;
    }

    .collection-banner .collection-banner-main.banner-1 .collection-banner-contain h3 {
        font-size: 18px;
    }

    .collection-banner .collection-banner-main.banner-1 .collection-banner-contain h4 {
        font-size: 22px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain {
        padding: 0 5px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain h3 {
        font-size: 14px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain h4 {
        font-size: 14px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-img {
        height: 130px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-banner-contain h3 {
        font-size: 23px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-banner-contain h4 {
        font-size: 14px;
        margin-bottom: 40px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-img {
        height: 275px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-banner-contain h3 {
        font-size: 15px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-banner-contain h4 {
        font-size: 17px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-img {
        height: 130px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-img {
        height: 150px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-banner-contain h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-banner-contain h4 {
        font-size: 32px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-banner-contain .btn-normal {
        padding: 8px 25px;
    }

    .collection-banner .collection-banner-main.banner-10 .collection-img {
        height: 240px;
    }

    .collection-banner .collection-banner-main.banner-11 .collection-img {
        height: 495px;
    }

    .collection-banner .collection-banner-main.banner-12 .collection-img {
        height: 250px;
    }

    .collection-banner .collection-banner-main.banner-13 .collection-img {
        height: 220px;
    }

    .collection-banner .collection-banner-main.banner-14 .collection-img {
        height: 455px;
    }

    .collection-banner .collection-banner-main.banner-15 .collection-img {
        height: 300px;
    }

    .collection-banner .collection-banner-main.banner-16 .collection-img {
        height: 455px;
    }

    .collection-banner .collection-banner-main.banner-style1 .collection-banner-contain {
        background-color: rgba(0, 0, 0, 0.1);
    }

    .collection-banner .collection-banner-main.banner-style1 .collection-banner-contain h3 {
        margin-bottom: 12px;
    }

    .collection-banner .collection-banner-main.banner-style1 .collection-banner-contain h4 {
        color: #ffffff;
    }

    .collection-banner .collection-banner-main.banner-style1 .collection-banner-contain .shop {
        margin-top: 12px;
    }

    .collection-banner .collection-banner-main.banner-style4 .collection-banner-contain {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .collection-banner .collection-banner-main.banner-style4 .collection-banner-contain h4 {
        color: #ef9f77;
    }

    .collection-banner .collection-banner-main.banner-style6 .collection-banner-contain {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .collection-banner .collection-banner-main.banner-style6 .collection-banner-contain h4 {
        color: #ffffff;
    }

    .collection-banner .collection-p6>div:nth-child(n+3) {
        padding-left: 15px;
        padding-top: 15px;
    }

    .collection-layout3>div:nth-child(2) {
        padding-right: 15px;
    }

    .full-banner {
        padding: 100px 0;
    }

    .rounded-category {
        padding: 30px 0 15px;
    }

    .box-category .box-category-contain {
        padding: 20px 0;
    }

    .hot-deal .slick-prev,
    .hot-deal .slick-next {
        top: 48px;
    }

    .hot-deal .hot-deal-contain .hotdeal-right-nav img {
        height: auto;
        width: 65px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer span {
        min-width: 22px;
        padding: 12px;
    }

    .hot-deal .hot-deal-contain .hot-deal-subcontain>div:nth-child(3) {
        margin-top: 20px;
    }

    .hot-deal .hot-deal-contain .hotdeal-right-nav img {
        height: auto;
        width: 105px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .hotdeal-right-nav img,
    .hot-deal.hotdeal-third .hot-deal-contain .hotdeal-right-nav img {
        width: 65px;
        height: auto;
    }

    .Jewellery-banner {
        text-align: center;
        background-color: rgba(255, 255, 255, 0.7);
        background-blend-mode: overlay;
    }

    .Jewellery-banner a {
        font-size: 18px;
    }

    .Jewellery-banner h6 {
        font-size: 40px;
    }

    .theme-slider .slider-banner .slider-img {
        height: 350px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-img {
        height: 250px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h1 {
        font-size: 30px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h1 span {
        margin-left: 5px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h4 {
        font-size: 16px;
        padding-bottom: 15px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h2 {
        font-size: 20px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-banner-contain .btn-normal {
        padding: 10px 20px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-img {
        height: 330px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain {
        min-width: 250px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain span {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain h1 {
        font-size: 32px;
        padding-bottom: 14px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain h4 {
        font-size: 14px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-1 li {
        width: 380px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-2 li {
        width: 270px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-3 li {
        width: 300px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-img {
        height: 320px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain {
        padding: 40px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h3 {
        font-size: 25px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h1 {
        font-size: 35px;
        padding-bottom: 10px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h2 {
        font-size: 16px;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-img {
        height: 400px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain {
        padding: 40px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h3 {
        font-size: 25px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h1 {
        font-size: 35px;
        padding-bottom: 10px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h2 {
        font-size: 16px;
    }

    .multiple-slider>div:nth-child(n+3) {
        margin-top: 15px;
    }

    .top-header {
        padding: 5px 0;
    }

    .layout-header1 .main-menu-block .menu-left .brand-logo img {
        height: 40px;
    }

    .category-header .navbar-menu .category-left {
        width: auto;
    }

    .category-header .navbar-menu .category-right .gift-block {
        margin-left: 20px;
        padding: 12px 20px;
    }

    .category-header .navbar-menu .category-right .gift-block .grif-icon {
        display: none;
    }

    .category-header .navbar-menu .category-right .gift-block .gift-offer span {
        font-size: 16px;
    }

    .category-header .navbar-menu .category-right .contact-block {
        display: none;
    }

    .category-header-2 .navbar-menu .category-right .gift-block .gift-offer {
        display: none;
    }

    .category-header-2 .navbar-menu .category-right .contact-block i {
        display: block;
    }

    .category-header-2 .navbar-menu .category-right .contact-block span {
        display: none;
    }

    .layout-header3 .main-menu .menu-left .logo-block img {
        height: 40px;
    }

    .no-slider .product-box {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: calc(50% - 30px);
        margin: 0 15px 30px !important;
    }

    .no-slider .product-box:nth-last-child(-n+2) {
        margin: 0 15px 0 !important;
    }

    .no-slider.five-product .product-box {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: calc(50% - 30px);
        margin: 0 15px 30px !important;
    }

    .no-slider.five-product .product-box:nth-last-child(-n+2) {
        margin: 0 15px 0 !important;
    }

    .product-block4>div:nth-child(n+4) {
        margin-top: 30px;
    }

    .order-tracking .order-tracking-box .form-group .form-control {
        height: 45px;
    }

    .order-tracking .order-tracking-box .paymant-collapce {
        padding: 20px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li .form-control {
        height: 35px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li img {
        width: 40px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li:nth-child(n+2) {
        margin-left: 15px;
    }

    .order-tracking .order-tracking-box .paymant-collapce .cash-pay .form-control {
        height: 40px;
    }

    .order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox .captchaimagecode {
        width: 110px;
    }

    .order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox .captchaimagecode .reloadbtncapcha svg {
        width: 14px;
    }

    .shpping-block>div:nth-child(n+2) {
        margin-top: 30px;
    }

    .category-page-side .collection-collapse-block .collapse-block-title {
        margin-top: 0;
    }

    .category-page-side .collection-sidebar-banner {
        margin-bottom: 20px !important;
        margin-top: 10px !important;
    }

    .collection-product-wrapper .product-wrapper-grid .product-box .product-detail .icon-detail a,
    .collection-product-wrapper .product-wrapper-grid .product-box .product-detail .icon-detail button {
        width: 35px;
        height: 35px;
    }

    .collection-product-wrapper .product-wrapper-grid .product-box .product-detail .icon-detail a svg,
    .collection-product-wrapper .product-wrapper-grid .product-box .product-detail .icon-detail button svg {
        width: 16px;
    }

    .collection-wrapper .product-slick {
        background-color: #f1f5f8;
    }

    .collection-wrapper .product-slick img {
        width: 50%;
        margin: 0 auto;
    }

    .collection-wrapper .tab-product {
        margin-top: 30px !important;
    }

    .collection-filter>div:nth-child(n+2) {
        margin-top: 0;
    }

    .login-page .authentication-right {
        height: auto;
    }

    .blog-detail-page .blog-advance ul {
        margin-bottom: 20px;
    }

    .blog-detail-page .blog-advance p:last-child {
        margin-bottom: 0;
    }

    .top-banner-content h4 {
        font-size: 20px;
    }

    .collection-product-wrapper .product-top-filter {
        border-top: none;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
        border-top: 1px solid #dddddd;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
        display: none;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view {
        display: none;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
        width: 50%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view:before,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before {
        right: 15px !important;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
        border-left: 1px solid #dddddd;
        padding: 21px 34px 21px 34px;
        border-top: none;
        border-bottom: none;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select {
        border-right: none !important;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter-toggle {
        display: none;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .product-page-filter {
        width: 50%;
        border-top: 1px solid #dddddd;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .product-page-per-view {
        border-top: 1px solid #dddddd;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter {
        position: fixed;
        height: 100vh;
        top: 0;
        left: -350px;
        background-color: #ffffff;
        z-index: 99;
        overflow-y: scroll;
        max-width: 350px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -webkit-box-shadow: 1px 0 5px #dddddd;
        box-shadow: 1px 0 5px #dddddd;
        display: block;
        padding-top: unset;
        padding-bottom: unset;
        padding-left: 40px;
        padding-right: 20px;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter .horizontal-filter-contain {
        display: block;
        width: 100%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter .horizontal-filter-contain .filter-group {
        width: auto;
        border-right: none;
        margin-right: unset;
        padding-right: unset;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter .btn {
        display: none;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
        border-top: 1px solid #dddddd;
    }

    .video-product video {
        height: 400px;
    }

    .category-list .product-wrapper-grid.list-view .product-box .product-imgbox {
        width: 85%;
    }

    .filter-main-btn {
        display: block;
    }

    .collection-filter {
        position: fixed;
        height: 100vh;
        top: 0;
        left: -350px;
        background-color: #ffffff;
        z-index: 99;
        overflow-y: scroll;
        width: 350px;
        max-width: 350px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -webkit-box-shadow: 1px 0 5px #dddddd;
        box-shadow: 1px 0 5px #dddddd;
    }

    .collection-filter>div:first-child {
        padding-top: 30px !important;
    }

    .collection-filter>div:last-child {
        padding-bottom: 30px !important;
    }

    .collection-filter .creative-card {
        background-color: transparent;
    }

    .collection-filter .creative-card.creative-inner {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .collection-filter .collection-sidebar-banner {
        text-align: center;
    }

    .collection-filter .theme-card {
        padding-left: 30px;
        padding-right: 30px;
    }

    .collection-filter .collection-sidebar-banner {
        padding: 0 30px;
    }

    .collection-filter-block {
        border: none;
    }

    .collection-mobile-back {
        display: block;
        padding-top: 0;
        line-height: 1;
    }

    .collection .section-t-space {
        padding-top: 30px;
    }

    .collection .partition-collection>div:nth-last-child(1) {
        margin-top: 30px;
    }

    .collection .partition-collection>div:nth-last-child(2) {
        margin-top: 30px;
    }

    .right-login {
        margin-top: 30px;
    }

    .contact-page .map iframe {
        height: 350px;
    }

    .contact-page .contact-right {
        padding-bottom: 0;
    }

    .contact-page .contact-right ul li {
        padding-left: 0;
        border-bottom: 1px solid #dddddd;
        text-align: center;
        padding-bottom: 10px;
        margin-top: 15px;
    }

    .contact-page .contact-right ul li .contact-icon {
        position: relative;
        margin: 0 auto;
        border-right: 0;
    }

    .contact-page .contact-right ul li p {
        margin-bottom: 10px;
    }

    .cart-section .cart-table thead th:last-child {
        display: none;
    }

    .cart-section tbody tr td {
        min-width: 135px;
    }

    .cart-section tbody tr td:last-child {
        display: none;
    }

    .cart-section .cart-buttons>div:last-child {
        padding-right: 15px;
    }

    .cart-section tfoot tr td {
        padding-right: 0;
    }

    .cart-section.order-history tbody tr td:last-child {
        display: table-cell;
    }

    .cart-section.order-history .cart-table thead th {
        /*text-transform: capitalize;*/
    }

    .cart-section.order-history .cart-table thead th:last-child {
        display: table-cell;
    }

    .wishlist-section tbody tr td {
        min-width: 138px;
    }

    .is_stuck {
        margin-top: 0;
    }

    .product-right h2 {
        margin-top: 15px;
    }

    .product-right {
        text-align: center;
    }

    .product-right .pro-group .product-title.size-text {
        text-align: left;
    }

    .product-right .pro-group .revieu-box {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .product-right .pro-group ul.best-seller {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .product-right .pro-group .qty-box {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .product-right .pro-group .delivery-detail .delivery-detail-contian {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .product-right .pro-group .delivery-detail .delivery-lable {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .product-right .pro-group .delivery-services {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .product-right .product-offer .offer-contain {
        text-align: left;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .product-right .product-offer .offer-contain .show-offer {
        text-align: center;
    }

    .review-block>div:nth-child(n+3) {
        margin-top: 20px;
    }

    .product-form-box {
        margin-top: 30px;
    }

    .product_img_scroll {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
        width: 55%;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        margin-bottom: 20px;
        background: none #042c49;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .open-popup {
        text-align: left;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup>a {
        color: #ffffff;
        font-weight: 700;
        letter-spacing: 0.05em;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup .collection-filter {
        height: 100vh;
        left: -350px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .search-count {
        width: 100%;
        padding: 10px 0;
        text-align: center;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .collection-view,
    .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
        display: none;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
        width: 50%;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before,
    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
        top: 13px;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
        left: unset;
        right: 35px;
    }

    .tab-product {
        padding-top: 0;
        padding-bottom: 0;
    }

    .product-side-tab {
        margin-top: 25px !important;
    }

    .tab-product .nav-material.nav-tabs {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .tab-product .tab-content.nav-material p {
        padding: 20px 0 0 0;
    }

    .pro_sticky_info {
        border: none;
        padding: 10px 0;
    }

    .dashboard-left {
        position: fixed;
        height: 100vh;
        top: 0;
        left: -350px;
        background-color: white;
        z-index: 99;
        padding: 0 35px 35px;
        max-width: 350px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -webkit-box-shadow: 0 0 8px 0 #dddddd;
        box-shadow: 0 0 8px 0 #dddddd;
    }

    .dashboard-left .block-title h2 {
        display: none;
    }

    .dashboard-left .block-content {
        border: none;
        padding: 0;
        margin-top: 20px;
    }

    .dashboard-left .block-content ul li:hover {
        padding-left: 0;
    }

    .account-sidebar {
        display: block;
    }

    .account-sidebar a {
        color: #ffffff;
    }

    .typography_section .row>div:first-child .typography-box:last-child {
        margin-bottom: 30px;
    }

    .product-slick .slick-prev,
    .product-slick .slick-next,
    .rtl-product-slick .slick-prev,
    .rtl-product-slick .slick-next,
    .product-right-slick .slick-prev,
    .product-right-slick .slick-next,
    .rtl-product-right-slick .slick-prev,
    .rtl-product-right-slick .slick-next {
        opacity: 1;
    }

    .product-slick .slick-prev,
    .rtl-product-slick .slick-prev,
    .product-right-slick .slick-prev,
    .rtl-product-right-slick .slick-prev {
        left: 20px;
    }

    .product-slick .slick-next,
    .rtl-product-slick .slick-next,
    .product-right-slick .slick-next,
    .rtl-product-right-slick .slick-next {
        right: 20px;
    }

    .order-up {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .bundle .bundle_img {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .bundle .bundle_detail .theme_checkbox {
        padding: 0 20%;
    }

    .checkout-page .checkout-form .checkout-details {
        margin-top: 30px;
    }

    .checkout-second .checkout-box {
        width: 100%;
    }

    .collection-collapse-block {
        border-bottom: 1px solid #dddddd;
        padding: 15px 0;
    }

    .tools-parallax-product .tools-description h3 {
        text-align: center;
    }

    .tools-parallax-product .tools-description .tools-form {
        text-align: center;
    }

    .tools-parallax-product .tools-description .tools-form .search-box {
        margin: 20px auto 20px;
    }

    .tools-parallax-product .tools-description .tools-form .btn-find {
        background-size: 1100px;
    }

    .tools-parallax-product .tools-grey {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
        margin-bottom: 30px;
    }

    .tools-parallax-product.full-banner {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .tools_product .multiple-slider>div:nth-child(-n + 2) {
        margin-bottom: 0;
    }

    .tools_product .tools-grey {
        margin-top: 30px;
    }

    .tools_product .banner-tools {
        margin-left: 10px;
        margin-right: 10px;
    }

    .full-box .theme-card .offer-slider .product-box2 .media img {
        height: 160px;
    }

    .theme-modal#exampleModal .close span {
        color: #444444;
    }

    .theme-modal#exampleModal .newslatter-main>div:last-child {
        display: none !important;
    }

    .theme-modal#exampleModal .offer-content {
        background-color: #ffffff;
        background-image: url("/../assets/images/modal-popup/Genaral.png");
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-left: 0;
        min-height: 400px;
        padding-right: 0;
        background-size: cover;
    }

    .theme-modal#exampleModal .offer-content.vagi-offer-contant {
        background-image: url("/../assets/images/modal-popup/Genaral.png");
        background-size: cover;
    }

    .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body .buttons {
        margin-bottom: 10px;
    }

    .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body .buttons a {
        margin: 5px 2px;
    }

    .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart .media .media-body a h6 {
        margin-bottom: 10px;
    }

    .theme-modal .quick-view-img {
        height: 400px;
        width: auto;
    }

    .theme-modal.blackfriday-modal#exampleModal .modal-dialog {
        max-width: 500px;
    }

    .theme-modal.blackfriday-modal#exampleModal .modal-body {
        height: 350px;
    }

    .theme-modal.blackfriday-modal#exampleModal .offer-content {
        min-height: 300px;
    }

    .theme-modal.cyber-monday#exampleModal .modal-dialog {
        max-width: 540px;
    }

    .theme-modal.cyber-monday#exampleModal .offer-content {
        min-height: auto;
    }

    .theme-modal.cyber-monday#exampleModal .offer-content h3 {
        margin-bottom: 15px;
    }

    .product-order .final-total h3 {
        margin-bottom: 15px;
    }

    .masonory-banner .masonary-banner-main .masonory-banner-img {
        height: 300px;
    }

    .masonory-banner .masonary-banner-main .masonory-banner-img.masonory-img1 {
        height: 615px;
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant {
        padding: 30px 20px;
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant h5 {
        letter-spacing: 5px;
    }

    .portfolio-section .filter-button {
        padding: 8px 20px;
    }

    .dashboard-left {
        padding: 20px;
    }
}

@media (max-width: 768px) {
    .brand-panel .brand-panel-box .brand-panel-contain ul>li:not(first-child) {
        margin: 2px 1px;
        border: 1px solid #ddd;
    }

    .product .product-box .product-imgbox .product-icon {
        -webkit-transform: translateX(0px) !important;
        transform: translateX(0px) !important;
        right: 3px;
    }

    .product .product-box .product-imgbox .product-icon.icon-inline {
        opacity: 1;
    }

    .product .product-box .product-imgbox .product-icon.icon-center {
        display: block;
        left: unset;
        right: 0;
        margin-right: unset;
    }

    .product .product-box .product-imgbox .product-icon button {
        width: 35px;
        height: 35px;
    }

    .product .product-box .product-imgbox .product-icon a {
        width: 35px;
        height: 35px;
    }

    .product .product-box .product-imgbox .product-icon a i {
        padding: 10px;
    }

    .product .product-box .product-detail.detail-center {
        overflow: unset;
    }

    .product .product-box .product-detail.detail-center .icon-detail {
        bottom: 70px;
    }

    .product .product-box .product-detail .detail-title {
        padding-top: 0;
    }

    .product .product-box .product-detail.detail-inline {
        padding-top: 0;
    }

    .product .product-box:hover .product-icon button {
        -webkit-animation: none;
        animation: none;
    }

    .product .product-box:hover .product-icon a:nth-child(2) i,
    .product .product-box:hover .product-icon a:nth-child(3) i,
    .product .product-box:hover .product-icon a:nth-child(4) i {
        -webkit-animation: none;
        animation: none;
    }

    .product .product-box:hover .product-icon.icon-inline button {
        -webkit-animation: none;
        animation: none;
    }

    .product .product-box:hover .product-icon.icon-inline a:nth-child(2) i,
    .product .product-box:hover .product-icon.icon-inline a:nth-child(3) i,
    .product .product-box:hover .product-icon.icon-inline a:nth-child(4) i {
        -webkit-animation: none;
        animation: none;
    }

    .product .product-box:hover .product-detail.detail-center .detail-title {
        opacity: 1;
    }

    .product .product-box:hover .product-detail.detail-center .icon-detail {
        bottom: 70px;
    }

    .product .product-box:hover .product-detail.detail-center .icon-detail button {
        -webkit-animation: none;
        animation: none;
    }

    .product .product-box:hover .product-detail.detail-center .icon-detail a:nth-child(2) i,
    .product .product-box:hover .product-detail.detail-center .icon-detail a:nth-child(3) i,
    .product .product-box:hover .product-detail.detail-center .icon-detail a:nth-child(4) i {
        -webkit-animation: none;
        animation: none;
    }

    .media-banner .media-banner-box .media .media-body .media-contant .cart-info {
        opacity: 1;
        visibility: visible;
        position: unset;
        margin-top: 5px;
    }

    .media-banner .media-banner-box .media:hover .media-body .media-contant .product-detail {
        visibility: visible;
        opacity: 1;
    }

    .media-banner .media-banner-box .media:hover .media-body .media-contant .cart-info button {
        -webkit-animation: none;
        animation: none;
    }

    .media-banner .media-banner-box .media:hover .media-body .media-contant .cart-info a:nth-child(2) {
        -webkit-animation: none;
        animation: none;
    }

    .media-banner .media-banner-box .media:hover .media-body .media-contant .cart-info a:nth-child(3) {
        -webkit-animation: none;
        animation: none;
    }

    .media-banner .media-banner-box .media:hover .media-body .media-contant .cart-info a:nth-child(4) {
        -webkit-animation: none;
        animation: none;
    }

    .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block {
        padding: 0;
        background-color: transparent;
        margin-left: 0;
    }

    .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block svg {
        fill: #999999;
    }

    .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block .item-count-contain {
        color: #ffffff;
        background-color: #042c49;
        top: -7px;
        left: 12px;
    }

    .layout-header1 .main-menu-block .menu-right .icon-nav ul li .cart-item {
        display: none;
    }

    .cosmetic-slide .slide-main {
        background-position: 0 !important;
    }

    .cosmetic-slide .slide-main .slide-contain {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .cosmetic-slide .slide-main .slide-contain h3 {
        color: #ffffff;
        margin-bottom: 15px;
    }

    .cosmetic-slide .slide-main .slide-contain h4 {
        color: #ffffff;
        margin-bottom: 20px;
    }

    .furniture-slide .slide-main {
        background-position: 95% !important;
    }

    .furniture-slide .slide-main .slide-contain {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        text-align: center;
    }

    .furniture-slide .slide-main .slide-contain h2 {
        margin-bottom: 5px;
    }

    .furniture-slide .slide-main .slide-contain h3 {
        color: #ffffff;
    }

    .furniture-slide .slide-main .slide-contain p {
        color: #ffffff;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .furniture-slide .animat-block .animat1 {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }

    .furniture-slide .animat-block .animat3 {
        right: 82%;
    }

    .furniture-slide .animat-block .animat4 {
        right: 80%;
    }

    .farming-slide .slide-main .slide-contain {
        background-color: rgba(0, 0, 0, 0.7);
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: 48vh;
    }

    .farming-slide .slide-main .slide-contain h3 {
        margin-bottom: 10px;
    }

    .farming-slide .slide-main .slide-contain h2 {
        margin-bottom: 10px;
        color: #ef9f77;
    }

    .farming-slide .slide-main .slide-contain h4 {
        margin-bottom: 15px;
    }

    .farming-slide .slide-main .animat-block {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .farming-slide .slide-main .animat-block .animat1 {
        left: 0;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
    }

    .digitalmark-slide .slide-main {
        background-position: 0 !important;
    }

    .digitalmark-slide .slide-main .slide-contain {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        height: 49vh;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain {
        width: 90%;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain h2 {
        margin-bottom: 8px;
        color: #ffffff;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain p {
        margin-bottom: 16px;
        color: #ffffff;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group {
        margin-left: auto;
        margin-right: auto;
    }

    .digitalmark-slide .slide-main .animat-block .animat1,
    .digitalmark-slide .slide-main .animat-block .animat2 {
        z-index: -1;
    }

    .kids-slide .slide-main {
        background-position: 90% !important;
    }

    .kids-slide .slide-main .slide-contain {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.5);
        height: 56vh;
    }

    .kids-slide .slide-main .slide-contain h4 {
        letter-spacing: 0.05em;
        margin-bottom: 10px;
    }

    .kids-slide .slide-main .slide-contain h3 {
        color: #ffffff;
        margin-bottom: 10px;
    }

    .kids-slide .slide-main .slide-contain h2 {
        margin-bottom: 15px;
    }

    .kids-slide .slide-main .slide-contain p {
        color: #ffffff;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
    }

    .kids-slide .animat-block .animat1 {
        width: 230px;
    }

    .kids-slide .animat-block .animat5 {
        width: 70px;
        bottom: -39px;
    }

    .kids-slide .animat-block .animat6 {
        display: none;
    }

    .tools-slide .slide-main .slide-contain {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.5);
        height: 54vh;
    }

    .tools-slide .slide-main .slide-contain h2 {
        color: #042c49;
    }

    .tools-slide .slide-main .slide-contain h3 {
        color: #ef9f77;
    }

    .tools-slide .slide-main .slide-contain h4 span {
        color: #ef9f77;
    }

    .tools-slide .slide-main .slide-contain p {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        color: #ffffff;
        margin-bottom: 10px;
    }

    .tools-slide .slide-main .animat-block .animat1 {
        width: 80%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .pets-slide .slide-main .slide-contain {
        height: 54vh;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .pets-slide .slide-main .slide-contain h2 {
        color: #042c49;
    }

    .pets-slide .slide-main .slide-contain h3 {
        color: #ffffff;
    }

    .pets-slide .slide-main .slide-contain .form-control {
        width: 272px;
        height: 48px;
    }

    .pets-slide .slide-main .animat-block .animat1 {
        width: 250px;
    }

    .pets-slide .slide-main .animat-block .animat2 {
        width: 250px;
    }

    .grocery-slide .slide-main .slide-contain {
        position: relative;
        z-index: 1;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .grocery-slide .slide-main .slide-contain h3 {
        color: #042c49;
        margin-bottom: 10px;
    }

    .grocery-slide .slide-main .slide-contain h2 {
        margin-bottom: 7px;
    }

    .grocery-slide .slide-main .slide-contain h4 {
        color: #042c49;
        margin-bottom: 18px;
    }

    .grocery-slide .slide-main .animat-block .animat1 {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
    }

    .megastore-slide .slide-main .slide-contain h4 {
        margin-bottom: 11px;
    }

    .megastore-slide .slide-main .slide-contain h2 {
        margin-bottom: 12px;
    }

    .megastore-slide .slide-main .slide-contain h3 {
        margin-bottom: 20px;
    }

    .video-banner .video-banner-contain {
        height: 320px;
    }

    .video-banner .video-banner-contain h3 {
        margin-top: 17px;
    }

    .video-banner .video-banner-contain h2 {
        margin-top: 15px;
    }

    .testimonial2 .testimonial-box .img-wrapper {
        margin-bottom: 17px;
    }

    .app-download .app-download-main {
        background-position: 28% !important;
    }

    .hotdeal-second .hotdeal-box .img-wrapper a {
        height: 215px;
    }

    .hotdeal-second .hotdeal-box .hotdeal-contain {
        padding: 15px;
    }

    .hotdeal-second .hotdeal-box .hotdeal-contain .timer2 span {
        min-width: 52px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .order-tracking .order-tracking-box .form-group .form-control {
        height: 40px;
    }
}

@media (max-width: 767px) {
    .layout-header1~.category-header .gift-dropdown.dropdown-menu {
        right: 0 !important;
        -webkit-transform: translate3d(0px, 63px, 0px) !important;
        transform: translate3d(0px, 63px, 0px) !important;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-1 li {
        left: -120px;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-1 li:nth-child(2) {
        right: -50px;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-3 li {
        left: -100px;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-3 li:nth-child(2) {
        right: -30px;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-2 li {
        left: -50px;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-2 li:nth-child(2) {
        right: -60px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .layout-3-hotdeal>div:nth-child(2) {
        padding: 0 15px;
        width: 100%;
        margin-top: 15px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .blog-page .order-sec {
        padding-left: 15px;
    }

    .tab-product-main .tab-prodcut-contain ul li:nth-child(n+2) {
        margin-left: 20px;
    }

    .left-pro-img {
        margin-top: 30px !important;
    }

    .media-view {
        display: none;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul li .cart-item {
        display: none;
    }

    .category-header-2 .navbar-menu .category-left .nav-block .nav-left .navbar {
        padding: 18px 0;
    }

    .category-header-2 .navbar-menu .category-left .menu-block .toggle-nav {
        padding-top: 13px;
        padding-bottom: 13px;
    }

    .footer-title h5 {
        color: #777;
    }

    .hot-deal .hot-deal-contain .hot-deal-subcontain>div:nth-child(3) {
        position: relative;
        bottom: 0;
    }

    .hot-deal .hot-deal-contain .hotdeal-right-slick img {
        width: 50%;
        margin: 0 auto;
    }

    .theme-pannel-main {
        display: none;
    }

    .theme-tab.product {
        margin-bottom: 0px;
    }

    .contact-page .theme-form {
        padding: 15px;
        border-width: 15px;
    }

    .testimonial .testimonial-contain .media .testimonial-img {
        width: 80px;
        height: 80px;
    }

    .testimonial3 .testimonial-box .testimonial-detail h3 {
        margin-bottom: 5px;
    }

    .testimonial3 .testimonial-box .testimonial-detail p {
        margin-bottom: 7px;
    }

    .testimonial3 .testimonial-box .testimonial-detail h6 {
        margin-bottom: 15px;
    }

    .testimonial3 .testimonial-box .img-wrapper {
        margin-bottom: 15px;
    }

    .testimonial4 .testimonial-box .img-wrapper {
        margin-bottom: 10px;
    }

    .testimonial4 .testimonial-box .testimonial-detail p {
        margin-bottom: 9px;
    }

    .testimonial4 .testimonial-box .testimonial-detail h6 {
        margin-bottom: 13px;
    }

    .collection-banner .collection-banner-main .collection-img {
        height: 200px;
    }

    .collection-banner .collection-banner-main.banner-11 .collection-img {
        height: 240px;
    }

    .collection-banner .collection-banner-main.banner-14 .collection-img {
        height: 220px;
    }

    .collection-banner .collection-banner-main.banner-16 .collection-img {
        height: 250px;
    }

    .collection-banner .collection-banner-main.p-top .collection-banner-contain {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .collection-banner .collection-p1>div:nth-child(2) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .collection-banner .collection-p1>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .collection-banner .collection-p2>div:first-child>div>div:first-child {
        padding-right: 15px;
    }

    .collection-banner .collection-p2>div:first-child>div>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .collection-banner .collection-p2>div:last-child {
        padding-left: 15px;
        margin-top: 15px;
    }

    .collection-banner .collection-p3>div:first-child {
        padding-right: 15px;
    }

    .collection-banner .collection-p3>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .collection-banner .collection-p3>div:nth-child(n+2)>div>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .collection-banner .collection-p3>div:last-child>div>div:first-child {
        padding-right: 15px;
    }

    .collection-banner .collection-p4>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .collection-banner .collection-p4>div:nth-child(2) {
        padding-left: 15px;
        padding-right: 15px;
    }

    .collection-banner .collection-p5>div>div>div:nth-child(n+2) {
        padding-left: 15px;
        margin-top: 15px;
    }

    .collection-banner .collection-p6>div:nth-child(n+2) {
        padding-left: 15px;
        padding-top: 15px;
    }

    .collection-banner .pl-md-0 {
        padding-right: 15px;
    }

    .layout-3-collection>div:first-child {
        margin-bottom: 15px;
        padding-right: 15px;
    }

    .layout-3-hotdeal>div:first-child {
        padding-right: 15px;
    }

    .collection-layout1>div {
        max-width: 100% !important;
    }

    .offer-banner {
        display: none;
    }

    .offer-banner-img img {
        height: 300px;
    }

    .counter-block>div:nth-child(n+3) {
        margin-top: 20px;
    }

    .custom-container {
        padding-right: 15px;
        padding-left: 15px;
    }

    .section-big-pt-space {
        padding-top: 15px;
    }

    .section-big-pb-space {
        padding-bottom: 15px;
    }

    .section-big-py-space {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .section-big-mt-space {
        margin-top: 30px;
    }

    .section-big-mb-space {
        margin-bottom: 30px;
    }

    .section-big-my-space {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .small-section {
        padding: 20px 0;
    }

    .theme-tab .tab-title.media-tab li {
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .theme-tab .tab-title.media-tab li.current:before {
        height: 3px;
    }

    .instagram .insta-contant .insta-sub-contant .insta-title {
        padding: 13px 20px;
    }

    .instagram .insta-contant1 .insta-sub-contant1 .insta-title {
        padding: 10px 18px;
    }

    .key-block>div:nth-child(n+5) {
        margin-top: 15px;
    }

    .services .service-block>div {
        padding-top: 0;
    }

    .services .service-block>div:nth-child(1) {
        padding: 23px 0;
    }

    .services .service-block>div:nth-child(2) {
        padding-bottom: 23px;
    }

    .services .service-block>div:last-child .media {
        margin-left: 0;
    }

    .services .service-block .media {
        display: block;
        text-align: center;
    }

    .services1 .services-box .media .media-body .btn {
        margin-top: 7px;
    }

    .services1.style2 .services-box .media .icon-wrraper {
        margin-bottom: 15px;
    }

    .discount-banner {
        padding-top: 35px;
        padding-bottom: 35px;
    }

    .discount-banner .discount-banner-contain h2 {
        margin-bottom: 10px;
    }

    .discount-banner .discount-banner-contain h1 {
        line-height: 1.4;
        margin-bottom: 8px;
    }

    .discount-banner .discount-banner-contain .rounded-contain .rounded-subcontain {
        line-height: 1.4;
        padding: 12px 0;
    }

    .collection-banner.layout-3 .collection3>div:first-child {
        padding-right: 15px;
    }

    .collection-banner .collection>div:nth-child(2) {
        padding: 0;
    }

    .collection-banner .collection2>div:first-child,
    .collection-banner .collection2>div:nth-child(2) {
        margin-bottom: 15px;
    }

    .collection-banner .collection3>div:first-child {
        margin-bottom: 15px;
    }

    .collection-banner .collection5>div {
        margin-top: 0;
    }

    .collection-banner .collection5>div:last-child {
        margin-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain {
        padding: 15px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain h3 {
        font-size: 25px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain h4 {
        font-size: 25px;
    }

    .collection-banner .collection-banner-main.banner-1 .collection-banner-contain h3 {
        font-size: 22px;
    }

    .collection-banner .collection-banner-main.banner-1 .collection-banner-contain h4 {
        font-size: 40px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain {
        padding: 28px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain h3 {
        font-size: 22px;
        margin-bottom: 5px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain h4 {
        font-size: 22px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain .shop {
        margin-top: 10px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain .shop a {
        font-size: 14px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-banner-contain h3 {
        font-size: 32px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-banner-contain h4 {
        margin-top: 15px;
        font-size: 15px;
        margin-bottom: 90px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-banner-contain h3 {
        font-size: 24px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-banner-contain h4 {
        font-size: 30px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-img {
        height: 265px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-banner-contain h3 {
        font-size: 14px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-banner-contain h4 {
        font-size: 18px;
        margin-bottom: 10px;
    }

    .banner-3-padding>div:nth-child(2) {
        padding-right: 15px !important;
    }

    .banner-4-padding>div {
        padding-left: 15px !important;
    }

    .collection2>div:first-child,
    .collection2>div:nth-child(2) {
        padding-right: 15px;
        padding-left: 15px;
    }

    .contact-banner {
        padding: 20px 0 12px;
    }

    .contact-banner .contact-banner-contain {
        display: unset;
        text-align: center;
    }

    .contact-banner .contact-banner-contain .contact-banner-img {
        margin-bottom: 15px;
    }

    .contact-banner .contact-banner-contain .contact-banner-img img {
        margin-right: 0;
    }

    .contact-banner .contact-banner-contain h3 {
        margin-bottom: 10px;
        margin-right: 0;
        font-size: 16px;
    }

    .subscribe-block1>div:nth-child(n+2) {
        padding-left: 15px;
        margin-top: 15px;
    }

    .contact-banner2 .subscribe-box {
        height: auto;
    }

    .contact-banner2 .subscribe-box h2 {
        margin-bottom: 8px;
    }

    .contact-banner2 .contact-bannerbox {
        height: auto;
    }

    .product .product-box .product-imgbox .new-label {
        padding: 5px;
        width: 80%;
        height: 80%;
    }

    .product .product-box .product-imgbox .new-label:before {
        border-top-width: 50px;
        border-right-width: 50px;
    }

    .product .product-box .product-imgbox .new-label1 {
        width: 40px;
        height: 40px;
    }

    .product .product-box .product-imgbox .new-label3 {
        padding: 5px;
        /*text-transform: capitalize;*/
    }

    .product .product-box .product-imgbox .product-icon button {
        font-size: 12px;
    }

    .product .product-box .product-imgbox .product-icon a i {
        padding: 8px;
        font-size: 12px;
    }

    .product .product-box .product-detail .icon-detail button {
        margin: 0 3px;
    }

    .product .product-box .product-detail .icon-detail button i {
        font-size: 12px;
    }

    .product .product-box .product-detail .icon-detail a {
        margin: 0 3px;
    }

    .product .product-box .product-detail .icon-detail a i {
        padding: 8px;
        font-size: 12px;
    }

    .product .product-box .product-detail.detail-center1 {
        padding-bottom: 5px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .hotdeal-right-slick .img-wrraper {
        background-color: #ffffff;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .hotdeal-right-slick .img-wrraper>div {
        width: 50% !important;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .hot-deal.hotdeal-two .hot-deal-contain {
        padding: 20px;
    }

    .hot-deal.hotdeal-two .hot-deal-contain .rating {
        margin-bottom: 10px;
    }

    .hot-deal.hotdeal-third .hot-deal-contain .hotdeal-right-slick .img-wrraper {
        background-color: #ffffff;
    }

    .hot-deal.hotdeal-third .hot-deal-contain .hotdeal-right-slick .img-wrraper>div {
        width: 50%;
        height: 330px;
        margin-left: auto;
        margin-right: auto;
    }

    .hot-deal .hot-deal-contain .hotdeal-right-nav img {
        width: 115px;
    }

    .hot-deal .hot-deal-contain .hot-deal-subcontain .deal-order-3 {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        margin-top: 8px;
    }

    .hot-deal .hot-deal-contain .hot-deal-subcontain>div:last-child {
        margin-top: 18px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center {
        margin-left: 0;
        text-align: center;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer {
        margin-top: 15px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer span {
        padding: 15px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center h6 {
        margin: 10px 0;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .btn {
        margin-top: 17px;
    }

    .hot-deal .hot-deal-contain1 .hot-deal-subcontain>div:nth-child(2),
    .hot-deal .hot-deal-contain1 .hot-deal-subcontain>div:nth-child(3) {
        margin-top: 15px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain,
    .hot-deal.hotdeal-third .hot-deal-contain {
        text-align: center;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .hotdeal-right-nav img,
    .hot-deal.hotdeal-third .hot-deal-contain .hotdeal-right-nav img {
        margin-right: auto;
        margin-left: auto;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .btn,
    .hot-deal.hotdeal-third .hot-deal-contain .btn {
        margin-left: auto;
        margin-right: auto;
    }

    .Jewellery-banner a {
        font-size: 14px;
    }

    .Jewellery-banner h6 {
        font-size: 30px;
    }

    .footer-title {
        border-bottom: 1px solid #bfbfbf;
        padding-bottom: 15px;
        margin-bottom: 15px;
        position: relative;
    }

    .footer-title.active .according-menu:before {
        content: "\f106";
        position: absolute;
        right: 15px;
        top: 5px;
        color: #444444;
        font-size: 20px;
    }

    .footer-title .according-menu {
        font-family: FontAwesome;
    }

    .footer-title .according-menu:before {
        content: "\f107";
        position: absolute;
        right: 15px;
        top: 9px;
        color: #444444;
        font-size: 20px;
        line-height: 0;
    }

    .footer-title.mobile-title {
        display: block;
    }

    .footer-contant {
        padding-bottom: 15px;
    }

    .footer-contant .footer-logo {
        margin-bottom: 10px;
    }

    .footer-contant .footer-logo img {
        height: 33px;
    }

    .footer-contant ul li {
        padding-bottom: 8px;
    }

    .footer-contant ul.sosiyal {
        margin-top: 10px;
    }

    .footer-contant P {
        margin-bottom: 10px;
    }

    .footer1 .footer-main {
        display: block;
    }

    .footer1 .footer-main>div {
        max-width: 100%;
        width: 100%;
    }

    .footer1 .footer-main>div:nth-child(n+3) {
        margin-top: unset;
    }

    .footer1 .footer-main .footer-box:last-child .footer-title {
        margin-bottom: 0;
    }

    .footer1 .footer-main .footer-box:last-child .footer-contant {
        padding-bottom: 0;
        padding-top: 15px;
    }

    .subfooter {
        text-align: center;
    }

    .subfooter .footer-left,
    .subfooter .footer-right {
        text-align: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        height: auto;
    }

    .subfooter .footer-right {
        margin-top: 5px;
    }

    .theme-slider .offset-xl-2 {
        max-width: 100%;
    }

    .theme-slider .slider-banner .slider-img {
        height: 300px;
    }

    .theme-slider .slider-banner .slider-banner-contain {
        padding: 0 40px;
    }

    .theme-slider .slider-banner .slider-banner-contain h4 {
        font-size: 14px;
        padding-bottom: 5px;
    }

    .theme-slider .slider-banner .slider-banner-contain h1 {
        font-size: 30px;
        padding-bottom: 4px;
    }

    .theme-slider .slider-banner .slider-banner-contain h2 {
        font-size: 16px;
        padding-bottom: 7px;
    }

    .theme-slider .slider-banner .slider-banner-contain .btn-rounded {
        padding: 8px 22px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-img {
        height: 270px;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-img {
        height: 300px;
    }

    .theme-slider .slider-banner.slide-banner-5 .layout6-slide-1 li {
        width: 300px;
        right: 0px;
    }

    .megastore-slide .mega-slide-block>div:first-child>div>div:last-child {
        padding-left: 15px;
    }

    .top-header .top-header-left .shpping-order {
        display: none;
    }

    .top-header2 .top-header-left {
        text-align: center;
    }

    .top-header2 .top-header-right {
        text-align: center;
    }

    .layout-header1 .main-menu-block .menu-right .icon-nav ul .mobile-user a i {
        padding-right: 15px;
    }

    .category-header .navbar-menu .category-left {
        width: 80%;
    }

    .category-header .navbar-menu .category-right .gift-block .grif-icon {
        display: block;
    }

    .category-header .navbar-menu .category-right .gift-block .gift-offer {
        display: none;
    }

    .category-header-2 .navbar-menu .category-left .nav-block {
        min-width: 200px;
        margin-left: 30px;
    }

    .category-header-2 .navbar-menu .category-left .nav-block .nav-left {
        width: 200px;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul li a i {
        font-size: 30px;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-user a i {
        padding-right: 15px;
    }

    .category-header-2 .navbar-menu .category-left .menu-block {
        margin-left: 20px;
    }

    .category-header-2 .navbar-menu .category-right .contact-block {
        display: none;
    }

    .category-header-2 .navbar-menu .category-right .gift-block {
        padding: 10px 28px;
    }

    .category-header-2 .navbar-menu .category-right .gift-block .grif-icon i {
        font-size: 28px;
    }

    .layout-header2 .main-menu-block .input-block .input-box .big-deal-form .input-group span {
        display: none;
    }

    .layout-header2 .main-menu-block .input-block .input-box .big-deal-form .input-group select {
        width: 115px;
        background-image: none;
    }

    .newsletter {
        display: block;
        padding: 30px 0;
    }

    .newsletter .news-leble {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-right: 0;
    }

    .newsletter .news-leble img {
        height: 40px;
    }

    .newsletter .subscribe-block {
        margin-top: 25px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .newsletter .subscribe-block .input-group {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
    }

    .blog-detail-page .comment-section li {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .product-pagination .pagination {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-left: 1px solid #dddddd;
    }

    .about-page {
        text-align: center;
    }

    .about-page p {
        line-height: 1.5;
    }

    .team h2 {
        font-size: 25px;
    }

    .product-wrapper-grid.list-view .product-box {
        display: block;
        border: 1px solid #dddddd;
        padding: 15px;
        margin-top: 30px;
    }

    .product-wrapper-grid.list-view .product-box .product-imgbox {
        margin: 0 auto 15px;
        width: 50% !important;
    }

    .product-wrapper-grid.list-view .product-box .product-detail {
        padding-left: 0;
    }

    .collection .partition-collection>div:nth-last-child(1),
    .collection .partition-collection>div:nth-last-child(2),
    .collection .partition-collection>div:nth-last-child(3) {
        margin-top: 30px;
    }

    .collection .partition-collection>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .collection .collection-block img,
    .collection .collection-block .bg-size {
        margin-bottom: 10px;
    }

    .collection-product-wrapper .product-pagination .pagination .page-item a {
        padding: 15px 18px;
    }

    .collection-product-wrapper .product-pagination .product-search-count-bottom {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-right: 0;
    }

    .collection-product-wrapper .product-pagination .product-search-count-bottom h5 {
        padding: 10px 0;
    }

    .collection-product-wrapper .product-pagination .theme-paggination-block nav {
        border-right: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
    }

    .order-history .responsive-data {
        display: block;
    }

    .cart-section tbody tr td a,
    .wishlist-section tbody tr td a {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .cart-section .cart-table thead th:nth-last-child(-n+4) {
        display: none;
    }

    .cart-section tbody tr td:nth-last-child(-n+4) {
        display: none;
    }

    .cart-section tbody tr td .mobile-cart-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .wishlist-section .cart-table thead th:nth-last-child(-n+3) {
        display: none;
    }

    .wishlist-section tbody tr td:nth-last-child(-n+3) {
        display: none;
    }

    .wishlist-section tbody tr td .mobile-cart-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .search-product>div:nth-child(n+3) {
        margin-top: 15px;
    }

    .cart-section .wishlist-buttons,
    .wishlist-section .wishlist-buttons {
        padding-top: 25px;
    }

    .tab-pane iframe {
        width: 100%;
    }

    .product-order .product-order-detail img {
        height: auto;
    }

    .typography_section .typography-box .typo-content.product-pagination .pagination {
        border-left: none;
    }

    .typography_section .typography-box .typo-content.typo-buttons a:last-child {
        margin-top: 5px;
    }

    .related-pro .product-box {
        padding-right: 0;
    }

    .product-box3 .media {
        display: block;
    }

    .product-box3 .media .img-wrapper img {
        width: 100%;
    }

    .product-box3 .product-detail {
        padding-left: unset;
        padding-top: 15px;
    }

    .product-box3 .product-detail .rating {
        margin-bottom: 10px;
    }

    .product-block4>div {
        padding-left: 10px;
        padding-right: 10px;
    }

    .product-block4>div:nth-child(n+3) {
        margin-top: 15px;
    }

    .collection-filter>div:first-child {
        padding-top: 15px !important;
    }

    .collection-filter>div:last-child {
        padding-bottom: 15px !important;
    }

    .collection-filter .creative-card.creative-inner {
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .center-product .theme-card {
        margin-right: 0;
    }

    .review-block>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .masonory-banner .masonary-banner-main .masonory-banner-img {
        height: 250px;
    }

    .masonory-banner .masonary-banner-main .masonory-banner-img.masonory-img1 {
        height: 515px;
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant {
        background-color: rgba(0, 0, 0, 0.3);
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant h5 {
        letter-spacing: 0.05em;
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant h2 {
        margin-bottom: 10px;
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant .btn-rounded {
        margin-top: 10px;
    }

    .theme-modal.cart-modal .product-section {
        display: none;
    }

    .blog-detail-page .blog-detail {
        text-align: center;
    }

    .blog-detail-page .blog-detail h3 {
        text-align: center;
    }

    .blog-detail-page .blog-detail .post-social {
        text-align: center;
    }

    .blog-detail-page .blog-advance p {
        text-align: center;
    }

    .blog-page .order-sec {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .blog-page .blog-media {
        border-width: 20px;
        padding: 20px 0;
        text-align: center;
    }

    .blog-page .blog-media:last-child {
        margin-bottom: 30px;
    }

    .blog-page .blog-media.media-change {
        text-align: center;
    }

    .blog-page .blog-sidebar .theme-card {
        border-width: 20px;
        padding: 20px 15px;
    }

    .blog-page .blog-sidebar ul li {
        display: block;
    }

    .checkout-page .checkout-form .checkout-details {
        margin-top: 15px;
    }

    .title6 h4 {
        padding: 0 10px;
    }

    .title7 .line:before {
        width: 10px;
        height: 10px;
        top: -4px;
    }

    .title8 {
        margin-bottom: 15px;
    }

    .product-order .final-total h3 {
        margin-bottom: 10px;
    }

    .compare-page .table-wrapper .table tbody tr .product_price {
        margin: 10px 0;
    }

    .product-side-tab {
        padding-top: 0 !important;
        margin-top: 0 !important;
    }

    .product-right.product-form-box {
        margin-bottom: 0;
    }

    .tab-product.tab-exes {
        margin-top: 30px !important;
    }

    .bottom-cart-sticky .selection-section .form-group:nth-child(2) {
        margin-left: 15px;
    }

    .bottom-cart-sticky .selection-section .form-group .form-control {
        font-size: 14px;
    }

    .portfolio-section .portfolio-2 {
        margin-bottom: -40px;
    }

    .portfolio-section .portfolio-3 {
        margin-bottom: -20px;
    }

    .dashboard-right .dashboard {
        padding: 20px;
    }

    .dashboard-left {
        padding: 20px;
    }

    .contact-page .map .theme-card {
        border-width: 15px;
        padding: 0;
    }

    .login-page .theme-card {
        padding: 15px;
    }

    .lookbook-part>div:first-child {
        margin-bottom: 30px;
    }

    #fb-root {
        display: none;
    }

    .order-tracking #progressbar {
        margin-bottom: 15px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li {
        display: block;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li:nth-child(n+2) {
        margin-top: 15px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li>div:first-child {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-bottom: 10px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li>div:nth-child(n+2) {
        margin-left: unset;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li:nth-child(n+2) {
        margin-left: 10px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li img {
        width: 30px;
    }

    .order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox .reloadbtncapcha svg {
        width: 14px;
    }

    .order-tracking .order-tracking-box .paymant-collapce .cash-pay .btn {
        margin-top: 10px;
    }
}

@media (max-width: 600px) {
    .collection-layout1>div:first-child {
        margin-bottom: 15px;
    }

    .collection-layout1>div:nth-child(2) {
        margin-bottom: 0;
    }

    .collection-layout1>div:nth-child(3) {
        padding-bottom: 0;
    }

    .layout-4-collection>div {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .layout-4-collection>div:nth-child(2) {
        padding-left: 15px;
        padding-top: 15px;
    }

    .layout-4-collection>div:first-child {
        padding-right: 15px;
    }

    .theme-slider .offset-xl-2 {
        max-width: 100%;
    }

    .theme-slider .slider-banner .layout2-slide-1 li {
        width: 400px;
    }

    .theme-slider .slider-banner .layout2-slide-2 li {
        width: 250px;
        bottom: 10px;
    }

    .theme-slider .slider-banner .layout2-slide-3 li {
        bottom: 10px;
        width: 250px;
    }

    .offer-banner {
        display: none;
    }

    .collection-banner .offset-xl-2 {
        max-width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }

    .collection-banner .collection>div:nth-child(2),
    .collection-banner .pl-md-0 {
        padding-left: 15px;
        padding-bottom: 15px;
        max-width: 100%;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }

    .collection-banner .collection>div:nth-child(2) {
        padding-right: 15px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .hotdeal-right-slick .img-wrraper div {
        height: 250px;
    }

    .hot-deal.hotdeal-third .hot-deal-contain .hotdeal-right-slick .img-wrraper div {
        height: 250px;
    }
}

@media (max-width: 577px) {

    .btn-rounded,
    .btn-white,
    .btn-normal,
    .btn-solid {
        padding: 13px 15px;
    }

    .instagram .insta-contant .insta-sub-contant .insta-title {
        padding: 9px 13px;
    }

    .instagram .insta-contant2 .insta-sub-contant2 .insta-title {
        padding: 10px 15px;
    }

    .collection-banner .collection-banner-main .collection-img {
        height: 190px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-img {
        height: 195px;
    }

    .product .product-box .product-detail .product-title .price {
        font-size: 14px;
    }

    .product .product-box .product-imgbox .new-label {
        padding: 3px;
        width: 100%;
        height: 100%;
    }

    .theme-slider .slider-banner .slider-img {
        height: 250px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-img {
        height: 200px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h1 {
        font-size: 20px;
    }

    .theme-slider .slider-banner.slide-banner-1 .slider-banner-contain h2 {
        font-size: 14px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-img {
        height: 260px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain {
        padding: 20px;
        min-width: 220px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain span {
        font-size: 14px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain {
        padding: 0 30px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h5 {
        font-size: 14px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h3 {
        font-size: 20px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h1 {
        font-size: 28px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h2 {
        font-size: 14px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain .btn-normal {
        padding: 7px 12px;
        font-size: 12px;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-img {
        height: 300px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain {
        padding: 0 30px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h5 {
        font-size: 14px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h3 {
        font-size: 20px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h1 {
        font-size: 28px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h2 {
        font-size: 14px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain .btn-normal {
        padding: 7px 12px;
        font-size: 12px;
    }

    .category-header {
        height: 0;
    }

    .category-header .navbar-menu {
        padding: 0;
    }

    .category-header .navbar-menu .category-left {
        display: none;
    }

    .category-header .navbar-menu .category-right .gift-block {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
        margin-left: 0;
        display: none;
    }

    .category-header .navbar-menu .category-right .contact-block i {
        display: none;
    }

    .category-header .navbar-menu .category-right .contact-block span {
        font-size: 0;
    }

    .masonory-banner .masonary-banner-main .masonory-banner-img {
        height: 207px;
    }

    .masonory-banner .masonary-banner-main .masonory-banner-img.masonory-img1 {
        height: 430px;
    }

    /*=====================
     Inner pages CSS
==========================*/
    .template-password #container #login {
        margin-bottom: 0;
    }

    .error-section {
        padding: 100px 0;
    }

    .error-section h1 {
        font-size: 100px;
    }

    .error-section h2 {
        margin: 20px 0;
        font-size: 18px;
    }

    .about-page .about-head {
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    .about-page .about-head .theme-breadcrumb .breadcrumb {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 0;
    }

    .blog-detail-page .blog-detail .post-social li {
        display: block;
    }

    .blog-detail-page .blog-detail .post-social li+li {
        padding-left: 0;
        margin-left: 0;
        border-left: none;
    }

    .collection .collection-block .collection-content p {
        margin-bottom: 10px;
    }

    .collection .collection-block .collection-content h4,
    .collection .collection-block .collection-content h3 {
        margin-bottom: 5px;
    }

    .collection-product-wrapper .product-wrapper-grid .product-box {
        margin-top: 30px;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .search-count {
        padding: 10px 20px 10px 20px;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5 {
        font-size: 14px;
        line-height: 20px;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
        width: 100%;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
        padding: 10px 20px 10px 20px;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view:before,
    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter:before {
        top: 11px;
    }

    .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view {
        border-right: 1px solid #dddddd !important;
        border-bottom: 1px solid #dddddd;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .search-count {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view {
        border-bottom: 1px solid #dddddd;
        width: 100%;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
        border-right: none;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view:before {
        top: 14px;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
        width: 100%;
        border-top: none;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter select {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter:before {
        left: unset;
        right: 35px !important;
        top: 14px;
    }

    .video-product video {
        height: 280px;
    }

    .cart-section .cart-buttons>div:last-child {
        padding-right: 15px;
    }

    .cart-section .cart-buttons .btn-solid {
        padding: 7px 8px;
    }

    .wishlist-section .btn-solid {
        padding: 7px 8px;
    }

    .collection-wrapper .order-up {
        -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
        order: -1;
    }

    .collection-wrapper .tab-product {
        margin-top: 15px;
    }

    .checkout-page .checkout-form .checkout-details {
        padding: 15px;
    }

    .product-box .img-wrapper .label-block .label3 {
        font-size: 12px;
        padding: 13px 8px;
    }

    .product-box .img-block .label-wrapper .label1,
    .product-box .img-block .label-wrapper .label2 {
        padding: 6px 9px 8px 15px;
        font-size: 12px;
    }

    .tab-product .tab-content.nav-material p,
    .product-full-tab .tab-content.nav-material p {
        padding: 15px 0;
    }

    .tab-product .tab-content.nav-material p {
        line-height: 1.3;
    }

    .slider-right-nav .slick-slide:first-child>div {
        margin-top: 15px;
    }

    .lookbook .lookbook-block .lookbook-dot .dot-showbox {
        width: 90px;
        top: -70px;
    }

    .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
        line-height: 16px;
    }

    .padding-cls {
        padding-left: 15px;
        padding-right: 15px;
    }

    .success-text i {
        font-size: 40px;
    }

    .success-text p {
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 1.2;
    }

    .success-text h2 {
        margin-bottom: 10px;
    }

    .order-success-sec>div {
        margin-top: 10px;
    }

    .delivery-sec {
        padding: 15px;
        margin-top: 0;
    }

    .product-order h3 {
        font-size: 20px;
    }

    .product-order .product-order-detail .order_detail h4,
    .product-order .product-order-detail .order_detail h5 {
        font-size: 14px;
        line-height: 1.2;
    }

    .product-order .total-sec ul li {
        font-size: 16px;
    }

    .product-order .final-total h3 {
        font-size: 16px;
    }

    .beauty-about .service .service-block1 {
        margin-bottom: 20px;
    }

    .beauty-about .service .service-block1:last-child {
        margin-bottom: 0;
    }

    .main-menu .menu-left .navbar i {
        font-size: 24px;
    }

    .typography_section .typography-box .typo-content.typo-buttons a:last-child {
        margin-top: 0;
    }

    .typography_section .typography-box .typo-content.typo-buttons .btn-solid.btn-sm {
        padding: 3px 12px;
    }

    .bundle .bundle_img .img-box img {
        max-width: 70px;
    }

    .bundle .bundle_detail .theme_checkbox {
        padding: 0;
    }

    .tools-parallax-product.full-banner {
        padding-top: 45px;
        padding-bottom: 45px;
    }
}

@media (max-width: 576px) {
    .sticky .header7 .sm-horizontal .mobile-back {
        padding: 15px;
    }

    .sticky.header-style2 .category-header7 {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .hot-deal .hot-deal-contain {
        padding: 25px;
    }

    .hot-deal .hot-deal-contain .hotdeal-right-nav img {
        width: 90px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer span {
        font-size: 14px;
    }

    .media-banner .media-banner-box .media {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .media-banner .media-banner-box .media img {
        margin: 0 auto;
        width: 80px;
    }

    .media-banner .media-banner-box .media .media-body .media-contant {
        height: auto;
    }

    .media-banner .media-banner-box .media .media-body .media-contant p {
        font-size: 13px;
    }

    .media-banner .media-banner-box .media .media-body .media-contant h6 {
        font-size: 16px;
    }

    .media-banner:nth-last-child(2) {
        margin-bottom: 0;
    }

    .newsletter .news-leble .news-text {
        margin-left: 10px;
    }

    .newsletter .subscribe-block {
        display: inherit;
        text-align: center;
    }

    .newsletter .subscribe-block .input-group input {
        width: auto;
    }

    .newsletter .subscribe-block .btn-normal {
        margin-top: 20px;
        padding: 13px 20px;
    }

    .no-slider .product-box {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: calc(100% - 30px);
        margin: 0 15px 30px !important;
    }

    .no-slider .product-box:nth-last-child(1) {
        margin: 0 15px 0 !important;
    }

    .no-slider .product-box:nth-last-child(2) {
        margin: 0 15px 30px !important;
    }

    .no-slider.five-product .product-box {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: calc(100% - 30px);
        margin: 0 15px 30px !important;
    }

    .no-slider.five-product .product-box:nth-last-child(1) {
        margin: 0 15px 0 !important;
    }

    .no-slider.five-product .product-box:nth-last-child(2) {
        margin: 0 15px 30px !important;
    }

    .collection-product-wrapper .product-wrapper-grid .product-box .product-detail {
        padding-top: 10px;
    }

    .collection-product-wrapper .product-wrapper-grid .product-box .product-detail .detail-title .detail-left .price-title {
        padding-top: 0;
    }

    .metro-block .product-box .product-imgbox .product-detail {
        bottom: 15px;
        background-color: rgba(255, 255, 255, 0.8);
        padding: 10px;
    }

    .product-wrapper-grid.list-view .product-box {
        display: block;
    }

    .product-wrapper-grid.list-view .product-box .product-imgbox {
        margin: 0 auto;
    }

    .tap-top {
        width: 35px;
        height: 35px;
        padding: 2px;
        font-size: 19px;
    }
}

@media (max-width: 575px) {
    .discount-banner {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .product-notification img {
        height: 60px;
    }

    .product-notification {
        width: 230px;
        padding: 10px;
        margin: 5px;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-1 li {
        left: -170px;
    }

    .theme-slider .slider-banner.slide-banner-1 .layout1-slide-1 li:nth-child(2) {
        right: -160px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li {
        width: 200px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li:last-child {
        width: 200px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li {
        width: 180px;
        left: 10px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li:last-child {
        width: 180px;
        right: 10px;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-banner-contain {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-1 li {
        width: 80%;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-2 li {
        width: 80%;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-3 li {
        width: 90%;
    }

    .cosmetic-slide .slide-main .slide-contain h3 {
        margin-bottom: 8px;
    }

    .cosmetic-slide .slide-main .slide-contain h2 {
        margin-bottom: 5px;
    }

    .cosmetic-slide .slide-main .slide-contain h4 {
        margin-bottom: 10px;
    }

    .cosmetic-slide .slide-main .animat-block {
        display: none;
    }

    .furniture-slide .slide-main .slide-contain p {
        width: auto;
    }

    .furniture-slide .animat-block .animat1 {
        width: 410px;
    }

    .furniture-slide .animat-block .animat2 {
        width: 105px;
    }

    .furniture-slide .animat-block .animat3 {
        width: 35px;
    }

    .furniture-slide .animat-block .animat4 {
        width: 32px;
    }

    .farming-slide .slide-main .animat-block .animat1 {
        width: 80%;
    }

    .farming-slide .slide-main .animat-block .animat2 {
        height: 70px;
    }

    .farming-slide .slide-main .animat-block .animat3 {
        height: 110px;
    }

    .kids-slide .slide-main {
        background-position: 80% !important;
    }

    .kids-slide .slide-main .slide-contain p {
        width: auto;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain {
        width: 100%;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group {
        width: 300px;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group .form-control {
        height: 48px;
        padding-left: 18px;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group .btn {
        padding: 10px 17px;
    }

    .tools-slide .slide-main .slide-contain h3 {
        margin-bottom: 5px;
    }

    .tools-slide .slide-main .slide-contain p {
        width: auto;
    }

    .pets-slide .slide-main .slide-contain h3 {
        margin-bottom: 10;
    }

    .pets-slide .slide-main .slide-contain h2 {
        margin-bottom: 10px;
    }

    .pets-slide .slide-main .slide-contain .form-control {
        margin-bottom: 15px;
    }

    .pets-slide .slide-main .slide-contain .form-control {
        width: 210px;
        height: 42px;
    }

    .pets-slide .slide-main .animat-block .animat1 {
        width: 200px;
    }

    .pets-slide .slide-main .animat-block .animat2 {
        width: 200px;
    }

    .grocery-slide .slide-main .slide-contain {
        height: 48vh;
    }

    .megastore-slide .slide-main {
        background-position: 82% !important;
    }

    .megastore-slide .slide-main .slide-contain {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .megastore-slide .slide-main .slide-contain h4 {
        color: #ffffff;
    }

    .megastore-slide .slide-main .slide-contain h3 {
        color: #ffffff;
    }

    .mobile-fix-option {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 45px;
        z-index: 9;
        background-color: #000000;
    }

    .icon-block .mobile-search,
    .icon-block .mobile-wishlist,
    .icon-block .mobile-cart,
    .icon-block .mobile-setting,
    .icon-block .mobile-user {
        position: fixed !important;
        bottom: 14px;
        right: 16%;
        padding: 0;
        z-index: 9;
        color: #ffffff !important;
        text-align: center;
    }

    .icon-block .mobile-search svg,
    .icon-block .mobile-wishlist svg,
    .icon-block .mobile-cart svg,
    .icon-block .mobile-setting svg,
    .icon-block .mobile-user svg {
        fill: #ffffff !important;
        width: 16px !important;
    }

    .icon-block .mobile-search p,
    .icon-block .mobile-wishlist p,
    .icon-block .mobile-cart p,
    .icon-block .mobile-setting p,
    .icon-block .mobile-user p {
        display: block !important;
        font-size: 8px !important;
        /*text-transform: capitalize;*/
        font-weight: 600;
        font-family: "PT Sans", sans-serif;
        margin-top: 3px !important;
    }

    .icon-block .mobile-search {
        right: 80%;
    }

    .icon-block .mobile-wishlist {
        right: 63%;
    }

    .icon-block .mobile-cart {
        right: 48%;
    }

    .icon-block .mobile-user {
        right: 32%;
    }

    .item-count .item-count-contain {
        width: 12px;
        height: 12px;
    }

    .item-count .item-count-contain.item-md {
        width: 12px;
        height: 12px;
    }

    .searchbar-input .input-group .twitter-typeahead {
        width: calc(100% - 84px);
    }

    .searchbar-input .input-group .input-group-text svg {
        width: 16px;
    }

    .layout-header2 .main-menu-block .sm-nav-block {
        margin-right: unset;
    }

    .layout-header2 .main-menu-block .brand-logo img {
        height: 30px;
    }

    .layout-header2 .main-menu-block .icon-block ul li svg {
        fill: #ffffff;
        width: 16px;
    }

    .layout-header2 .main-menu-block .icon-block ul li .item-count-contain {
        top: -7px;
        left: 8px;
        width: 12px;
        height: 12px;
    }

    .layout-header2 .main-menu-block .icon-block ul li .cart-block .cart-item {
        display: none;
    }

    .layout-header2 .main-menu-block .menu-nav {
        margin-left: unset;
    }

    .layout-header1 .main-menu-block .menu-right .icon-nav ul li .item-count-contain {
        left: 11px;
    }

    .layout-header1 .main-menu-block .menu-right .icon-nav ul li.cart-block .item-count-contain {
        top: -7px;
        left: 10px;
    }

    .layout-header1 .main-menu-block .menu-right .toggle-nav {
        margin-left: unset;
    }

    .layout-header1 .main-menu-block .menu-left .brand-logo img {
        height: 30px;
    }

    .layout-header1 .main-menu-block .menu-left .sm-nav-block {
        margin-right: unset;
    }

    .layout-header3 .main-menu .icon-block ul li.mobile-wishlist .cart-item {
        display: none;
    }

    .layout-header3 .main-menu .icon-block ul li.mobile-wishlist .item-count-contain {
        top: -4px;
        left: 8px;
    }

    .layout-header3 .main-menu .icon-block ul li.mobile-cart .item-count-contain {
        top: -7px;
        left: 6px;
    }

    .layout-header3 .main-menu .icon-block ul li.mobile-setting {
        display: inline-block;
    }

    .layout-header3 .main-menu .menu-left {
        position: unset;
    }

    .layout-header3 .main-menu .menu-left .sm-nav-block {
        margin-right: unset;
    }

    .layout-header3 .main-menu .menu-left .logo-block img {
        height: 30px;
    }

    .layout-header3 .main-menu .icon-block .toggle-nav-desc {
        margin-left: unset;
    }

    .layout-header4 .icon-block {
        display: none;
    }

    .category-header-4 .navbar-menu .category-right .icon-block ul li.mobile-setting {
        display: inline-block;
    }

    .category-header-4 .navbar-menu .category-right .icon-block .toggle-nav {
        margin-left: unset;
    }

    .category-header-4 .navbar-menu .category-left .sm-nav-btn {
        margin-right: unset;
    }

    .category-header-4 .navbar-menu .category-left .logo-block img {
        height: 30px;
    }

    .category-header7 .category-contain .category-left .logo-block .brand-logo img {
        height: 30px;
    }

    .sticky.header-style2 .category-header7 .category-contain .category-left .header-category3 {
        display: block;
    }

    .sticky.header-style2 .category-header7 .category-contain .category-left .logo-block {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .sticky.header-style2 .category-header7 .category-contain .category-left .logo-block .mobilecat-toggle {
        display: block;
    }

    .logo-sm-center {
        position: absolute;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .subfooter {
        margin-bottom: 45px;
    }

    .top-header {
        height: auto;
    }

    .top-header .top-header-left {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .top-header .top-header-right {
        display: none;
    }

    .top-header2 {
        display: none;
    }

    .top-header2 .top-header-left .category-header {
        display: none;
    }

    .category-header-2 {
        background-color: transparent;
    }

    .category-header-2 .navbar-menu .category-left {
        width: 100%;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul li .item-count-contain {
        top: -2px;
        left: 10px;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul li i {
        font-size: 18px !important;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-setting,
    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-search,
    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-user,
    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-wishlist,
    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-cart {
        display: block;
        position: fixed;
        bottom: 14px;
        right: 16%;
        font-size: 22px;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-wishlist {
        right: 64%;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-wishlist .cart-item {
        display: none;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-wishlist i {
        margin-right: 0 !important;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-search {
        right: 80%;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-user {
        right: 32%;
        padding-right: 0;
    }

    .category-header-2 .navbar-menu .category-left .icon-block ul .mobile-user a i {
        padding-right: 0;
    }

    .category-header-2 .navbar-menu .category-left .menu-block {
        width: 50%;
        text-align: right;
    }

    .category-header-2 .navbar-menu .category-left .menu-block .pixelstrap .dark-menu-item:hover {
        color: #ef9f77;
    }

    .category-header-2 .navbar-menu .category-left .menu-block .toggle-nav {
        display: none;
    }

    .category-header-2 .navbar-menu .category-left .nav-block {
        width: 50%;
        margin-left: 0;
    }

    .category-header-2 .navbar-menu .category-left .nav-block .nav-left {
        display: none;
    }

    .category-header-2 .navbar-menu .category-right .gift-block {
        display: none;
    }

    .header7 .header-contain .logo-block .brand-logo img {
        height: 30px;
    }

    .header7 .header-contain .logo-block .logo-sm-center {
        position: absolute;
        left: 50%;
        margin-right: -50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    .header7 .header-contain .icon-block ul li .item-count-contain {
        top: -6px;
        right: -5px;
    }

    .header7 .header-contain .icon-block ul li.icon-desk-none {
        display: block;
    }

    .header7 .header-contain .icon-block ul.rounde-icon li {
        width: auto;
        height: auto;
        border: none;
        border: none;
    }

    .header7 .header-contain .icon-block ul.rounde-icon li svg {
        fill: #ffffff;
        width: 18px;
    }

    .header7 .header-contain .icon-block ul.theme-color li {
        width: auto;
        height: auto;
        background-color: transparent;
    }

    .header7 .header-contain .icon-block ul.theme-color li svg {
        width: 18px;
    }

    .header7 .header-contain .icon-block ul.theme-color.color-full li {
        background-color: transparent !important;
    }

    .header7 .header-contain .icon-block ul.theme-color.color-full li:nth-child(n+2) {
        margin-left: 0;
    }

    .icon-lable {
        width: 12px;
        height: 12px;
    }

    .app-download .app-download-main {
        background-position: 10% !important;
        position: relative;
    }

    .app-download .app-download-main:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .app-download .app-download-main .app-download-contian {
        z-index: 1;
        position: relative;
    }

    .app-download .app-download-main .app-download-contian h2 {
        color: #042c49;
        margin-bottom: 5px;
    }

    .app-download .app-download-main .app-download-contian h3 {
        color: #ef9f77;
        margin-bottom: 10px;
    }

    .app-download .app-download-main .app-download-contian .btn {
        padding: 10px 25px;
    }

    .video-banner .video-banner-contain {
        height: 245px;
    }

    .video-banner .video-banner-contain h3 {
        margin-top: 9px;
    }

    .video-banner .video-banner-contain h2 {
        margin-top: 10px;
    }

    .key-block .col-xs-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .sale-banenr {
        position: relative;
    }

    .sale-banenr:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
    }

    .sale-banenr .sale-banenr-contain {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .sale-banenr .sale-banenr-contain h4 {
        margin-bottom: 5px;
    }

    .sale-banenr .sale-banenr-contain h2 {
        margin-bottom: 10px;
    }

    .sale-banenr .sale-banenr-contain h3 {
        margin-bottom: 10px;
        color: #ffffff;
    }

    .sale-banenr.banner-style1 .sale-banenr-contain h2 {
        margin-bottom: 10px;
    }

    .sale-banenr.banner-style1 .sale-banenr-contain h3 {
        margin-bottom: 10px;
    }

    .sale-banenr.banner-style2 {
        background-position: -19% !important;
    }

    .sale-banenr.banner-style2 .sale-banenr-contain.p-right {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .sale-banenr.banner-style2 .sale-banenr-contain h4 {
        color: #ffffff;
    }

    .sale-banenr.banner-style2 .sale-banenr-contain h2 {
        color: #ffffff;
    }

    .sale-banenr.banner-style2 .sale-banenr-contain h3 {
        color: #ffffff;
    }

    .subscribe1 .subscribe-contain .subscribe-right .input-group .form-control {
        width: auto;
    }

    .subscribe2 .subscribe-contain .form-control {
        width: 100%;
    }

    .deal-banner .deal-banner-containe h2 {
        margin-bottom: 10px;
    }

    .deal-banner .deal-banner-containe h1 {
        margin-bottom: 15px;
    }

    .testimonial2 .testimonial-box .img-wrapper {
        margin-bottom: 13px;
    }

    .testimonial2 .testimonial-box .testimonial-detail p {
        margin-bottom: 9px;
    }

    .testimonial5 .testimonial-detail h4 {
        margin-bottom: 5px;
    }

    .testimonial5 .testimonial-detail p {
        margin-bottom: 5px;
    }

    .hot-1>div:first-child {
        padding-right: 15px;
        padding-left: 15px;
    }

    .hot-1>div:nth-child(2) {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 15px;
    }

    .hot-1>div:last-child {
        padding-right: 15px;
        padding-left: 15px;
    }

    .layout-3-hotdeal>div:first-child {
        padding-right: 15px;
    }

    .layout-3-hotdeal>div:nth-child(4) {
        padding-right: 15px;
        padding-bottom: 10px;
    }

    .hot-deal.hotdeal-two .hot-deal-contain .hotdeal-right-nav-1 img {
        width: 70px;
        margin-left: auto;
        margin-right: auto;
    }

    .hot-deal.hotdeal-two .hot-deal-contain .hot-deal-subcontain>div:nth-child(3) {
        margin-top: 10px;
    }

    .hot-deal.hotdeal-two .hot-deal-contain .btn {
        padding: 8px 15px;
    }

    .hot-deal.hotdeal-two .hot-deal-contain1 .hot-deal-subcontain {
        text-align: center;
    }

    .hot-deal.hotdeal-two .hot-deal-contain1 .hot-deal-subcontain .btn {
        margin-left: auto;
        margin-right: auto;
    }

    .hot-deal.hotdeal-two .hot-deal-contain1 .hot-deal-subcontain>div:nth-child(2) {
        -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
        order: 3;
        margin-top: 5px;
    }

    .hot-deal .hot-deal-contain1 .layout1-slide-2 li {
        left: -140px;
    }

    .hot-deal .hot-deal-contain1 .layout1-slide-2 li:nth-child(2) {
        right: -140px;
    }

    .hot-deal .hot-deal-contain1 .layout1-slide-3 li {
        left: -160px;
    }

    .hot-deal .hot-deal-contain1 .layout1-slide-3 li:nth-child(2) {
        right: -130px;
    }

    .hot-deal .hot-deal-contain1 .hotdeal-right-slick-1 {
        text-align: center;
    }

    .creative-card {
        border-width: 0;
        padding: 0;
    }

    .tab-product-main {
        padding: 20px 0 15px;
    }

    .tab-product-main .tab-prodcut-contain ul li {
        border: 1px solid;
        padding: 2px 12px;
    }

    .tab-product-main .tab-prodcut-contain ul li:nth-child(n+2) {
        margin-left: 5px;
        margin-top: 2px;
    }

    .tab-product-main .tab-prodcut-contain ul li.current {
        border-color: #042c49;
    }

    .tab-product-main .tab-prodcut-contain ul li.current:before {
        display: none;
    }

    .tab-product-main.tab-four {
        padding: 0;
    }

    .tab-product-main.tab-four .tab-prodcut-contain ul li {
        border: none;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .login-page .theme-card .theme-form {
        padding: 10px;
        background-color: transparent;
    }

    .breadcrumb-main {
        padding: 30px 0;
    }

    .masonary-banner-block>div {
        margin-bottom: 15px !important;
    }

    .masonary-banner-block.masonary-inner1>div:last-child {
        margin-bottom: 0 !important;
    }

    .masonary-banner-block2>div:first-child {
        margin-bottom: 15px;
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant h5 {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .masonory-banner .masonary-banner-main .masonary-banner-contant .masonary-banner-subcontant {
        margin-right: 0;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 100%;
    }

    .masonary-banner-block1>div:first-child {
        margin-bottom: 15px;
    }

    .masonary-banner-block1>div:last-child {
        padding-left: 0;
    }

    .masonary-banner-block1 .col-sm-6.pl-3 {
        padding-left: 0 !important;
    }

    .masonory-p1>div:first-child {
        padding-right: 15px;
    }

    .masonory-p1>div:last-child>div>div:nth-child(n+2)>div>div:last-child {
        margin-top: 15px;
    }

    .masonory-p1>div:last-child>div>div>div>div:nth-child(n+2) {
        padding-left: 15px;
    }

    .masonory-p1>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .counter-block>div:nth-child(n+3) {
        margin-top: 15px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-img {
        height: 175px;
    }

    .collection-banner .collection-banner-main.banner-15 .collection-img {
        height: 220px;
    }

    .theme-slider .slider-banner.slide-banner-5 .layout6-slide-1 li {
        width: 200px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain {
        background-color: rgba(255, 255, 255, 0.4);
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-1 li {
        width: 325px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-2 li {
        width: 230px;
    }

    .theme-slider .slider-banner.slide-banner-3 .layout4-slide-3 li {
        width: 250px;
    }

    .checkout-second .checkout-box .checkout-body .btn {
        padding: 10px 18px;
    }

    .multiple-slider>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .blog-page .blog-media .blog-left .date-label {
        padding: 5px 8px;
    }

    .blog-page .blog-media:hover .blog-left img {
        -webkit-transform: translateX(0) scale(1);
        transform: translateX(0) scale(1);
    }

    .blog-page .blog-sidebar .theme-card {
        border-width: 0;
        padding: 0;
        background-color: transparent;
    }

    .blog-detail-page .comment-section {
        padding: 0;
    }

    .dashboard .box-head h2 {
        margin-top: 5px;
    }

    .dashboard .box .box-title {
        padding: 9px 0;
    }

    .account-sidebar {
        margin-bottom: 11px;
    }

    .subscribe2 .subscribe-contain h2 {
        margin-bottom: 2px;
    }

    .subscribe2 .subscribe-contain p {
        margin-bottom: 10px;
    }

    .subscribe2 .subscribe-contain .form-control {
        margin-bottom: 17px;
    }

    .subscribe2 .subscribe-contain ul {
        margin-bottom: 15px;
    }

    .login-page .theme-card .btn {
        padding: 11px 15px;
    }

    .contact-page .btn {
        margin-top: 0;
    }

    .about-page .creative-card {
        padding: 0 15px;
    }

    .search-product>div:nth-child(n+2) {
        margin-top: 15px;
    }

    .portfolio-section .isotopeSelector {
        margin-bottom: 30px;
    }

    .collection-product-wrapper .product-wrapper-grid .product-box {
        margin-top: 10px;
    }

    .collection-product-wrapper .product-wrapper-grid .col-6 {
        padding-left: 5px;
        padding-right: 5px;
    }

    .product-right .pro-group .product-offer .offer-contain ul:nth-child(n+2) {
        border-top: 1px dashed #042c49;
        margin-top: 10px;
    }

    .product-right .pro-group .product-offer .offer-contain ul li {
        display: block;
        text-align: center;
    }

    .product-right .pro-group .product-offer .offer-contain ul li .code-lable {
        display: block;
        margin-bottom: 5px;
        margin-left: auto;
        margin-right: auto;
    }

    .product-right .pro-group .product-offer .offer-contain ul li:nth-child(n+2) {
        border-top: 1px dashed #042c49;
        padding-top: 10px;
    }

    .bottom-cart-sticky.open-cart {
        display: none;
    }

    .cookie-bar {
        display: block;
        text-align: center;
    }

    .theme-modal.blackfriday-modal#exampleModal .modal-dialog {
        max-width: 100%;
    }

    .theme-modal.cyber-monday#exampleModal .modal-dialog {
        max-width: 100%;
    }

    .order-tracking #progressbar li:nth-child(n+2) {
        margin-left: 30px;
    }

    .order-tracking #progressbar li .icon {
        width: 35px;
        height: 35px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay {
        display: block;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li {
        width: calc(50% - 10px);
        display: inline-block;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li:nth-child(n+2) {
        margin-left: unset;
        margin-top: unset;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li:nth-child(n+3) {
        margin-top: 10px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li:nth-child(even) {
        margin-left: 10px;
    }

    .order-tracking .order-tracking-box .btn {
        padding: 8px 11px;
    }

    .order-tracking .order-tracking-sidebar .coupan-block .btn {
        padding: 5px 8px;
        font-size: 12px;
    }

    .order-tracking .order-tracking-contain .tracking-group .delevery-code .btn {
        padding: 8px 10px;
        font-size: 12px;
    }

    .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body .size-box ul li {
        margin-left: unset;
        width: 38px;
        height: 30px;
    }
}

@media (max-width: 480px) {
    .product-slide-tab {
        padding-left: 7px;
    }

    .hot-4>div:last-child,
    .hot-4>div:first-child {
        max-width: calc(100%);
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
    }

    .blog-page .blog-sidebar .theme-card .popular-blog li .blog-date {
        height: 50px;
        width: 50px;
        padding: 5px;
    }

    .product .product-box {
        padding-right: 7px;
    }

    .product .pr-0 {
        padding-left: 7px;
    }

    .layout-3-hotdeal .media-banner {
        padding-bottom: 1px;
    }

    .layout-5 .slider-banner-contain {
        background-color: rgba(255, 255, 255, 0.3);
    }

    .contact-page .map .theme-card {
        padding: 0;
        background-color: #ffffff;
        border: 15px solid #ffffff;
    }

    .contact-page .theme-form {
        padding: 10px;
        background-color: #ffffff;
        border: 15px solid #ffffff;
    }

    .contact-page .theme-form textarea {
        padding: 17px 25px;
        margin-bottom: 20px;
        height: inherit;
    }

    .masonary-banner-block1 .col-sm-6.pl-3 {
        padding-left: 0 !important;
    }

    .discount-banner .discount-banner-contain h2 {
        margin-bottom: 7px;
    }

    .discount-banner .discount-banner-contain .rounded-contain .rounded-subcontain {
        padding: 15px 20px;
    }

    .key-block {
        padding-left: 5px;
        padding-right: 5px;
    }

    .key-block>div {
        padding-left: 5px;
        padding-right: 5px;
    }

    .key-block>div:nth-child(n+5) {
        margin-top: 10px;
    }

    .subscribe1 .subscribe-contain .subscribe-right .input-group .form-control {
        width: 100%;
        border-radius: 5px;
    }

    .subscribe1 .subscribe-contain .subscribe-right .input-group .input-group-text {
        border-radius: 5px !important;
        margin-left: auto !important;
        margin-right: auto;
        margin-top: 12px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain {
        padding: 30px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain h3 {
        font-size: 20px;
    }

    .collection-banner .collection-banner-main .collection-banner-contain h4 {
        font-size: 20px;
    }

    .collection-banner .collection-banner-main.banner-1 .collection-banner-contain h3 {
        font-size: 18px;
    }

    .collection-banner .collection-banner-main.banner-1 .collection-banner-contain h4 {
        font-size: 30px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain {
        padding: 13px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain h3 {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain h4 {
        font-size: 18px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain .shop {
        margin-top: 10px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-banner-contain .shop a {
        font-size: 13px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-img {
        height: 185px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-banner-contain h3 {
        font-size: 25px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-banner-contain h4 {
        margin-top: 10px;
        margin-bottom: 50px;
        font-size: 13px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-img {
        height: 385px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-banner-contain h3 {
        font-size: 18px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-banner-contain h4 {
        font-size: 20px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-img {
        height: 160px;
    }

    .collection-banner .collection-banner-main.banner-5.p-center .collection-banner-contain {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        padding: 20px;
    }

    .collection-banner .collection-banner-main.banner-5 .collection-img {
        background-position: right !important;
    }

    .collection-banner .collection-banner-main.banner-5.p-center {
        -webkit-box-pack: left;
        -ms-flex-pack: left;
        justify-content: left;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain {
        padding: 10px;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain h3 {
        font-size: 14px;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain h4 {
        font-size: 16px;
    }

    .collection-banner .collection-banner-main.banner-7 .collection-banner-contain .shop {
        margin-top: 10px;
    }

    .collection-banner .collection-banner-main.banner-10 .collection-img {
        height: 190px;
    }

    .collection-banner .collection-banner-main.banner-11 .collection-img {
        height: 190px;
    }

    .collection-banner .collection-banner-main.banner-12 .collection-img {
        height: 190px;
    }

    .collection-banner .collection-banner-main .collection-img {
        height: 190px;
    }

    .product .product-box .product-imgbox .new-label {
        padding: 5px;
        width: 80%;
        height: 80%;
    }

    .product .product-box .product-imgbox .new-label2 {
        top: 10px;
        left: 10px;
    }

    .product .product-box .product-imgbox .new-label1 {
        top: 10px;
        left: 10px;
        width: 35px;
        height: 35px;
    }

    .product .product-box .product-imgbox .on-sale1,
    .product .product-box .product-imgbox .on-sale2 {
        top: 25px;
        right: -15px;
    }

    .product .product-box .product-imgbox .product-icon.icon-center {
        display: unset;
        height: auto;
        width: auto;
    }

    .product .product-box .product-imgbox .product-icon button {
        width: 30px;
        height: 30px;
    }

    .product .product-box .product-imgbox .product-icon a {
        width: 30px;
        height: 30px;
    }

    .hot-deal.space-abjust .hot-deal-contain {
        padding: 20px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .hotdeal-right-slick .img-wrraper>div {
        height: 200px;
    }

    .hot-deal.hotdeal-third .hot-deal-contain .hotdeal-right-slick .img-wrraper>div {
        height: 200px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center p {
        margin-top: 0px;
        margin-bottom: 5px;
    }

    .hot-deal .hot-deal-contain .hot-deal-subcontain>div:last-child {
        margin-top: 10px;
    }

    .hot-deal .hot-deal-contain .hotdeal-right-nav img {
        width: 100px;
    }

    .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hotdeal-right-nav-1 img {
        width: 80px;
    }

    .hot-deal.hotdeal-first .hot-deal-contain .timer span,
    .hot-deal.hotdeal-third .hot-deal-contain .timer span {
        min-width: 49px;
    }

    .theme-slider .slider-banner .slider-img {
        height: 200px;
    }

    .theme-slider .slider-banner .slider-banner-contain {
        padding: 0 25px;
    }

    .theme-slider .slider-banner .slider-banner-contain h3:before,
    .theme-slider .slider-banner .slider-banner-contain h3:after {
        display: none;
    }

    .theme-slider .slider-banner .slider-banner-contain .btn-rounded {
        font-size: 12px;
        padding: 7px 12px;
    }

    .theme-slider .slider-banner .slider-banner-contain h4 {
        font-size: 14px;
    }

    .theme-slider .slider-banner .slider-banner-contain h1 {
        font-size: 14px;
    }

    .theme-slider .slider-banner .slider-banner-contain h2 {
        font-size: 14px;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-img {
        height: 220px;
        background-position: left !important;
    }

    .theme-slider .slider-banner.slide-banner-2 .slider-banner-contain .sub-contain {
        background-color: rgba(255, 255, 255, 0.7);
        min-width: 220px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-img {
        height: 230px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h3 {
        font-size: 16px;
    }

    .theme-slider .slider-banner.slide-banner-3 .slider-banner-contain h1 {
        font-size: 18px;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-1 li {
        width: 250px;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-2 li {
        width: 300px;
    }

    .theme-slider .slider-banner.slide-banner-4 .layout5-slide-3 li {
        width: 250px;
    }

    .theme-slider .slider-banner.slide-banner-4 .slider-img {
        height: 280px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h3 {
        font-size: 16px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain h1 {
        font-size: 18px;
    }

    .cosmetic-slide .slide-main {
        background-position: 15% !important;
    }

    .furniture-slide .slide-main {
        background-position: 80% !important;
    }

    .furniture-slide .slide-main .slide-contain h3 {
        margin-bottom: 5px;
    }

    .furniture-slide .slide-main .slide-contain h4 {
        margin-bottom: 5px;
    }

    .farming-slide .slide-main .slide-contain h2 {
        margin-bottom: 5px;
    }

    .farming-slide .slide-main .slide-contain h4 {
        margin-bottom: 10px;
    }

    .digitalmark-slide .slide-main .slide-contain .sub-contain .input-group {
        width: 275px;
    }

    .kids-slide .slide-main .slide-contain h4 {
        margin-bottom: 5px;
    }

    .kids-slide .slide-main .slide-contain h2 {
        margin-bottom: 10px;
    }

    .kids-slide .slide-main .slide-contain h3 {
        margin-bottom: 5px;
    }

    .kids-slide .slide-main .slide-contain p {
        margin-bottom: 10px;
    }

    .pets-slide .slide-main .animat-block .animat1 {
        left: -20px;
        width: 170px;
    }

    .pets-slide .slide-main .animat-block .animat2 {
        right: -20px;
        width: 170px;
    }

    .grocery-slide .slide-main .slide-contain h3 {
        margin-bottom: 2px;
    }

    .grocery-slide .slide-main .slide-contain h2 {
        margin-bottom: 3px;
    }

    .grocery-slide .slide-main .slide-contain h4 {
        margin-bottom: 10px;
    }

    .megastore-slide .slide-main .slide-contain {
        height: 46vh;
    }

    .megastore-slide .slide-main .slide-contain h4 {
        margin-bottom: 6px;
    }

    .megastore-slide .slide-main .slide-contain h2 {
        margin-bottom: 7px;
    }

    .megastore-slide .slide-main .slide-contain h3 {
        margin-bottom: 13px;
    }

    .top-header .top-header-right .language-block .language-dropdown span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .top-header .top-header-right .language-block .curroncy-dropdown span {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .category-header .navbar-menu .category-right .gift-block {
        padding: 12px 15px;
    }

    .category-header .navbar-menu .category-right .contact-block {
        margin-left: 20px;
    }

    .category-header .navbar-menu .category-right .contact-block span {
        margin-left: 0;
    }

    .header7 .header-contain .logo-block .brand-logo img {
        height: 32px;
    }

    .select_input select {
        padding: 12px 15px;
        margin-bottom: 18px;
    }

    .about-page p {
        line-height: 1.5;
    }

    .blog-detail-page .blog-contact h2 {
        font-size: 30px;
        margin-bottom: 25px;
    }

    .blog-detail-page .blog-contact .theme-form input,
    .blog-detail-page .blog-contact .theme-form textarea {
        padding: 12px 20px;
        margin-bottom: 20px;
    }

    .blog-detail-page .blog-detail h3 {
        margin-bottom: 15px;
    }

    .blog-detail-page .blog-detail img {
        margin-bottom: 25px;
    }

    .blog-detail-page .blog-advance ul {
        line-height: 1.5;
    }

    .blog-detail-page .blog-advance img {
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .blog-detail-page .blog-advance p {
        line-height: 1.5;
    }

    .blog-detail-page .comment-section li {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .blog-detail-page .comment-section li p {
        line-height: 1.5;
    }

    .blog-detail-page .comment-section li h6 {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .blog-detail-page .comment-section li h6 span {
        display: inherit;
        margin-left: 0;
    }

    .blog-detail-page .comment-section .media {
        display: inline-block;
        text-align: center;
    }

    .pwd-page h2 {
        font-size: 25px;
        margin-bottom: 15px;
    }

    .login-page .authentication-right h6 {
        margin-bottom: 10px;
    }

    .login-page .theme-card {
        padding: 25px;
    }

    .search-block .btn-solid {
        padding: 10px 15px;
    }

    .contact-page .map iframe {
        height: 275px;
    }

    .contact-page .theme-form input {
        padding: 8px 20px;
        margin-bottom: 15px;
    }

    .account-sidebar {
        width: 45%;
    }

    .success-text h2 {
        font-size: 20px;
    }

    .success-text p {
        font-size: 14px;
    }

    .delivery-sec h3,
    .delivery-sec h2 {
        font-size: 18px;
    }

    .typography_section .typography-box .typo-content.typo-buttons a {
        margin: 5px 0;
    }

    .search-product>div:nth-last-child(5) {
        margin-top: 30px;
    }

    .size-box ul li:nth-child(n+2) {
        margin-left: unset;
    }

    .color-selector.inline ul li:nth-child(n+2) {
        margin-left: 0;
    }

    .product-right .pro-group .timer p span {
        width: 50px;
        height: 50px;
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .product-right .pro-group .timer p span .timer-cal {
        font-size: 12px;
    }

    .product-right .pro-group .timer p span:nth-child(n+2) {
        margin-left: 5px;
    }

    .product-right .pro-group .delivery-detail .delivery-detail-contian .input-group {
        height: 40px;
        width: 250px;
    }

    .product-right .pro-group .delivery-detail .delivery-detail-contian .input-group .form-control {
        font-size: 14px;
    }

    .product-right .pro-group .delivery-detail .delivery-detail-contian .btn {
        padding: 10px 20px;
    }

    .product-right .pro-group .delivery-detail .delivery-lable {
        width: auto;
    }

    .product-right .pro-group .product-buttons a:last-child {
        margin-left: 5px;
    }

    .product-right .pro-group ul.best-seller {
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .product-right .pro-group ul.best-seller li {
        width: 100%;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .product-right .pro-group ul.best-seller li:nth-child(n+2) {
        margin-top: 5px;
        margin-left: unset;
    }

    .product-right .pro-group ul.delivery-services {
        display: block;
    }

    .product-right .pro-group ul.delivery-services li:nth-child(n+2) {
        margin-top: 5px;
        margin-left: unset;
    }

    .theme-modal.cart-modal .modal-dialog .modal-content .modal-body .modal-bg .media {
        display: block;
        text-align: center;
    }

    .theme-modal.blackfriday-modal#exampleModal .offer-content .timer span {
        min-width: 56px;
        font-size: 20px;
        margin-left: 2px;
        margin-right: 2px;
    }

    .theme-modal.blackfriday-modal#exampleModal .offer-content .timer span .timer-cal {
        font-size: 14px;
    }

    .theme-modal.blackfriday-modal#exampleModal .offer-content .lable {
        margin-right: 20px;
    }

    .order-tracking .order-tracking-box .form-group .form-control {
        height: 36px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .order-tracking .order-tracking-box .form-group .form-control:nth-child(n+2) {
        margin-left: 10px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li .form-control {
        margin-bottom: 10px;
        width: 90%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li>div:nth-child(n+2) {
        margin-left: unset;
        display: block;
    }

    .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li .btn {
        margin-left: unset;
    }

    .order-tracking .order-tracking-box .paymant-collapce .cash-pay .form-control {
        padding-left: 10px;
        padding-right: 10px;
        width: auto;
    }

    .order-tracking .order-tracking-box .card .card-header .payment-toggle {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media {
        display: block;
        text-align: center;
    }

    .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body {
        margin-left: unset;
    }

    .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body .qty-box {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

@media (max-width: 420px) {
    .category-header .navbar-menu .category-right .gift-block .grif-icon i {
        display: none;
    }

    .category-header .navbar-menu .category-right .gift-block .gift-offer span {
        font-size: 16px;
    }

    .category-header .navbar-menu .category-right .contact-block span span {
        font-size: 16px;
    }

    .theme-slider .slider-banner .layout2-slide-1 li {
        width: 290px;
    }

    .theme-slider .slider-banner .layout2-slide-2 li {
        width: 160px;
    }

    .theme-slider .slider-banner .layout2-slide-3 li {
        width: 160px;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li {
        width: 130px;
        left: 0;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-1 li:last-child {
        width: 130px;
        right: 0;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li {
        width: 120px;
        left: 0;
    }

    .theme-slider .slider-banner.slide-banner-2 .layout3-slide-2 li:last-child {
        width: 120px;
        right: 0;
    }

    .testimonial5 .img-wrraper-mian {
        width: 100%;
    }

    /*=====================
     Inner pages CSS
==========================*/
    .blog-details p {
        font-size: 14px;
        line-height: 1.3;
    }

    .blog-details p {
        font-size: 14px;
        line-height: 1.3;
    }

    .product-right .product-icon .product-social li {
        padding-right: 4px;
    }

    .product-right .product-icon .product-social li a i {
        font-size: 14px;
    }

    .product-right .product-icon .wishlist-btn span {
        font-size: 14px;
    }

    .product-right .product-icon .wishlist-btn i {
        font-size: 14px;
    }

    .tab-product .nav-material.nav-tabs {
        display: block;
    }

    .tab-product .nav-material.nav-tabs .nav-item {
        width: 100%;
    }

    .tab-product .nav-material.nav-tabs .nav-item .nav-link {
        padding: 10px 20px;
        text-align: center;
    }

    .vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
    .vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
        padding: 10px 20px;
    }

    .video-product video {
        height: 200px;
    }

    .product-right .product-buttons .btn-normal {
        padding: 10px 15px;
    }

    .product-right.product-form-box .timer span {
        min-width: 45px;
    }

    .product-right .timer span {
        min-width: 55px;
        margin-right: 8px;
        margin-left: 0;
    }

    .product-right .timer span:last-child {
        margin-right: 0;
    }

    .product-right .product-icon .product-social {
        margin-top: 0;
    }

    .product-right .product-icon .product-social li {
        padding-right: 5px;
    }

    .product-right .product-icon .product-social li a i {
        font-size: 14px;
    }

    .product-right .product-icon .wishlist-btn i {
        font-size: 14px;
        padding-left: 10px;
        margin-left: 5px;
    }

    .product-right .product-icon .wishlist-btn span {
        font-size: 14px;
    }

    .tab-product .theme-form input,
    .tab-product .theme-form textarea {
        font-size: 12px;
        padding: 8px 15px;
    }

    .category-border div .category-banner .category-box h2 {
        font-size: 25px;
    }

    .full-banner .banner-contain h2 {
        font-size: 55px;
    }

    .full-banner .banner-contain h3 {
        font-size: 36px;
    }

    .theme-card.center-align {
        display: unset;
        height: unset;
        -webkit-box-align: unset;
        -ms-flex-align: unset;
        align-items: unset;
    }

    .theme-card.card-border .offer-slider img {
        padding: 0 15px 0 15px;
    }

    .full-box .center-slider .offer-slider .product-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-bottom: 1px solid #dddddd;
        border-top: 1px solid #dddddd;
    }

    .full-box .center-slider .offer-slider .product-box .img-wrapper img {
        height: 120px;
    }

    .full-box .center-slider .offer-slider .product-box .product-info {
        text-align: left;
    }

    .full-box .center-slider .offer-slider .product-box .product-info h6 {
        padding-top: 5px;
    }

    .full-box .center-slider .offer-slider .product-box .product-info .color-variant {
        padding-top: 2px;
    }

    .full-box .center-slider .offer-slider .product-box .product-info .color-variant li {
        height: 15px;
        width: 15px;
        padding-right: 3px;
    }

    .full-box .center-slider .offer-slider .product-box .product-detail {
        padding-left: 0;
    }

    .full-box .center-slider .offer-slider .product-box .product-detail h4 {
        font-size: 20px;
    }

    .full-box .center-slider .offer-slider .product-box .product-detail .color-variant {
        padding-top: 0;
    }

    .full-box .center-slider .offer-slider .product-box:hover .product-info .btn-outline {
        display: none;
    }

    .full-box .center-slider .offer-slider .product-box:hover .product-info .color-variant {
        opacity: 1;
    }

    .full-box .theme-card .offer-slider .sec-1 {
        display: inline;
    }

    .full-box .theme-card .offer-slider .sec-1 .product-box2 {
        border-bottom: 1px solid #dddddd;
    }

    .full-box .theme-card .offer-slider .sec-1 .product-box2:last-child {
        border-bottom: none;
    }

    .full-box .theme-card .offer-slider .sec-1 .product-box2+.product-box2 {
        border-left: none;
    }

    .full-box.center-align .offer-slider .product-box2 {
        padding-bottom: 15px;
    }

    .center-slider .offer-slider .product-box .product-detail {
        text-align: left !important;
    }

    .center-slider .offer-slider .product-box .product-detail .rating i {
        padding-right: 3px;
    }

    .center-slider .offer-slider .product-box .product-detail h4 {
        padding-bottom: 0.5rem;
    }

    .center-slider .offer-slider .product-box .product-detail .color-variant li {
        height: 18px;
        width: 18px;
        padding-right: 3px;
    }

    .center-slider .offer-slider .product-box .img-wrapper .cart-box {
        display: none;
    }

    .team h6 {
        line-height: 15px;
    }

    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
        padding: 30px;
    }

    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
        font-size: 30px;
    }

    .login-page .authentication-right p {
        line-height: 1.5;
    }

    .login-page .theme-card .theme-form input {
        padding: 10px 20px;
    }

    .tab-product .nav-material.nav-tabs {
        display: block;
    }

    .tab-product .nav-material.nav-tabs .nav-item {
        width: 100%;
    }

    .tab-product .nav-material.nav-tabs .nav-item .nav-link {
        padding: 10px 20px;
    }

    .vertical-tab.tab-product .nav-material.nav-tabs .nav-item .nav-link,
    .vertical-tab .product-full-tab .nav-material.nav-tabs .nav-item .nav-link {
        padding: 10px 20px;
    }

    .product-right .product-buttons .btn-solid,
    .product-right .product-buttons .btn-outline {
        padding: 7px 13px;
    }

    .product-right.product-form-box .timer span {
        min-width: 45px;
    }

    .product-right .timer span {
        min-width: 45px !important;
    }

    .product-right .product-icon .product-social {
        margin-top: 0;
    }

    .product-right .product-icon .product-social li {
        padding-right: 5px;
    }

    .product-right .product-icon .product-social li a i {
        font-size: 14px;
    }

    .product-right .product-icon .wishlist-btn i {
        font-size: 14px;
        padding-left: 10px;
        margin-left: 5px;
    }

    .product-right .product-icon .wishlist-btn span {
        font-size: 14px;
    }

    .tab-product .theme-form input,
    .tab-product .theme-form textarea {
        font-size: 12px;
        padding: 8px 15px;
    }

    .order-box .sub-total .shipping {
        width: unset;
        float: unset;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

    .order-box .sub-total .shipping .shopping-option:last-child {
        padding-left: 20px;
    }

    .checkout-page .checkout-form .form-group {
        margin-bottom: 0;
    }

    .checkout-page .checkout-form input[type="text"],
    .checkout-page .checkout-form input[type="email"],
    .checkout-page .checkout-form input[type="password"],
    .checkout-page .checkout-form input[type="tel"],
    .checkout-page .checkout-form input[type="number"],
    .checkout-page .checkout-form input[type="url"] {
        height: 40px;
    }

    .checkout-page .checkout-form select,
    .checkout-page .checkout-form textarea {
        height: 40px;
    }

    .theme-tab .tab-title {
        margin-bottom: 15px;
    }

    .theme-tab .tab-title.media-tab li {
        display: block;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: 0 auto;
        padding-top: 10px;
        line-height: 1;
    }
}

@media (max-width: 360px) {
    .product .product-box .product-imgbox .product-icon.icon-inline button {
        margin: 0 1px;
    }

    .product .product-box .product-imgbox .product-icon.icon-inline a {
        margin: 0 1px;
    }

    .product .product-box .product-imgbox .new-label {
        padding: 5px;
        width: 100%;
        height: 100%;
    }

    .product .product-box .product-detail .icon-detail button {
        margin: 0 1px;
    }

    .product .product-box .product-detail .icon-detail a {
        margin: 0 1px;
    }

    .product .product-box .product-detail.detail-center {
        padding-top: 20px;
    }

    .product .product-box .product-detail.detail-center .icon-detail {
        bottom: 78px;
    }

    .product .product-box:hover .product-detail.detail-center .icon-detail {
        bottom: 78px;
    }

    .collection-banner .banner-5.p-center .collection-banner-contain .sub-contain {
        background-color: rgba(255, 255, 255, 0.4);
        padding: 15px;
    }

    .collection-banner .collection-banner-main .collection-img {
        height: 135px;
    }

    .collection-banner .collection-banner-main.banner-2 .collection-img {
        height: 135px;
    }

    .collection-banner .collection-banner-main.banner-3 .collection-img {
        height: 280px;
    }

    .collection-banner .collection-banner-main.banner-4 .collection-img {
        height: 115px;
    }

    .hot-deal .hot-deal-contain .hotdeal-right-nav img {
        width: 65px;
    }

    .hot-deal .hot-deal-contain .hot-deal-center .timer span {
        padding: 9px;
    }

    .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hotdeal-right-nav-1 img {
        width: 65px;
    }

    .theme-modal#exampleModal .offer-content {
        min-height: 300px;
    }

    .theme-slider .slider-banner.slide-banner-5 .slider-banner-contain {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .key-feature .key-feature-box img {
        width: 35px;
    }

    /*=====================
   Inner pages CSS
==========================*/
    .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body a h6 {
        font-size: 13px;
    }

    .theme-tab .tab-content .product-tab .tab-box .product-box2 .media .media-body .color-variant li {
        height: 12px;
        width: 12px;
    }

    .theme-tab .tab-content .product-tab .tab-box .product-box2 img {
        height: 115px;
    }

    .blog-detail-page .blog-detail p {
        line-height: 1.5;
    }

    .blog-detail-page .blog-contact h2 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .about-page p {
        line-height: 1.2;
    }

    .theme-modal .modal-dialog .modal-content .modal-body {
        padding: 10px;
    }

    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg {
        padding: 20px;
    }

    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .close {
        right: 10px;
    }

    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .login-page .theme-card {
        padding: 18px;
    }

    .search-block .btn-solid {
        padding: 10px 5px;
    }

    .search-block .form-header .input-group input {
        padding: 10px 12px;
    }

    .contact-page .map iframe {
        height: 200px;
    }

    .collection-product-wrapper .product-pagination .pagination .page-item a {
        padding: 15px 13px;
    }

    .collection-product-wrapper .product-pagination .product-search-count-bottom {
        padding-left: 10px;
    }

    .collection-product-wrapper .product-pagination .product-search-count-bottom h5 {
        line-height: 15px;
    }

    .collection-product-wrapper .product-top-filter .popup-filter .sidebar-popup {
        width: 80%;
    }

    .cart-section tbody tr td,
    .wishlist-section tbody tr td {
        min-width: 115px;
    }

    .cart-section tbody tr td .mobile-cart-content .col-xs-3,
    .wishlist-section tbody tr td .mobile-cart-content .col-xs-3 {
        margin-left: 5px;
        margin-right: 5px;
    }

    .cart-section tbody tr td a img,
    .wishlist-section tbody tr td a img {
        height: 80px;
    }

    .cart-section tfoot tr td,
    .wishlist-section tfoot tr td {
        padding-right: 27px;
    }

    .cart-section tfoot tr td h2,
    .wishlist-section tfoot tr td h2 {
        font-size: 21px;
    }

    .wishlist-section .wishlist-buttons:last-child a {
        margin-left: 0;
    }

    .product-buttons .btn-solid,
    .product-buttons .btn-outline {
        padding: 7px 16px;
    }

    .single-product-tables.detail-section table {
        width: 70%;
    }

    .product-right .timer span .padding-l {
        padding-left: 10px;
    }

    .product-form-box .timer span {
        min-width: 45px;
    }

    .product-form-box .timer span .padding-l {
        padding-left: 9px;
    }

    .product-form-box .product-buttons .btn-solid,
    .product-form-box .product-buttons .btn-outline {
        padding: 5px 5px;
    }

    .product-form-box .timer span,
    .border-product .timer span {
        min-width: 45px;
    }

    .product-form-box .timer span .padding-l,
    .border-product .timer span .padding-l {
        padding-right: 5px;
    }

    .tools-parallax-product .tools-description .tools-form .search-box {
        width: 92%;
    }

    .full-box .center-slider .offer-slider .product-box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border-bottom: 1px solid #dddddd;
        border-top: 1px solid #dddddd;
    }

    .full-box .center-slider .offer-slider .product-box .img-wrapper img {
        height: 120px;
    }

    .full-box .center-slider .offer-slider .product-box .product-info {
        text-align: left;
    }

    .full-box .center-slider .offer-slider .product-box .product-info h6 {
        padding-top: 5px;
    }

    .full-box .center-slider .offer-slider .product-box .product-info .color-variant {
        padding-top: 2px;
    }

    .full-box .center-slider .offer-slider .product-box .product-info .color-variant li {
        height: 15px;
        width: 15px;
        padding-right: 3px;
    }

    .full-box .center-slider .offer-slider .product-box .product-detail {
        padding-left: 0;
    }

    .full-box .center-slider .offer-slider .product-box .product-detail h4 {
        font-size: 20px;
    }

    .full-box .center-slider .offer-slider .product-box .product-detail .color-variant {
        padding-top: 0;
    }

    .full-box .center-slider .offer-slider .product-box:hover .product-info .btn-outline {
        display: none;
    }

    .full-box .center-slider .offer-slider .product-box:hover .product-info .color-variant {
        opacity: 1;
    }

    .full-box .theme-card .offer-slider .sec-1 {
        display: inline;
    }

    .full-box .theme-card .offer-slider .sec-1 .product-box2 {
        border-bottom: 1px solid #dddddd;
    }

    .full-box .theme-card .offer-slider .sec-1 .product-box2:last-child {
        border-bottom: none;
    }

    .full-box .theme-card .offer-slider .sec-1 .product-box2+.product-box2 {
        border-left: none;
    }

    .center-slider .offer-slider .product-box .product-detail {
        text-align: left !important;
    }

    .center-slider .offer-slider .product-box .product-detail .rating i {
        padding-right: 3px;
    }

    .center-slider .offer-slider .product-box .product-detail h4 {
        padding-bottom: 0.5rem;
    }

    .center-slider .offer-slider .product-box .product-detail .color-variant li {
        height: 18px;
        width: 18px;
        padding-right: 3px;
    }

    .center-slider .offer-slider .product-box .img-wrapper .cart-box {
        display: none;
    }

    .order-tracking .order-tracking-box .form-group {
        display: block;
    }

    .order-tracking .order-tracking-box .form-group .form-control:nth-child(n+2) {
        margin-left: unset;
        margin-top: 15px;
    }
}

@media (max-width: 320px) {
    .rounded-category .category-contain .img-wrapper {
        width: 70px;
        height: 70px;
    }

    .deal-banner .deal-banner-containe h1 {
        line-height: 1.4;
    }
}

/*=====================
Dark start
==========================*/
body.dark {
    background-color: #1b1b1b !important;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    color: #cfd4da;
}

.dark {
    /*=====================
  inner pages
  ==========================*/
}

.dark .product-notification {
    background-color: #262626;
}

.dark .product-notification .btn-close {
    color: #dddddd;
    -webkit-filter: invert(1);
    filter: invert(1);
}

.dark h1 {
    color: #ffffff;
}

.dark h2 {
    color: #f1f3f5;
}

.dark h3 {
    color: #eaedef;
}

.dark h4 {
    color: #e7eaec;
}

.dark h5 {
    color: #dee2e6;
}

.dark h6 {
    color: #cfd4da;
}

.dark p {
    color: #cfd4da;
}

.dark li {
    color: #cfd4da;
}

.dark a {
    color: #cbcbcb;
}

.dark input,
.dark textarea {
    border-color: #3c3c3c !important;
    background-color: #262626;
}

.dark input::-webkit-input-placeholder,
.dark textarea::-webkit-input-placeholder {
    color: #cfd4da !important;
}

.dark input::-moz-placeholder,
.dark textarea::-moz-placeholder {
    color: #cfd4da !important;
}

.dark input:-ms-input-placeholder,
.dark textarea:-ms-input-placeholder {
    color: #cfd4da !important;
}

.dark input::-ms-input-placeholder,
.dark textarea::-ms-input-placeholder {
    color: #cfd4da !important;
}

.dark input::placeholder,
.dark textarea::placeholder {
    color: #cfd4da !important;
}

.dark select {
    color: #cfd4da !important;
}

.dark select:focus {
    background-color: #262626;
    color: #cfd4da !important;
}

.dark .form-check .form-check-input {
    background-color: #1b1b1b;
}

.dark .gift-dropdown.dropdown-menu {
    border: 2px solid #3c3c3c;
    background-color: #1b1b1b;
}

.dark .gift-dropdown.dropdown-menu .media+.media {
    border-top: 1px solid #3c3c3c;
}

.dark .header-category ul.collapse-category {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .header-category ul.collapse-category li.back-btn {
    border-color: #3c3c3c;
}

.dark .header-category ul.collapse-category li a {
    border-color: #3c3c3c;
    color: #f1f3f5;
}

.dark .header-category ul.collapse-category li:first-child {
    color: #f1f3f5;
}

.dark .header-category2 ul.collapse-category {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .header-category2 ul.collapse-category li a {
    color: #ffffff;
}

.dark .header-category2 ul.collapse-category li:nth-child(n+3) a {
    border-color: #3c3c3c;
}

.dark .header-search .input-group .form-control,
.dark .header-search .input-group .typeahead {
    color: #f1f3f5;
    background-color: #262626 !important;
}

.dark .header-search .input-group .form-control:focus,
.dark .header-search .input-group .typeahead:focus {
    background-color: #262626;
}

.dark .header-search.search-style2 .input-group {
    border-color: #3c3c3c;
}

.dark .header-search2 .input-group .input-group-text {
    border: none;
}

.dark .header-search2 .input-group .form-control {
    color: #f1f3f5;
}

.dark .header-search2 .input-group .form-control:focus {
    background-color: #262626;
}

.dark .header-search3 .input-group .form-control {
    color: #f1f3f5;
}

.dark .header-search3 .input-group .form-control:focus {
    background-color: #262626;
}

.dark label {
    color: #e7eaec !important;
}

.dark .toggle-nav i {
    color: #f1f3f5;
}

.dark .sm-horizontal .mobile-back,
.dark .sm-vertical .mobile-back {
    color: #e7eaec;
    border-bottom-color: #3c3c3c;
}

.dark .tab-product-main .tab-prodcut-contain ul li a {
    color: #ffffff;
}

.dark .tab-product-main.tab-second .tab-prodcut-contain ul li a svg {
    fill: #ffffff;
}

.dark .blog .blog-contain .blog-img {
    border: 1px solid #262626;
}

.dark .blog .blog-contain .blog-details-2 {
    background-color: #262626;
}

.dark .theme-pannel-main {
    background-color: #262626;
}

.dark .theme-pannel-main ul li {
    border-color: #3c3c3c;
}

.dark .theme-pannel-main ul li span {
    color: #f1f3f5;
}

.dark .color-picker {
    background-color: #262626;
}

.dark .color-picker h4 {
    border-color: #3c3c3c;
}

.dark .color-picker .title {
    background-color: #262626;
}

.dark .input-block .input-box .big-deal-form {
    border-color: #3c3c3c;
}

.dark .input-block .input-box .big-deal-form .input-group {
    background-color: #262626;
}

.dark .input-block .input-box .big-deal-form .input-group span {
    background-color: #262626;
}

.dark .input-block .input-box .big-deal-form .input-group .form-control:focus {
    background-color: #262626;
}

.dark .input-block .input-box .big-deal-form .input-group select {
    background-color: #262626;
}

.dark .searchbar-input .input-group .input-group-text {
    background-color: #262626;
}

.dark .searchbar-input .input-group .form-control {
    background-color: #262626 !important;
}

.dark .sm-nav-block .nav-slide {
    background-color: #262626;
}

.dark .sm-nav-block .nav-slide>li {
    border-color: #3c3c3c;
}

.dark .sm-nav-block .nav-slide>li .nav-sm-back {
    color: #eaedef;
    border-color: #3c3c3c;
}

.dark .sm-nav-block .nav-slide>li a {
    color: #cfd4da;
}

.dark .sm-nav-block .nav-slide>li.mor-slide-open ul li {
    border-color: #3c3c3c;
}

.dark .layout-header2 {
    background-color: #262626;
}

.dark .layout-header2 .main-menu-block .icon-block ul li svg {
    fill: #cbcbcb;
}

.dark .layout-header2 .main-menu-block .icon-block ul li .cart-block .cart-item h5 {
    color: #cbcbcb;
}

.dark .layout-header2 .main-menu-block .sm-nav-block .nav-slide {
    background-color: #262626;
}

.dark .layout-header2 .main-menu-block .sm-nav-block .nav-slide li {
    border-color: #3c3c3c;
}

.dark .layout-header2 .main-menu-block .sm-nav-block .nav-slide li a {
    color: #dee2e6;
}

.dark .layout-header2 .main-menu-block .sm-nav-block .nav-slide li .nav-sm-back {
    color: #e7eaec;
    border-color: #3c3c3c;
}

.dark .category-header-2 .navbar-menu .nav-block .nav-left .nav-cat {
    background-color: #262626;
}

.dark .category-header-2 .navbar-menu .nav-block .nav-left .nav-cat li img {
    border-color: #3c3c3c;
}

.dark .category-header-2 .navbar-menu .nav-block .nav-left .nav-cat li a {
    color: #cbcbcb;
}

.dark .layout-header1 {
    background-color: #1b1b1b;
}

.dark .layout-header1 .main-menu-block .menu-right .icon-nav ul li svg {
    fill: #cbcbcb;
}

.dark .layout-header1 .main-menu-block .menu-right .icon-nav ul li .cart-item span {
    color: #cbcbcb;
}

.dark .layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide {
    background-color: #262626;
}

.dark .layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li {
    border-color: #3c3c3c;
}

.dark .layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li a {
    color: #cbcbcb;
}

.dark .layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li .nav-sm-back {
    color: #cfd4da;
    border-color: #3c3c3c;
}

.dark .layout-header1 .main-menu-block .menu-left .sm-nav-block .nav-slide>li.mor-slide-open ul li {
    border-color: #3c3c3c;
}

.dark .category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat {
    background-color: #262626;
}

.dark .category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li img {
    border-color: #3c3c3c;
}

.dark .category-header .navbar-menu .category-left .nav-block .nav-left .nav-cat li a {
    color: #cbcbcb;
}

.dark .layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat {
    background-color: #262626;
}

.dark .layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat li a {
    color: #cfd4da;
}

.dark .layout-header3 .main-menu .menu-left .nav-block .nav-left .nav-cat li img {
    border-color: #3c3c3c;
}

.dark .layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide {
    background-color: #2d2d2d;
}

.dark .layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide li {
    border-color: #3c3c3c;
}

.dark .layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide>li {
    border-color: #3c3c3c;
}

.dark .layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide>li .nav-sm-back {
    color: #cfd4da;
    border-color: #3c3c3c;
}

.dark .layout-header3 .main-menu .menu-left .sm-nav-block .nav-slide>li a {
    color: #cbcbcb;
}

.dark .layout-header3 .main-menu .input-block .input-box .big-deal-form {
    border-color: #3c3c3c;
}

.dark .layout-header3 .main-menu .input-block .input-box .big-deal-form .input-group select {
    border-color: #3c3c3c;
}

.dark .layout-header3 .main-menu .menu-nav .desc-horizontal {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .layout-header3 .main-menu .menu-nav .desc-horizontal .desc-back {
    color: #cfd4da;
    border-color: #3c3c3c;
}

.dark .layout-header4 {
    background-color: #262626;
}

.dark .layout-header4 .contact-block .desc-nav-block ul li .contact-item {
    color: #cbcbcb;
}

.dark .layout-header4 .contact-block .desc-nav-block ul li .contact-item span {
    color: #cbcbcb;
}

.dark .layout-header4 .contact-block .sm-nav-block .nav-slide {
    background-color: #262626;
}

.dark .layout-header4 .contact-block .sm-nav-block .nav-slide li {
    border-color: #3c3c3c;
}

.dark .layout-header4 .contact-block .sm-nav-block .nav-slide li a {
    color: #cbcbcb;
}

.dark .layout-header4 .contact-block .sm-nav-block .nav-slide li .nav-sm-back {
    color: #cfd4da;
    border-color: #3c3c3c;
}

.dark .layout-header4 .icon-block ul li a svg {
    fill: #cbcbcb;
}

.dark .layout-header4 .icon-block ul li a .cart-item {
    color: #cbcbcb;
}

.dark .category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat {
    background-color: #262626;
}

.dark .category-header-4 .navbar-menu .category-left .nav-block .nav-left .nav-cat li a {
    color: #cfd4da;
}

.dark .header7 {
    background-color: #262626;
}

.dark .header7 .header-contain .icon-block ul li svg {
    fill: #cbcbcb;
}

.dark .category-header7 .category-contain .category-right .menu-block ul li a {
    color: #ffffff !important;
}

.dark .sticky .category-header-2 {
    background-color: #262626;
}

.dark .sticky .category-header-2 .navbar-menu .icon-block ul li svg {
    fill: #cbcbcb;
}

.dark .sticky .category-header-4 {
    background-color: #262626;
}

.dark .sticky .category-header-4 .navbar-menu .category-right .icon-block ul li svg {
    fill: #cbcbcb;
}

.dark .sticky.header-style2 .category-header7 {
    background-color: #262626;
}

.dark .sticky.header-style2 .category-header7 .category-contain .icon-block ul li svg {
    fill: #cbcbcb;
}

.dark .b-g-white {
    background-color: #262626 !important;
}

.dark .b-g-white1 {
    background-color: #262626 !important;
}

.dark .slick-prev:before,
.dark .slick-next:before {
    color: #303030 !important;
}

.dark .bg-light {
    background-color: #1b1b1b !important;
}

.dark .bg-white {
    background-color: #1b1b1b !important;
}

.dark .b-g-light {
    background-color: #1b1b1b !important;
}

.dark .creative-card {
    background-color: #262626;
}

.dark .creative-card .form-control {
    color: #e7eaec;
}

.dark .btn-rounded.btn-outline {
    background-color: #262626;
    color: #ef9f77 !important;
}

.dark .btn-rounded.btn-outline:hover {
    color: #ffffff !important;
}

.dark .header-category2 ul.collapse-category li.back-btn {
    color: #f1f3f5;
}

.dark .header-category3 ul.collapse-category {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .header-category3 ul.collapse-category li {
    background-color: #262626;
}

.dark .header-category3 ul.collapse-category li a {
    color: #eaedef;
}

.dark .header-category3 ul.collapse-category>li ul {
    border-color: #3c3c3c;
}

.dark .header-category3 ul .categoryone .collapse-mega {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .header-category3 ul .categoryone .collapse-mega .mega-box h5 {
    color: #cbcbcb;
}

.dark .header-category3 ul li a span:before {
    color: #cbcbcb;
}

.dark .pets-slide .slide-main .slide-contain .form-control {
    background-color: #ffffff;
    border-color: #dddddd !important;
}

.dark .pixelstrap ul {
    border-color: #4c4c4c;
    background-color: #262626;
}

.dark .pixelstrap ul a,
.dark .pixelstrap ul:hover,
.dark .pixelstrap ul:active {
    color: #cbcbcb !important;
}

.dark .pixelstrap a {
    color: #cbcbcb !important;
}

.dark .pixelstrap .link-section h5 {
    color: #dee2e6;
}

.dark .brand-panel .brand-panel-box .brand-panel-contain {
    background-color: #262626;
}

.dark .brand-panel .brand-panel-box .brand-panel-contain ul li a {
    color: #cfd4da;
}

.dark .brand-second .brand-box {
    background-color: #262626;
}

.dark .brand-second .brand-box img {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.dark .team1 .team-box {
    background-color: #262626;
}

.dark .team1 .team-box .team-detail h5 {
    color: #f1f3f5;
}

.dark .team1 .team-box .team-detail p {
    color: #eaedef;
}

.dark .pricing-table1 .pricing-box {
    border-color: #3c3c3c;
}

.dark .pricing-table1 .pricing-box .pricing-body {
    background-color: #262626;
}

.dark .pricing-table1 .pricing-box .pricing-body ul li {
    border-color: #3c3c3c;
}

.dark .pricing-table1 .pricing-box .pricing-body ul li a {
    color: #eaedef;
}

.dark .pricing-table2 .pricing-box {
    border-color: #3c3c3c;
}

.dark .pricing-table2 .pricing-box .pricing-body {
    background-color: #262626;
}

.dark .pricing-table2 .pricing-box .pricing-body ul li {
    border-color: #3c3c3c;
}

.dark .pricing-table2 .pricing-box .pricing-body ul li a {
    color: #f1f3f5;
}

.dark .pricing-table3 .pricing-box {
    background-color: #1b1b1b;
    border-color: #3c3c3c;
}

.dark .pricing-table3 .pricing-box .pricing-body h3 {
    color: #f1f3f5;
}

.dark .pricing-table3 .pricing-box .pricing-body ul li {
    border-color: #3c3c3c;
}

.dark .pricing-table3 .pricing-box .pricing-body ul li:nth-child(odd) {
    background-color: #262626;
}

.dark .pricing-table3 .pricing-box .pricing-body ul li a {
    color: #f1f3f5;
}

.dark .hot-deal .hot-deal-heading h3 {
    color: #f1f3f5;
}

.dark .hot-deal .hot-deal-contain h5 {
    color: #f1f3f5;
}

.dark .hot-deal .hot-deal-contain .timer span {
    background-color: #262626;
    color: #f1f3f5;
}

.dark .hot-deal.hotdeal-two .hot-deal-contain .timer span {
    background-color: #1b1b1b;
}

.dark .hot-deal.hotdeal-two .hot-deal-contain .timer span .timer-cal {
    color: #e7eaec;
}

.dark .hotdeal-second .hotdeal-box {
    background-color: #262626;
}

.dark .hotdeal-second .hotdeal-box .hotdeal-contain h3 {
    color: #ffffff;
}

.dark .hotdeal-second .hotdeal-box .hotdeal-contain .timer2 span {
    background-color: #2d2d2d;
}

.dark .team2 .team-box .team-detail {
    background-color: #262626;
}

.dark .team2 .team-box .team-detail h5 {
    color: #f1f3f5;
}

.dark .collection-banner .collection-banner-main:after {
    background-color: rgba(27, 27, 27, 0.2);
}

.dark .collection-mobile-back {
    border-color: #3c3c3c;
}

.dark .collection-mobile-back span {
    color: #e7eaec;
}

.dark .collection-wrapper .product-slick {
    background-color: #262626;
}

.dark .product-wrapper-grid.list-view .product .product-box {
    background-color: #262626;
}

.dark .load-more-sec a {
    border-color: #3c3c3c;
}

.dark .Jewellery-banner h6 {
    color: #262626;
}

.dark .Jewellery-banner a {
    color: #262626;
}

.dark .blog .blog-contain .blog-details {
    background-color: #262626;
}

.dark .blog .blog-contain .blog-details p {
    color: #cfd4da;
}

.dark .blog .blog-contain h4 {
    color: #e7eaec;
}

.dark .blog .blog-contain .blog-details-2 .btn-normal {
    background-color: #262626;
    color: #ffffff !important;
}

.dark .blog .blog-contain.blog-border {
    border-color: #3c3c3c;
}

.dark .blog .blog-contain.blog-border .blog-details-2 {
    background-color: #1b1b1b;
}

.dark .blog-detail-page .comment-section li {
    border-color: #3c3c3c;
}

.dark .breadcrumb-main {
    background-color: #262626;
}

.dark .breadcrumb-main .breadcrumb-contain h2 {
    color: #f1f3f5;
}

.dark .breadcrumb-main .breadcrumb-contain ul li i {
    color: #e7eaec;
}

.dark .breadcrumb-main .breadcrumb-contain ul li a {
    color: #cfd4da;
}

.dark .box-category .box-category-contain {
    background-color: #262626;
}

.dark .box-category .box-category-contain h4 {
    color: #e7eaec;
}

.dark .box-category .box-category-contain:hover {
    background: #bfbfbf;
}

.dark .category2 .category-box {
    background-color: #262626;
}

.dark .category2 .category-box .category-details h6 {
    color: #f1f3f5;
}

.dark .category3 .category-box .category-detail {
    background-color: #262626;
}

.dark .category4 .category-box {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .category4 .category-box .category-detail h2 {
    color: #f1f3f5;
}

.dark .category4 .category-box .category-detail ul li a {
    color: #eaedef;
}

.dark .category5 .category-box .contain-wrraper {
    background-color: #262626;
}

.dark .category5 .category-box .contain-wrraper h3 {
    color: #f1f3f5;
}

.dark .category5 .category-box .contain-wrraper p {
    color: #eaedef;
}

.dark .subscribe1 .subscribe-contain .subscribe-right .input-group .form-control {
    color: #e7eaec;
}

.dark .subscribe1 .subscribe-contain .subscribe-right .input-group .form-control:focus {
    background-color: #262626;
}

.dark .contact-banner2 .subscribe-box {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .contact-banner2 .subscribe-box .form-control:focus {
    background-color: #1b1b1b;
}

.dark .contact-banner2 .contact-bannerbox {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .contact-banner2 .contact-bannerbox ul li svg {
    fill: #ffffff;
}

.dark .contact-banner2 .contact-bannerbox ul li h3 {
    color: #eaedef;
}

.dark .brand-third {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .brand-third .brand-box {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.dark .services1 .services-box {
    background-color: #262626;
}

.dark .services1.style2 .services-box .media .media-body h4 {
    color: #f1f3f5;
}

.dark .services1.style2 .services-box .media .media-body p {
    color: #eaedef;
}

.dark .services1.border-services .services-box {
    border-color: #3c3c3c;
    background-color: #1b1b1b;
}

.dark .services1.border-services .services-box .media .icon-wrraper {
    background-color: #1b1b1b;
}

.dark .services1.services-transparent .services-box {
    background-color: transparent;
}

.dark .services2 .services-box {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .media-banner {
    background-color: #1b1b1b;
    border-color: #3c3c3c;
}

.dark .media-banner .media-banner-box .media-heading h5 {
    color: #dee2e6;
}

.dark .media-banner .media-banner-box .media .media-body .media-contant p {
    color: #eaedef;
}

.dark .media-banner .media-banner-box .media .media-body .media-contant h6 span {
    color: #dee2e6;
}

.dark .media-banner .media-banner-box .media .media-body .media-contant .cart-info button svg {
    color: #f1f3f5;
}

.dark .media-banner .media-banner-box .media .media-body .media-contant .cart-info a svg {
    color: #f1f3f5;
}

.dark .discount-banner {
    background-color: #262626;
}

.dark .discount-banner .discount-banner-contain h1 {
    color: #e7eaec;
}

.dark .hot-deal .hot-deal-contain1 {
    background-color: #262626;
}

.dark .hot-deal .hot-deal-contain1 .hot-deal-heading h5 {
    color: #dee2e6;
}

.dark .hot-deal .hot-deal-contain1.hot-deal-banner-1 {
    background-color: #1b1b1b;
}

.dark .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center h5 {
    color: #cfd4da;
}

.dark .hot-deal .hot-deal-contain1 .hot-deal-subcontain .hot-deal-center .timer span {
    color: #cfd4da;
}

.dark .hot-deal .hot-deal-contain {
    background-color: #1b1b1b;
}

.dark .hot-deal .hot-deal-contain .hot-deal-center .price span {
    color: #dee2e6;
}

.dark .hot-deal .hot-deal-contain .hot-deal-center h5 {
    color: #dee2e6;
}

.dark .hot-deal .hot-deal-contain .hot-deal-center .timer span {
    background-color: #262626;
    color: #cfd4da;
}

.dark .hot-deal .hot-deal-contain.hot-deal-contain2 .hot-deal-center .timer span {
    background-color: #1b1b1b;
}

.dark .hot-deal .slick-prev:before,
.dark .hot-deal .slick-next:before {
    color: #f1f3f5;
}

.dark .tab-product-main {
    background-color: #262626;
}

.dark .product .product-box .product-detail {
    background-color: #262626;
}

.dark .product .product-box .product-detail h3 {
    color: #f1f3f5;
}

.dark .product .product-box .product-detail h5 span {
    color: #f1f3f5;
}

.dark .product .product-box .product-detail.detail-inline {
    background-color: #262626;
}

.dark .product .product-box .product-detail.detail-center {
    background-color: #262626;
}

.dark .product .product-box .product-detail.detail-center1 h6 {
    color: #f1f3f5;
}

.dark .product .product-box .product-detail.detail-center1 .detail-price span {
    color: #eaedef;
}

.dark .product .product-box .product-detail .detail-title .detail-left .price-title {
    color: #cfd4da;
}

.dark .product-box3 {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .product-box3 .product-detail h3 {
    color: #f1f3f5;
}

.dark .product-box3 .product-detail h4 {
    color: #eaedef;
}

.dark .digipro-box {
    border-color: #3c3c3c;
    background-color: #262626;
}

.dark .digipro-box .product-detail {
    background-color: #1b1b1b;
}

.dark .digipro-box .product-detail h4 {
    color: #f1f3f5;
}

.dark .digipro-box .product-detail h5 {
    color: #eaedef;
}

.dark .digipro-box .product-detail .sale-box h4.price {
    color: #e7eaec;
}

.dark .digipro-box .product-detail .pro-sale h4 {
    color: #e7eaec;
}

.dark .instagram .insta-contant {
    border-color: #262626;
}

.dark .instagram .insta-contant.insta-contant-inverse {
    border-color: #1b1b1b;
}

.dark .instagram .insta-contant .insta-sub-contant .insta-title {
    background-color: #262626;
}

.dark .instagram .insta-contant .insta-sub-contant .insta-title h4 {
    color: #e7eaec;
}

.dark .instagram .insta-contant1 .insta-sub-contant1 .insta-title {
    background-color: #1b1b1b;
}

.dark .instagram .insta-contant1 .insta-sub-contant1 .insta-title h4 {
    color: #e7eaec;
}

.dark .instagram .insta-contant2 {
    border-color: #262626;
}

.dark .instagram .insta-contant2 .insta-title {
    background-color: #1b1b1b;
}

.dark .instagram .insta-contant2 .insta-title h4 {
    color: #e7eaec;
}

.dark .title1 {
    background-color: #262626;
}

.dark .title1 h4 {
    color: #cfd4da;
}

.dark .title2 {
    background-color: #262626;
}

.dark .title2 h4 {
    color: #cfd4da;
}

.dark .title3 {
    background-color: #1b1b1b;
}

.dark .title3 h4 {
    color: #cfd4da;
}

.dark .title4 {
    background-color: #262626;
}

.dark .title4 h4 {
    color: #cfd4da;
}

.dark .title5 {
    background-color: #262626;
}

.dark .title5 h4 {
    color: #cfd4da;
}

.dark .title4 {
    background-color: #1b1b1b;
}

.dark .title6 {
    background-color: #1b1b1b;
}

.dark .title6 h4 {
    color: #cfd4da;
}

.dark .title7 {
    background-color: #262626;
}

.dark .title7 h4 {
    color: #cfd4da;
}

.dark .newsletter {
    background-color: #262626;
}

.dark .newsletter .news-leble {
    border-color: #3c3c3c;
}

.dark .newsletter .news-leble .news-text {
    color: #e7eaec;
}

.dark .key-feature .key-feature-box {
    background-color: #1b1b1b;
    border-color: #3c3c3c;
}

.dark .key-feature .key-feature-box .key-feature-detail h4 {
    color: #f1f3f5;
}

.dark ul.sosiyal li {
    background-color: #1b1b1b;
}

.dark ul.sosiyal li:hover {
    background-color: #042c49;
}

.dark .footer-title h5 {
    color: #f1f3f5;
}

.dark .footer-contant p {
    color: #eaedef;
}

.dark .footer-contant ul li a {
    color: #e7eaec;
}

.dark .footer-contant .news-letter .input-group .form-control {
    color: #e7eaec;
}

.dark .footer-contant .news-letter .input-group .form-control:focus {
    background-color: #262626;
}

.dark .footer-contant .newsletter-second .form-group .form-control {
    color: #f1f3f5;
}

.dark .footer-contant .newsletter-second .form-group .form-control:focus {
    background-color: #262626;
}

.dark .footer1 {
    background-color: #1b1b1b;
}

.dark .footer1 .footer-main .footer-box ul.sosiyal li {
    background-color: #262626;
}

.dark .footer1 .footer-main .footer-box ul.sosiyal li:hover {
    background-color: #042c49;
}

.dark .subfooter {
    background-color: #262626;
}

.dark .subfooter.footer-border {
    border-color: #3c3c3c;
}

.dark .subfooter.dark-footer {
    background-color: #262626;
}

.dark .invoice-popup {
    background-color: #262626;
}

.dark .cookie-bar.left-bottom,
.dark .cookie-bar.right-bottom {
    background-color: #262626;
}

.dark .cookie-bar.left-bottom svg,
.dark .cookie-bar.right-bottom svg {
    fill: #e7eaec;
}

.dark .cookie-bar.left-bottom .btn-close,
.dark .cookie-bar.right-bottom .btn-close {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.dark .cookie-bar.left-bottom p,
.dark .cookie-bar.right-bottom p {
    color: #dee2e6;
}

.dark .page-link {
    background-color: #1b1b1b;
    border-color: #3c3c3c;
}

.dark .collection-collapse-block {
    border-color: #3c3c3c;
}

.dark .collection-collapse-block .collapse-block-title {
    color: #e7eaec;
}

.dark .collection-filter {
    border-color: #3c3c3c;
}

.dark .collection-filter-block {
    border-color: #3c3c3c;
}

.dark .collection-filter-block .collapse-block-title {
    color: #dee2e6;
}

.dark .collection-filter-block .collapse-block-title::after {
    color: #f1f3f5;
}

.dark .collection-filter-block .collection-collapse-block-content .collection-brand-filter .collection-filter-checkbox label {
    color: #cfd4da;
}

.dark .collection-filter-block .collection-collapse-block-content .category-list li a {
    color: #cfd4da;
}

.dark .collection-filter-block .product-service .media {
    border-color: #3c3c3c;
}

.dark .collection-filter-block .product-service .media .media-body h4 {
    color: #dee2e6;
}

.dark .top-banner-wrapper .top-banner-content h4 {
    color: #e7eaec;
}

.dark .top-banner-wrapper .top-banner-content h5 {
    color: #dee2e6;
}

.dark .top-banner-wrapper .top-banner-content p {
    color: #e7eaec;
}

.dark .collection-product-wrapper .product-top-filter {
    border-color: #3c3c3c;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .search-count,
.dark .collection-product-wrapper .product-top-filter .popup-filter .search-count {
    border-color: #3c3c3c;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .search-count h5,
.dark .collection-product-wrapper .product-top-filter .popup-filter .search-count h5 {
    color: #e7eaec;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .collection-grid-view,
.dark .collection-product-wrapper .product-top-filter .popup-filter .collection-grid-view {
    border-color: #3c3c3c;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .collection-view ul li i,
.dark .collection-product-wrapper .product-top-filter .popup-filter .collection-view ul li i {
    color: #e7eaec;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
.dark .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view select {
    color: #e7eaec;
    border-color: #3c3c3c;
    background-color: #1b1b1b;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content select,
.dark .collection-product-wrapper .product-top-filter .popup-filter select {
    background-color: #1b1b1b;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter-toggle,
.dark .collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter-toggle {
    border-color: #3c3c3c;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter-toggle h4,
.dark .collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter-toggle h4 {
    color: #dee2e6;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter,
.dark .collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter {
    background-color: #262626;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .horizontal-filter .horizontal-filter-contain .filter-group,
.dark .collection-product-wrapper .product-top-filter .popup-filter.horizontal-filter-mian .horizontal-filter .horizontal-filter-contain .filter-group {
    border-color: #3c3c3c;
}

.dark .collection-product-wrapper .product-top-filter .product-filter-content .open-popup,
.dark .collection-product-wrapper .product-top-filter .popup-filter .open-popup {
    border-color: #3c3c3c;
}

.dark .collection-product-wrapper .product-top-filter .sidebar-popup {
    border-color: #3c3c3c;
}

.dark .collection-product-wrapper .product-top-filter .sidebar-popup a {
    color: #e7eaec;
}

.dark .collection-product-wrapper .product-top-filter .product-wrapper-grid.list-view .product-box {
    background-color: #262626;
}

.dark .product-wrapper-grid.list-view .product-box {
    background-color: #262626;
}

.dark .theme-card .offer-slider .media .media-body h6 {
    color: #cfd4da;
}

.dark .theme-card .offer-slider .media .media-body h4 {
    color: #e7eaec;
}

.dark .theme-card .offer-slider .media .popup-filter .sidebar-popup a {
    color: #e7eaec;
}

.dark .theme-card .offer-slider .media .popup-filter .collection-view ul li {
    color: #e7eaec;
}

.dark .theme-card h5.title-border {
    border-color: #3c3c3c;
}

.dark .theme-card input,
.dark .theme-card textarea,
.dark .theme-card select {
    color: #e7eaec;
}

.dark .product-pagination {
    border-color: #3c3c3c;
}

.dark .product-pagination .pagination {
    border-color: #3c3c3c;
}

.dark .product-pagination .pagination .page-item.active a {
    background-color: #262626;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-color: #3c3c3c;
}

.dark .product-pagination .pagination .page-item a {
    color: #e7eaec;
    border-color: #3c3c3c;
}

.dark .product-pagination .pagination .page-item:hover {
    border-color: #3c3c3c;
}

.dark .product-pagination .product-search-count-bottom {
    border-color: #3c3c3c;
}

.dark .product-pagination .product-search-count-bottom h5 {
    color: #e7eaec;
}

.dark .filter-slide .irs--flat .irs-line {
    background-color: #3c3c3c;
}

.dark .color-selector ul li {
    color: #e7eaec;
}

.dark .color-selector ul li>div {
    border-color: #3c3c3c;
}

.dark .nav-tabs {
    border-color: #3c3c3c;
}

.dark .dashboard .box .box-title {
    border-color: #3c3c3c;
}

.dark .qty-box .input-group {
    border-color: #3c3c3c;
}

.dark .qty-box .input-group .form-control {
    background-color: #262626;
    color: #cfd4da;
    border-color: #cfd4da;
}

.dark .qty-box .input-group button {
    border-color: #3c3c3c;
    background-color: #262626 !important;
}

.dark .qty-box .input-group button i {
    color: #cfd4da;
}

.dark .qty-box .input-group button:before,
.dark .qty-box .input-group button:after {
    color: #e7eaec;
}

.dark .size-box ul li {
    border-color: #3c3c3c;
}

.dark .size-box ul li a {
    color: #e7eaec;
}

.dark .size-box ul li.active {
    background-color: #3c3c3c;
}

.dark .product-right .pro-group:nth-child(n+2) {
    border-color: #3c3c3c;
}

.dark .product-right .pro-group h2 {
    color: #eaedef;
}

.dark .product-right .pro-group .timer p span {
    background-color: #262626;
    border-color: #3c3c3c;
    color: #e7eaec;
}

.dark .product-right .pro-group ul.pro-price li span {
    color: #cfd4da;
}

.dark .product-right .pro-group ul.best-seller li {
    background-color: #262626;
    color: #e7eaec;
}

.dark .product-right .pro-group ul.delivery-services li {
    border-color: #262626;
    color: #e7eaec;
}

.dark .product-right .pro-group .delivery-detail .delivery-detail-contian .input-group .form-control {
    color: #e7eaec;
}

.dark .product-right .pro-group .delivery-detail .delivery-detail-contian .input-group .form-control:focus {
    background-color: #262626;
}

.dark .product-right .pro-group .delivery-detail .delivery-detail-contian .input-group .input-group-text {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .product-right .pro-group .delivery-detail .delivery-detail-contian .input-group .input-group-text i {
    color: #e7eaec;
}

.dark .product-right .pro-group .delivery-detail .delivery-lable {
    background-color: #262626;
}

.dark .product-right .pro-group .product-social li a {
    color: #e7eaec;
    border-color: #3c3c3c;
}

.dark .product-right.product-form-box {
    background-color: #262626;
}

.dark .pro_sticky_info {
    border-color: #3c3c3c;
}

.dark .bottom-cart-sticky {
    background-color: #262626;
}

.dark .bottom-cart-sticky .product-image .content h5 {
    color: #e7eaec;
}

.dark .bottom-cart-sticky .product-image .content h6 {
    color: #dee2e6;
}

.dark .bottom-cart-sticky .selection-section .form-control {
    background-color: #1b1b1b;
    border-color: #3c3c3c;
    color: #e7eaec;
}

.dark .tab-product .nav-material.nav-tabs .nav-item .nav-link {
    color: #dee2e6;
}

.dark .tab-product .nav-material.nav-tabs .nav-item .nav-link.active {
    color: #042c49;
}

.dark .tab-product .theme-form input {
    background-color: #262626;
}

.dark .tab-product .theme-form input::-webkit-input-placeholder {
    color: #cfd4da;
}

.dark .tab-product .theme-form input::-moz-placeholder {
    color: #cfd4da;
}

.dark .tab-product .theme-form input:-ms-input-placeholder {
    color: #cfd4da;
}

.dark .tab-product .theme-form input::-ms-input-placeholder {
    color: #cfd4da;
}

.dark .tab-product .theme-form input::placeholder {
    color: #cfd4da;
}

.dark .tab-product .theme-form textarea {
    background-color: #262626;
}

.dark .tab-product .theme-form textarea::-webkit-input-placeholder {
    color: #cfd4da;
}

.dark .tab-product .theme-form textarea::-moz-placeholder {
    color: #cfd4da;
}

.dark .tab-product .theme-form textarea:-ms-input-placeholder {
    color: #cfd4da;
}

.dark .tab-product .theme-form textarea::-ms-input-placeholder {
    color: #cfd4da;
}

.dark .tab-product .theme-form textarea::placeholder {
    color: #cfd4da;
}

.dark .single-product-tables table tr td {
    color: #e7eaec;
}

.dark .product-related h2 {
    color: #eaedef;
    border-color: #3c3c3c;
}

.dark .product-accordion .card-header {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .product-accordion .card-header h5 {
    color: #e7eaec;
}

.dark .product-accordion .card-body {
    background-color: #262626;
}

.dark .product-accordion .btn-link {
    color: #e7eaec;
}

.dark .bundle .bundle_detail .price_product {
    color: #cfd4da;
}

.dark .tab-border {
    border-color: #3c3c3c;
}

.dark .tab-border .nav-border {
    border-color: #3c3c3c;
}

.dark .card {
    border-color: #3c3c3c;
}

.dark .card-header {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .card-body {
    background-color: #262626;
}

.dark .blog-page .blog-sidebar .theme-card {
    background-color: #1b1b1b;
    border-color: #262626;
}

.dark .blog-page .blog-sidebar h4 {
    color: #e7eaec;
}

.dark .blog-page .blog-sidebar h6 {
    color: #dee2e6;
}

.dark .blog-page .blog-sidebar p {
    color: #cfd4da;
}

.dark .blog-page .blog-media {
    background-color: #1b1b1b;
    border-color: #262626;
}

.dark .blog-page .blog-media .blog-right h4 {
    color: #dee2e6;
}

.dark .blog-page .blog-media .blog-right p {
    color: #cfd4da;
}

.dark .blog-detail-page .blog-detail .post-social {
    border-color: #3c3c3c;
}

.dark .blog-detail-page .blog-detail h3 {
    color: #e7eaec;
}

.dark .blog-detail-page .blog-detail p {
    color: #cfd4da;
}

.dark .blog-detail-page .blog-advance ul li {
    color: #e7eaec;
}

.dark .blog-detail-page .comment-section {
    border-color: #3c3c3c;
}

.dark .blog-detail-page .comment-section li h6 {
    color: #dee2e6;
}

.dark .blog-detail-page .comment-section li span {
    color: #e7eaec;
}

.dark .blog-detail-page .blog-contact h2 {
    color: #e7eaec;
}

.dark .blog-detail-page .blog-contact .theme-form input,
.dark .blog-detail-page .blog-contact .theme-form textarea {
    background-color: #262626;
}

.dark .cart-section .cart-table thead th,
.dark .wishlist-section .cart-table thead th {
    border-color: #3c3c3c !important;
    color: #dee2e6;
}

.dark .cart-section tbody tr td,
.dark .wishlist-section tbody tr td {
    border-color: #3c3c3c !important;
    color: #cfd4da;
}

.dark .cart-section tbody tr td a,
.dark .cart-section tbody tr td p,
.dark .cart-section tbody tr td h2,
.dark .wishlist-section tbody tr td a,
.dark .wishlist-section tbody tr td p,
.dark .wishlist-section tbody tr td h2 {
    color: #cfd4da;
}

.dark .team h2 {
    border-color: #3c3c3c;
}

.dark .dashboard-left {
    background-color: #262626;
}

.dark .dashboard-left .block-content ul li.active a {
    color: #042c49;
}

.dark .dashboard-left .block-content ul li a {
    color: #cfd4da;
}

.dark .dashboard-right .dashboard {
    background-color: #262626;
}

.dark .dashboard-right .dashboard .page-title h2 {
    color: #e7eaec;
}

.dark .dashboard .box-head h2 {
    color: #e7eaec;
}

.dark .dashboard .box .box-title h3 {
    color: #e7eaec;
}

.dark .login-page .theme-card {
    border-color: #3c3c3c;
    background-color: #262626;
}

.dark .login-page .theme-card input {
    background-color: #262626;
}

.dark .login-page .theme-card .theme-form {
    background-color: #1b1b1b;
}

.dark .login-page .authentication-right h6 {
    color: #dee2e6;
}

.dark .login-page .authentication-right p {
    color: #cfd4da;
}

.dark .contact-page .theme-form {
    background-color: #1b1b1b;
    border-color: #262626;
}

.dark .contact-page .theme-form input,
.dark .contact-page .theme-form textarea,
.dark .contact-page .theme-form select {
    background-color: #262626;
    border-color: #3c3c3c;
    color: #e7eaec;
}

.dark .contact-page .theme-form input:focus,
.dark .contact-page .theme-form textarea:focus,
.dark .contact-page .theme-form select:focus {
    background-color: #262626;
    border-color: #3c3c3c;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.dark .contact-page .map .theme-card {
    border-color: #3c3c3c;
}

.dark .register-page .theme-card {
    border-color: #3c3c3c;
}

.dark .register-page .theme-card .theme-form input {
    background-color: #262626;
}

.dark .contact-page .contact-right ul li p {
    color: #dee2e6;
}

.dark .checkout-page .checkout-title h3 {
    color: #dee2e6;
}

.dark .checkout-page .checkout-form .form-group .field-label {
    color: #e7eaec;
}

.dark .checkout-page .checkout-form .checkout-details {
    background-color: #262626;
}

.dark .checkout-page .checkout-form select {
    border-color: #3c3c3c;
}

.dark .checkout-second .checkout-box {
    background-color: #262626;
}

.dark .checkout-second .checkout-box .checkout-header h2 {
    color: #eaedef;
}

.dark .checkout-second .checkout-box .checkout-header h4 {
    color: #cfd4da;
}

.dark .checkout-second .checkout-box .checkout-body .checkout-form .menu-bar {
    background-color: #2d2d2d;
}

.dark .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card h3.form-title {
    color: #e7eaec;
}

.dark .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .form-control {
    border-color: #3c3c3c;
    color: #cfd4da;
}

.dark .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group .form-control:focus {
    background-color: #1b1b1b;
}

.dark .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .form-group select {
    background-color: #262626 !important;
}

.dark .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card ul.payment-info li {
    border-color: #3c3c3c;
}

.dark .checkout-second .checkout-box .checkout-body .checkout-form .checkout-fr-box .form-card .payment-success {
    background-color: #2d2d2d;
}

.dark .paymant-offer-mian {
    background-color: #262626;
}

.dark .order-box .title-box {
    color: #dee2e6;
    border-color: #3c3c3c;
}

.dark .order-box .qty,
.dark .order-box .sub-total {
    border-color: #3c3c3c;
}

.dark .order-box .qty li,
.dark .order-box .sub-total li {
    color: #e7eaec;
}

.dark .order-box .qty li span,
.dark .order-box .sub-total li span {
    color: #e7eaec;
}

.dark .order-box .total li {
    color: #e7eaec;
}

.dark .review-page .comnt-sec li a {
    color: #cfd4da;
}

.dark .review-page .comnt-sec li a i {
    color: #cfd4da;
}

.dark .review-box {
    background-color: #262626;
}

.dark .product-order .product-order-detail .order_detail h4 {
    color: #e7eaec;
}

.dark .product-order .total-sec {
    border-color: #3c3c3c;
}

.dark .product-order .total-sec ul li {
    color: #cfd4da;
}

.dark .delivery-sec {
    background-color: #262626;
}

.dark .order-history .dark-data {
    color: #eaedef;
}

.dark .light-layout {
    background-color: #262626;
}

.dark .order-tracking #progressbar li {
    background-color: #1b1b1b;
}

.dark .order-tracking #progressbar li span {
    color: #e7eaec;
}

.dark .order-tracking #progressbar li.active span {
    color: #042c49;
}

.dark .order-tracking h4.tracking-title {
    color: #e7eaec;
}

.dark .order-tracking .order-tracking-box {
    background-color: #262626;
}

.dark .order-tracking .order-tracking-box .form-group input,
.dark .order-tracking .order-tracking-box .form-group textarea,
.dark .order-tracking .order-tracking-box .form-group select {
    border-color: #3c3c3c;
    color: #e7eaec;
    background-color: #262626;
}

.dark .order-tracking .order-tracking-box .form-group input:focus,
.dark .order-tracking .order-tracking-box .form-group textarea:focus,
.dark .order-tracking .order-tracking-box .form-group select:focus {
    background-color: #262626;
}

.dark .order-tracking .order-tracking-box .paymant-collapce {
    background-color: #262626;
}

.dark .order-tracking .order-tracking-box .paymant-collapce ul.upi-pay li .form-control {
    background-color: #262626;
    color: #e7eaec;
}

.dark .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li {
    background-color: #1b1b1b;
}

.dark .order-tracking .order-tracking-box .paymant-collapce ul.bank-pay li span {
    color: #e7eaec;
}

.dark .order-tracking .order-tracking-box .paymant-collapce .cash-pay .form-control {
    color: #e7eaec;
}

.dark .order-tracking .order-tracking-box .paymant-collapce .cash-pay .form-control:focus {
    background-color: #262626;
}

.dark .order-tracking .order-tracking-box .paymant-collapce .cash-pay .captchabox .reloadbtncapcha svg {
    fill: #e7eaec;
}

.dark .order-tracking .order-tracking-box .paymant-collapce select {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .order-tracking .order-tracking-box .card {
    border-color: #3c3c3c;
}

.dark .order-tracking .order-tracking-contain .tracking-group ul.may-product>li {
    border-color: #3c3c3c;
}

.dark .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body h3 {
    color: #e7eaec;
}

.dark .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .media-body h6 {
    color: #dee2e6;
}

.dark .order-tracking .order-tracking-contain .tracking-group ul.may-product>li .media .pro-add a svg {
    color: #dee2e6;
}

.dark .order-tracking .order-tracking-contain .tracking-group .delevery-code h4 {
    color: #e7eaec;
}

.dark .order-tracking .order-tracking-contain .tracking-group .delevery-code .btn {
    background-color: #262626;
    color: #e7eaec !important;
}

.dark .order-tracking .order-tracking-contain .tracking-group:nth-child(n+2) {
    border-color: #3c3c3c;
}

.dark .order-tracking .order-tracking-sidebar .coupan-block {
    border-color: #3c3c3c;
}

.dark .order-tracking .order-tracking-sidebar .coupan-block h5 {
    color: #e7eaec;
}

.dark .order-tracking .order-tracking-sidebar .coupan-block .btn {
    background-color: #262626;
    color: #e7eaec !important;
}

.dark .order-tracking .order-tracking-sidebar ul li {
    color: #e7eaec;
}

.dark .order-tracking .order-tracking-sidebar ul li .total {
    color: #e7eaec;
    border-color: #3c3c3c;
}

.dark .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info {
    background-color: #262626;
}

.dark .lookbook .lookbook-block .lookbook-dot .dot-showbox .dot-info h5 {
    color: #cfd4da;
}

.dark .faq-section .accordion.theme-accordion .card {
    border-color: #3c3c3c;
}

.dark .faq-section .accordion.theme-accordion .card .card-header {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .faq-section .accordion.theme-accordion .card .card-header button {
    color: #dee2e6;
}

.dark .faq-section .accordion.theme-accordion .card .card-header button::before {
    border-top-color: #dee2e6;
}

.dark .faq-section .accordion.theme-accordion .card .card-body {
    background-color: #1b1b1b;
}

.dark .faq-section .accordion.theme-accordion .card .card-body p {
    color: #cfd4da;
}

.dark .faq-section .accordion-item .accordion-header .accordion-button {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .faq-section .accordion-item .accordion-collapse {
    border-color: #3c3c3c;
}

.dark .layout-5 .media-banner.media-banner-1 {
    background-color: #1b1b1b;
}

.dark .theme-tab .tab-title a,
.dark .theme-tab .tab-title2 a {
    color: #dee2e6;
}

.dark .theme-tab .tab-title.current,
.dark .theme-tab .tab-title2.current {
    background-color: #ffffff;
}

.dark .theme-tab .tab-title.current:before,
.dark .theme-tab .tab-title2.current:before {
    background-color: #ffffff;
}

.dark .theme-tab .tab-title.current a,
.dark .theme-tab .tab-title2.current a {
    color: #042c49;
    background-color: #262626;
}

.dark .tab-bg {
    background-color: #262626;
}

.dark .center-slider {
    border-color: #3c3c3c;
}

.dark .portfolio-section .isotopeSelector .overlay {
    border-color: #3c3c3c;
}

.dark .portfolio-section .filter-button {
    background-color: #262626;
    color: #e7eaec;
}

.dark .portfolio-section .filter-button.active {
    background-color: #042c49;
}

.dark .pixelstrap ul a,
.dark .pixelstrap ul a:hover,
.dark .pixelstrap ul a:focus,
.dark .pixelstrap ul a:active {
    color: #e7eaec !important;
}

.dark .testimonial .testimonial-contain .media .media-body p {
    color: #ffffff !important;
}

.dark .testimonial2 .testimonial-box {
    background-color: #262626;
}

.dark .testimonial2 .testimonial-box .testimonial-detail p {
    color: #ffffff;
}

.dark .testimonial3 .testimonial-box {
    background-color: #262626;
}

.dark .testimonial3 .testimonial-box .testimonial-detail p {
    color: #eaedef;
}

.dark .testimonial6 .testimonial-box {
    background-color: #1b1b1b;
}

.dark .testimonial6 .testimonial-box .img-wrapper {
    border-color: #262626;
}

.dark .testimonial6 .testimonial-box .img-wrapper img {
    border-color: #1b1b1b;
}

.dark .testimonial6 .testimonial-box .testimonial-detail p {
    color: #e7eaec;
}

.dark .add_to_cart,
.dark .add_to_cart.bottom {
    border-color: #3c3c3c;
}

.dark .add_to_cart.top .cart-inner,
.dark .add_to_cart.bottom .cart-inner,
.dark .add_to_cart.left .cart-inner,
.dark .add_to_cart.right .cart-inner,
.dark .add_to_cart.bottom.top .cart-inner,
.dark .add_to_cart.bottom.bottom .cart-inner,
.dark .add_to_cart.bottom.left .cart-inner,
.dark .add_to_cart.bottom.right .cart-inner {
    background-color: #1b1b1b;
}

.dark .add_to_cart.top .cart-inner .cart_top,
.dark .add_to_cart.bottom .cart-inner .cart_top,
.dark .add_to_cart.left .cart-inner .cart_top,
.dark .add_to_cart.right .cart-inner .cart_top,
.dark .add_to_cart.bottom.top .cart-inner .cart_top,
.dark .add_to_cart.bottom.bottom .cart-inner .cart_top,
.dark .add_to_cart.bottom.left .cart-inner .cart_top,
.dark .add_to_cart.bottom.right .cart-inner .cart_top {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .add_to_cart.top .cart-inner .cart_top h3,
.dark .add_to_cart.bottom .cart-inner .cart_top h3,
.dark .add_to_cart.left .cart-inner .cart_top h3,
.dark .add_to_cart.right .cart-inner .cart_top h3,
.dark .add_to_cart.bottom.top .cart-inner .cart_top h3,
.dark .add_to_cart.bottom.bottom .cart-inner .cart_top h3,
.dark .add_to_cart.bottom.left .cart-inner .cart_top h3,
.dark .add_to_cart.bottom.right .cart-inner .cart_top h3 {
    color: #e7eaec;
}

.dark .add_to_cart.top .cart-inner .cart_top .close-cart i,
.dark .add_to_cart.bottom .cart-inner .cart_top .close-cart i,
.dark .add_to_cart.left .cart-inner .cart_top .close-cart i,
.dark .add_to_cart.right .cart-inner .cart_top .close-cart i,
.dark .add_to_cart.bottom.top .cart-inner .cart_top .close-cart i,
.dark .add_to_cart.bottom.bottom .cart-inner .cart_top .close-cart i,
.dark .add_to_cart.bottom.left .cart-inner .cart_top .close-cart i,
.dark .add_to_cart.bottom.right .cart-inner .cart_top .close-cart i {
    color: #e7eaec;
}

.dark .add_to_cart.top .cart-inner .cart_media li .media .media-body h4,
.dark .add_to_cart.bottom .cart-inner .cart_media li .media .media-body h4,
.dark .add_to_cart.left .cart-inner .cart_media li .media .media-body h4,
.dark .add_to_cart.right .cart-inner .cart_media li .media .media-body h4,
.dark .add_to_cart.bottom.top .cart-inner .cart_media li .media .media-body h4,
.dark .add_to_cart.bottom.bottom .cart-inner .cart_media li .media .media-body h4,
.dark .add_to_cart.bottom.left .cart-inner .cart_media li .media .media-body h4,
.dark .add_to_cart.bottom.right .cart-inner .cart_media li .media .media-body h4 {
    color: #eaedef;
}

.dark .add_to_cart.top .cart-inner .cart_media li .media .media-body h4 span,
.dark .add_to_cart.bottom .cart-inner .cart_media li .media .media-body h4 span,
.dark .add_to_cart.left .cart-inner .cart_media li .media .media-body h4 span,
.dark .add_to_cart.right .cart-inner .cart_media li .media .media-body h4 span,
.dark .add_to_cart.bottom.top .cart-inner .cart_media li .media .media-body h4 span,
.dark .add_to_cart.bottom.bottom .cart-inner .cart_media li .media .media-body h4 span,
.dark .add_to_cart.bottom.left .cart-inner .cart_media li .media .media-body h4 span,
.dark .add_to_cart.bottom.right .cart-inner .cart_media li .media .media-body h4 span {
    color: #e7eaec;
}

.dark .add_to_cart.top .cart-inner .cart_media .close-circle i,
.dark .add_to_cart.bottom .cart-inner .cart_media .close-circle i,
.dark .add_to_cart.left .cart-inner .cart_media .close-circle i,
.dark .add_to_cart.right .cart-inner .cart_media .close-circle i,
.dark .add_to_cart.bottom.top .cart-inner .cart_media .close-circle i,
.dark .add_to_cart.bottom.bottom .cart-inner .cart_media .close-circle i,
.dark .add_to_cart.bottom.left .cart-inner .cart_media .close-circle i,
.dark .add_to_cart.bottom.right .cart-inner .cart_media .close-circle i {
    color: #eaedef;
}

.dark .add_to_cart.top .cart-inner .cart_media .total,
.dark .add_to_cart.bottom .cart-inner .cart_media .total,
.dark .add_to_cart.left .cart-inner .cart_media .total,
.dark .add_to_cart.right .cart-inner .cart_media .total,
.dark .add_to_cart.bottom.top .cart-inner .cart_media .total,
.dark .add_to_cart.bottom.bottom .cart-inner .cart_media .total,
.dark .add_to_cart.bottom.left .cart-inner .cart_media .total,
.dark .add_to_cart.bottom.right .cart-inner .cart_media .total {
    border-color: #3c3c3c;
}

.dark .add_to_cart.top .cart_media .cart_product li,
.dark .add_to_cart.bottom .cart_media .cart_product li,
.dark .add_to_cart.left .cart_media .cart_product li,
.dark .add_to_cart.right .cart_media .cart_product li,
.dark .add_to_cart.bottom.top .cart_media .cart_product li,
.dark .add_to_cart.bottom.bottom .cart_media .cart_product li,
.dark .add_to_cart.bottom.left .cart_media .cart_product li,
.dark .add_to_cart.bottom.right .cart_media .cart_product li {
    border-color: #3c3c3c;
}

.dark .add_to_cart .setting-block h5,
.dark .add_to_cart.bottom .setting-block h5 {
    color: #e7eaec;
}

.dark .add_to_cart .setting-block ul li a,
.dark .add_to_cart.bottom .setting-block ul li a {
    color: #dee2e6;
}

.dark .add_to_cart .cart-inner .cart_media ul.cart_product li {
    border-color: #3c3c3c;
    color: #e7eaec;
}

.dark .add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body h6 {
    color: #dee2e6;
}

.dark .add_to_cart .cart-inner .cart_media ul.cart_product li .media .media-body .addit-box .pro-add svg {
    color: #cfd4da;
}

.dark .add_to_cart .cart-inner .cart_media ul.cart_total li {
    color: #e7eaec;
}

.dark .add_to_cart .cart-inner .cart_media ul.cart_total li .total {
    border-color: #3c3c3c;
}

.dark .add_to_cart .cart-inner .cart_media ul.cart_total li .total h5 span {
    color: #dee2e6;
}

.dark .add_to_cart .setting-block .form-group select {
    border-color: #3c3c3c;
    background-color: #262626;
}

.dark .account-bar .theme-form .forget-class a {
    color: #cfd4da;
}

.dark .search-overlay {
    background-color: #262626;
}

.dark .search-block .form-header .input-group input {
    color: #e7eaec;
}

.dark .search-block .form-header .input-group input:focus {
    background-color: #262626;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #3c3c3c;
}

.dark .modal-header {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .modal-header .btn-close {
    -webkit-filter: invert(1);
    filter: invert(1);
    opacity: 1;
}

.dark .modal-content {
    background-color: #262626;
}

.dark .theme-modal#exampleModal .offer-content {
    background-color: #262626;
}

.dark .theme-modal .modal-dialog .quick-view-modal button.btn-close,
.dark .theme-modal .demo_modal .quick-view-modal button.btn-close {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg,
.dark .theme-modal .demo_modal .modal-content .modal-body .modal-bg {
    background-color: #262626;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .btn-close,
.dark .theme-modal .demo_modal .modal-content .modal-body .modal-bg .btn-close {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content h2,
.dark .theme-modal .demo_modal .modal-content .modal-body .modal-bg .offer-content h2 {
    color: #eaedef;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content p,
.dark .theme-modal .demo_modal .modal-content .modal-body .modal-bg .offer-content p {
    color: #cfd4da;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .img-wrapper,
.dark .theme-modal .demo_modal .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .img-wrapper {
    -webkit-box-shadow: 0 0 5px rgba(60, 60, 60, 0.6);
    box-shadow: 0 0 5px rgba(60, 60, 60, 0.6);
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6,
.dark .theme-modal .demo_modal .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h6 {
    color: #dee2e6;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h4 span,
.dark .theme-modal .demo_modal .modal-content .modal-body .modal-bg.addtocart #upsell_product .product-box .product-detail h4 span {
    color: #dee2e6;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .product-upsell h4,
.dark .theme-modal .demo_modal .modal-content .modal-body .product-upsell h4 {
    color: #e7eaec;
}

.dark .theme-modal#exampleModal .offer-content p {
    color: #cfd4da;
}

.dark .theme-modal#exampleModal .close span {
    color: #e7eaec;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body {
    background-color: #262626;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control {
    color: #e7eaec;
}

.dark .theme-modal .modal-dialog .modal-content .modal-body .modal-bg .offer-content form .form-group .form-control:focus {
    background-color: #262626;
}

.dark .theme-modal .modal-dialog .demo_modal button.close span,
.dark .theme-modal .modal-dialog .quick-view-modal button.close span {
    color: #f1f3f5;
}

.dark .typography_section .typography-box .typo-content .sub-title {
    color: #e7eaec;
}

.dark .typography_section .typography-box .typo-content.heading_content h1,
.dark .typography_section .typography-box .typo-content.heading_content h2,
.dark .typography_section .typography-box .typo-content.heading_content h3,
.dark .typography_section .typography-box .typo-content.heading_content h4,
.dark .typography_section .typography-box .typo-content.heading_content h5,
.dark .typography_section .typography-box .typo-content.heading_content h6 {
    color: #dee2e6;
}

.dark .typography_section .typography-box .headings {
    background-color: #262626;
}

.dark .typography_section .typography-box .headings h3 {
    color: #e7eaec;
}

.dark .typography_section .typography-box .headings span {
    color: #e7eaec;
}

.dark .compare-page .table-wrapper .table {
    border-color: #3c3c3c;
}

.dark .compare-page .table-wrapper .table thead,
.dark .compare-page .table-wrapper .table tbody,
.dark .compare-page .table-wrapper .table tfoot,
.dark .compare-page .table-wrapper .table tr,
.dark .compare-page .table-wrapper .table td,
.dark .compare-page .table-wrapper .table th {
    border-color: #3c3c3c !important;
}

.dark .compare-page .table-wrapper .table thead .th-compare th {
    border-color: #3c3c3c !important;
}

.dark .compare-page .table-wrapper .table thead .th-compare td {
    background-color: #262626;
    color: #dee2e6;
    border-color: #3c3c3c;
}

.dark .compare-page .table-wrapper .table thead tr {
    border-color: #3c3c3c;
}

.dark .compare-page .table-wrapper .table thead tr td,
.dark .compare-page .table-wrapper .table thead tr th {
    background-color: #262626;
    color: #dee2e6;
    border-color: #3c3c3c;
}

.dark .compare-page .table-wrapper .table thead tr th .remove-compare {
    color: #dee2e6;
}

.dark .compare-page .table-wrapper .table tbody tr {
    border-color: #3c3c3c;
}

.dark .compare-page .table-wrapper .table tbody tr th,
.dark .compare-page .table-wrapper .table tbody tr td {
    border-color: #3c3c3c;
    background-color: #262626;
    color: #dee2e6;
}

.dark .compare-page .table-wrapper .table tbody tr p {
    color: #cfd4da;
}

.dark .compare-section .slick-slide:first-child {
    border-color: #3c3c3c;
}

.dark .compare-section .slick-slide>div {
    border-color: #3c3c3c;
}

.dark .compare-section .compare-part .close-btn {
    color: #e7eaec;
}

.dark .compare-section .compare-part .detail-part .title-detail {
    background-color: #262626;
    border-color: #3c3c3c;
}

.dark .compare-section .compare-part .detail-part .title-detail h5 {
    color: #e7eaec;
}

.dark .compare-section .compare-part .img-secton a h5 {
    color: #eaedef;
}

.dark .compare-section .compare-part .btn-part {
    border-color: #3c3c3c;
}

.dark .collection .collection-block .collection-content h4 {
    color: #e7eaec;
}

.dark .collection .collection-block .collection-content h3 {
    color: #eaedef;
}

.dark .blog-page .blog-sidebar .theme-card {
    background-color: #262626;
}

.dark .blog-page .blog-media {
    background-color: #262626;
}

@media (max-width: 1470px) {
    .dark .header-search .input-group .input-group-text select {
        padding-left: 30px;
        padding-right: 15px;
    }

    .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter {
        border-color: #3c3c3c;
    }

    .dark .collection-product-wrapper .product-top-filter .product-filter-content .collection-view {
        border-color: #3c3c3c;
    }
}

@media (max-width: 1199px) {

    .dark .sm-horizontal,
    .dark .sm-vertical {
        background-color: #262626;
        border-color: #3c3c3c;
    }

    .dark .sm-horizontal .mobile-back,
    .dark .sm-vertical .mobile-back {
        color: #e7eaec;
        border-bottom-color: #3c3c3c;
    }

    .dark .category-header-4 {
        background-color: #262626;
    }

    .dark .category-header-4 .navbar-menu .category-right .icon-block ul li svg {
        fill: #cbcbcb;
    }
}

@media (max-width: 991px) {
    .dark .collection-filter {
        background-color: #262626;
    }

    .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-per-view select,
    .dark .collection-product-wrapper .product-top-filter .product-filter-content .product-page-filter select {
        border-color: #3c3c3c;
    }

    .dark .collection-product-wrapper .product-top-filter .product-filter-content.horizontal-filter-mian .product-page-per-view {
        border-color: #3c3c3c;
    }

    .dark .collection-product-wrapper .product-top-filter .popup-filter .product-page-per-view,
    .dark .collection-product-wrapper .product-top-filter .popup-filter .product-page-filter {
        border-color: #3c3c3c;
    }
}


@media (min-width: 1680px) {
    .layout-header2 .main-menu-block .brand-logo img {
        height: 75px;
    }
}
