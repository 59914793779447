body {
    margin: 0;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

.jumbotron.jumbotron-product-detail {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 302px;
}

.jumbotron.jumbotron-product-detail.jumbotron-video {
    padding-top: 0;
    padding-bottom: 0;
}

.jumbotron.jumbotron-product-detail.jumbotron-video>.container-fluid {
    padding-left: 0;
    padding-right: 0;
}

#featuted-image {
    position: relative;
    top: -50px;
    z-index: 2;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#featuted-image.no-jumbo {
    top: initial;
}

.cart-steps {
    border-bottom: 1px solid #f6f6f6;
    margin-bottom: 10px;

}

.cart-steps ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.cart-steps ul li {
    display: inline-block;
    border-top: 1px solid #f6f6f6;
    border-left: 1px solid #f6f6f6;
    border-right: 1px solid #f6f6f6;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    margin-right: 10px;
    font-size: 20px;
    padding: 5px 10px;
}

.cart-steps ul li>span {
    background: #c1151b;
    padding: 5px 10px;
    border-radius: 50%;
    color: #fff;
    font-weight: 700;
}

.cart-steps ul li.active {
    background: #c1151b;
    color: #fff;
}

/*~==============================~*/
/* FAQs                           */
/*~==============================~*/
body .faqs-content {
    width: 100%;
    float: left;
    margin-bottom: 70px
}

body .faqs-content .nav-tabs {
    border-bottom: 0
}

body .faqs-content .nav-tabs>li {
    width: 100%;
    margin-bottom: 10px
}

body .faqs-content .nav-tabs>li>a {
    text-transform: uppercase;
    font-size: 14px;
    background-color: #fff;
    color: #898989;
    margin-right: 0;
    border-radius: 0;
    padding: 20px 30px
}

body .faqs-content .nav-tabs>li>a,
body .faqs-content .nav-tabs>li.active>a,
body .faqs-content .nav-tabs>li.active>a:focus,
body .faqs-content .nav-tabs>li.active>a:hover {
    border: 0
}

body .faqs-content .nav-tabs>li:hover>a,
body .faqs-content .nav-tabs>li.active>a,
body .faqs-content .nav-tabs>li.active>a:focus,
body .faqs-content .nav-tabs>li.active>a:hover {
    background-color: #212121;
    color: #fff !important
}

body .faqs-content .faqs-inner {
    margin-left: -15px;
    margin-right: -15px
}

body .faqs-content .tab-content .panel-group .panel+.panel {
    margin-top: 10px
}

body .faqs-content .tab-content .panel-group .panel {
    border-radius: 0;
    box-shadow: none
}

body .faqs-content .tab-content .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: transparent
}

body .faqs-content .tab-content .panel-default>.panel-heading {
    background-color: transparent
}

body .faqs-content .tab-content .panel-body {
    padding: 0 20px 25px;
    font-size: 15px
}

body .faqs-content .tab-content .panel-heading {
    padding: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
    position: relative
}

body .faqs-content .tab-content .panel-heading .panel-title {
    font-style: normal
}

body .faqs-content .tab-content .panel-heading a {
    font-weight: 400;
    font-size: 14px
}

body .faqs-content .tab-content .panel-heading .fa-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 25px;
    margin-top: -12.5px;
    color: #bdbdbd
}

body .faqs-content .tab-content .panel-heading.active {
    border-top: 3px solid #10b7bd
}

body .faqs-content .tab-content .panel-heading.active .fa-icon {
    color: #10b7bd
}






.alert-custom {
    position: fixed;
    z-index: 99999;
    top: 30px;
    right: 30px;
    width: 500px;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.alert-custom h3 {
    margin: 0;
}

a.btn-aviso-disponibilidade {
    font-weight: normal;
    margin-left: 15px;
    text-decoration: underline;
    color: #09C2D2;
    transition: all 0.5s ease;
}

.form-availability {
    display: none;
}



input#cod-promocional {
    width: 350px;
}

.heart-red {
    color: #c0392b;
    transition: all 0.5s ease-in;
    -webkit-animation: hearth 0.7s;
    /* Safari 4.0 - 8.0 */
    animation: hearth 0.7s;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes hearth {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.3);
    }

    50% {
        transform: scale(1.7);
    }

    25% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}

/* Standard syntax */
@keyframes hearth {
    0% {
        transform: scale(1);
    }

    25% {
        transform: scale(1.3);
    }

    50% {
        transform: scale(1.7);
    }

    25% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}


.lembretes-list {
    margin-bottom: 20px;
}

.lembretes-list>.item-list,
.diasSemanaLembrete,
.diasMesLembrete {
    background-color: #FFF;
    margin-bottom: 15px;
    border-radius: 6px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.01);
    padding: 7px;
}

#dataUnicaLembrete,
#tipoLembrete {
    margin-bottom: 15px;
}

.lembretes-list>.item-list>.img {
    margin-right: 10px;
}

.lembretes-list>.item-list .btn-toogleActive,
.lembretes-list>.item-list .btn-remove {
    background-color: transparent;
    border: none;
    margin-top: 15px;
    cursor: pointer;
}

.panel-heading {
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-bottom: 1px solid transparent;
    border-color: transparent;
    border-radius: 0;
}

.panel .panel-heading.head-custom {
    font-weight: 700 !important;
    color: #fff;
    text-align: left;
    font-size: 18px !important;
    background-color: #c1151b;
    border-color: #c1151b;
    padding-top: 13px;
    padding-bottom: 13px;
}

.panel .panel-heading.head-custom h3 {
    font-weight: 700 !important;
    font-size: 18px !important;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
}

.menu-account .list-group>.list-group-item.list-group-header {
    font-weight: 700;
    color: #fff;
    text-align: center;
    font-size: 18px;
    background-color: #c1151b;
    border-color: #c1151b;
    border-radius: 0px;
}

.menu-account .list-group>.list-group-item {
    border-bottom: none;
    border-bottom-color: inherit;
    border-top: none;
    border-top-color: inherit;
}

.bg-grey {
    background-color: #f0f0f0 !important;
}


.img-payments-detail {
    max-width: 240px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.col-card-with-shadow {
    padding: 15px;
    background-color: #FFF;
    border: none;
    margin-bottom: 24px;
    border-radius: 0px;
    -webkit-box-shadow: 0 4px 16px rgba(0, 0, 0, .12);
    box-shadow: 0 4px 16px rgba(0, 0, 0, .12);
}

.col-card-with-shadow .header-col-card {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
}

.header-col-card .badge-header-col-card,
.header-col-card .title-header-col-card {
    float: left;
    box-sizing: border-box;
    margin: 0;
    display: block;
}

.header-col-card .badge-header-col-card {
    max-width: 13%;
    width: 13%;
}

.header-col-card .badge-header-col-card:before,
.header-col-card .badge-header-col-card:after {
    content: " ";
    display: table;
    clear: both;
}

.header-col-card .badge-header-col-card span {
    background-color: #c1151b;
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
    line-height: 30px;
    color: #FFF;
    font-weight: 700;
    border-radius: 100%;
}

.header-col-card .title-header-col-card {
    text-align: left;
    line-height: 30px;
    font-size: 18px;
    max-width: 87%;
    width: 87%;
}

.col-card-with-shadow h4 {
    margin: 0;
    padding: 0;
}

.btn-block {
    width: 100%;
}

.a-highlight {
    color: #c1151b;
    font-weight: 700;
    text-decoration: underline;
}


.btn.btn-1 {
    width: auto;
    /* display: block; */
    /* margin: 0 auto; */
    margin-bottom: 0px;
    /* float: none; */
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    height: 40px;
    line-height: 40px;
    color: #222;
    border-color: #222;
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;
}


.radio-morada {
    border: 1px solid;
    border-color: #F0F0F0;
    padding: 15px;
    margin-bottom: 25px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
}

.radio-morada>.input {
    padding-right: 10px;
    box-sizing: border-box;
    padding-top: 20px;
    max-width: 10%;
    width: 10%;
}

.radio-morada>.imagem {
    padding-right: 10px;
    padding-top: 10px;
    box-sizing: border-box;
}

.radio-morada>.texto {
    box-sizing: border-box;
    max-width: 90%;
    width: 90%;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.faq-icons {
    font-size: 2.5rem !important;
    color: #c1151b;
}


.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    border: none !important;
}

.border-bottom-1px {
    border-bottom: 1px solid #ccc !important;
}


.banner-brand-at-search {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 320px;
    display: none;
}

.text-brand-at-search {
    background: white;
    padding: 45px;
    margin-bottom: 30px;
    margin-top: -40px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: none;
}

.text-brand-at-search p:first-of-type::first-letter {
    color: #c1151b;
    font-weight: 700;
    font-size: 4rem;
    float: left;
    margin-top: -9px;
    margin-right: 9px;
    line-height: initial;
}

.brand-product {
    padding: 10px;
    margin-bottom: 15px;
    background-color: rgb(250, 250, 250);
    margin-top: 15px;
}

.brand-product .brand-text {
    box-sizing: border-box;
    line-height: 20px;
    height: 40px;
    overflow: hidden;
    font-size: 13px;
    position: relative;

}

.brand-product .brand-readme-more {
    background-color: transparent;
    border: none;
    padding: 10px;
    font-size: 20px;
    text-align: center;
    color: #c1151b;
    position: absolute;
    top: 5px;
    right: 8px;
    z-index: 2;
}

.brand-product .brand-readme-more.active {
    top: initial;
    bottom: 5px;
}

.row-items-center {
    display: flex;
    align-items: center;
}

.breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
    padding: 0;
    text-align: center;
    color: #FFF;
}

.breadcrumb span,
.breadcrumb strong {
    padding-left: 5px;
    padding-right: 5px;
    color: #FFF;
}

.breadcrumb a {
    color: #FFF;
}

.breadcrumb .arrow-space {
    padding-left: 0;
}


.content-account .panel-body {
    background-color: #FFFFFF;
    border-color: #ccc;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    padding-top: 30px;
    min-height: 350px;
}

.content-account .panel-title {
    font-size: 18px;
}

.menu-account .list-group {
    border-radius: 0 !important;
    min-height: 350px;
}

.menu-account .list-group .list-group-item:last-of-type {
    border-bottom: 1px solid #CCC;
    padding-bottom: 30px;
}

.menu-account .list-group .list-group-item.activo {
    background-color: rgba(4, 44, 73, .3);
    color: rgb(100, 100, 100);
}

.menu-account .list-group-header {
    padding-bottom: 0.75rem !important;
    border-bottom: none !important;
}


/*
#########
SIDEBAR
#########
 */
body .sidebar .sidebar-block {
    background-color: #fff;
    padding: 0px 15px 25px;
    width: 100%;
    float: left
}

body .sidebar .sidebar-block.filter-block {
    margin-bottom: 0px;
    padding-bottom: 30px
}

body .sidebar .sidebar-block.filter-block .title {
    margin-bottom: 13px;
    font-size: 12px
}

body .sidebar .sidebar-block.blogs-bestseller .bestseller-content .bestseller-item .product {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 10px;
    border-width: 0
}

body .sidebar .sidebar-block.blogs-bestseller .bestseller-content .bestseller-item .product:hover {
    border-width: 0
}

body .sidebar .sidebar-block.blogs-bestseller .bestseller-content .bestseller-item .product .row-left {
    width: 40%
}

body .sidebar .sidebar-block.blogs-bestseller .bestseller-content .bestseller-item .product .row-right {
    width: 60%;
    padding-left: 15px
}

body .sidebar .sidebar-block.blogs-bestseller .bestseller-content .bestseller-item .product .row-right .product-title {
    margin-bottom: 6px
}

body .sidebar .sidebar-block.blogs-bestseller .bestseller-content .bestseller-item .product .row-right .product-title .title-5 {
    font-size: 12px;
    font-weight: 300
}

body .sidebar .sidebar-block.blogs-bestseller .bestseller-content .bestseller-item .product .product-price .price_sale {
    float: left
}

body .sidebar .sidebar-block.blogs-bestseller .bestseller-content .bestseller-item .product .product-price .price_sale .money {
    font-size: 14px;
    color: #c1151b
}

body .sidebar .sidebar-block.blogs-bestseller .bestseller-content .bestseller-item .product .product-price del.price_compare {
    float: left
}

body .sidebar .sidebar-block.blogs-bestseller .bestseller-content .bestseller-item .product .product-price del.price_compare .money {
    font-size: 12px
}

body .sidebar .sidebar-block.seller-block .sidebar-title {
    margin-bottom: 21px
}

body .sidebar .sidebar-block.seller-block .seller_products_item>div {
    width: 100%
}

body .sidebar .sidebar-block.seller-block .seller_products_item .product {
    margin-bottom: 15px;
    border: 0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    -o-align-items: flex-start;
    align-items: flex-start
}

body .sidebar .sidebar-block.seller-block .seller_products_item .product>div {
    float: left
}

body .sidebar .sidebar-block.seller-block .seller_products_item .product .row-left {
    width: 39%;
    border: 1px solid #ebebeb
}

body .sidebar .sidebar-block.seller-block .seller_products_item .product .row-right {
    width: 61%;
    padding: 0;
    padding-left: 15px;
    text-align: left;
    position: static
}

body .sidebar .sidebar-block.seller-block .seller_products_item .product .row-right .product-price {
    color: #c1151b;
    font-weight: 400
}

body .sidebar .sidebar-block.seller-block .seller_products_item .product .row-right .product-price .price_sale {
    color: #c1151b
}

body .sidebar .sidebar-block.seller-block .seller_products_item .product .row-right .product-price .price_compare {
    float: none
}

@media (max-width: 1199px) {
    body .sidebar .sidebar-block.seller-block .seller_products_item .product .row-right {
        text-align: center;
        padding: 20px
    }
}

body .sidebar .sidebar-block.seller-block .seller_products_item .product .product-title a {

    font-size: 12px
}

body .sidebar .sidebar-block.seller-block .seller_products_item .product .rating-star {
    display: block !important;
    margin-bottom: 5px
}

@media (max-width: 767px) {
    body .sidebar .sidebar-block.seller-block .seller_products_item .product .sale_banner {
        bottom: auto !important;
        top: 10px !important
    }
}

body .sidebar .sidebar-block.seller-block .seller_products_item:last-child .product {
    margin-bottom: 5px
}

@media (max-width: 1199px) {

    body .sidebar .sidebar-block.seller-block .product .row-right,
    body .sidebar .sidebar-block.seller-block .product .row-left {
        width: 100% !important
    }
}

@media (max-width: 767px) {
    body .sidebar .sidebar-block.seller-block .seller-inner {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: start;
        -webkit-align-items: flex-start;
        -moz-align-items: flex-start;
        -ms-align-items: flex-start;
        -o-align-items: flex-start;
        align-items: flex-start;
        -webkit-justify-content: flex-start;
        -ms-justify-content: flex-start;
        justify-content: flex-start
    }

    body .sidebar .sidebar-block.seller-block .seller-inner .seller_products_item {
        width: 25%;
        float: left;
        display: block
    }

    body .sidebar .sidebar-block.seller-block .seller-inner .seller_products_item .product .row-left {
        padding-bottom: 0;
        border-right-width: 0
    }

    body .sidebar .sidebar-block.seller-block .seller-inner .seller_products_item:last-child .product .row-left {
        border-right-width: 1px
    }
}

@media (max-width: 600px) {
    body .sidebar .sidebar-block.seller-block .seller-inner .product {
        margin-bottom: 0
    }

    body .sidebar .sidebar-block.seller-block .seller-inner .seller_products_item {
        width: 50%
    }

    body .sidebar .sidebar-block.seller-block .seller-inner .seller_products_item:nth-of-type(2) .product .row-left {
        border-right-width: 1px
    }
}

@media (max-width: 360px) {
    body .sidebar .sidebar-block.seller-block .seller-inner .product {
        min-height: 320px
    }
}

@media (max-width: 320px) {
    body .sidebar .sidebar-block.seller-block .seller_products_item {
        width: 100% !important
    }

    body .sidebar .sidebar-block.seller-block .seller_products_item .product .row-left {
        border-right-width: 1px !important
    }
}

body .sidebar .sidebar-block.blog-category .sidebar-title,
body .sidebar .sidebar-block.collection-block .sidebar-title {
    margin-bottom: 10px
}

body .sidebar .sidebar-block.blog-category .category li,
body .sidebar .sidebar-block.blog-category .list-cat li,
body .sidebar .sidebar-block.collection-block .category li,
body .sidebar .sidebar-block.collection-block .list-cat li {
    padding: 4px 0
}

body .sidebar .sidebar-block.blog-category .category li>a,
body .sidebar .sidebar-block.blog-category .list-cat li>a,
body .sidebar .sidebar-block.collection-block .category li>a,
body .sidebar .sidebar-block.collection-block .list-cat li>a {
    position: relative;
    /*text-transform: capitalize;*/
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0px;

    color: #333
}

body .sidebar .sidebar-block.banner-block {
    width: 100%;
    float: left
}

body .sidebar .sidebar-block.banner-block>a {
    display: block
}

body .sidebar .sidebar-block.blog-tags .sidebar-title,
body .sidebar .sidebar-block.tags-block .sidebar-title {
    margin-bottom: 24px
}

body .sidebar .sidebar-block:first-child {
    padding-top: 25px
}

body .sidebar .sidebar-title {
    text-transform: uppercase;
    font-weight: 400;

    color: #222;
    font-size: 16px;
    margin-bottom: 15px;
    letter-spacing: 0px;
    position: relative
}

body .sidebar .sidebar-title>span {
    background-color: #fff;
    padding-right: 15px;
    z-index: 1;
    position: relative
}

body .sidebar .sidebar-title:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ebebeb
}

body .sidebar .sidebar-content ul {
    list-style: none
}

body .sidebar .sidebar-content ul li>a {
    color: #323232
}

body .sidebar .sidebar-content ul li>a:hover {
    color: #c1151b
}

body .sidebar .blogs-recent {
    padding-bottom: 32px
}

body .sidebar .blogs-recent .sidebar-title {
    margin-bottom: 20px
}

body .sidebar .blogs-recent .ra-item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px
}

body .sidebar .blogs-recent .ra-item:last-child {
    margin-bottom: 0px
}

body .sidebar .blogs-recent .ra-item .article-top {
    margin-bottom: 13px
}

body .sidebar .blogs-recent .ra-item .article-bottom a {
    font-size: 15px;

    font-style: italic;
    color: #464646;
    line-height: 1.7em;
    font-weight: 400
}

body .sidebar .blogs-recent .article {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px
}

body .sidebar .blogs-recent .article-image {
    width: 40%;
    margin-bottom: 0
}

body .sidebar .blogs-recent .article-image:hover>a:after {
    font-size: 12px;
    width: 30px;
    height: 30px;
    line-height: 30px
}

body .sidebar .blogs-recent .articleinfo_group {
    width: 60%;
    padding-left: 20px
}

body .sidebar .blogs-recent .articleinfo_group .article-title {
    margin-top: -5px;
    margin-bottom: 5px
}

body .sidebar .blogs-recent .articleinfo_group .article-title .article-name a {
    font-size: 14px;
    font-weight: 400
}

body .sidebar .blogs-recent .articleinfo_group .article-comment {
    display: none
}

body .sidebar .blogs-recent .articleinfo_group .article-info {
    margin-bottom: 0
}

body .sidebar.collection-leftsidebar {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    font-size: 14px;
    margin-top: 0px
}

@media (max-width: 767px) {
    body .sidebar.collection-leftsidebar {
        margin-top: 0px;
        width: 100%
    }

    body .sidebar.collection-leftsidebar .banner-block,
    body .sidebar.collection-leftsidebar .sidebar-content {
        display: none
    }

    body .sidebar.collection-leftsidebar .list-cat {
        max-width: 300px;
        padding: 20px
    }

    body .sidebar.collection-leftsidebar .sidebar-block {
        padding-top: 15px;
        padding-bottom: 0
    }

    body .sidebar.collection-leftsidebar .sidebar-block:last-child {
        padding-bottom: 15px
    }

    body .sidebar.collection-leftsidebar .sidebar-block.filter-block .filter-title {
        font-size: 12px
    }

    body .sidebar.collection-leftsidebar .sidebar-title {
        margin-bottom: 1px
    }

    body .sidebar.collection-leftsidebar .sidebar-title:before {
        display: none
    }

    body .sidebar.collection-leftsidebar .sidebar-title span {
        background-color: transparent
    }
}

@media (min-width: 768px) {
    body .sidebar .sidebar-title .fa {
        display: none
    }

    body .sidebar .filter-tag-group .title .show_filter_content {
        display: none
    }
}

@media (max-width: 767px) {
    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-title {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 0;
        border: 1px solid #ebebeb;
        height: 40px;
        line-height: 40px
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-title>span {
        padding-bottom: 0;
        font-size: 15px;
        display: inline-block;
        *display: inline;
        zoom: 1
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-title>span:before {
        display: none
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-title .fa {
        position: absolute;
        right: 0px;
        top: 50%;
        text-align: center;
        width: 30px;
        height: 30px;
        line-height: 30px;
        margin-top: -15px;
        font-size: 14px
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-title.active {
        background-color: #c1151b;
        border: 1px solid #c1151b
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-title.active>span {
        color: #fff
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-title.active .fa {
        color: #fff
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .list-cat {
        width: 100%;
        max-width: 100%
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-content {
        border: 1px solid #ebebeb;
        border-top: 0
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-content .filter-tag-group {
        padding: 20px
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-content .filter-tag-group .tag-group {
        margin-bottom: 0px
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-content .filter-tag-group .tag-group .title {
        margin: 0 0 !important;
        position: relative
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-content .filter-tag-group .tag-group .title .show_filter_content {
        position: absolute;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        right: 0px;
        top: 50%;
        margin-top: -10px;
        padding-right: 0
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-content .seller-inner {
        padding: 20px
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-content .seller-inner .seller_products_item .product {
        background-color: transparent;
        border-width: 1px;
        margin-bottom: 0;
        border-right-width: 0
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-content .seller-inner .seller_products_item:last-child .product {
        border-right-width: 1px
    }

    body .sidebar .sidebar-block:not(.blogs-recent):not(.blog-category):not(.blog-tags) .sidebar-content .tags-inner {
        padding: 20px
    }

    body .sidebar .sidebar-block.blogs-bestseller .sidebar-title {
        padding-left: 0 !important;
        border: 0 !important
    }

    body .sidebar .sidebar-block.blogs-bestseller .sidebar-content {
        border: 0 !important
    }
}

@media (max-width: 600px) {
    body .sidebar .sidebar-content .seller_products_item:nth-of-type(even) .product {
        border-right-width: 1px !important
    }

    body .sidebar .sidebar-content .seller_products_item:nth-of-type(1) .product,
    body .sidebar .sidebar-content .seller_products_item:nth-of-type(2) .product {
        border-bottom-width: 0px !important
    }
}

@media (max-width: 320px) {

    body .sidebar.blog-tags,
    body .sidebar.blogs-recent,
    body .sidebar.blog-category {
        max-width: 100%
    }
}

.filter_c {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    clear: both;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
}

.filter_c span,
.filter_c div,
.filter_c select {
    display: inline-block;
    vertical-align: middle;
}

.filter_c span {
    margin-right: 10px;
}

.filter_c .filter-box {
    margin-left: 5px;
    margin-right: 5px;
}

.filter_c .filter-box .select_box select {
    height: 37px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid rgba(0, 179, 183, 0.2);
}

.price-box .to {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    display: block;
}

.brand-box .sidebar-content li {
    padding-bottom: 15px;
}

.brand-box .sidebar-content .filter-brand {
    float: right;
}

.product .product-title a.title-prod-grid {
    font-size: 1.35rem;
    min-height: 65px;
}

.background-grey-shop .content_product {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}

body .list-collection-content.background-grey-shop .product {
    margin: 0;
}

.background-grey-shop .content_product .hoverBorderWrapper img {
    min-height: 150px;
    max-height: 150px;
    max-width: 160px;
    padding: 10px;
    /* height: 300px; */
}

.page_c {
    background-color: #FFF;
    padding: 10px;
    margin-bottom: 30px;
}

.page_c a,
.page_c ul,
.page_c li,
.page_c span {
    display: inline-block;
    margin: 3px;
    transition: all 0.2s ease-out;
}

.page_c ul>li,
.page_c>a {
    padding: 5px 10px;
}

.page_c ul>li:hover,
.page_c>a:hover {
    background-color: #c1151b;
    color: #FFF !important;
    transition: all 0.2s ease-in;
}

.page_c ul>li:hover>a {
    color: #FFF !important;
}

.page_c ul>li.active,
.page_c>a.active {
    background-color: #c1151b;
    color: #FFF !important;
    font-weight: bold;
    transition: all 0.2s ease-in;
}

.page_c ul>li.active>a {
    color: #FFF !important;
}

.sidebar-content ul.list-cat>li.active,
.sidebar-content ul.list-cat>li.active>a {
    font-weight: bold !important;
}

.container-img-zoom-2x {
    position: relative;
    overflow: hidden;
}

.container-img-zoom-2x .img-zoom-2x {
    transform: scale(1.2);
}

.services1 {
    .services-box {
        min-height: 126px;

        .media {
            .media-body {
                h4 {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.form-search-mobile {
    height: 100%;
}
